/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "react-final-form"
import { CustomSelect } from "@elevate_security/elevate-component-library"
import { useDispatch } from "react-redux"
import { FieldContainer, InputContainer, InputText } from "../styles"
import { updateForm } from "../../../../../../../sagas/configuratorSaga/reducer"
import FieldExampleLink from "../../../FieldExampleLink"
import FieldLabel from "../../../FieldLabel"
import { darklyGetFlag } from "../../../../../../../utils/utils"

const Dropdown = (props) => {
  const dispatch = useDispatch()
  const [isSelected, setIsSelected] = useState(false)
  const { control, form, isToggleGroups } = props
  const {
    name,
    type,
    value,
    options,
    example,
    parent,
    props: controlProps,
    propagateChange,
    hidden,
    showOptionWithFeatureFlag,
    language_source
  } = control
  const { disabled } = controlProps || {}

  const languageState = language_source ? form.getFieldState(language_source) : null
  const [languageListener, setLanguageListener] = useState(null)
  const [language, setLanguage] = useState(languageState ? languageState.value : null)
  // dropdown value after change initial value for language_source dropdown
  const [selectedValue, setSelectedValue] = useState("")

  useEffect(() => {
    if (language_source) {
      // selected dropdown value after changing the language
      setLanguageListener(
        form.subscribe(
          ({ values }) => {
            if (values[language_source] !== language) {
              setLanguage(values[language_source])
              // getting value base on language
              const fieldValue = control[`value_${values[language_source]}`]
              setSelectedValue(fieldValue || "")
              form.change(name, fieldValue)
            }
          },
          { values: true }
        )
      )
    }
    return () => languageListener && languageListener()
  })

  const geDefaultValue = () => {
    // getting language_source dropdown value
    if (selectedValue.length) {
      return selectedValue
    }

    // getting language_source dropdown initial value
    if (language_source) {
      const valueKey = `value_${language || "en_US"}`

      if (valueKey in control && control[valueKey]) {
        setSelectedValue(control[valueKey])
        return control[valueKey]
      }
    }

    if (name === "show_score_breakdown_most_to_least_risky") {
      // return boolean value as string to show selected value in
      return value?.toString() || ""
    }

    // if dropdown has no language_source
    return value || formValues[name] || options[0].value
  }

  const handleChange = ({ value: controlValue }, input) => {
    if (propagateChange && value !== controlValue) {
      if (language_source) {
        setSelectedValue(controlValue)
      }

      dispatch(
        updateForm({
          [name]: controlValue
        })
      )
    }

    input.onChange(controlValue)
  }

  const formValues = form?.getState()?.values || {}

  const getReadOnlyLabel = () => {
    if (language_source) {
      return formValues[name]
    }

    let initialValue = value

    if (name === "show_score_breakdown_most_to_least_risky") {
      // return boolean value as string to show selected value in dropdown
      initialValue = value?.toString() || ""
    }

    return (options?.find((option) => option.value === (initialValue || options[0]?.value)) || {})
      ?.label
  }

  const realValue = geDefaultValue()

  /**
   * If `showOptionWithFeatureFlag` is enabled and option has `featureFlag`
   * then show/hide option based on the value of feature flag
   */
  const filteredOptions = showOptionWithFeatureFlag
    ? options.filter((option) => !option.featureFlag || darklyGetFlag(option.featureFlag))
    : options

  useEffect(() => {
    if (isToggleGroups && parent) {
      setIsSelected(!formValues[parent])
    }
  }, [formValues])
  return (
    <Field name={name} type={type} initialValue={realValue}>
      {({ input }) => {
        input.onChange(realValue)
        return (
          <FieldContainer hidden={hidden}>
            <FieldLabel control={control} />
            <InputContainer>
              {!disabled && (
                <CustomSelect
                  {...input}
                  options={filteredOptions}
                  defaultValue={realValue}
                  disabled={isSelected}
                  onChange={(data) => handleChange(data, input)}
                />
              )}
              {disabled && <InputText>{getReadOnlyLabel()}</InputText>}
            </InputContainer>
            {!disabled && example !== null && <FieldExampleLink control={control} />}
          </FieldContainer>
        )
      }}
    </Field>
  )
}

Dropdown.propTypes = {
  control: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool,
    helpText: PropTypes.string,
    propagateChange: PropTypes.bool,
    example: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string
    }),
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string
      })
    ),
    props: PropTypes.shape({
      required: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired,
  isToggleGroups: PropTypes.bool
}

Dropdown.defaultProps = {
  isToggleGroups: false
}

export default Dropdown
