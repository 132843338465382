import React from "react"
import PropTypes from "prop-types"
import { SkeletonWrapper } from "./style"

export const SkeletonLoader = ({
  width,
  height = 15,
  align = "center",
  marginTop = 0,
  marginLeft = 0,
  marginRight = 0,
  marginBottom = 0,
  circle = false
}) => (
  <SkeletonWrapper
    justifyContent={align}
    margin={`${marginTop}px ${marginRight}px ${marginBottom}px ${marginLeft}px`}
    width={width ? `${width}px` : "100%"}
    height={`${height}px`}
    borderRadious={circle ? "50%" : "4px"}
  />
)

SkeletonLoader.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  align: PropTypes.string,
  marginTop: PropTypes.number,
  marginLeft: PropTypes.number,
  marginRight: PropTypes.number,
  marginBottom: PropTypes.number,
  circle: PropTypes.bool
}

SkeletonLoader.defaultProps = {
  height: 15,
  align: "center",
  marginTop: 0,
  marginLeft: 0,
  marginRight: 0,
  marginBottom: 0,
  circle: false
}
