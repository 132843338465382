import React from "react"

export default [
  {
    primaryButton: "Next",
    placement: "right",
    body: (
      <>
        <b>Create and configure</b>
        {" a new Pulse template by starting here."}
      </>
    )
  },
  {
    secondaryButton: "Previous",
    primaryButton: "Next",
    placement: "right",
    body: (
      <>
        <b>Edit templates</b>
        {" by clicking on the template title."}
      </>
    )
  },
  {
    secondaryButton: "Previous",
    primaryButton: "Next",
    placement: "right",
    body: (
      <>
        <b>Default templates</b>
        {
          " are a set of global configurations that can be re-used and re-configured when creating new templates."
        }
      </>
    )
  },
  {
    secondaryButton: "Previous",
    primaryButton: "Done",
    placement: "left",
    body: (
      <>
        <b>Use the quick actions</b>
        {
          " to preview, duplicate, edit, or delete a template. Only custom templates can be deleted."
        }
      </>
    )
  }
]
