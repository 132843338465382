import React from "react"
import PropTypes from "prop-types"

import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import { Wrapper, Navigation, Arrow, PageNumbersContent, PageNumber } from "./styles"
import { generateArrayOfPageRangeWithDots } from "./utils"

const Paginator = ({ currentPage, onNavigate, pages }) => {
  const previousPage = currentPage - 1
  const nextPage = currentPage + 1

  const renderButtonPagination = (numberPage) =>
    numberPage !== "..." ? (
      <PageNumber
        key={numberPage}
        title={`Navigation to page ${numberPage}`}
        onClick={() => onNavigate(numberPage - 1)}
        disabled={currentPage === numberPage - 1}
      >
        {numberPage}
      </PageNumber>
    ) : (
      <span key={Math.random()} className="ellipsis">
        {numberPage}
      </span>
    )

  /**
   * The renderPageNumbers it's responsible by to render the
   * number buttons of the table
   * @returns {[JSX.Element]}
   */
  const renderPageNumbers = () => {
    if (!pages || pages === 0) return null
    const numbers = []
    const pageRange = generateArrayOfPageRangeWithDots(pages, currentPage)
    for (let index = 0; index < pageRange.length; index += 1) {
      const numberPage = pageRange[index]
      numbers.push(renderButtonPagination(numberPage))
    }
    return numbers
  }

  return (
    <Wrapper className="table-navigation">
      <Navigation>
        <Arrow onClick={() => onNavigate(previousPage)} disabled={currentPage === 0}>
          <Icon name="CaretLeft" />
        </Arrow>
        <PageNumbersContent>{renderPageNumbers()}</PageNumbersContent>
        <Arrow onClick={() => onNavigate(nextPage)} disabled={nextPage === pages}>
          <Icon name="CaretRight" />
        </Arrow>
      </Navigation>
    </Wrapper>
  )
}

Paginator.propTypes = {
  currentPage: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onNavigate: PropTypes.func.isRequired
}

export default Paginator
