/* eslint-disable react/forbid-prop-types,react/no-array-index-key */
import React, { Component } from "react"
import {
  CustomSelect,
  Input,
  Typography,
  Icon,
  Button,
  RadioButton,
  Toggle,
  Tooltip
} from "@elevate_security/elevate-component-library"
import { Form as FinalForm, Field } from "react-final-form"
import PropTypes from "prop-types"
import styled from "styled-components"

import DateInput from "../CustomDateInput"
import SelectWithTextInput from "../SelectWithTextInput"
import theme from "../../css/theme"
import { StyledAsyncTypeAhead } from "../StyledAsyncTypeAhead"
import GroupsMenu from "../GroupsMenu"

const { Small, Text, H2 } = Typography
const { colors } = theme

const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
`
const DateContainer = styled.div`
  position: relative;
`
const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 500px;

  & > div:last-child {
    min-height: 30px;
    position: relative;
    small {
      position: absolute;
      top: 0;
      min-width: 350px;
    }
  }

  .upload-btn-wrapper {
    position: relative;
    overflow: hidden;
    display: flex;
  }
  .upload-btn-wrapper input[type="file"] {
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
  }
  .upload-btn-wrapper button {
    margin-left: 25px;
  }
`
const Textarea = styled.textarea`
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid rgba(189, 197, 209, 0.5);
`
const ToggleWrapper = styled.div`
  margin-top: ${(props) => `${props.fieldName === "sendAllEmails" ? "16px" : "28px"}`};
  margin-left: ${(props) => `${props.fieldName === "sendAllEmails" ? "20px" : "0px"}`};
  display: flex;
  [class^='Togglestyles__LabelText'] {
    padding: 2px 4px 2px 10px;
  }
  span {
    margin-top: 2px;
  }
  svg: hover {
    path {
      stroke: #039BA1; !important;
      fill: #039BA1; !important;
    }
  }
`

class Form extends Component {
  validateForm = () => false

  handleChange = (e, fieldName, fieldType) => {
    const { handleChange } = this.props
    if (handleChange) {
      handleChange(e, fieldName, fieldType)
    }
  }

  handleBlur = (e, fieldName, fieldType) => {
    const { handleBlur } = this.props
    if (handleBlur) {
      handleBlur(e, fieldName, fieldType)
    }
  }

  handleSearch = (e, fieldName, fieldType) => {
    const { handleSearch } = this.props
    if (handleSearch) {
      handleSearch(e, fieldName, fieldType)
    }
  }

  onSubmit = (e) => {
    e.preventDefault()
    const errors = this.validateForm()

    if (!errors) {
      const { handleSubmit } = this.props
      handleSubmit(e)
    }
  }

  renderFormElement = (fieldType, fieldName, fieldProps) => {
    switch (fieldType) {
      case "radio":
        return (
          <Field name={fieldName} required={fieldProps.required}>
            {() => (
              <RadioButton
                data-id={fieldName}
                {...fieldProps}
                vertical
                onChange={(e) => this.handleChange(e, fieldName, fieldType)}
              />
            )}
          </Field>
        )
      case "date":
        return (
          <Field name={fieldName} required={fieldProps.required}>
            {() => (
              <DateContainer>
                <DateInput
                  {...fieldProps}
                  data-id={fieldName}
                  selected={fieldProps.value}
                  onChange={(date) => this.handleChange(date, fieldName, fieldType)}
                />
                <Icon
                  name={fieldProps.icon}
                  size="sm"
                  stroke={colors.icon.default}
                  fill={colors.icon.default}
                  className="date-picker-icons"
                />
              </DateContainer>
            )}
          </Field>
        )
      case "select-group":
        return (
          <Field name={fieldName} required={fieldProps.required}>
            {/*
           TODO: this HTML select will be replaced
           by CUSTOM-DROPDOWN component when its ready
          */}
            {() => (
              <StyledAsyncTypeAhead
                inputProps={{ name: fieldName, required: fieldProps.required }}
                selectHintOnEnter
                placeholder="Type to search..."
                labelKey="name"
                selected={fieldProps.selected}
                options={fieldProps.options}
                onSearch={(e) => this.handleSearch(e, fieldName, fieldType)}
                onChange={(e) => this.handleChange(e, fieldName, fieldType)}
                labels={["name"]}
                ItemComponent={GroupsMenu}
              />
            )}
          </Field>
        )
      case "select":
        /* eslint-disable no-case-declarations */
        const { senderEmails } = fieldProps
        const senderEmail = senderEmails
          ? senderEmails.find((s) => s.sender_email === fieldProps.selected)
          : null
        const selected = senderEmail && `${senderEmail.sender_name} <${senderEmail.sender_email}>`
        /* eslint-enable no-case-declarations */
        return (
          <CustomSelect
            id="senderEmail"
            style={{ width: "100%" }}
            placeholder="Select Sender Email"
            onChange={(e) => this.handleChange(e, fieldName)}
            defaultValue={selected}
            options={
              fieldProps.senderEmails
                ? fieldProps.senderEmails.map((s) => `${s.sender_name} <${s.sender_email}>`)
                : []
            }
          />
        )
      case "simple-select":
        return (
          <CustomSelect
            id={fieldProps.id}
            style={{ width: "100%" }}
            placeholder={fieldProps.placeholder}
            defaultValue={fieldProps.selected}
            options={fieldProps.options}
            onChange={(e) => this.handleChange(e, fieldName, fieldType)}
            maxHeightDropdown="300px"
          />
        )
      case "heading":
        return <H2>{fieldProps.heading}</H2>
      case "toggle":
        return (
          <ToggleWrapper fieldName={fieldName}>
            <Toggle
              data-id={fieldName}
              onChange={(e) => this.handleChange(e, fieldName, fieldType)}
              checked={fieldProps.value}
              label={fieldProps.label}
            />
            {fieldProps.tooltip && (
              <Tooltip
                body={fieldProps.tooltipText}
                placement="top"
                size="sm"
                theme="light-border"
                readOnly
              >
                <Icon name="InfoCheckOutline" size="xsm" fill="#959DA8" />
              </Tooltip>
            )}
          </ToggleWrapper>
        )
      case "textarea":
        return (
          <Textarea
            data-id={fieldName}
            rows={fieldProps.rows}
            cols={fieldProps.cols}
            onChange={(e) => this.handleChange(e, fieldName, fieldType)}
            value={fieldProps.value}
            onBlur={(e) => this.handleBlur(e, fieldName, fieldType)}
          />
        )
      case "checkbox":
        return (
          <input
            data-id={fieldName}
            type="checkbox"
            className="regular-checkbox"
            checked={fieldProps.value}
            onChange={(e) => this.handleChange(e, fieldName, fieldType)}
          />
        )
      case "upload-img":
        return (
          <div className="upload-btn-wrapper">
            <Input value={fieldProps.value} />
            <input
              data-id={fieldName}
              type={fieldProps.type}
              accept={fieldProps.accept || "image/*"}
              onChange={(e) => this.handleChange(e, fieldName, fieldType)}
            />
            <Button>{fieldProps.btnText || "Upload"}</Button>
          </div>
        )
      case "group-url":
        return (
          <SelectWithTextInput
            {...fieldProps}
            handleChange={(e) => this.handleChange(e, fieldName, fieldType)}
          />
        )
      case "text":
      default:
        return (
          <Field name={fieldName} required={fieldProps.required} value={fieldProps.value}>
            {() => (
              <Input
                {...fieldProps}
                data-id={fieldName}
                disabled={fieldProps.disabled}
                autoFocus={fieldProps.autoFocus}
                onChange={(e) => this.handleChange(e, fieldName, fieldType)}
                onBlur={(e) => this.handleBlur(e, fieldName, fieldType)}
              />
            )}
          </Field>
        )
    }
  }

  render() {
    const { formFields, addRow, horizontal } = this.props
    return (
      <FinalForm
        onSubmit={this.onSubmit}
        render={() => (
          <form onSubmit={this.onSubmit} id="submit-form">
            <div className={addRow ? "space-between" : null}>
              {formFields.map(
                ({ fieldName, fieldType, fieldProps, helpText, label, preFieldHelpText }, i) =>
                  horizontal ? (
                    <div
                      key={`${label}_${i}`}
                      className={fieldProps.className !== "" ? fieldProps.className : null}
                      hidden={fieldProps.hidden}
                    >
                      <Row>
                        <Text color="500" fontWeight="500" margin="0 2rem 0 0">
                          {label}
                        </Text>
                        {preFieldHelpText || ""}
                        <div className="mt-2 align-right">
                          {!preFieldHelpText &&
                            this.renderFormElement(fieldType, fieldName, fieldProps)}
                        </div>
                        {helpText && (
                          <div>
                            <Small margin="0 0 0 1rem">{helpText}</Small>
                          </div>
                        )}
                      </Row>
                    </div>
                  ) : (
                    <div
                      className={fieldProps.className !== "" ? fieldProps.className : null}
                      key={fieldName}
                      hidden={fieldProps.hidden}
                    >
                      <div className="form-group">
                        <Text color="700" fontWeight="bold">
                          {label}
                        </Text>
                        {preFieldHelpText || ""}
                        <div className="mt-2">
                          {!preFieldHelpText &&
                            this.renderFormElement(fieldType, fieldName, fieldProps)}
                        </div>
                        {helpText && (
                          <div>
                            <Small>{helpText}</Small>
                          </div>
                        )}
                      </div>
                    </div>
                  )
              )}
            </div>
            <HiddenInput type="submit" id="submit-form" />
          </form>
        )}
      />
    )
  }
}

Form.propTypes = {
  addRow: PropTypes.bool,
  horizontal: PropTypes.bool,
  formFields: PropTypes.arrayOf(
    PropTypes.shape({
      helpText: PropTypes.string,
      label: PropTypes.string,
      placeholder: PropTypes.string,
      required: PropTypes.bool,
      type: PropTypes.string,
      value: PropTypes.string
    })
  ),
  handleChange: PropTypes.func,
  handleSearch: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleBlur: PropTypes.func,
  organizations: PropTypes.array
}

Form.defaultProps = {
  horizontal: false,
  addRow: false,
  formFields: [],
  handleChange: null,
  handleSubmit: null,
  handleSearch: null,
  organizations: null,
  handleBlur: null
}

export default Form
