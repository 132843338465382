export const CAMPAIGN_STATUS = {
  ALL: "ALL",
  DRAFT: "DRAFT",
  SCHEDULED: "SCHEDULED",
  COMPLETED: "COMPLETED",
  RUNNING: "RUNNING",
  ERROR: "ERROR"
}

export const CAMPAIGN_STATUS_NAME = {
  ALL: "All",
  DRAFT: "Draft",
  SCHEDULED: "Scheduled",
  COMPLETED: "Completed",
  RUNNING: "Running",
  ERROR: "Error"
}

export const WIZARD_STEPS = {
  first: 1,
  second: 2,
  third: 3
}

export const PULSE_POST_DAYS = ["7 days post-send", "14 days post-send"]

export default {
  CAMPAIGN_STATUS
}

export const TOUR_TEMPLATE_KEY = "TOUR_TEMPLATE_KEY"
export const ASSETS_BASE_URL = "https://hm-assets.elevatesecurity.com/master/pulse/"
