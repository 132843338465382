import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Header, Modal, Typography } from "@elevate_security/elevate-component-library"
import { Form as FinalForm } from "react-final-form"
import { Text as TextControl } from "../../../Template/components/Form/fields"
import ActivityIndicator from "../../../../../components/ActivityIndicator"
import Dropdown from "../../../Template/components/Form/fields/Dropdown"

const { H2 } = Typography

const CreateForm = styled.form`
  position: relative;
  padding-bottom: 12px;

  & > div {
    border-bottom: 0;
    padding: 0;
  }
`
const CreateModalContainer = styled.div`
  [class^="Modalstyles__ModalContainer"] {
    padding: 40px;
  }
`

const CreateModal = ({ mode, templates, active, loading, onCreate, onClose, values }) => {
  const isCreating = mode === "create"

  return (
    <CreateModalContainer>
      <FinalForm
        onSubmit={(e) => onCreate(e)}
        render={(formProps) => (
          <Modal
            key="confirmationModal"
            style={{ maxWidth: "684px" }}
            onClose={onClose}
            header={
              <Header>
                <H2 color="900" fontWeight="bold">
                  {isCreating ? "Enter template info" : "Edit template info"}{" "}
                </H2>
              </Header>
            }
            rightButtons={[
              <Button
                key="yes"
                disabled={
                  loading ||
                  (!Object.values(formProps.touched).includes(false) && formProps.invalid)
                }
                type="submit"
                form="create-config-form"
              >
                {isCreating ? "Create New Template" : "Save"}
              </Button>
            ]}
            isOpen={active}
          >
            <CreateForm id="create-config-form" onSubmit={formProps.handleSubmit}>
              <Dropdown
                form={formProps.form}
                control={{
                  name: "template_id",
                  type: "text",
                  label: "Default Template",
                  value: values.template_id,
                  propagateChange: true,
                  helpText:
                    "Default templates are required to created custom templates as a starting point",
                  options: templates
                    .filter((t) => t.template_type !== "static")
                    .map((t) => ({
                      label: t.label,
                      value: t.template_path.split(".")[0]
                    })),
                  constrains: [
                    {
                      type: "required",
                      message: "Please choose a default template"
                    }
                  ]
                }}
              />
              <br />
              <br />
              <TextControl
                form={formProps.form}
                control={{
                  name: "name",
                  type: "text",
                  label: isCreating ? "New Template Name" : "Template Name",
                  value: values.name,
                  constrains: [
                    {
                      type: "required",
                      message: "Please enter a new template name"
                    }
                  ],
                  placeholder: "Enter new template name"
                }}
              />
              <ActivityIndicator active={loading} />
            </CreateForm>
          </Modal>
        )}
      />
    </CreateModalContainer>
  )
}

CreateModal.propTypes = {
  mode: PropTypes.oneOf(["create", "edit"]),
  active: PropTypes.bool.isRequired,
  templates: PropTypes.arrayOf(PropTypes.any),
  loading: PropTypes.bool,
  onCreate: PropTypes.func,
  onClose: PropTypes.func,
  values: PropTypes.shape({
    template_id: PropTypes.string,
    name: PropTypes.string
  })
}

CreateModal.defaultProps = {
  mode: "create",
  templates: [],
  loading: false,
  onCreate: () => null,
  onClose: () => null,
  values: {
    template_id: null,
    name: null
  }
}

export default CreateModal
