/**
 * This transform adds a few properties useful when building forms.
 *
 * - The name of the field so we dont have to type it in all definitions twice
 * - The immediate parent control OR group
 * - The index of the field, used by field labels
 *
 * @param {Map} group A group map to add the parent to
 * @returns {Map} The updated group
 */
const addMetaProperties = (group) => {
  const controls = group.get("controls")
  let idx = 1
  let sidx = 1
  if (controls) {
    const names = Array.from(controls.keys())
    let subIndexes = false

    names.forEach((name) => {
      const control = controls.get(name)
      const skipIndex = control.get("type") === "language" || control.get("index") === false
      const sStart = control.get("sub_index_start")
      const sEnd = control.get("sub_index_end")
      const customIndex = control.get("custom_index")
      const customSubIndex = control.get("custom_sub_index")

      // If control definition property index is false, keep it false.
      // this hides the label index in the fields rendering
      const index = skipIndex ? false : customIndex || idx
      if (sStart || customSubIndex) {
        subIndexes = true
      }
      const subIndex = skipIndex || !subIndexes ? false : customSubIndex || sidx
      if (sEnd) {
        subIndexes = false
        sidx = 1
      }

      if (!skipIndex && !subIndexes && !customIndex) {
        idx += 1
      }
      if (subIndexes && !skipIndex && !customSubIndex) {
        sidx += 1
      }
      return controls.set(
        name,
        control
          .set("index", index)
          .set("subIndex", subIndex)
          .set("name", name)
          .set("parent", group.get("name"))
      )
    })
  }

  return group
}

export default addMetaProperties
