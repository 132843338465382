import styled from "styled-components"
import { Typography } from "@elevate_security/elevate-component-library"
import theme from "../../css/theme"
import { SpaceBetween } from "../../components/SpaceBetween"

const { colors } = theme

const { Text } = Typography

export const EmptyListMessage = styled(Text)`
  font-size: 17px;

  [class^="DragosaurWithUmbrellastyles"] {
    padding-top: 70px !important;
    span {
      border-bottom: none !important;
      margin-left: 30px;
    }
  }
`
export const HeadingWrapper = styled(Text)`
  h1 {
    line-height: normal;
  }
`
export const Wrapper = styled.div`
  max-height: calc(100vh - 253px);
  overflow-y: scroll;
  overflow-x: hidden;
`

export const DropdownWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 23px;
  border: solid 1px lightgray;
  border-radius: 5px;
  min-width: 480px;
`
export const StyledDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding-right: 40px;
`

export const TitleStatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
`

export const CampaignTitle = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 15px;
  text-decoration: underline;
  font-weight: 600;
  margin-right: 5px;
`

export const ItemStatus = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  border-radius: 40px;
  margin: 0 5px;
  padding: 5px 10px;
  width: auto;
`

export const DateAndTrashContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`

export const DateDiv = styled.div`
  margin-right: 20px;
`
export const ActionsRow = styled.div`
  display: flex;
  gap: 20px;
  width: 75px;
  justify-content: flex-end;
  align-items: center;
`
export const DropdownItem = styled.div`
  width: 100%;
`
export const DropdownTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  z-index: 0;
  width: 100%;
`

export const DropdownItemContent = styled.div`
  display: flex;
  flex-direction: column;
  opacity: ${(props) => (!props.isOpen ? 0 : 1)};
  max-height: ${(props) => (!props.isOpen ? "0px" : "3500px")};
  transition:
    max-height 0.5s ease,
    opacity 0.5s ease;
  [class^="Tabsstyles__TabsHeaderContainer-sc-1x30v42-1"] {
    margin: 0 !important;
  }
`

export const Column = styled.div`
  width: auto;
`
export const DropdownButton = styled.button.attrs({ type: "button" })`
  border: 0;
  background: none;
  padding: 0;
  outline: 0;
  transition: ease-out transform 350ms;
  transform: rotate(${({ isOpen }) => (!isOpen ? "180deg" : "0deg")});
  &:focus,
  &:active {
    outline: 0;
  }
`

export const IsTestMessage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding-top: 5px;
`

export const StrongTitle = styled.span`
  margin-right: 5px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #565d66;
`

export const RecipientsLabel = styled.div`
  width: 205px;
  height: 40px;
  border-radius: 4px;
  margin-top: 16px;
  padding: 7px;
  background-color: #e0eff0;
  text-align: center;
  vertical-align: center;
  font-weight: 700;
  font-size: 14px;
`

export const RecipientsContainer = styled.div`
  display: flex;
  flex: 0 1 150px;
  flex-direction: column;
`

export const RecipientCount = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #565d66;
  margin-bottom: 8px;
`

export const RecipientProgressBar = styled.div`
  display: block;
  height: 4px;
  border-radius: 2px;
  background-color: ${colors.athens_gray};

  &:before {
    display: absolute;
    top: 0;
    content: " ";
    border-radius: 2px ${(props) => (props.progress > 99 ? `2px 2px` : "0px 0px")} 2px;
    background-color: ${colors.surfie_green};
    width: ${(props) => props.progress}%;
    height: 4px;
    display: block;
  }
`

export const RecipientLegend = styled.div`
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  color: ${colors.rolling_stone};
`

export const StyledDropDownOpenContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 10px;
`
export const SearchInputWithFiltersWrapper = styled(SpaceBetween)`
  & [class^="SearchInputstyles"] {
    max-width: 100%;
    input {
      width: 100%;
    }
  }
`
