/* eslint-disable no-undef */
import axios from "../../api"

export function fetchCampaigns(payload) {
  return axios.get(`${REACT_APP_PULSE_API_URL}/recurringcampaigns`, { params: payload })
}

export function fetchCampaignById(id) {
  return axios.get(`${REACT_APP_PULSE_API_URL}/recurringcampaigns/${id}`)
}

export function createCampaign(payload) {
  return axios.post(`${REACT_APP_PULSE_API_URL}/recurringcampaigns`, payload)
}

export function updateCampaign(id, payload) {
  return axios.patch(`${REACT_APP_PULSE_API_URL}/recurringcampaigns/${id}`, payload)
}

export function deleteCampaign(id) {
  return axios.delete(`${REACT_APP_PULSE_API_URL}/recurringcampaigns/${id}`)
}

export function getEmailSubject(id) {
  return axios.get(`${REACT_APP_PULSE_API_URL}/recurringcampaigns/${id}/subject`)
}

export function fetchCampaignsOfRecurringCampaign(id, payload) {
  return axios.get(`${REACT_APP_PULSE_API_URL}/recurringcampaigns/${id}/campaigns`, {
    params: payload
  })
}
