import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useSelector } from "react-redux"
import ListRow from "../ListRow"
import ConfiguratorPageSkeletonLoader from "../../routes/configurator/Configurations/ConfiguratorPageSkeletonLoader"
import { isConfigurationLoading } from "../../sagas/configuratorSaga/selectors"

const List = styled.div`
  display: block;
  min-width: 100%;
  flex-direction: column;
`

const ConfigurationsList = ({ rows, onPreview, onCopy, onDelete }) => {
  const isLoading = useSelector(isConfigurationLoading)

  if (isLoading) {
    return <ConfiguratorPageSkeletonLoader />
  }

  return (
    <List>
      {rows.map((row, index) => {
        if (row.title) {
          return (
            <ListRow
              hasTourTooltips={index === 0}
              row={row}
              onPreview={onPreview}
              onDelete={onDelete}
              onCopy={onCopy}
            />
          )
        }
        return null
      })}
    </List>
  )
}

ConfigurationsList.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object),
  onPreview: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func
}

ConfigurationsList.defaultProps = {
  rows: [],
  onPreview: () => null,
  onDelete: () => null,
  onCopy: () => null
}

export default ConfigurationsList
