import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button } from "@elevate_security/elevate-component-library"
import { FormApi } from "final-form"
import { Field } from "react-final-form"
import { useDispatch, useSelector } from "react-redux"
import widthForm from "../../../../routes/configurator/Template/components/Form/withForm"
import { FieldContainer } from "../../../../routes/configurator/Template/components/Form/fields/styles"
import { getTemplatePreview } from "../../../../sagas/configuratorSaga/reducer"
import { isLoading } from "../../../../sagas/configuratorSaga/selectors"
import { StyledAsyncTypeAhead } from "../../../StyledAsyncTypeAhead"
import GroupsMenu from "../../../GroupsMenu"
import { getEmailListData } from "../../../../sagas/templatesSaga/api"

const FormContainer = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  & > div:first-child {
    flex: 1;
    margin-right: 16px;
  }

  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }

  [class^="FieldContainer"] {
    padding: 0 0;
  }
`

const UserTemplateSelector = ({ formProps, templateId, configurationId }) => {
  const { form } = formProps
  const dispatch = useDispatch()
  const loading = useSelector(isLoading)
  const [emailArray, setEmailArray] = React.useState([])
  const [loadingEmail, setLoadingEmail] = React.useState(false)

  const submit = () => {
    const selectedItem = form.getFieldState("recipient_email")
    const selectedEmail = selectedItem?.value || ""

    dispatch(
      getTemplatePreview({
        type: templateId.split("/")[0],
        recipientEmail: selectedEmail,
        configurationId
      })
    )
  }

  const searchHandler = async (value) => {
    setLoadingEmail(true)
    const searchValue = value?.toLowerCase()?.trim() || ""
    const data = await getEmailListData(searchValue, templateId.split("/")[0])
    setEmailArray(data)
    setLoadingEmail(false)
  }

  const selectedValue = form.getFieldState("recipient_email")?.value || ""

  return (
    <FormContainer onSubmit={formProps.handleSubmit}>
      <Field name={"recipient_email"} required>
        {({ input }) => (
          <StyledAsyncTypeAhead
            inputProps={{ name: "recipient_email", required: true }}
            selectHintOnEnter
            placeholder="Enter user email"
            labelKey="name"
            options={emailArray}
            onSearch={searchHandler}
            onChange={(selectedItems) => input.onChange(selectedItems?.[0]?.name || "")}
            labels={["name"]}
            ItemComponent={GroupsMenu}
            props={{ isLoading: loadingEmail }}
          />
        )}
      </Field>
      <FieldContainer container_styles={{ width: "auto" }}>
        <Button disabled={loading || !selectedValue.length} onClick={() => submit()}>
          Render User Template
        </Button>
      </FieldContainer>
    </FormContainer>
  )
}

UserTemplateSelector.propTypes = {
  templateId: PropTypes.string.isRequired,
  configurationId: PropTypes.string.isRequired,
  formProps: PropTypes.shape({
    form: PropTypes.instanceOf(FormApi).isRequired,
    handleSubmit: PropTypes.func.isRequired
  }).isRequired
}

UserTemplateSelector.defaultProps = {}

export default widthForm(UserTemplateSelector)
