import { TIME_ZONES_INVERTED } from "@elevate_security/elevate-component-library"

export const OCCURRENCE = {
  ASAP: "run_now",
  ONE_TIME: "single_run",
  INTERVAL: "interval",
  DAY: "day",
  WEEK: "week",
  MONTH: "month",
  YEAR: "year"
}

export const humanizeCadenceMap = {
  [OCCURRENCE.ASAP]: "As soon as possible",
  [OCCURRENCE.ONE_TIME]: "Run once",
  [OCCURRENCE.INTERVAL]: "Every",
  [OCCURRENCE.DAY]: "Daily",
  [OCCURRENCE.WEEK]: "Weekly",
  [OCCURRENCE.MONTH]: "Monthly",
  [OCCURRENCE.YEAR]: "Annually"
}

export function convertTo12Hours(hour) {
  const value = {
    hour,
    timePeriod: "AM"
  }
  if (hour > 12) {
    value.hour = `${hour % 12}`
    value.timePeriod = "PM"
  }
  return value
}

export function ordinal_suffix_of(i) {
  const j = i % 10
  const k = i % 100
  if (j == 1 && k != 11) {
    return `${i}st`
  }
  if (j == 2 && k != 12) {
    return `${i}nd`
  }
  if (j == 3 && k != 13) {
    return `${i}rd`
  }
  return `${i}th`
}

export function formatTimezone(timeZoneAbbr, timezone) {
  const letterPattern = /^[a-zA-Z]{3,4}$/

  if (!letterPattern.test(timeZoneAbbr)) {
    return TIME_ZONES_INVERTED[timezone]
  }

  return timeZoneAbbr
}

export function isEmpty(value) {
  return value === undefined || value === null || value === ""
}
