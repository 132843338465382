import styled from "styled-components"

export const TabsContainer = styled.div`
  padding: 24px 0 0 0;
  [class^="Tabsstyles__TabsHeaderContainer"] {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #dde1e7;
    text-transform: capitalize;
    a[role="tab"] {
      opacity: ${(props) => (props.active ? 0.6 : 1)};
    }
    a[role="tab"][aria-selected="true"] {
      color: #0c7d84;
      font-weight: bold;
      font-size: 15px;
      line-height: 32px;
      opacity: 1;

      span {
        color: rgb(29, 30, 31);
        font-weight: 700;
      }
    }
  }
`
export default TabsContainer
