/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import debounce from "lodash/debounce"

import MenuGroup from "./components/MenuGroup"
import { MenuGroups } from "./styles"
import FormManager from "../../../../../sagas/configuratorSaga/form"
import {
  getHiddenGroups,
  getOrderedGroupKeys,
  normalizeTarget,
  toObject
} from "../../../../../sagas/configuratorSaga/helpers/utils"

const Menu = ({ formRef, form }) => {
  const [selectedMenu, setSelectedMenu] = useState("settings")

  useEffect(() => {
    const { current: window } = formRef
    /**
     * This listener is responsible to update the sidebar's
     * selected menu depending upon the scroll position
     */
    const scrollListerner = debounce(() => {
      // conver NodeList to Array
      const targets = Array.prototype.slice.call(
        window.ownerDocument.querySelectorAll(".cfg-block-header")
      )

      if (targets && Array.isArray(targets)) {
        const selectedTarget = targets.find(
          // eslint-disable-next-line no-undef
          (target) => $(target).offset().top > 130
        )

        if (selectedTarget) {
          setSelectedMenu(selectedTarget.dataset.blockName)
        }
      }
    }, 50)

    window.addEventListener("scroll", scrollListerner)

    return () => {
      window.removeEventListener("scroll", scrollListerner)
    }
  }, [])

  /**
   * Scrolls to a given group, the group must have the
   * hash id in the template for it to work.
   *
   * When adding new groups, make sure the top level
   * element for the block in the template has the same
   * id name as the group configuration here in the UI
   * plus the `cfg-` prefix, check normalizeTarget for
   * reference.
   *
   * @param {Map} group THe group to scroll to.
   */
  const handleScrollTo = (group) => {
    const { current: window } = formRef
    const targetName = normalizeTarget(group.get("name"))
    const target = window.ownerDocument.querySelector(targetName)

    if (target) {
      window.scrollTo({
        top: target.offsetTop - 20,
        left: 0,
        behavior: "smooth"
      })
    }
  }

  const handleMenuCLick = (group) => {
    handleScrollTo(group)
  }

  const groups = form.groups()
  const templateId = form.controls.get("template_id").get("value")

  const behaviorsSubMenu = Array.from(groups.get("behaviors").get("controls").values())
    .map(toObject)
    .map(({ name }) => name)

  return (
    <MenuGroups>
      {getOrderedGroupKeys(groups).map((groupName) => {
        // hide certain groups for managers template
        const hiddenGroups = getHiddenGroups(templateId, groupName)
        if (hiddenGroups.includes(groupName)) return null
        if (["group", "section"].indexOf(groups.get(groupName).get("type")) > -1) {
          return (
            <MenuGroup
              selected={selectedMenu}
              behaviorSelected={behaviorsSubMenu.includes(selectedMenu)}
              group={groups.get(groupName)}
              onSelect={handleMenuCLick}
            />
          )
        }
        return null
      })}
    </MenuGroups>
  )
}

Menu.propTypes = {
  formRef: PropTypes.shape({ current: PropTypes.element }).isRequired,
  form: PropTypes.instanceOf(FormManager).isRequired
}

export default Menu
