import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  theme,
  Button,
  Typography,
  Icon,
  Tooltip
} from "@elevate_security/elevate-component-library"
import { FormApi } from "final-form"
import {
  normalizeId,
  toObject,
  getInputdValidationError
} from "../../../../../sagas/configuratorSaga/helpers/utils"
import ActivityIndicator from "../../../../../components/ActivityIndicator"
import Form from "../Form"

export const BlockFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 648px;
  padding: 25px;
  border: 1px solid ${theme.colors.border.default};
  margin-bottom: 20px;
  border-radius: 8px;
  ${({ id }) =>
    id === "cfg-settings"
      ? `.field-label_label {
        margin-bottom: 0;
      }`
      : null}
`

export const BlockFormHeader = styled.div`
  background: white;
  padding: 10px 0 0 0;
  border-bottom: 1px solid ${theme.colors.border.light};
  position: sticky;
  position: -webkit-sticky;
  top: -24px;
  z-index: 10;
  h2 {
    font-weight: bold;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    span:hover {
      cursor: pointer;
    }
  }
  p {
    font-size: 14px;
    color: #565d66;
  }
`

export const BlockFormFooter = styled.div`
  z-index: 9;
  display: flex;
  padding-top: 20px;
  border-top: 1px solid ${theme.colors.border.light};

  & > div:first-child {
    flex: 1;
  }

  & > button:not(:first-child) {
    margin-left: 16px;
  }
  & svg {
    margin-right: 8px;
  }
`

const { H2 } = Typography

const FormBlock = ({
  form,
  loading,
  group,
  editing,
  isNewTemplate,
  showValidations,
  onToggle,
  onSave,
  onLoad
}) => {
  const [backup, setBackup] = useState({})
  const [isEditing, setIsEditing] = useState(null)
  const [showValidationErrors, setShowValidationErrors] = useState(false)

  useEffect(() => {
    const is = editing.find((g) => g.get("name") === group.get("name")) !== undefined
    if (is !== isEditing) {
      setIsEditing(is)
    }
  }, [editing])

  const handleEdit = () => {
    onToggle(true)
    setShowValidationErrors(false)
    setBackup(form.getState().values)
  }

  const handleCancel = () => {
    onToggle(false)
    setShowValidationErrors(false)
    form.reset(backup)
  }

  const handleSave = (values) => {
    const formFields = Array.from(group.get("controls").values()).map(toObject)
    const isValidForm = formFields.reduce(
      (valid, field) =>
        valid && !getInputdValidationError(values[field.name], null, field.required, field.isURL),
      true
    )
    setShowValidationErrors(!isValidForm)

    if (isValidForm) {
      onSave(values)
    }
  }

  return (
    <BlockFormContainer id={normalizeId(group.get("name"))}>
      <BlockFormHeader data-block-name={group.get("name")} className="cfg-block-header">
        {group && (
          <H2 color="700" fontWeight="bolder">
            {group.get("label")}
            {!isEditing && (
              <Tooltip body={"Edit section"} placement="top" readOnly size="sm">
                <div onClick={handleEdit}>
                  <Icon name="Pencil" size="xsm" fill="#959DA8" />
                </div>
              </Tooltip>
            )}
          </H2>
        )}
        {group && <p>{group.get("description")}</p>}
      </BlockFormHeader>
      <Form
        group={group}
        locked={!isEditing}
        onLoad={onLoad}
        showErrors={showValidationErrors || showValidations}
        onSubmit={handleSave}
      />
      {!isNewTemplate && (
        <BlockFormFooter>
          {!isEditing && (
            <Button theme="secondary" onClick={handleEdit}>
              <Icon name="Pencil" size="xsm" fill="#0C7D84" />
              Edit
            </Button>
          )}
          <div> </div>
          {isEditing && (
            <Button theme="ghost" onClick={handleCancel}>
              Cancel
            </Button>
          )}
          {isEditing && (
            <Button theme="primary" onClick={() => form && form.submit()}>
              Save
            </Button>
          )}
        </BlockFormFooter>
      )}
      <ActivityIndicator active={loading} />
    </BlockFormContainer>
  )
}

FormBlock.propTypes = {
  form: PropTypes.instanceOf(FormApi),
  loading: PropTypes.bool,
  editing: PropTypes.arrayOf(PropTypes.instanceOf(Map)),
  isNewTemplate: PropTypes.bool,
  showValidations: PropTypes.bool,
  group: PropTypes.instanceOf(Map).isRequired,
  onToggle: PropTypes.func,
  onSave: PropTypes.func,
  onLoad: PropTypes.func
}

FormBlock.defaultProps = {
  form: null,
  loading: false,
  editing: false,
  isNewTemplate: false,
  showValidations: false,
  onToggle: () => null,
  onSave: () => null,
  onLoad: () => null
}

export default FormBlock
