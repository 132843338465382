/* eslint-disable no-undef */
/* eslint-disable import/prefer-default-export */

export function getCompanyInfo() {
  const info = JSON.parse(window.localStorage.getItem("company"))
  if (!info) return null
  return info
}

export function getDefaultTimezone() {
  const { default_timezone } = getCompanyInfo() || {}
  return default_timezone || "UTC"
}
