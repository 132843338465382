/* eslint-disable react/prop-types */
import React, { useEffect, useRef, useState } from "react"
import { Typography } from "@elevate_security/elevate-component-library"
import { buildGraph } from "./graph"
import { AxisLabel, Row, Column, NoDataMessage } from "./styles"

const { Body } = Typography

export default function ChartLines(props) {
  const { historicalStats, onMouseEnter, pulseDays, hideOpened, hideClicked } = props
  const graphRef = useRef()
  const noData = !Object.keys(historicalStats).length > 0
  const [barType, setBarType] = useState("opened")

  useEffect(() => {
    if (!noData) {
      buildGraph({
        graphRef,
        historicalStats,
        onMouseEnter,
        barType,
        setBarType,
        pulseDays,
        hideOpened,
        hideClicked
      })
    }
  }, [historicalStats, barType, pulseDays, hideOpened, hideClicked])

  return (
    <>
      {!noData && (
        <>
          <Row>
            <Column>
              <AxisLabel
                transform="rotate(180deg)"
                writingMode="vertical-rl"
                textOrientation="mixed"
                padding="0 0 0 16px"
              >
                # of Emails
              </AxisLabel>
            </Column>
            <svg ref={graphRef} />
          </Row>
          <Row>
            <Column width="100%">
              <AxisLabel margin="0  0 0 auto" padding="18px 0 0 0">
                Time
              </AxisLabel>
            </Column>
          </Row>
        </>
      )}
      {noData && (
        <NoDataMessage>
          <Body>No data found.</Body>
        </NoDataMessage>
      )}
    </>
  )
}
