/* eslint-disable import/prefer-default-export */
const select = (state) => state.recurring_campaigns

export const selectIsCampaignsLoading = (store) => select(store).isCampaignsLoading
export const selectCampaignsPage = (store) => select(store).data
export const selectSearchCampaign = (store) => select(store).searchCampaign
export const selectError = (store) => select(store).error
export const selectMetadataTask = (store) => select(store).metadataTask
export const selectCampaignsOfRecurringCampaign = (recurring_campaign_id) => (store) =>
  select(store).recurring_detail[recurring_campaign_id]
