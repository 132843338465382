/* eslint-disable react/forbid-prop-types,import/no-named-as-default */
/* eslint-disable react/destructuring-assignment */

import React, { useState, useEffect, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch } from "react-redux"
import PropTypes from "prop-types"
import { useDebouncedCallback } from "use-debounce"
import { Button, Typography, SearchInput } from "@elevate_security/elevate-component-library"
import {
  getCampaignById,
  resetCampaign,
  getCampaigns,
  setSearchCampaign
} from "../../../sagas/recurringCampaignSaga/reducer"

import { SpaceBetween } from "../../../components/SpaceBetween"
import CampaignWizard from "../wizard"
import { HeadingWrapper, SearchInputWithFiltersWrapper } from "../styles"
import { CampaignList } from "./campaignList"

const { H1 } = Typography

const PAGE_SIZE = 25

const Campaigns = ({ match, base_path }) => {
  const [modalOpen, setModalOpen] = useState(false)
  const [searchValue, setSearchValue] = useState()
  const history = useHistory()
  const dispatch = useDispatch()

  const delayedSearch = useDebouncedCallback(
    (value) => {
      dispatch(setSearchCampaign(value))
    },
    // delay in ms
    1500
  )

  useEffect(() => {
    const { url, params } = match
    const isNewCampaignPath = url.endsWith("/new-campaign")
    if (isNewCampaignPath) {
      setModalOpen(true)
      return
    }
    const isEditCampaignPath = params?.id
    if (isEditCampaignPath) {
      dispatch(getCampaignById(params.id, () => setModalOpen(true)))
      return
    }

    setModalOpen(false)
  }, [match])

  const onClickNewCampaign = useCallback(() => {
    dispatch(resetCampaign())
    history.push(`${base_path}/new-campaign`)
  }, [])

  const closeWizard = useCallback(() => {
    dispatch(getCampaigns(1, PAGE_SIZE))
    history.push(base_path)
  }, [])

  const handleSearch = (value) => {
    setSearchValue(value)
    delayedSearch(value)
  }

  const clearSearch = () => {
    setSearchValue("")
    dispatch(setSearchCampaign(null))
  }

  return (
    <SpaceBetween size="sm">
      <SpaceBetween direction="row" alignItems="center">
        <HeadingWrapper>
          <H1 data-test-id="page-title" className="mt-3" color="700" fontWeight="heavy">
            Campaigns
          </H1>
        </HeadingWrapper>

        <Button type="secondary" className="mt-2" onClick={onClickNewCampaign}>
          {"+ Campaign"}
        </Button>
      </SpaceBetween>

      <SearchInputWithFiltersWrapper
        direction="row"
        justify="flex-start"
        data-test-id="search-input-with-wrapper"
      >
        <SearchInput
          value={searchValue}
          handleSearch={handleSearch}
          placeholder={"Search campaigns"}
          clearSearch={clearSearch}
          inputProps={{
            width: "full"
          }}
        />
      </SearchInputWithFiltersWrapper>

      <CampaignList base_path={base_path} />

      {modalOpen && <CampaignWizard modalOpen={modalOpen} toggleModal={closeWizard} />}
    </SpaceBetween>
  )
}

Campaigns.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    }),
    url: PropTypes.string
  }).isRequired
}

export default Campaigns
