import PropTypes from "prop-types"

export const campaignType = PropTypes.shape({
  id: PropTypes.string,
  wizard_step: PropTypes.number,
  status: PropTypes.string,
  name: PropTypes.string,
  sender_email: PropTypes.string,
  group_id: PropTypes.string,
  group_name: PropTypes.string,
  launch_date: PropTypes.string,
  organization_id: PropTypes.string,
  campaign_type: PropTypes.string,
  language: PropTypes.string,
  is_test: PropTypes.bool
})

export default {
  campaignType
}
