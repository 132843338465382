/* eslint-disable import/prefer-default-export */
import styled from "styled-components"

export const PulseContainer = styled.div``

export const PageContainer = styled.div`
  height: 100%;
  overflow: auto;
  padding: 24px 40px;
`
export const ButtonsBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const ButtonsGroup = styled.div`
  display: flex;
  gap: 18px;
`
