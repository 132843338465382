/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"
import { Field, FormApi } from "react-final-form"

import { resolveValidations } from "../../../../../../../sagas/configuratorSaga/validations"
import FieldError from "../../../../../../../components/FieldError"
import { FieldContainer, InputText, InputContainer } from "../styles"
import FieldExampleLink from "../../../FieldExampleLink"
import { ControlProps } from "../../types"
import FieldLabel from "../../../FieldLabel"

/**
 * Multi language Date Input
 *
 * This input will listen for changes to it's
 * "language_source" sibling input, a control in
 * the same group of type "language", it will use
 * the language value of this control to switch
 * it's contents to the right language.
 *
 * @param form
 * @param control
 * @returns {JSX.Element}
 * @constructor
 */
const DateInput = ({ form, control }) => {
  const {
    name,
    type,
    language_source,
    value,
    constrains,
    suppressFeedback,
    separator,
    example,
    defaultValue,
    props: controlProps,
    hidden
  } = control
  const { disabled } = controlProps || {}
  const languageState = language_source ? form.getFieldState(language_source) : null
  const [languageListener, setLanguageListener] = useState(null)
  const [language, setLanguage] = useState(languageState ? languageState.value : null)

  useEffect(() => {
    if (language_source) {
      setLanguageListener(
        form.subscribe(
          ({ values }) => {
            if (values[language_source] !== language) {
              setLanguage(values[language_source])
              form.change(name, control[`value_${values[language_source]}`])
            }
          },
          { values: true }
        )
      )
    }
    return () => languageListener && languageListener()
  })

  const geDefaultValue = () => {
    if (language_source) {
      if (language) {
        const valueKey = `value_${language || "en_US"}`

        if (valueKey in control && control[valueKey]) {
          return control[valueKey]
        }

        if (defaultValue && language && language in defaultValue) {
          return defaultValue[valueKey]
        }
      }
    }

    return value
  }

  const invalidProp = ({ invalid }) => (invalid ? { invalid } : {})

  return (
    <Field
      name={name}
      type={type}
      initialValue={geDefaultValue()}
      validate={resolveValidations(constrains)}
    >
      {({ input, meta }) => (
        <FieldContainer separator={separator} hidden={hidden}>
          <FieldLabel control={control} />
          <InputContainer>
            {!disabled && (
              <DatePicker
                {...(controlProps || {})}
                {...input}
                {...invalidProp(meta)}
                disabled={disabled}
                showTimeSelect={false}
                showTimeSelectOnly={false}
                placeholderText="MM/DD/YYYY"
                onChange={(e) => input.onChange(e ? new Date(e).toISOString().split("T")[0] : null)}
                format="MM/dd/yyyy"
                selected={input.value ? new Date(input.value) : null}
              />
            )}
            {disabled && <InputText>{input.value || geDefaultValue() || "N/A"}</InputText>}
          </InputContainer>
          {!suppressFeedback && <FieldError {...meta} />}
          {!disabled && example !== null && <FieldExampleLink control={control} />}
        </FieldContainer>
      )}
    </Field>
  )
}

DateInput.propTypes = {
  form: PropTypes.instanceOf(FormApi).isRequired,
  control: ControlProps.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func)
}

DateInput.defaultProps = {
  validations: []
}

export default DateInput
