import styled from "styled-components"

// eslint-disable-next-line import/prefer-default-export
export const TooltipText = styled.div`
  all: initial;
  font-style: normal !important;
  font-size: 13px !important;
  line-height: 16px !important;
  color: #565d66 !important;

  span {
    font-weight: 400 !important;
  }
`
