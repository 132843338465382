import React from "react"
import { Form as FinalForm } from "react-final-form"

const widthForm =
  (Component) =>
  ({ onSubmit, ...otherProps }) => (
    <FinalForm
      onSubmit={(values) => typeof onSubmit === "function" && onSubmit(values, otherProps.group)}
      render={(props) => <Component {...otherProps} formProps={props} />}
    />
  )

export default widthForm
