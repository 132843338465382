import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const ConfiguratorPage = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow: ${(props) => (props.scroll ? "auto" : "hidden")};
  height: 100%;
  margin: 0;
  position: relative;
  padding: 24px 40px;

  .menu-enter {
    display: block;
    position: absolute;
    opacity: 0;
    left: -30px;
  }
  .menu-enter-active {
    position: absolute;
    opacity: 1;
    transition:
      left 200ms ease-in,
      opacity 400ms;
    left: 0;
  }
  .menu-enter-done {
    display: block;
  }
  .menu-exit {
    opacity: 1;
    left: 0;
  }
  .menu-exit-active {
    position: absolute;
    opacity: 0;
    left: -50px;
    transition:
      left 300ms ease-out,
      opacity 400ms;
  }
  .menu-exit-done {
    position: absolute;
    display: none;
  }
`

export const ConfiguratorContainer = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  ${(props) => (props.scroll ? "overflow-y: auto; overflow-x: hidden;" : null)};
  ${(props) => (props.scroll ? "height: 100%;" : null)};
`

export const ConfiguratorMain = styled.div`
  display: block;
  flex-direction: column;
  min-width: 642px;
  min-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  flex: 1 1 auto;
  padding-top: 24px;
  padding-left: 24px;
  padding-bottom: 400px;
`

export const ConfigurationHeader = styled.div`
  padding-bottom: 24px;
  border-bottom: 1px solid ${theme.colors.border.light};

  h1 {
    margin-bottom: 16px;

    & > span {
      display: inline-block;
      margin-right: 10px;
    }

    & > svg {
      position: relative;
      top: -4px;
    }
  }
`

export const ErrorWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  > small {
    margin-left: 5px;
    margin-top: 0;
    font-size: 13px !important;
  }
`
