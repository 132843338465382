/* eslint-disable no-undef */
/* eslint-disable react/forbid-prop-types */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import styled from "styled-components"
import { Typography, theme } from "@elevate_security/elevate-component-library"

import { getEmailTemplates } from "../../../sagas/templatesSaga/reducer"
import { listConfigurations as listConfigurationsActionCreator } from "../../../sagas/configuratorSaga/reducer"
import { campaignType } from "../../../types"
import Form from "../../../components/Form"
import ActivityIndicator from "../../../components/ActivityIndicator"
import SendTestEmail from "./SendTestEmail"
import {
  darklyGetFlag,
  getLanguagesOptions,
  getLanguagesOptionsReverted,
  getTemplateId
} from "../../../utils/utils"
import { getConfigurations } from "../../../sagas/configuratorSaga/selectors"
import { CAMPAIGN_TYPES_MAPPING } from "../../../sagas/configuratorSaga/constants"

const { Small } = Typography

const Badge = styled.div`
  background-color: rgb(12, 125, 132);
  color: rgb(255, 255, 255);
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  border-radius: 6px;
  text-transform: capitalize;
`
const DefaultBadge = styled(Small).attrs({
  fontWeight: "normal"
})`
  color: ${theme.text.color.default};
  font-size: 13px;
  height: 24px;
  line-height: 24px;
  padding: 0 12px;
  text-align: center;
  display: inline-block;
  margin-left: 8px;
  border-radius: 35px;
  background-color: ${theme.colors.bg.gray};
  margin-top: 2px;
`

/* eslint-disable-next-line react/prop-types */
const CustomOption = ({ label, type }) => (
  <div style={{ display: "flex", justifyContent: "space-between" }}>
    <span>{label}</span>
    <Badge>{`${type} Audience`}</Badge>
  </div>
)

export const FORM_FIELDS = [
  {
    fieldType: "simple-select",
    fieldName: "templateIndex",
    label: "Campaign Template",
    showLabel: true,
    fieldProps: {
      required: true,
      width: "full",
      placeholder: "Choose template",
      id: "selectTemplate",
      options: []
    }
  },
  {
    fieldName: "sendEmailTemplate",
    fieldProps: {
      required: false
    }
  },
  {
    fieldType: "text",
    fieldName: "campaignName",
    label: "Campaign Name",
    showLabel: true,
    helpText: "",
    fieldProps: {
      required: true,
      width: "full",
      autoFocus: true
    }
  },
  {
    fieldType: "text",
    fieldName: "campaignDescription",
    label: "Campaign Description",
    showLabel: true,
    helpText: "",
    fieldProps: {
      required: false,
      width: "full",
      autoFocus: true
    }
  }
]

const FORM_FIELDS_LANGUAGES = {
  fieldType: "simple-select",
  fieldName: "language",
  label: "Campaign Language",
  showLabel: true,
  helpText: "",
  fieldProps: {
    required: true,
    width: "full",
    placeholder: "Choose language",
    id: "selectLanguage",
    options: []
  }
}

const FORM_FIELDS_TEST_CAMPAIGN = {
  fieldType: "toggle",
  fieldName: "testCampaign",
  fieldProps: {
    required: false,
    label: "Test campaign",
    tooltip: true,
    tooltipText:
      "Flag the campaign as a Test Campaign on the Campaigns page. All members of the Recipient Group will receive a copy of their actual snapshot. To prevent sending snapshots to the Recipient Group, select the 'Send all recipient emails to me' option, which will appear after selecting Test Campaign."
  }
}

const FORM_FIELDS_SEND_ALL_EMAILS = {
  fieldType: "toggle",
  fieldName: "sendAllEmails",
  fieldProps: {
    required: false,
    label: "Send all recipient emails to me",
    tooltip: true,
    tooltipText: (
      <div>
        <div>
          Instead of sending campaign emails to the selected recipient(s), we will send those emails
          to just your inbox so you can see exactly what your recipients would see if this were a
          real campaign.
        </div>
        <br />
        <div>
          <strong>Note:</strong> To avoid cluttering your inbox, when selecting this option for a
          large recipient group, there is a cap of 5% or 50 of the total emails, whichever is
          greater, which will serve as a representative sample and be delivered to your inbox.
        </div>
      </div>
    )
  }
}

const CAMPAIGN_TEMPLATE_INDEX = 0
const SEND_EMAIL_TEMPLATE_INDEX = 1
const CAMPAIGN_NAME_INDEX = 2
const CAMPAIGN_DESCRIPTION_INDEX = 3
const CAMPAIGN_LANGUAGE_INDEX = 4
const TEST_CAMPAIGN_INDEX = 5
const SEND_ALL_EMAILS = 6

export class CampaignInfo extends Component {
  constructor(props) {
    super(props)
    const { campaign, languageOptions } = props
    const revertedOptions = getLanguagesOptionsReverted(languageOptions)
    this.state = {
      campaignName: campaign ? campaign.name : "",
      campaignDescription: campaign ? campaign.description : "",
      language:
        campaign && campaign.language ? revertedOptions[campaign.language] || "" : "English",
      templatesList: [],
      testCampaign: campaign && campaign.is_external_test,
      sendAllEmails: campaign && campaign.is_external_test && campaign.is_test,
      templateIndex: null
    }
    FORM_FIELDS[TEST_CAMPAIGN_INDEX] = FORM_FIELDS_TEST_CAMPAIGN
    const showLanguage = darklyGetFlag("pulse-show-language")

    if (showLanguage) {
      FORM_FIELDS_LANGUAGES["fieldProps"]["options"] = Object.keys(languageOptions)
      FORM_FIELDS[CAMPAIGN_LANGUAGE_INDEX] = FORM_FIELDS_LANGUAGES
    }
  }

  componentDidMount() {
    const { getEmailTemplatesFunc, listConfigurations } = this.props
    getEmailTemplatesFunc()
    listConfigurations()
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { templates, campaign } = nextProps
    const { templatesList } = prevState

    if (!templatesList.length && templates && templates.length) {
      const templatesListFiltered = templates
        .filter(({ name }) => !!name) // filter out the template with empty names
        .map((t) => {
          if (t.default && t.template_id === "employees/default")
            return {
              ...t,
              name: t.name.replace("Employees", "Individuals")
            }
          return t
        })
        .sort((a, b) => a.name.localeCompare(b.name)) // sort by name
        .sort((a) => (a.default ? -1 : 1)) // sort the defaut templates at top

      const templateIndex = campaign
        ? templatesListFiltered.findIndex((t) => t.id === campaign.template_data_id)
        : null

      return {
        templatesList: templatesListFiltered,
        templateIndex: templateIndex === -1 ? null : templateIndex
      }
    }
    return null
  }

  /**
   * This function returns the index of the
   * selected Campaign Template
   * @param {string} templatePath
   */
  getSelectedTemplateIndex(value) {
    const { templatesList } = this.state
    const index =
      templatesList && templatesList.length ? templatesList.findIndex((t) => t.id === value) : null
    return index === -1 ? null : index
  }

  handleChange = (e, fieldName, fieldType) => {
    let fieldValue

    switch (fieldType) {
      case "simple-select":
        if (fieldName === "templateIndex") {
          fieldValue = this.getSelectedTemplateIndex(e.value)
          FORM_FIELDS[CAMPAIGN_TEMPLATE_INDEX].fieldProps.selected = fieldValue
          this.setState({
            templateIndex: fieldValue
          })
        }

        if (fieldName === "language") {
          fieldValue = e.value
          FORM_FIELDS[CAMPAIGN_LANGUAGE_INDEX].fieldProps.selected = fieldValue
          this.setState({
            language: fieldValue
          })
        }
        break
      case "toggle":
        if (fieldName === "testCampaign") {
          fieldValue = e.checked
          this.setState({
            testCampaign: fieldValue
          })
        }
        if (fieldName === "sendAllEmails") {
          fieldValue = e.checked
          this.setState({
            sendAllEmails: fieldValue
          })
        }
        break
      case "text":
      default:
        fieldValue = e
        this.setState({
          [fieldName]: fieldValue
        })
    }
  }

  getCampaignType(selectedTemplate) {
    const campaign_type_candidate = (selectedTemplate.template_id || "").split("/")[0].toUpperCase()

    return CAMPAIGN_TYPES_MAPPING[campaign_type_candidate] || campaign_type_candidate
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { handleSubmit, languageOptions } = this.props
    const {
      campaignName,
      campaignDescription,
      language,
      testCampaign,
      sendAllEmails,
      templateIndex,
      templatesList
    } = this.state
    const selectedTemplate = templateIndex !== null ? templatesList[templateIndex] : {}

    if (campaignName.length) {
      handleSubmit({
        name: campaignName,
        description: campaignDescription,
        language: languageOptions[language],
        is_external_test: testCampaign,
        is_test: testCampaign && sendAllEmails,
        campaign_type: this.getCampaignType(selectedTemplate),
        template: selectedTemplate.template_id,
        template_data_id: selectedTemplate.id
      })
    }
  }

  render() {
    const {
      campaignName,
      campaignDescription,
      language,
      templatesList,
      testCampaign,
      sendAllEmails,
      templateIndex
    } = this.state

    const { languageOptions } = this.props

    const showLanguage = darklyGetFlag("pulse-show-language")
    const selectedTemplate = templateIndex !== null ? templatesList[templateIndex] : {}

    const templateOptions = templatesList.map((t) => ({
      label: t.name,
      value: t.id,
      render: (
        <CustomOption
          label={
            <>
              <span>{t.name}</span>
              {t.default && <DefaultBadge>Default</DefaultBadge>}
            </>
          }
          type={t.template_id ? getTemplateId(t.template_id) : ""}
        />
      )
    }))

    FORM_FIELDS[CAMPAIGN_NAME_INDEX].fieldProps.value = campaignName
    FORM_FIELDS[CAMPAIGN_DESCRIPTION_INDEX].fieldProps.value = campaignDescription

    if (showLanguage) FORM_FIELDS[CAMPAIGN_LANGUAGE_INDEX].fieldProps.selected = language

    FORM_FIELDS[CAMPAIGN_TEMPLATE_INDEX].fieldProps.selected =
      templatesList && templatesList.length === 1 ? 0 : templateIndex

    FORM_FIELDS[CAMPAIGN_TEMPLATE_INDEX].fieldProps.options = templateOptions
    FORM_FIELDS[TEST_CAMPAIGN_INDEX].fieldProps.value = testCampaign

    if (testCampaign && FORM_FIELDS.length === 6) {
      FORM_FIELDS.push(FORM_FIELDS_SEND_ALL_EMAILS)
    }
    if (!testCampaign && FORM_FIELDS.length === 7) {
      FORM_FIELDS.pop()
    }

    if (FORM_FIELDS[SEND_ALL_EMAILS]) {
      FORM_FIELDS[SEND_ALL_EMAILS].fieldProps.value = sendAllEmails
    }

    FORM_FIELDS[SEND_EMAIL_TEMPLATE_INDEX].preFieldHelpText = (
      <span>
        <SendTestEmail
          language={languageOptions[language]}
          template={selectedTemplate.template_id}
          templateType={
            selectedTemplate.template_id
              ? selectedTemplate.template_id.split("/")[0]
              : selectedTemplate.template_type
          }
          configurationId={selectedTemplate.id}
        />
      </span>
    )

    return (
      <>
        {templatesList && templatesList.length ? (
          <Form
            formFields={FORM_FIELDS}
            handleSubmit={this.handleSubmit}
            handleChange={this.handleChange}
          />
        ) : (
          <ActivityIndicator active />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  languageOptions: getLanguagesOptions(),
  templates: getConfigurations(state)
})

const mapDispatchToProps = {
  getEmailTemplatesFunc: getEmailTemplates,
  listConfigurations: listConfigurationsActionCreator
}
CampaignInfo.propTypes = {
  campaign: campaignType,
  handleSubmit: PropTypes.func,
  templates: PropTypes.shape({
    results: PropTypes.string
  }),
  getEmailTemplatesFunc: PropTypes.func,
  listConfigurations: PropTypes.func,
  languageOptions: PropTypes.object
}

CampaignInfo.defaultProps = {
  campaign: null,
  handleSubmit: null,
  templates: null,
  getEmailTemplatesFunc: null,
  listConfigurations: null,
  languageOptions: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignInfo)
