import React from "react"
import PropTypes from "prop-types"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"

import { TooltipText } from "./styles"
import { useTooltipTourDispatch, useTooltipTourState } from "./TooltipTourContext"

const TourTooltip = ({ children, stepNo }) => {
  const { tourStep, tooltips = [] } = useTooltipTourState()
  const { onStepChange } = useTooltipTourDispatch()

  const tooltip = (tooltips || [])[stepNo - 1]
  const {
    primaryButton = "Next",
    placement = "bottom",
    secondaryButton = "Previous",
    close = -1,
    body
  } = tooltip || {}

  const handlePrimaryAction = () => onStepChange(stepNo + 1)
  const handleSecondayAction = () => onStepChange(stepNo - 1)
  const handleCloseEvent = () => onStepChange(close)

  return (
    <Tooltip
      showCloseIcon
      placement={placement}
      trigger="manual"
      theme="light-border"
      distance={14}
      hideOnClick={false}
      stepNo={stepNo}
      totalSteps={tooltips.length}
      body={<TooltipText>{body}</TooltipText>}
      primaryButton={primaryButton}
      secondaryButton={secondaryButton}
      visible={tourStep === stepNo}
      onPrimaryAction={handlePrimaryAction}
      onSecondaryAction={handleSecondayAction}
      onCloseEvent={handleCloseEvent}
    >
      {children}
    </Tooltip>
  )
}

TourTooltip.propTypes = {
  stepNo: PropTypes.number.isRequired,
  children: PropTypes.node.isRequired
}

export default TourTooltip
