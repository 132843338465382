export const LIST_CONFIGURATIONS = "LIST_CONFIGURATIONS"
export const LIST_CONFIGURATIONS_SUCCESS = "LIST_CONFIGURATIONS_SUCCESS"
export const LIST_CONFIGURATIONS_FAILED = "LIST_CONFIGURATIONS_FAILED"

export const GET_GROUPS = "GET_GROUPS"
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS"
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED"

export const RESET_CONFIGURATION = "RESET_CONFIGURATION"
export const GET_CONFIGURATION = "GET_CONFIGURATION"
export const GET_CONFIGURATION_SUCCESS = "GET_CONFIGURATION_SUCCESS"
export const GET_CONFIGURATION_FAILED = "GET_CONFIGURATION_FAILED"

export const GET_SCORE_CONFIGURATION = "GET_SCORE_CONFIGURATION"
export const GET_SCORE_CONFIGURATION_SUCCESS = "GET_SCORE_CONFIGURATION_SUCCESS"
export const GET_SCORE_CONFIGURATION_FAILED = "GET_SCORE_CONFIGURATION_FAILED"

export const CREATE_CONFIGURATION = "CREATE_CONFIGURATION"
export const CREATE_CONFIGURATION_SUCCESS = "CREATE_CONFIGURATION_SUCCESS"
export const CREATE_CONFIGURATION_FAILED = "CREATE_CONFIGURATION_FAILED"

export const PATCH_CONFIGURATION = "PATCH_CONFIGURATION"
export const PATCH_CONFIGURATION_SUCCESS = "PATCH_CONFIGURATION_SUCCESS"
export const PATCH_CONFIGURATION_FAILED = "PATCH_CONFIGURATION_FAILED"

export const DELETE_CONFIGURATION = "DELETE_CONFIGURATION"
export const DELETE_CONFIGURATION_SUCCESS = "DELETE_CONFIGURATION_SUCCESS"
export const DELETE_CONFIGURATION_FAILED = "DELETE_CONFIGURATION_FAILED"

export const COPY_CONFIGURATION = "COPY_CONFIGURATION"
export const COPY_CONFIGURATION_SUCCESS = "COPY_CONFIGURATION_SUCCESS"
export const COPY_CONFIGURATION_FAILED = "COPY_CONFIGURATION_FAILED"

export const GET_TEMPLATE_PREVIEW = "GET_TEMPLATE_PREVIEW"
export const GET_TEMPLATE_PREVIEW_SUCCESS = "GET_TEMPLATE_PREVIEW_SUCCESS"
export const GET_TEMPLATE_PREVIEW_FAILED = "GET_TEMPLATE_PREVIEW_FAILED"

export const SET_PREVIEW_CONFIGURATION_ID = "SET_PREVIEW_CONFIGURATION_ID"
export const SET_PREVIEW_TEMPLATE_ID = "SET_PREVIEW_TEMPLATE_ID"
export const SET_PREVIEW_STATE = "SET_PREVIEW_STATE"
export const SET_PREVIEW_LANGUAGE = "SET_PREVIEW_LANGUAGE"
export const SET_PREVIEW_REF = "SET_PREVIEW_REF"
export const SET_CURRENT_SCROLL_POSITION = "SET_CURRENT_SCROLL_POSITION"

export const TOGGLE_CREATE_CONFIGURATION_FORM = "TOGGLE_CREATE_CONFIGURATION_FORM"
export const TRACK_READ_ONLY_CONFIGURATION_BLOCK = "TRACK_READ_ONLY_CONFIGURATION_BLOCKS"

export const SEND_EMAIL = "SEND_EMAIL"
export const SEND_EMAIL_SUCCESS = "SEND_EMAIL_SUCCESS"
export const SEND_EMAIL_FAILED = "SEND_EMAIL_FAILED"
export const SEND_EMAIL_RESET_STATE = "SEND_EMAIL_RESET_STATE"

export const UPDATE_CONFIGURATOR_FORM = "UPDATE_CONFIGURATOR_FORM"
export const SET_LANGUAGES_OPTIONS = "SET_LANGUAGES_OPTIONS"
