/* eslint-disable camelcase */
/* eslint-disable react/forbid-prop-types */
import React from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import { getGroupsList, getSenderEmailsList } from "../../../sagas/organizationsSaga/reducer"
import { campaignType } from "../../../types"
import Form from "../../../components/Form"

export const FORM_FIELDS = [
  {
    fieldType: "select",
    fieldName: "senderEmail",
    label: "Sender Email",
    fieldProps: {
      required: true,
      disabled: false
    }
  },
  {
    fieldType: "select-group",
    fieldName: "recipientGroup",
    label: "Recipient Group",
    fieldProps: {
      required: true
    },
    options: []
  }
]
const SENDER_EMAIL_INDEX = 0
const RECIPIENT_GROUP_INDEX = 1

export class SelectUsers extends React.Component {
  constructor(props) {
    super(props)
    const { group_id, group_name, sender_email, sender_name } = props.campaign || {}
    const { senderEmails } = this.props
    this.state = {
      groupId: group_id || "",
      groupName: group_name || "",
      senderEmail: sender_email,
      senderName: sender_name,
      senderDetails: senderEmails
        ? senderEmails.find((senderDetail) => senderDetail.sender_email === sender_email)
        : null
    }
  }

  componentDidMount() {
    const { getSenderEmailsFunc } = this.props
    // getGroupsFunc()
    getSenderEmailsFunc()
  }

  static getDerivedStateFromProps(nextProps) {
    const { groups, senderEmails } = nextProps

    if (groups) {
      FORM_FIELDS[RECIPIENT_GROUP_INDEX].fieldProps.options = groups.results.map((group) => ({
        name: group.name,
        id: group.id
      }))
    }
    if (senderEmails) {
      FORM_FIELDS[SENDER_EMAIL_INDEX].fieldProps.senderEmails = senderEmails
    }
    return null
  }

  handleSearchGroups = (e, fieldName, fieldType) => {
    const { getGroupsFunc } = this.props
    getGroupsFunc(e)
  }

  handleChange = (e, fieldName) => {
    if (fieldName === "recipientGroup") {
      if (e.length > 0) {
        const { id, name } = e[0]

        this.setState({
          groupId: id,
          groupName: name
        })
      }
    } else if (fieldName === "senderEmail") {
      const { senderEmails } = this.props
      const senderInfo = e.value.split("<")
      const senderIndex = e.index
      const senderDetails = senderEmails[senderIndex]
      const senderEmail = senderInfo[1].substring(0, senderInfo[1].lastIndexOf(">"))
      const senderName = senderInfo[0].trim()
      this.setState({
        senderEmail,
        senderName,
        senderDetails
      })
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { handleSubmit } = this.props
    const { groupId, groupName, senderEmail, senderName } = this.state
    if (/^\S+@\S+\.\S+$/.test(senderEmail)) {
      handleSubmit({
        group_id: groupId,
        group_name: groupName,
        sender_email: senderEmail,
        sender_name: senderName
      })
    }
  }

  render() {
    const { senderEmail, groupId, groupName, senderDetails } = this.state
    FORM_FIELDS[SENDER_EMAIL_INDEX].fieldProps.selected = senderEmail
    FORM_FIELDS[RECIPIENT_GROUP_INDEX].fieldProps.selected = [
      {
        name: groupName,
        id: groupId
      }
    ]

    const senderSignatureConfirmed = senderDetails ? senderDetails.sender_signature_confirmed : true
    return (
      <div>
        <Form
          formFields={FORM_FIELDS}
          handleSubmit={this.handleSubmit}
          handleChange={this.handleChange}
          handleSearch={this.handleSearchGroups}
        />
        {!senderSignatureConfirmed && (
          <div>
            <h5>Warning: this sender email is not validated</h5>
          </div>
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  campaign: state.recurring_campaigns.campaignInfo,
  groups: state.organization.groups,
  senderEmails: state.organization.senderEmails
})

const mapDispatchToProps = {
  getGroupsFunc: getGroupsList,
  getSenderEmailsFunc: getSenderEmailsList
}

SelectUsers.propTypes = {
  campaign: campaignType.isRequired,
  groups: PropTypes.shape({
    results: PropTypes.array
  }),
  senderEmails: PropTypes.shape({
    results: PropTypes.string
  }),
  getGroupsFunc: PropTypes.func,
  getSenderEmailsFunc: PropTypes.func,
  handleSubmit: PropTypes.func
}

SelectUsers.defaultProps = {
  groups: null,
  senderEmails: null,
  getGroupsFunc: null,
  getSenderEmailsFunc: null,
  handleSubmit: null
}

export default connect(mapStateToProps, mapDispatchToProps)(SelectUsers)
