/* eslint-disable no-undef */
import axios from "../../api"

export function fetchCampaigns() {
  return axios.get(`${REACT_APP_PULSE_API_URL}/campaigns`)
}

export function fetchCampaignById(id) {
  return axios.get(`${REACT_APP_PULSE_API_URL}/campaigns/${id}`)
}

export function fetchMetadataTaskStatus() {
  return axios.get(`${REACT_APP_PULSE_API_URL}/get-metadata-task-status`)
}

export function createCampaign(payload) {
  return axios.post(`${REACT_APP_PULSE_API_URL}/campaigns`, payload)
}

export function deleteCampaign(id) {
  return axios.delete(`${REACT_APP_PULSE_API_URL}/campaigns/${id}`)
}

export function sendTestEmail(payload) {
  return axios.post(`${REACT_APP_PULSE_API_URL}/campaigns/send-test-email`, payload)
}

export function getEmailSubject(id) {
  return axios.get(`${REACT_APP_PULSE_API_URL}/campaigns/${id}/subject`)
}

export function updateCampaign(id, payload) {
  return axios.patch(`${REACT_APP_PULSE_API_URL}/campaigns/${id}`, payload)
}

export function fetchEmailStats(id) {
  return axios.get(`${REACT_APP_EP_API_URL}/scores/pulse-stats/?campaign_id=${id}`)
}

export function fetchHistoricalStats(id) {
  return axios.get(`${REACT_APP_EP_API_URL}/scores/pulse-historical-stats/?campaign_id=${id}`)
}

export function fetchNewHiresRatio(id) {
  return axios.get(`${REACT_APP_EP_API_URL}/scores/pulse-new-hires-ratio/?campaign_id=${id}`)
}

export function fetchRecipientsCount(id) {
  return axios.get(`${REACT_APP_PULSE_API_URL}/campaigns/${id}/recipients-user-id?limit=1`)
}
