/* eslint-disable import/prefer-default-export,camelcase */
import cloneDeep from "lodash/cloneDeep"
import sortBy from "lodash/sortBy"

import {
  CONTROLS,
  DEFAULT_TEMPLATE,
  DEFAULT_TEMPLATE_CONFIG,
  FOOTER_GROUPS,
  HEADER_GROUPS,
  HIDDEN_GROUPS,
  MAPPING
} from "../constants"
import { darklyGetFlag } from "../../../utils/utils"

export const getTemplateKeyData = (key) => {
  try {
    return Object.keys(DEFAULT_TEMPLATE_CONFIG).reduce(
      (preValue, temp_id) => ({ ...preValue, ...DEFAULT_TEMPLATE_CONFIG[temp_id][key] }),
      {}
    )
  } catch {
    return {}
  }
}

export const getHiddenGroups = (templateId, groupsNames = []) => {
  try {
    return DEFAULT_TEMPLATE_CONFIG[templateId][HIDDEN_GROUPS]
  } catch {
    return groupsNames
  }
}

export const configurationGroups = {
  id: {
    name: "id",
    type: "hidden"
  },
  name: {
    name: "name",
    type: "hidden"
  },
  template_id: {
    name: "template_id",
    type: "hidden"
  },
  default: {
    name: "default",
    type: "hidden"
  },
  ...getTemplateKeyData(HEADER_GROUPS),
  behaviors: {
    name: "behaviors",
    label: "Behaviors",
    type: "section",
    position: 4,
    controls: {
      ...getTemplateKeyData(CONTROLS)
    }
  },
  ...getTemplateKeyData(FOOTER_GROUPS)
}

/**
 * Given a control mapping key, find the control within
 * the control Map.
 *
 * @param keyName A mapping key from mappings.json
 * @param controls The controls Map
 * @returns {Map} the found control
 */
export const findByMappingKey = (keyName, controls) => {
  const templateId = controls.get("template_id").get("value")
  let mappings
  try {
    mappings = DEFAULT_TEMPLATE_CONFIG[templateId][MAPPING]
  } catch {
    mappings = DEFAULT_TEMPLATE_CONFIG[DEFAULT_TEMPLATE.EMPLOYEES][MAPPING]
  }

  const mappingKey = mappings[keyName] || mappings[`${keyName}|language`] || null

  if (mappingKey) {
    if (mappingKey.indexOf("/") > -1) {
      const parts = mappingKey.split("/")

      return parts.reduce((control, current) => {
        if (!control) return controls.get(current)
        return control.get("controls").get(current)
      }, null)
    }

    return controls.get(mappingKey)
  }

  return controls.get(keyName)
}

/**
 * Recursively finds a FormManager member given it's name
 * and the set of controls to look into.
 *
 * @param keyName
 * @param controls
 * @returns {boolean|*}
 */
export const findMember = (keyName, controls) => {
  const members = Array.from(controls.keys())

  // eslint-disable-next-line no-restricted-syntax
  for (const member of members) {
    if (keyName === member) {
      return controls.get(member)
    }

    if (controls.get(member).get("controls")) {
      const found = findMember(keyName, controls.get(member).get("controls"))

      if (found) return found
    }
  }

  return false
}

/**
 * Returns a clone of the original form configurations
 *
 * @returns {Object}
 */
export const getDefaultConfiguration = () => cloneDeep(configurationGroups)

/**
 * Converts an object to a Map instance
 *
 * @param {Object} obj The object to convert
 * @returns {Map}
 */
export const toMap = (obj, recurse = true) => {
  const mp = new Map()

  Object.keys(obj).forEach((k) => {
    if (k === "controls" && recurse) {
      mp.set(
        k,
        Object.keys(obj[k]).reduce(
          (controls, control) => controls.set(control, toMap(obj[k][control])),
          new Map()
        )
      )
    } else {
      mp.set(k, obj[k])
    }
  })

  return mp
}

/**
 * Returns a new Map instance for a given Map
 *
 * @param {Map} mapObj The instance to be cloned
 * @returns {Map} The new instance
 */
export const fromMap = (mapObj, recurse = true) => {
  const instance = new Map()

  Array.from(mapObj.keys()).forEach((key) => {
    const value = mapObj.get(key)

    if (key === "controls" && recurse) {
      instance.set(
        key,
        Array.from(value.keys()).reduce(
          (controls, control) => controls.set(control, fromMap(value.get(control))),
          new Map()
        )
      )
    } else {
      instance.set(key, value)
    }
  })

  return instance
}

/**
 * Converts a map to an object
 *
 * @param map
 * @returns {{}}
 */
export const toObject = (map) => {
  const obj = {}
  Array.from(map.keys()).forEach((key) => {
    if (map.get(key) instanceof Map) {
      obj[key] = toObject(map.get(key))
    } else {
      obj[key] = map.get(key)
    }
  })
  return obj
}

export const trackSelected = (existing, on, group) => {
  const exist = existing.find((g) => g.get("name") === group.get("name"))

  if (exist && !on) {
    return existing.filter((g) => g.get("name") !== group.get("name"))
  }

  if (!exist && on) {
    existing.push(group)
  }

  return existing
}

/**
 * Converts the flat configuration data object into a
 * FormData instance so it deal with data and file uploads.
 *
 * @param data
 * @returns {FormData}
 */
export const toFormData = (data) =>
  Object.keys(data).reduce(
    (form, keyName) => {
      if (data[keyName] === null || data[keyName] === undefined) {
        return form
      }

      if (["string", "number", "boolean"].indexOf(typeof data[keyName]) > -1) {
        form.append(keyName, data[keyName])
      } else if (data[keyName] === null || data[keyName] === undefined) {
        form.append(keyName, null)
        // eslint-disable-next-line no-undef
      } else if (data[keyName] instanceof File) {
        form.append(keyName, data[keyName])
      } else if (Object.keys(data[keyName]).length === 0) form.append(keyName, null)
      else {
        Object.keys(data[keyName]).forEach((key) =>
          form.append(`${keyName}.${key}`, data[keyName][key])
        )
      }

      return form
    },
    // eslint-disable-next-line no-undef
    new FormData()
  )

/**
 * Removes the `|language` suffix from the provided name.
 *
 * @param {string} name
 * @returns {string}
 */
export const normalizeName = (name) => (name.indexOf("|language") > -1 ? name.split("|")[0] : name)

/**
 * Given a group name, convert to a configuration string id format, used in navigation.
 *
 * @param groupName
 * @returns {string}
 */
export const normalizeId = (groupName) => `cfg-${groupName.replace(new RegExp("_", "g"), "-")}`
export const normalizeTarget = (groupName) => `#${normalizeId(groupName)}`

// eslint-disable-next-line max-len
export const getOrderedGroupKeys = (groups) =>
  sortBy(Array.from(groups.keys()), (key) => groups.get(key).get("position"))

export const getOrderedGroups = (groups) =>
  sortBy(Array.from(groups), (group) => group.get("position"))

// eslint-disable-next-line valid-typeof
export const isOfType = (value, type) => typeof value === type
export const isString = (value) => isOfType(value, "string")

/**
 * If you want to check whether a string is valid HTTP URL
 * @param {string} value
 */
export const isValidHttpUrl = (value) => {
  let url

  try {
    url = new URL(value)
  } catch {
    return false
  }

  return url.protocol === "http:" || url.protocol === "https:"
}

/**
 * Validates if the field is required
 * Validates if the field is URL and format is correct
 *
 * @param {string} value
 * @param {string} label
 * @param {boolean} required
 * @param {boolean} isURL
 */
export const getInputdValidationError = (value, label, required, isURL) => {
  const allowHTML = darklyGetFlag("pulse-allow-html")

  if (isURL && value && !isValidHttpUrl(value)) {
    return "Please enter a valid URL."
  }
  if (!allowHTML && containsHtmlCharacters(value)) {
    return "HTML characters are not allowed"
  }
  if (required && !value) {
    return `Please enter ${label || "required text"}.`
  }
  return null
}

export const containsHtmlCharacters = (str) => /(?=.*[<>])(?=.*['"])/.test(str)
