import { call, put, takeLatest } from "redux-saga/effects"

import { showAlert } from "../alertsSaga/reducer"
import { getErrorMessage } from "../../utils/utils"
import { fetchGroupEmployees } from "../organizationsSaga/api"

import * as actions from "./actions"
import * as api from "./api"

export function* fetchCampaigns(action) {
  try {
    const response = yield call(api.fetchCampaigns, action.payload)
    yield put({ type: actions.GET_RECURRING_CAMPAIGNS_SUCCESS, data: response.data })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_RECURRING_CAMPAIGNS_FAILED, error: e })
  }
}

export function* fetchCampaignById(action) {
  try {
    const response = yield call(api.fetchCampaignById, action.id)
    if (response.data && response.data.group_id) {
      try {
        const group = yield call(fetchGroupEmployees, response.data.group_id)
        response.data.members_count = group.data.count
      } catch (e) {
        response.data.members_count = null
      }
    }
    yield put({
      type: actions.GET_RECURRING_CAMPAIGN_BY_ID_SUCCESS,
      data: response.data
    })
    if (action.cb) action.cb(response.data)
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_RECURRING_CAMPAIGN_BY_ID_FAILED, error: e })
  }
}

export function* fetchCampaignsOfRecurringCampaign(action) {
  try {
    const response = yield call(api.fetchCampaignsOfRecurringCampaign, action.id, action.payload)
    yield put({
      type: actions.GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN_SUCCESS,
      id: action.id,
      data: response.data
    })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN_FAILED, id: action.id, error: e })
  }
}

export function* createCampaign(action) {
  try {
    const response = yield call(api.createCampaign, action.payload)
    yield put({ type: actions.CREATE_RECURRING_CAMPAIGN_SUCCESS, data: response.data })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.CREATE_RECURRING_CAMPAIGN_FAILED, error: e })
  }
}

export function* updateCampaign(action) {
  try {
    const response = yield call(api.updateCampaign, action.id, action.payload)
    yield put({
      type: actions.UPDATE_RECURRING_CAMPAIGN_SUCCESS,
      data: response.data,
      noStep: action.noStep
    })
    if (action.currentStep === 4 && !action.noStep) {
      yield put(showAlert(`${response.data.name} updated and scheduled`, false))
      yield put({ type: actions.RESET_RECURRING_CAMPAIGN })
      action.toggleModal()
    }
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.UPDATE_RECURRING_CAMPAIGN_FAILED, error: e })
  }
}

export function* deleteCampaign(action) {
  try {
    yield call(api.deleteCampaign, action.id)
    yield put({ type: actions.DELETE_RECURRING_CAMPAIGN_SUCCESS, id: action.id })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.DELETE_RECURRING_CAMPAIGN_FAILED, error: e })
  }
}

export function* getEmailSubject(action) {
  try {
    const response = yield call(api.getEmailSubject, action.id)
    yield put({ type: actions.GET_CAMPAIGN_EMAIL_SUBJECT_SUCCESS, data: response.data.subject })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_CAMPAIGN_EMAIL_SUBJECT_FAILED, error: e })
  }
}

export default function* recurringCampaignSaga() {
  yield takeLatest(actions.GET_RECURRING_CAMPAIGNS, fetchCampaigns)
  yield takeLatest(actions.CREATE_RECURRING_CAMPAIGN, createCampaign)
  yield takeLatest(actions.UPDATE_RECURRING_CAMPAIGN, updateCampaign)
  yield takeLatest(actions.DELETE_RECURRING_CAMPAIGN, deleteCampaign)
  yield takeLatest(actions.GET_RECURRING_CAMPAIGN_BY_ID, fetchCampaignById)
  yield takeLatest(actions.GET_CAMPAIGN_EMAIL_SUBJECT, getEmailSubject)
  yield takeLatest(actions.GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN, fetchCampaignsOfRecurringCampaign)
}
