/* eslint-disable max-len */
/* eslint-disable import/prefer-default-export */
import React from "react"
import moment from "moment-timezone"
import { Tooltip, Icon } from "@elevate_security/elevate-component-library"
import { TooltipContent } from "./styles"
import { getDefaultTimezone } from "../../../sagas/companySaga/selectors"

const getPercentage = (a, b) =>
  Number.isInteger((a / b) * 100) ? (a / b) * 100 : ((a / b) * 100).toFixed(1)

const getPercentageString = (a, b) => (b === 0 ? "N/A" : `${getPercentage(a, b)}%`)

const isNA = (value) => value === null || value === undefined

// Adds commas to numbers 1000 and above

const formatWithCommas = (num) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")

const getGraphImageUrl =
  ([a, b]) =>
  (average) => {
    const baseUrl = "https://hm-assets.elevatesecurity.com/master/pulse/distribution-curve-graph"
    if (average === 0) return `${baseUrl}/curve-1.png`
    if (average < a) return `${baseUrl}/curve-2.png`
    if (average < b) return `${baseUrl}/curve-3.png`
    if (average < 100) return `${baseUrl}/curve-4.png`
    return `${baseUrl}/curve-5.png`
  }

const getOpenRateGraphImageUrl = getGraphImageUrl([19, 21.3])
const getClickRateGraphImageUrl = getGraphImageUrl([2, 2.7])

const getGraphLegend =
  ([a, b]) =>
  (average) => {
    if (average < a) return "Below average"
    if (average < b) return "Average"
    return "Above average"
  }

const getOpenRateGraphLegend = getGraphLegend([19, 21.3])
const getClickRateGraphLegend = getGraphLegend([2, 2.7])

export const recipientHelpText =
  "Recipients may be of any employment type, including Full-time, Part-time, and Contractor."

export const getStatCards = (campaign, recipientsCount) => {
  if (isNA(campaign.stats) || isNA(campaign.stats.overview)) return []
  const {
    stats: {
      overview: {
        Sent,
        Bounced,
        TotalTrackedLinksSent,
        WithOpenTracking,
        UniqueOpens,
        UniqueLinksClicked
      }
    }
  } = campaign
  const deliveredCount = isNA(Sent) ? "N/A" : formatWithCommas(Sent)
  const bouncedCount = isNA(Bounced) ? "N/A" : formatWithCommas(Bounced)
  const openTrackingCount = isNA(WithOpenTracking) ? "N/A" : formatWithCommas(WithOpenTracking)
  const uniqueOpensCount = isNA(UniqueOpens) ? "N/A" : formatWithCommas(UniqueOpens)
  const trackedLinksSentCount = isNA(TotalTrackedLinksSent)
    ? "N/A"
    : formatWithCommas(TotalTrackedLinksSent)
  const uniqueLinksClickedCount = isNA(UniqueLinksClicked)
    ? "N/A"
    : formatWithCommas(UniqueLinksClicked)

  const averageOpenRate = getPercentage(UniqueOpens, WithOpenTracking)
  const averageClickRate = getPercentage(UniqueLinksClicked, TotalTrackedLinksSent)

  const legendOpenRate = getOpenRateGraphLegend(averageOpenRate)
  const legendClickRate = getClickRateGraphLegend(averageClickRate)

  const recipientsHelpText = (
    <span>
      <b>Note:</b> The # of emails sent may be higher than the # of recipients because we attempt to
      resend emails if we get a soft bounce.
    </span>
  )

  const distributionCurveOpenRate = {
    average: averageOpenRate,
    legend: legendOpenRate,
    imageUrl: getOpenRateGraphImageUrl(averageOpenRate),
    tooltipBody: (
      <TooltipContent>
        <p>
          Your open rate of <span>{getPercentageString(UniqueOpens, WithOpenTracking)}</span> is{" "}
          <span>{legendOpenRate.toLowerCase()}</span> .
        </p>
        <p>
          For reference, the average email open rate across all industries is roughly{" "}
          <span>21.3%</span> (per a 2019 email marketing study).
        </p>
      </TooltipContent>
    )
  }

  const distributionCurveClickRate = {
    average: averageClickRate,
    legend: legendClickRate,
    imageUrl: getClickRateGraphImageUrl(averageClickRate),
    tooltipBody: (
      <TooltipContent>
        <p>
          Your click rate of
          <span>{getPercentageString(UniqueLinksClicked, TotalTrackedLinksSent)}</span>
          is
          <span>{legendClickRate.toLowerCase()}</span>.
        </p>
        <p>
          For reference, the average email click rate across all industries is roughly{" "}
          <span>2.6%</span> (per a 2019 email marketing study).
        </p>
      </TooltipContent>
    )
  }

  return [
    {
      title: deliveredCount,
      subtitle: (
        <span>
          # of emails sent
          <Tooltip body={recipientsHelpText} placement="top" readOnly>
            <Icon name="CircleExclamation" size="xsm" fill="#959DA8" />
          </Tooltip>
        </span>
      ),
      description:
        deliveredCount === "N/A" ? (
          ""
        ) : (
          <span>
            <b>{deliveredCount}</b>
            {" Pulse emails were sent out to "}
            <b>{isNA(recipientsCount) ? "N/A" : formatWithCommas(recipientsCount)}</b>
            {" recipients across 1 group on "}
            {moment(campaign.launch_date).format("MMMM DD, YYYY")}
          </span>
        )
    },
    {
      title: isNA(Bounced && Sent) ? "N/A" : getPercentageString(Bounced, Sent),
      subtitle: "bounce rate",
      description:
        bouncedCount === "N/A" ? (
          ""
        ) : (
          <span>
            <b>{bouncedCount}</b>
            {" out of the "}
            <b>{deliveredCount}</b>
            {" emails sent bounced"}
          </span>
        )
    },
    {
      title: isNA(UniqueOpens && WithOpenTracking)
        ? "N/A"
        : getPercentageString(UniqueOpens, WithOpenTracking),
      subtitle: "email open rate",
      description:
        uniqueOpensCount === "N/A" ? (
          ""
        ) : (
          <span>
            {"Out of "}
            <b>{openTrackingCount}</b>
            {" emails with open tracking, "}
            <b>{uniqueOpensCount}</b>
            {" emails were opened"}
          </span>
        ),
      distributionCurve:
        getPercentageString(UniqueOpens, WithOpenTracking) !== "N/A"
          ? distributionCurveOpenRate
          : ""
    },
    {
      title: isNA(UniqueLinksClicked && TotalTrackedLinksSent)
        ? "N/A"
        : getPercentageString(UniqueLinksClicked, TotalTrackedLinksSent),
      subtitle: "click rate",
      description: (
        <span>
          {"Out of "}
          <b>{trackedLinksSentCount}</b>
          {" tracked links, "}
          <b>{uniqueLinksClickedCount}</b>
          {" links were clicked"}
        </span>
      ),
      distributionCurve:
        getPercentageString(UniqueLinksClicked, TotalTrackedLinksSent) !== "N/A"
          ? distributionCurveClickRate
          : ""
    }
  ]
}

export const getFields = (campaign = {}, templates = [], configurations = [], recipientsCount) => {
  const templateLabel = templates
    ? (templates.find((element) => element.template_path === campaign.template) || {})?.label
    : null
  const configurationName = campaign.template_data_id
    ? (configurations.find((c) => c.id === campaign.template_data_id) || {})?.name
    : null
  const default_timezone = getDefaultTimezone()
  const launchDateMoment = moment.utc(campaign.launch_date).tz(default_timezone)
  const launchDateTime = launchDateMoment.format("MMMM DD, YYYY, hh:mm A")

  return [
    {
      label: "Email Launch Date & Time",
      value: campaign?.launch_date ? `${launchDateTime} - ${default_timezone}` : "—"
    },
    {
      label: "Campaign Template",
      value: (campaign.template_data_id ? configurationName : templateLabel) || "—"
    },
    {
      label: "Recipient Group(s)",
      value: campaign?.group_name ? campaign.group_name : "—"
    },
    {
      label: "Campaign Language",
      value: campaign?.language ? campaign.language : "—"
    },
    {
      label: "Created by",
      value: campaign?.created_by_name ? campaign.created_by_name : "—"
    },
    {
      label: "Recipients List",
      value: isNA(recipientsCount) ? "—" : `${formatWithCommas(recipientsCount)} recipients`
    }
  ]
}
