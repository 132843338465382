/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "react-final-form"
import { Toggle as ToggleInput } from "@elevate_security/elevate-component-library"
import { FieldContainer } from "../styles"
import FieldLabel from "../../../FieldLabel"

const withForm = (Component) => (props) => {
  const { name, value } = props.control

  return (
    <Field name={name} type="checkbox" initialValue={value}>
      {({ input }) => <Component {...props} input={input} />}
    </Field>
  )
}

const Toggle = ({ control, input, mode, form, isToggleGroups }) => {
  const [isSelected, setIsSelected] = useState(false)
  const { props: controlProps, separator, hidden, parent } = control
  const { disabled } = controlProps || {}

  const formValues = form?.getState()?.values || {}

  useEffect(() => {
    if (isToggleGroups && parent) {
      setIsSelected(!formValues[parent])
    }
  }, [formValues])

  return (
    <FieldContainer
      direction={mode === "expanded" ? "row" : "row-reverse"}
      separator={separator}
      hidden={hidden}
    >
      <FieldLabel control={control} />
      <div>
        <ToggleInput
          {...input}
          disabled={disabled || isSelected}
          onChange={(data) => input.onChange(data.event)}
        />
      </div>
    </FieldContainer>
  )
}

Toggle.propTypes = {
  mode: PropTypes.oneOf(["expanded", "short"]),
  input: PropTypes.shape({
    name: PropTypes.string,
    onChange: PropTypes.func,
    onFocus: PropTypes.func
  }).isRequired,
  control: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool,
    separator: PropTypes.bool,
    helpText: PropTypes.string,
    props: PropTypes.shape({
      required: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired,
  isToggleGroups: PropTypes.bool
}

Toggle.defaultProps = {
  mode: "expanded",
  isToggleGroups: false
}

export default withForm(Toggle)
