import { call, put, takeLatest } from "redux-saga/effects"

import * as actions from "./actions"
import * as api from "./api"
import { fetchGroupEmployees } from "../organizationsSaga/api"
import { showAlert } from "../alertsSaga/reducer"
import { getErrorMessage } from "../../utils/utils"
import { CAMPAIGN_STATUS } from "../../constants"

export function* fetchCampaigns() {
  try {
    const response = yield call(api.fetchCampaigns)
    yield put({ type: actions.GET_CAMPAIGNS_SUCCESS, data: response.data })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_CAMPAIGNS_FAILED, error: e })
  }
}

export function* fetchCampaignById(action) {
  try {
    const response = yield call(api.fetchCampaignById, action.id)
    if (response.data && response.data.status === CAMPAIGN_STATUS.COMPLETED) {
      try {
        const stats = yield call(api.fetchEmailStats, action.id)
        response.data.stats = stats.data
      } catch (e) {
        response.data.stats = {}
      }
    }
    if (response.data && response.data.group_id) {
      try {
        const group = yield call(fetchGroupEmployees, response.data.group_id)
        response.data.members_count = group.data.count
      } catch (e) {
        response.data.members_count = null
      }
    }
    yield put({
      type: actions.GET_CAMPAIGN_BY_ID_SUCCESS,
      data: response.data
    })
    if (action.cb) action.cb(response.data)
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_CAMPAIGN_BY_ID_FAILED, error: e })
  }
}

export function* fetchMetadataTaskStatus() {
  try {
    const response = yield call(api.fetchMetadataTaskStatus)
    yield put({ type: actions.GET_METADATA_TASK_STATUS_SUCCESS, data: response.data })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_METADATA_TASK_STATUS_FAILED, error: e })
  }
}

export function* fetchHistoricalStatsById(action) {
  try {
    const historicalStats = yield call(api.fetchHistoricalStats, action.id)
    yield put({
      type: actions.GET_HISTORICAL_STATS_BY_ID_SUCCESS,
      data: historicalStats.data
    })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({
      type: actions.GET_HISTORICAL_STATS_BY_ID_FAILED,
      data: {},
      error: e
    })
  }
}

export function* fetchNewHiresRatioById(action) {
  try {
    const newHiresRatio = yield call(api.fetchNewHiresRatio, action.id)
    yield put({ type: actions.GET_NEW_HIRES_RATIO_BY_ID_SUCCESS, data: newHiresRatio.data })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({
      type: actions.GET_NEW_HIRES_RATIO_BY_ID_FAILED,
      data: {},
      error: e
    })
  }
}

export function* fetchRecipientsCountById(action) {
  try {
    const recipientsCount = yield call(api.fetchRecipientsCount, action.id)
    yield put({ type: actions.GET_RECIPIENTS_COUNT_BY_ID_SUCCESS, data: recipientsCount.data })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({
      type: actions.GET_RECIPIENTS_COUNT_BY_ID_FAILED,
      data: {},
      error: e
    })
  }
}

export function* createCampaign(action) {
  try {
    const response = yield call(api.createCampaign, action.payload)
    yield put({ type: actions.CREATE_CAMPAIGN_SUCCESS, data: response.data })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.CREATE_CAMPAIGN_FAILED, error: e })
  }
}

export function* deleteCampaign(action) {
  try {
    yield call(api.deleteCampaign, action.id)
    yield put({ type: actions.DELETE_CAMPAIGN_SUCCESS, id: action.id })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.DELETE_CAMPAIGN_FAILED, error: e })
  }
}

export function* sendTestEmail(action) {
  try {
    yield call(api.sendTestEmail, action.payload)
    yield put({ type: actions.SEND_TEST_EMAIL_SUCCESS })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.SEND_TEST_EMAIL_FAILED, error: e })
  }
}

export function* getEmailSubject(action) {
  try {
    const response = yield call(api.getEmailSubject, action.id)
    yield put({ type: actions.GET_EMAIL_SUBJECT_SUCCESS, data: response.data.subject })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_EMAIL_SUBJECT_FAILED, error: e })
  }
}

export function* updateCampaign(action) {
  try {
    const response = yield call(api.updateCampaign, action.id, action.payload)
    yield put({ type: actions.UPDATE_CAMPAIGN_SUCCESS, data: response.data, noStep: action.noStep })
    if (action.currentStep === 4 && !action.noStep) {
      yield put(showAlert(`${response.data.name} updated and scheduled`, false))
      yield put({ type: actions.RESET_CAMPAIGN })
      action.toggleModal()
    }
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.UPDATE_CAMPAIGN_FAILED, error: e })
  }
}

export default function* campaignSaga() {
  yield takeLatest(actions.GET_CAMPAIGNS, fetchCampaigns)
  yield takeLatest(actions.GET_CAMPAIGN_BY_ID, fetchCampaignById)
  yield takeLatest(actions.GET_METADATA_TASK_STATUS, fetchMetadataTaskStatus)
  yield takeLatest(actions.CREATE_CAMPAIGN, createCampaign)
  yield takeLatest(actions.DELETE_CAMPAIGN, deleteCampaign)
  yield takeLatest(actions.GET_EMAIL_SUBJECT, getEmailSubject)
  yield takeLatest(actions.SEND_TEST_EMAIL, sendTestEmail)
  yield takeLatest(actions.UPDATE_CAMPAIGN, updateCampaign)
  yield takeLatest(actions.GET_HISTORICAL_STATS_BY_ID, fetchHistoricalStatsById)
  yield takeLatest(actions.GET_NEW_HIRES_RATIO_BY_ID, fetchNewHiresRatioById)
  yield takeLatest(actions.GET_RECIPIENTS_COUNT_BY_ID, fetchRecipientsCountById)
}
