/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { FormApi } from "react-final-form"
import dompurify from "dompurify"
import { FieldContainer, InputContainer, InputText } from "../styles"
import withLanguageSupport from "../withLanguageSupport"
import FieldLabel from "../../../FieldLabel"
import FieldExampleLink from "../../../FieldExampleLink"

/**
 * Special field that has no input, instead, it listens
 * to a source field which can be any field in the current
 * form besides this one, and uses the source field value
 * to formulate it's value based on default strings provided
 * through the configuration.
 *
 * @param form
 * @param control
 * @param language
 * @returns {JSX.Element}
 * @constructor
 */
const TextListener = ({ form, control, language }) => {
  const {
    name,
    language_source,
    listen: { source, matcher },
    separator,
    defaults,
    example,
    props: controlProps,
    hidden
  } = control
  const { disabled } = controlProps || {}
  const [listener, setListener] = useState(null)
  const sanitizer = dompurify.sanitize

  const replaceMatch = (values) => defaults[language].value.replace(matcher, values[source]).trim()

  /**
   * This effect listens for changes to the source field
   * and uses it's new value to update this field value
   * by replacing the `matcher` string with the source
   * new value in the default language string.
   */
  useEffect(() => {
    if (source && matcher) {
      setListener(
        form.subscribe(
          ({ values }) => {
            if (source in values && values[language_source] === language) {
              const interpolated = replaceMatch(values)

              if (interpolated !== (values[name] || "").trim()) {
                form.change(name, interpolated)
              }
            }
          },
          { values: true }
        )
      )
    }

    return () => listener && listener()
  }, [form, source, matcher])

  // eslint-disable-next-line no-nested-ternary
  const textToRender = language
    ? defaults[language]?.display
    : defaults.en_US
      ? defaults.en_US.display
      : null

  return (
    <FieldContainer separator={separator} hidden={hidden}>
      <FieldLabel control={control} />
      <InputContainer>
        <InputText dangerouslySetInnerHTML={{ __html: sanitizer(textToRender || "N/A") }} />
      </InputContainer>
      {!disabled && example !== null && <FieldExampleLink control={control} />}
    </FieldContainer>
  )
}

TextListener.propTypes = {
  language: PropTypes.string.isRequired,
  form: PropTypes.instanceOf(FormApi).isRequired,
  control: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool,
    helpText: PropTypes.string,
    defaultValue: PropTypes.string,
    separator: PropTypes.bool,
    language_source: PropTypes.string,
    listen: PropTypes.shape({
      source: PropTypes.string,
      matcher: PropTypes.string
    }),
    defaults: PropTypes.arrayOf(
      PropTypes.shape({
        display: PropTypes.string,
        value: PropTypes.string
      })
    ),
    preview: PropTypes.oneOf([
      PropTypes.string,
      PropTypes.shape({
        source: PropTypes.string,
        template: PropTypes.string
      })
    ]),
    example: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string
    }),
    merge_from: PropTypes.string,
    props: PropTypes.shape({
      required: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired
}

export default withLanguageSupport(TextListener)
