import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import moment from "moment-timezone"
import { campaignType } from "../../../types"
import Form from "../../../components/Form"
import theme from "../../../css/theme"
import { getDefaultTimezone } from "../../../sagas/companySaga/selectors"

const AlertInfo = styled.div.attrs({
  className: "alert"
})`
  && {
    background-color: ${theme.colors.tag.secondary};
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    z-index: 0;
  }
`
const LAUNCH_DATE_INDEX = 0
const LAUNCH_TIME_INDEX = 1

export const FORM_FIELDS = [
  {
    fieldType: "date",
    fieldName: "launchDate",
    label: "Email Launch Date",
    fieldProps: {
      required: true,
      placeholderText: "MM/DD/YYYY",
      className: "col-sm-6 pl-0",
      icon: "Calendar"
    }
  },
  {
    fieldType: "date",
    fieldName: "launchTime",
    label: "Email Launch Time",
    fieldProps: {
      className: "col-sm-6 pr-0",
      dateFormat: "h:mm aa",
      placeholderText: "Choose time...",
      required: true,
      showTimeSelect: true,
      showTimeSelectOnly: true,
      icon: "Clock"
    }
  }
]

const getLaunchDate = (campaign, default_timezone) =>
  new Date(
    moment.tz(campaign.launch_date, "utc").tz(default_timezone).format("YYYY/MM/DD HH:mm:ss")
  )

class LaunchDate extends Component {
  constructor(props) {
    super(props)
    const { campaign } = props
    const default_timezone = getDefaultTimezone()
    const defaultDate =
      campaign && campaign.launch_date ? getLaunchDate(campaign, default_timezone) : null
    this.state = {
      timezone: default_timezone,
      launchDate: defaultDate,
      launchTime: defaultDate
    }
    FORM_FIELDS[LAUNCH_DATE_INDEX].fieldProps.value = defaultDate
    FORM_FIELDS[LAUNCH_TIME_INDEX].fieldProps.value = defaultDate
  }

  handleChange = (value, fieldName) => {
    switch (fieldName) {
      case "launchDate":
        FORM_FIELDS[LAUNCH_DATE_INDEX].fieldProps.value = value
        break
      case "launchTime":
      default:
        FORM_FIELDS[LAUNCH_TIME_INDEX].fieldProps.value = value
    }

    this.setState({
      [fieldName]: value
    })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const { launchDate, launchTime } = this.state
    const { handleSubmit } = this.props
    const default_timezone = getDefaultTimezone()
    if (launchDate && launchTime) {
      const dateTimeData = {
        year: launchDate.getFullYear(),
        month: launchDate.getMonth(),
        date: launchDate.getDate(),
        hour: launchTime.getHours(),
        minutes: launchTime.getMinutes()
      }
      const momentDate = moment.tz(dateTimeData, default_timezone)
      const dateISO = momentDate.toISOString()
      handleSubmit({
        launch_date: dateISO
      })
    }
  }

  render() {
    const { timezone } = this.state
    return (
      <div>
        <Form
          addRow
          formFields={FORM_FIELDS}
          handleChange={this.handleChange}
          handleSubmit={this.handleSubmit}
        />
        <AlertInfo>
          FYI, the email launch date & time are based on this time zone:
          <b>{` ${timezone}`}</b>
        </AlertInfo>
      </div>
    )
  }
}

LaunchDate.propTypes = {
  campaign: campaignType,
  handleSubmit: PropTypes.func
}

LaunchDate.defaultProps = {
  campaign: null,
  handleSubmit: null
}

export default LaunchDate
