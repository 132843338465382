import { getDefaultConfiguration } from "./helpers/utils"
import createMap from "./helpers/create-config-map"

/**
 * Initializes all groups and controls
 *
 * @param {Map} provided A groups Map to update instead of creating
 * @returns {Map} The new Map instance
 */
export default () => createMap(getDefaultConfiguration())
