/* eslint-disable import/no-named-as-default */
import React from "react"
import { Route, Switch, withRouter, Redirect } from "react-router-dom"
import { Scoped } from "kremling"
import Dashboard from "./campaign/Dashboard"
import OldDashboard from "./ocampaign/OldDashboard"
import Configurator from "./configurator/Template"
import ConfigurationsList from "./configurator/Configurations"
import styles from "../css/style.krem.css"
import { useSubscribeToLaunchDarklyFlag } from "../utils/darkly/useSubscribeToLaunchDarklyFlag"

const getRecurringCampaignPath = (showRecurringCampaigns) => {
  if (showRecurringCampaigns) {
    return "/engagement/pulse/campaigns"
  }
  return "/engagement/pulse/recurringcampaigns"
}

const getOldCampaignPath = (showRecurringCampaigns) => {
  if (!showRecurringCampaigns) {
    return "/engagement/pulse/campaigns"
  }
  return "/engagement/pulse/oldcampaigns"
}

const Routes = () => {
  const showRecurringCampaigns = useSubscribeToLaunchDarklyFlag("pulse-show-recurring-campaigns")
  return (
    <Scoped postcss={styles}>
      <div style={{ height: "100%", width: "100%" }}>
        <Switch>
          <Route
            path={getRecurringCampaignPath(showRecurringCampaigns)}
            render={(props) => (
              <Dashboard
                {...props}
                baseCampaignsPath={getRecurringCampaignPath(showRecurringCampaigns)}
              />
            )}
          />
          <Route
            path={getOldCampaignPath(showRecurringCampaigns)}
            render={(props) => (
              <OldDashboard
                {...props}
                baseCampaignsPath={getOldCampaignPath(showRecurringCampaigns)}
              />
            )}
          />
          <Route
            exact
            path="/engagement/pulse/templates"
            render={(props) => <ConfigurationsList {...props} />}
          />
          <Route
            exact
            path="/engagement/pulse/templates/:id"
            render={(props) => <Configurator {...props} />}
          />
          {/* TODO: Remove this when we'll have something at the index */}
          <Redirect from="/engagement/pulse" to="/engagement/pulse/campaigns" />
        </Switch>
      </div>
    </Scoped>
  )
}

export default withRouter(Routes)
