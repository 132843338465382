import { LANGUAGES, EMAIL_SUBJECT_DEFAULTS, SECURITY_SNAPSHOT_MATCHER } from "../constants"

const getControl = (name, group) =>
  group.has("controls") ? group.get("controls").get(name) : group

/**
 * Sets the value of multi language text inputs.
 *
 * @param mappingKey The key (name) of this field in the mapping file
 * @param member The current form member, it can be a group or a control, but
 * it will always be a Map instance.
 * @param data The configuration object coming from the server.
 * @returns {*}
 */
const hydrateLanguageValues = (mappingKey, member, data) => {
  if (mappingKey.indexOf("|language") > -1) {
    const keyName = mappingKey.split("|")[0]

    const control = getControl(keyName, member)

    if (control) {
      LANGUAGES.forEach((language) => {
        const propName = `${keyName}_${language}`
        if (propName in data) {
          control.set(`value_${language}`, data[propName])
        }
      })

      control.set("hydrated", true)
    }
  }

  return member
}

/**
 * Sets the value of Toggle Group fields
 *
 * @param mappingKey The key (name) of this field in the mapping file
 * @param member The current form member, it can be a group or a control, but
 * it will always be a Map instance.
 * @param data The configuration object coming from the server.
 * @returns {*}
 */
const hydrateToggleGroups = (mappingKey, member, data) => {
  const rootControl = getControl(mappingKey, member)

  if (rootControl && rootControl.get("type") === "toggle-group") {
    const controls = rootControl.get("controls")

    if (controls && data && "show_behaviors" in data) {
      Array.from(controls.keys()).forEach((controlName) => {
        if (controlName in data.show_behaviors) {
          controls.get(controlName).set("value", data.show_behaviors[controlName])
        }
      })

      member.set("hydrated", true)
    }
  }

  return member
}

/**
 * Hydrate the value of url-group fields
 * optional_activities_no_data_url and optional_activities_review_url
 * from Object (key, valu pairs) to an array of objects
 *
 * @param mappingKey The key (name) of this field in the mapping file
 * @param member The current form member, it can be a group or a control, but
 * it will always be a Map instance.
 * @param data The configuration object coming from the server.
 * @returns {*}
 */
const hydrateUrlGroupModuleField = (mappingKey, member, data) => {
  if (mappingKey.indexOf("|language") > -1) {
    const keyName = mappingKey.split("|")[0]

    if (
      keyName === "optional_activities_no_data_url" ||
      keyName === "optional_activities_review_url"
    ) {
      const control = getControl(keyName, member)

      if (control) {
        LANGUAGES.forEach((language) => {
          const propName = `${keyName}_${language}`

          if (propName in data && typeof data[propName] === "object" && data[propName] !== null) {
            const groupIds = Object.keys(data[propName])

            if (groupIds && groupIds.length) {
              const mappedArray = groupIds.map((group) => ({ group, url: data[propName][group] }))
              control.set(`value_${language}`, mappedArray)
            } else {
              control.set(`value_${language}`, null)
            }
          } else {
            control.set(`value_${language}`, null)
          }
        })
      }
      control.set("hydrated", true)
    }
  }

  return member
}

/**
 * Hydrate the value of email_subject field
 *
 * @param mappingKey The key (name) of this field in the mapping file
 * @param member The current form member, it can be a group or a control, but
 * it will always be a Map instance.
 * @param data The configuration object coming from the server.
 * @returns {*}
 *
 * Example value from server:
 * {% if (employee_name) %}{{ employee_name|safe }}: {% endif %}Your {{organization_name|safe}} Security Snapshot
 *
 * Example hydrated value:
 * Security Snapshot
 */
const hydrateEmailSubjectTitle = (mappingKey, member, data) => {
  if (mappingKey.indexOf("|language") > -1) {
    const keyName = mappingKey.split("|")[0]

    if (keyName === "email_subject") {
      const control = getControl(keyName, member)

      if (control) {
        LANGUAGES.forEach((language) => {
          const propName = `${keyName}_${language}`

          if (propName in data && typeof data[propName] === "string" && data[propName] !== null) {
            const def = EMAIL_SUBJECT_DEFAULTS[language]
            const parts = def.split(SECURITY_SNAPSHOT_MATCHER)
            const diff = parts.reduce((v, part) => v.replace(part, ""), `${data[propName]}`).trim()

            if (diff && typeof diff === "string") {
              control.set(`value_${language}`, diff)
            }
          }
        })
      }
      control.set("hydrated", true)
    }
  }

  return member
}

/**
 * If no custom hydrators exists for a field type, the default
 * will set the field value to the same key in the data
 * payload.
 *
 * @param mappingKey The key (name) of this field in the mapping file
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The configuration object coming from the server.
 * @returns {*}
 */
const defaultHydrator = (mappingKey, member, data) => {
  // eslint-disable-next-line camelcase
  const { show_behaviors: showBehaviors } = data || { show_behaviors: {} }
  const control = getControl(mappingKey, member)

  if (control && !control.get("hydrated")) {
    const value = data[mappingKey] || (showBehaviors && showBehaviors[mappingKey])

    if (value !== undefined && value !== null) {
      control.set("value", value)
    }
  }

  return member
}

/**
 * Hidrators translates the raw configuration object into values
 * that form fields can understand.
 *
 * If you need to modify the value coming from the backend
 * api before feeding it into your form, create a custom hydrator
 * here.
 *
 * Each member is passed into this pipeline when a
 * FormManager instance is being created.
 *
 * Use the member type property to target specific fields and
 * groups.
 *
 * @param mappingKey The key (name) of this field in the mapping file
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The configuration object coming from the server.
 * @returns {*}
 */
const runHydrators = (mappingKey, member, data) =>
  [
    hydrateLanguageValues,
    hydrateToggleGroups,
    hydrateUrlGroupModuleField,
    hydrateEmailSubjectTitle,
    defaultHydrator
  ].reduce((updatedGroup, callback) => callback(mappingKey, updatedGroup, data), member)

export default runHydrators
