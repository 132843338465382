/**
 * These utils are copied from elevate component library's table component
 * /

/**
 * The getRange is responsible by to get the
 * the range between number
 *
 * @param {Number} start
 * @param {Number} end
 * @returns {Array}
 */
function getRange(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((v, i) => i + start)
}

/**
 * The generateArrayOfPageRangeWithDots it's responsible by to generate the
 * range pagination with dots
 *
 * @returns {Array}
 */
export function generateArrayOfPageRangeWithDots(pages, currentPage) {
  // Base cases
  if (pages === 1) return [1]
  if (pages === 2) return [1, 2]

  const delta = 3
  const length = pages
  const current = currentPage + 1

  const range = {
    start: Math.round(current - delta / 2),
    end: Math.round(current + delta / 2)
  }

  if (range.start - 1 === 1 || range.end + 1 === length) {
    range.start += 1
    range.end += 1
  }

  let pageNumbers =
    current > delta
      ? getRange(Math.min(range.start, length - delta), Math.min(range.end, length))
      : getRange(1, Math.min(length, delta + 1))

  const withDots = (value, pair) => (pageNumbers.length + 1 !== length ? pair : [value])

  if (pageNumbers[0] !== 1) {
    pageNumbers = withDots(1, [1, "..."]).concat(pageNumbers)
  }

  if (pageNumbers[pageNumbers.length - 1] < length) {
    pageNumbers = pageNumbers.concat(withDots(length, ["...", length]))
  }
  return pageNumbers
}
