import React from "react"
import { SkeletonLoader } from "@elevate_security/elevate-component-library"
import { Wrapper } from "./styles"

export const ConfiguratorSidebarSkeletonLoader = () => (
  <Wrapper>
    {Array(6)
      .fill(null)
      .map(() => (
        <>
          <SkeletonLoader />
          <hr />
        </>
      ))}
  </Wrapper>
)
