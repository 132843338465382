/* eslint-disable react/prefer-stateless-function */
import React from "react"
import moment from "moment"
import PropTypes from "prop-types"
import DatePicker from "react-datepicker"

const DateInput = (props) => {
  const {
    showTimeSelect,
    showTimeSelectOnly,
    placeholderText,
    dateFormat,
    onChange,
    selected,
    required
  } = props

  return (
    <DatePicker
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      placeholderText={placeholderText}
      onChange={onChange}
      dateFormat={dateFormat}
      selected={selected}
      required={required}
    />
  )
}

DateInput.propTypes = {
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  placeholderText: PropTypes.string,
  dateFormat: PropTypes.string,
  onChange: PropTypes.func,
  selected: PropTypes.string,
  required: PropTypes.bool
}

DateInput.defaultProps = {
  showTimeSelect: false,
  showTimeSelectOnly: false,
  placeholderText: "",
  dateFormat: "MM/dd/yyyy",
  onChange: null,
  selected: "",
  required: true
}

export default DateInput
