import { normalizeName, toMap } from "./utils"

/**
 * Converts a control object to a Map
 *
 * @param {string} controlName The control name
 * @param {Object} controls The full list of controls in Object type
 * @param {Map} parent The parent Map to attach this control to
 * @returns {*}
 */
const mapControl = (controlName, controls, parent) => {
  const control = controls[controlName]

  if ("controls" in control) {
    // eslint-disable-next-line no-use-before-define
    return parent.set(normalizeName(controlName), mapControlGroup(control))
  }

  return parent.set(normalizeName(controlName), toMap(control, false))
}

/**
 * Converts one form group into a Map recursively
 *
 * @param {Object} group The group object to convert to a Map
 * @returns {Map}
 */
const mapControlGroup = (group) => {
  const { controls } = group

  if (controls) {
    // eslint-disable-next-line no-param-reassign
    group.controls = Object.keys(controls).reduce(
      (controlsMap, controlName) => mapControl(controlName, controls, controlsMap),
      new Map()
    )
  }

  return toMap(group, false)
}

/**
 * Creates a Map out of the default configuration
 *
 * @param defaults
 * @returns {Map<string, Map>}
 */
const createMap = (defaults) =>
  Object.keys(defaults)
    // eslint-disable-next-line max-len
    .reduce(
      (mappedGroups, name) =>
        mappedGroups.set(normalizeName(name), mapControlGroup(defaults[name])),
      new Map()
    )

export default createMap
