/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field, FormApi } from "react-final-form"
import styled from "styled-components"
import { useSelector } from "react-redux"
import { Input, CustomSelect, Link, IconAction } from "@elevate_security/elevate-component-library"
import { resolveValidations } from "../../../../../../../sagas/configuratorSaga/validations"
import { getGroups } from "../../../../../../../sagas/configuratorSaga/selectors"
import FieldError from "../../../../../../../components/FieldError"
import { FieldContainer, InputContainer, InputText } from "../styles"
import FieldExampleLink from "../../../FieldExampleLink"
import { ControlProps } from "../../types"
import ModuleLabel from "../../../ModuleLabel"

const ModuleWrapper = styled.div`
  display: flex;
  > div {
    flex: 1;
    &:first-child {
      margin-right: 5px;
    }
    &:nth-child(2) {
      margin-left: 5px;
      margin-right: 10px;
    }
  }
`

const LinkWrapper = styled.div`
  text-align: right;
  margin-top: 28px;
`

/**
 * Multi row module field for URL and Group
 *
 * This input will listen for changes to it's
 * "language_source" sibling input, a control in
 * the same group of type "language", it will use
 * the language value of this control to switch
 * it's contents to the right language.
 *
 * @param form
 * @param control
 * @returns {JSX.Element}
 * @constructor
 */
const UrlGroupModule = ({ form, control }) => {
  const {
    name,
    type,
    language_source,
    value,
    constrains,
    suppressFeedback,
    separator,
    example,
    defaultValue,
    props: controlProps,
    hidden
  } = control
  const { disabled } = controlProps || {}
  const languageState = language_source ? form.getFieldState(language_source) : null
  const [languageListener, setLanguageListener] = useState(null)
  const [language, setLanguage] = useState(languageState ? languageState.value : null)
  const [moduleFields, setModuleFields] = useState([])
  const groups = useSelector(getGroups)
  const groupsList = groups.map(({ id, name }) => ({ label: name, value: id }))

  useEffect(() => {
    if (language_source) {
      setLanguageListener(
        form.subscribe(
          ({ values }) => {
            if (values[language_source] !== language) {
              setLanguage(values[language_source])
              form.change(name, control[`value_${values[language_source]}`])
            }
          },
          { values: true }
        )
      )
    }
    return () => languageListener && languageListener()
  })

  useEffect(() => {
    const valueDefault = geDefaultValue()
    if (valueDefault && Array.isArray(valueDefault)) {
      setModuleFields(valueDefault)
    } else {
      setModuleFields([])
    }
  }, [language])

  const geDefaultValue = () => {
    if (language_source) {
      const valueKey = `value_${language || "en_US"}`

      if (valueKey in control && control[valueKey]) {
        return control[valueKey]
      }

      if (defaultValue && language && language in defaultValue) {
        return defaultValue[valueKey]
      }
    }

    return value
  }

  // const invalidProp = ({ invalid }) => (invalid ? { invalid } : {})

  const onChangeAlternative = (group, url, index, input) => {
    const cloneArray = [...moduleFields]
    if (group !== null) cloneArray[index].group = group
    if (url !== null) cloneArray[index].url = url

    setModuleFields(cloneArray)
    input.onChange(cloneArray)
  }

  const onRemoveAlternative = (index, input) => {
    const cloneArray = moduleFields.filter((_field, fieldIndex) => fieldIndex !== index)

    setModuleFields(cloneArray)
    input.onChange(cloneArray)
  }

  return (
    <Field
      name={name}
      type={type}
      initialValue={geDefaultValue()}
      validate={resolveValidations(constrains)}
    >
      {({ input, meta }) => (
        <FieldContainer separator={separator} hidden={hidden}>
          <InputContainer>
            {!disabled && (
              <div>
                <div>
                  {moduleFields.map(({ group, url }, subindex) => (
                    <div key={`${group}-${subindex}`}>
                      <br />
                      <ModuleLabel
                        control={control}
                        subindex={subindex}
                        label1="Alternative URL for specific group(s)"
                        label2="Recipient group(s) for alternative URL"
                      />
                      <ModuleWrapper>
                        <Input
                          value={url}
                          onChange={(newValue) =>
                            onChangeAlternative(null, newValue, subindex, input)
                          }
                          placeholder="https://"
                        />
                        {!groupsList || !groupsList.length ? (
                          <Input placeholder="Loading..." disabled />
                        ) : (
                          <CustomSelect
                            style={{ width: "100%" }}
                            id="customSelect"
                            placeholder="Choose or search group..."
                            onChange={({ value }) =>
                              onChangeAlternative(value, null, subindex, input)
                            }
                            options={groupsList}
                            defaultValue={groupsList.find((grp) => grp.value === group)}
                            isSearch
                            maxHeightDropdown="250px"
                            searchBy="label"
                          />
                        )}
                        <IconAction
                          name="Trash"
                          onClick={() => onRemoveAlternative(subindex, input)}
                          tooltipText="Remove entry"
                          renderTooltipOnHover
                        />
                      </ModuleWrapper>
                    </div>
                  ))}
                  <LinkWrapper>
                    <Link
                      to="/#"
                      onClick={(e) => {
                        e.preventDefault()
                        setModuleFields([
                          ...moduleFields,
                          {
                            group: null,
                            url: null
                          }
                        ])
                      }}
                    >
                      Add Alternative URL & Recipient Group(s)
                    </Link>
                  </LinkWrapper>
                </div>
              </div>
            )}
            {disabled && (
              <div>
                {moduleFields.map(({ url }, index) => (
                  <div>
                    <ModuleLabel
                      control={control}
                      index={3}
                      subindex={index}
                      label1="Alternative URL for specific group(s)"
                    />
                    <InputText>{url}</InputText>
                    <br />
                    <br />
                  </div>
                ))}
              </div>
            )}
          </InputContainer>
          {!suppressFeedback && <FieldError {...meta} />}
          {!disabled && example !== null && <FieldExampleLink control={control} />}
        </FieldContainer>
      )}
    </Field>
  )
}

UrlGroupModule.propTypes = {
  form: PropTypes.instanceOf(FormApi).isRequired,
  control: ControlProps.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func)
}

UrlGroupModule.defaultProps = {
  validations: []
}

export default UrlGroupModule
