/* eslint-disable no-shadow */
import * as campaignActions from "./actions"

const defaultState = {
  data: [],
  searchCampaign: null,
  metadataTask: {},
  campaignInfo: null,
  emailSubject: null,
  campaignInfoLoading: false,
  currentStep: null,
  isCampaignsLoading: false,
  loading: false,
  error: null,
  success: null,
  breadcrumbs: ["Pulse", "Campaigns"],
  recurring_detail: {}
}

export const setSearchCampaign = (search) => ({
  type: campaignActions.SET_SEARCH_RECURRING_CAMPAIGNS,
  search
})

export const getCampaigns = (page_number, page_size, search, showLoading = true) => ({
  type: campaignActions.GET_RECURRING_CAMPAIGNS,
  showLoading,
  payload: { page_number, page_size, search }
})

export const getCampaignsOfRecurringCampaign = (id, page_number, page_size) => ({
  type: campaignActions.GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN,
  id,
  payload: { page_number, page_size }
})

export const getCampaignById = (id, cb) => ({
  type: campaignActions.GET_RECURRING_CAMPAIGN_BY_ID,
  id,
  cb
})

export const resetCampaign = () => ({
  type: campaignActions.RESET_RECURRING_CAMPAIGN
})

export const createCampaign = (payload) => ({
  type: campaignActions.CREATE_RECURRING_CAMPAIGN,
  payload
})

export const updateCampaign = (id, payload, currentStep, noStep, toggleModal) => ({
  type: campaignActions.UPDATE_RECURRING_CAMPAIGN,
  id,
  payload,
  currentStep,
  noStep,
  toggleModal
})

export const deleteRecurringCampaign = (id) => ({
  type: campaignActions.DELETE_RECURRING_CAMPAIGN,
  id
})

export const setBreadcrumbs = (breadcrumbs) => ({
  type: campaignActions.SET_BREADCRUMBS,
  breadcrumbs: [...defaultState.breadcrumbs, ...breadcrumbs]
})

export const getMetadataTaskStatus = () => ({
  type: campaignActions.GET_METADATA_TASK_STATUS,
  payload: {}
})

export const moveCampaignWizardToStep = (step) => ({
  type: campaignActions.MOVE_RECURRING_CAMPAIGN_WIZARD_TO_STEP,
  step
})

function getNextStep(currentStep, noStep) {
  if (noStep) {
    return currentStep
  }
  return currentStep >= 4 ? currentStep : currentStep + 1
}

export const getEmailSubject = (id) => ({
  type: campaignActions.GET_CAMPAIGN_EMAIL_SUBJECT,
  id
})

const ACTION_HANDLERS = {
  [campaignActions.GET_RECURRING_CAMPAIGNS]: (state, action) => ({
    ...state,
    isCampaignsLoading: action.showLoading
  }),
  [campaignActions.GET_RECURRING_CAMPAIGNS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.data,
    isCampaignsLoading: false
  }),
  [campaignActions.GET_RECURRING_CAMPAIGNS_FAILED]: (state, { error }) => ({
    ...state,
    error,
    isCampaignsLoading: false
  }),
  [campaignActions.GET_RECURRING_CAMPAIGN_BY_ID]: (state) => ({
    ...state,
    loading: true,
    campaignInfoLoading: true,
    currentStep: 1
  }),
  [campaignActions.GET_RECURRING_CAMPAIGN_BY_ID_SUCCESS]: (state, action) => ({
    ...state,
    campaignInfo: action.data,
    loading: false,
    success: true,
    campaignInfoLoading: false
  }),
  [campaignActions.GET_RECURRING_CAMPAIGN_BY_ID_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error,
    campaignInfoLoading: false
  }),
  [campaignActions.RESET_RECURRING_CAMPAIGN]: (state) => ({
    ...state,
    campaignInfo: null,
    currentStep: 1
  }),
  [campaignActions.SET_BREADCRUMBS]: (state, action) => ({
    ...state,
    breadcrumbs: action.breadcrumbs
  }),
  [campaignActions.CREATE_RECURRING_CAMPAIGN]: (state) => ({ ...state, loading: true }),
  [campaignActions.UPDATE_RECURRING_CAMPAIGN]: (state) => ({ ...state, loading: true }),
  [campaignActions.CREATE_RECURRING_CAMPAIGN_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    success: true,
    campaignInfo: action.data,
    currentStep: action.data.wizard_step,
    data: { ...state.data, results: [action.data, ...state.data.results] }
  }),
  [campaignActions.CREATE_RECURRING_CAMPAIGN_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),
  [campaignActions.UPDATE_RECURRING_CAMPAIGN_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    success: true,
    campaignInfo: action.data,
    currentStep: getNextStep(state.currentStep, action.noStep),
    data: {
      ...state.data,
      results: state.data.results.map((campaign) => {
        if (campaign.id === action.data.id) {
          return {
            ...campaign,
            ...action.data
          }
        }
        return campaign
      })
    }
  }),
  [campaignActions.UPDATE_RECURRING_CAMPAIGN_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),
  [campaignActions.MOVE_RECURRING_CAMPAIGN_WIZARD_TO_STEP]: (state, action) => ({
    ...state,
    currentStep: action.step
  }),
  [campaignActions.DELETE_RECURRING_CAMPAIGN]: (state) => ({ ...state, loading: true }),
  [campaignActions.DELETE_RECURRING_CAMPAIGN_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    success: true,
    data: {
      ...state.data,
      results: state.data.results.filter((campaign) => campaign.id !== action.id)
    }
  }),
  [campaignActions.DELETE_RECURRING_CAMPAIGN_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),
  [campaignActions.GET_CAMPAIGN_EMAIL_SUBJECT]: (state) => ({ ...state, loading: true }),
  [campaignActions.GET_CAMPAIGN_EMAIL_SUBJECT_SUCCESS]: (state, action) => ({
    ...state,
    emailSubject: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_CAMPAIGN_EMAIL_SUBJECT_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN]: (state, action) => ({
    ...state,
    recurring_detail: {
      ...state.recurring_detail,
      [action.id]: { ...state.recurring_detail[action.id], loading: true }
    }
  }),
  [campaignActions.GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN_SUCCESS]: (state, action) => ({
    ...state,
    recurring_detail: {
      ...state.recurring_detail,
      [action.id]: { data: action.data, loading: false }
    }
  }),
  [campaignActions.GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN_FAILED]: (state, { id, error }) => ({
    ...state,
    recurring_detail: { ...state.recurring_detail, [id]: { error, loading: false } }
  }),
  [campaignActions.SET_SEARCH_RECURRING_CAMPAIGNS]: (state, { search }) => ({
    ...state,
    searchCampaign: search
  })
}

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
