/* eslint-disable import/prefer-default-export */
import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const MenuGroups = styled.ul`
  white-space: nowrap;
  margin: 0;
  padding: 0 10px 0 0;
  list-style: none;
  
  li {
    display: block;
    
    & > ul {
      width: 100%;
      display: inline-block;
      padding: bottom: 10px;
    }
  }
`

export const MenuLink = styled.a`
  font-size: 14px;
  color: #34383c;
  display: block;
  width: calc(100% - 3px);
  border-left: 3px solid white;
  height: 32px;
  line-height: 32px;
  display: flex;
  padding-left: 12px;
  align-items: center;

  & > span {
    flex: 1 1 auto;
    & > b {
      display: inline-block;
      margin-right: 8px;
    }
  }

  & > svg {
    height: 12px;
    width: 12px;
    & g > g > path {
      fill: ${theme.colors.gray.opaque[500]};
    }
  }

  &:hover {
    color: ${theme.text.color.linkHover};
  }

  &:focus {
    color: #34383c;
  }

  ${(props) => {
    if (props.selected) {
      return `
          border-color: ${theme.colors.primary["500"]};
          color: ${theme.colors.primary["500"]} !important;
          font-weight: bold;
        `
    }
    return ""
  }}
`
