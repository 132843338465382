/* eslint-disable no-undef */
/* eslint-disable camelcase, react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { connect, useSelector } from "react-redux"
import { Icon, CustomSelect } from "@elevate_security/elevate-component-library"
import ReactDOM from "react-dom"
import tippy from "tippy.js"
import {
  HeaderRow,
  CampaignTitle,
  StatusBadge,
  SectionTitle,
  VerticalCardsContainer,
  VerticalCard,
  Title,
  SubTitle,
  Description,
  DetailsCard,
  DetailsCardTitle,
  FieldsRow,
  Field,
  IconWrapper,
  GraphContainer,
  Row,
  LegendLabel,
  LegendBullet,
  LegendWrapper,
  LegendIcon
} from "./styles"
import { getStatCards, getFields } from "./utils"
import { campaignType } from "../../../types"
import { isLoading } from "../../../sagas/campaignSaga/selectors"
import { getConfigurations } from "../../../sagas/configuratorSaga/selectors"
import ActivityIndicator from "../../../components/ActivityIndicator"

import {
  getCampaignById,
  setBreadcrumbs,
  getHistoricalStatsById,
  getNewHiresRatioById,
  getRecipientsCountById
} from "../../../sagas/campaignSaga/reducer"
import { getEmailTemplates } from "../../../sagas/templatesSaga/reducer"
import { listConfigurations } from "../../../sagas/configuratorSaga/reducer"
import { CAMPAIGN_STATUS, PULSE_POST_DAYS } from "../../../constants"
import DistributionCurve from "./DistributionCurve"
import NewHiresRatio from "./NewHiresRatio"
import ChartLines from "./ChartLines"
import TrackingPlatformsChart from "./TrackingPlatforms"
import ScoreTooltip from "./ScoreTooltip"

const CampaignSummary = ({
  campaignInfo: campaign,
  match,
  getCampaignByIdFunc,
  setBreadcrumbsFunc,
  history,
  getHistoricalStatsByIdFunc,
  historicalStats,
  getEmailTemplatesFunc,
  templates,
  getNewHiresRatioFunc,
  newHiresInfo,
  getRecipientsCountByIdFunc,
  recipients,
  isMVP,
  listConfigurationsFunc,
  configurations,
  base_path
}) => {
  const loading = useSelector(isLoading)
  useEffect(() => {
    if (!configurations || !configurations.length) {
      listConfigurationsFunc()
    }
    const {
      params: { id }
    } = match
    if (id) {
      getCampaignByIdFunc(id, (camp) => {
        if (camp.status !== CAMPAIGN_STATUS.COMPLETED) {
          history.push(`${base_path}/${id}`)
        }
      })
    }
  }, [])

  useEffect(() => {
    if (campaign) {
      setBreadcrumbsFunc([campaign.name])
      getNewHiresRatioFunc(campaign.id)
      if (isMVP) {
        getHistoricalStatsByIdFunc(campaign.id)
      }
      getRecipientsCountByIdFunc(campaign.id)
    }
    getEmailTemplatesFunc()
  }, [campaign])

  const [pulseDays, setPulseDays] = useState(0)
  const [hideOpened, sethHdeOpened] = useState(false)
  const [hideClicked, setHideClicked] = useState(false)

  if (loading) return <ActivityIndicator active={loading} position="center" />
  const { count: recipientsCount } = recipients || {}

  const fields = getFields(campaign || {}, templates || [], configurations || [], recipientsCount)

  if (isMVP) {
    if (!campaign || !historicalStats || !newHiresInfo) return null
  } else if (!campaign) return null

  const handleOnMouseEnter = (info) => {
    const { score, date, elementId, barType } = info
    const tooltipId = `${elementId}-tooltip-content`
    const toolTipContent = document.createElement("div")

    toolTipContent.setAttribute("id", tooltipId)
    toolTipContent.style.display = "block"
    toolTipContent.style.minWidth = "900px"

    ReactDOM.render(<ScoreTooltip score={score} date={date} barType={barType} />, toolTipContent)

    const tippyProps = {
      theme: "light-border",
      allowHTML: true,
      content: toolTipContent.innerHTML,
      onHide() {
        toolTipContent.remove()
      }
    }

    const element = document.querySelector(`#${elementId}`)
    if (element) {
      tippy(element, tippyProps)
      // eslint-disable-next-line no-underscore-dangle
      element._tippy.show()
    }
  }

  const { name, status, stats } = campaign
  const { new_hires_ratio, total_campaign_new_hires, total_campaign_members } = newHiresInfo || {}
  const hiresRatio = Math.round(new_hires_ratio * 100) / 100
  // incase field are undefined
  const trackingPlatforms = stats || { open_platforms: {}, click_platforms: {} }

  return (
    <div>
      <HeaderRow>
        <CampaignTitle>{name}</CampaignTitle>
        <StatusBadge>{typeof status === "string" ? status.toLowerCase() : "N/A"}</StatusBadge>
      </HeaderRow>
      <SectionTitle>Campaign Summary</SectionTitle>
      <VerticalCardsContainer>
        {getStatCards(campaign, recipientsCount).map(
          ({ title, subtitle, description, distributionCurve }) => (
            <VerticalCard>
              <Title>{title}</Title>
              <SubTitle>{subtitle}</SubTitle>
              <Description>{description}</Description>
              <DistributionCurve {...{ distributionCurve }} />
            </VerticalCard>
          )
        )}
      </VerticalCardsContainer>
      <DetailsCard>
        <IconWrapper>
          <Icon name="ClipBoard" size="lg" fill="#F5A83F" />
        </IconWrapper>
        <DetailsCardTitle>Additional Details</DetailsCardTitle>
        <FieldsRow>
          {fields.map((field) => (
            <Field>
              <div className="field-label">{field.label}</div>
              <div className="field-value">{field.value}</div>
            </Field>
          ))}
        </FieldsRow>
      </DetailsCard>
      <TrackingPlatformsChart trackingPlatforms={trackingPlatforms} />
      <NewHiresRatio
        hiresRatio={hiresRatio}
        totalEmployees={total_campaign_members}
        totalNewHires={total_campaign_new_hires}
      />
      {isMVP && (
        <GraphContainer>
          <Row>
            <SectionTitle>Pulse Sends - Engagement Over Time</SectionTitle>
            <CustomSelect
              id="customSelect"
              placeholder="7 days post-send"
              options={PULSE_POST_DAYS}
              onChange={(e) => setPulseDays(e.index)}
            />
            {Object.keys(historicalStats).length > 0 && (
              <LegendWrapper>
                <LegendLabel onClick={() => sethHdeOpened(!hideOpened)}>
                  <LegendIcon icon={hideOpened ? "EyeClose" : "Eye"}>
                    <Icon name={hideOpened ? "EyeClose" : "Eye"} fill="#959DA8" />
                  </LegendIcon>
                  <LegendBullet color="#0C7D84" />
                  <span>Opened</span>
                </LegendLabel>
                <LegendLabel onClick={() => setHideClicked(!hideClicked)}>
                  <LegendIcon icon={hideClicked ? "EyeClose" : "Eye"}>
                    <Icon name={hideClicked ? "EyeClose" : "Eye"} fill="#959DA8" />
                  </LegendIcon>
                  <LegendBullet color="#668CE5" />
                  <span>Clicked</span>
                </LegendLabel>
              </LegendWrapper>
            )}
          </Row>
          <ChartLines
            historicalStats={historicalStats}
            onMouseEnter={handleOnMouseEnter}
            pulseDays={pulseDays}
            hideOpened={hideOpened}
            hideClicked={hideClicked}
          />
        </GraphContainer>
      )}
    </div>
  )
}

CampaignSummary.propTypes = {
  campaignInfo: campaignType,
  newHiresInfo: PropTypes.shape({
    params: PropTypes.shape({
      new_hires_ratio: PropTypes.number,
      total_campaign_new_hires: PropTypes.number,
      total_campaign_members: PropTypes.number
    })
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    })
  }).isRequired,
  historicalStats: PropTypes.shape({
    params: PropTypes.shape({
      Bounced: PropTypes.number,
      Clicked: PropTypes.number,
      Delivered: PropTypes.number,
      Opened: PropTypes.number
    })
  }),
  templates: PropTypes.shape({
    params: PropTypes.shape({
      label: PropTypes.string,
      template_path: PropTypes.string
    })
  }),
  getCampaignByIdFunc: PropTypes.func.isRequired,
  setBreadcrumbsFunc: PropTypes.func.isRequired,
  getNewHiresRatioFunc: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  recipients: PropTypes.shape({
    count: PropTypes.number
  }),
  isMVP: PropTypes.bool
}
CampaignSummary.defaultProps = {
  campaignInfo: null,
  historicalStats: {},
  templates: [],
  recipients: {},
  isMVP: false
}

const mapStateToProps = (state) => ({
  campaignInfo: state.campaigns.campaignInfo,
  newHiresInfo: state.campaigns.newHiresRatio,
  historicalStats: state.campaigns.historicalStats,
  recipients: state.campaigns.recipients,
  templates: state.templates.templates,
  configurations: getConfigurations(state)
})

const mapDispatchToProps = {
  getCampaignByIdFunc: getCampaignById,
  setBreadcrumbsFunc: setBreadcrumbs,
  getNewHiresRatioFunc: getNewHiresRatioById,
  getHistoricalStatsByIdFunc: getHistoricalStatsById,
  getEmailTemplatesFunc: getEmailTemplates,
  getRecipientsCountByIdFunc: getRecipientsCountById,
  listConfigurationsFunc: listConfigurations
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignSummary)
