import React from "react"
import PropTypes from "prop-types"
import dompurify from "dompurify"
import { Icon, Tooltip, theme, Typography } from "@elevate_security/elevate-component-library"
import styled from "styled-components"

const IconContainer = styled.span`
  svg > g > g > g > g {
    fill: ${({ danger }) =>
      danger ? theme.colors.border.error : theme.icons.color.light} !important;
  }
  &:hover {
    svg > g > g > g > g {
      fill: ${({ danger }) =>
        danger ? theme.colors.border.error : theme.icons.color.linkHover} !important;
    }
  }
`

const { Text } = Typography

const FieldHelpToolTip = ({ text, danger }) => {
  if (!text) return null
  const sanitizer = dompurify.sanitize

  return (
    <Tooltip
      body={
        <Text
          style={{ fontSize: "13px" }}
          fontWeight="normal"
          dangerouslySetInnerHTML={{ __html: sanitizer(text) }}
        />
      }
      placement="right"
      theme="light-border"
      size="xsm"
    >
      <IconContainer danger={danger}>
        <Icon name="InfoCheckOutline" size="xsm" fill={theme.colors.gray.opaque["300"]} />
      </IconContainer>
    </Tooltip>
  )
}

FieldHelpToolTip.propTypes = {
  text: PropTypes.oneOf([PropTypes.string, PropTypes.element]),
  danger: PropTypes.bool
}

FieldHelpToolTip.defaultProps = {
  text: null,
  danger: false
}

export default FieldHelpToolTip
