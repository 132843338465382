import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { getLanguage, getState, getPreview } from "../../../sagas/configuratorSaga/selectors"
import { getTemplatePreview } from "../../../sagas/configuratorSaga/reducer"

const withTemplatePreview = (Component) => (props) => {
  const dispatch = useDispatch()
  const state = useSelector(getState)
  const language = useSelector(getLanguage)
  const preview = useSelector(getPreview)
  const [previewActive, setPreviewActive] = useState(false)
  const [templateId, setTemplateId] = useState(null)
  const [configurationId, setConfigurationId] = useState(null)

  const reloadPreview = ({ id: _id, templateId: _templateId, ...rest }) => {
    const template = (_templateId || "employees/default").split("/")

    dispatch(
      getTemplatePreview({
        type: template[0],
        variation: template[1],
        state: state.value,
        language: language.value,
        configurationId: _id,
        ...(rest || {})
      })
    )
  }

  const handlePreview = (e, options) => {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }

    if (options.active) {
      reloadPreview(options)
    }

    setPreviewActive(options.active)
    setTemplateId(options.templateId)
    setConfigurationId(options.id)
  }

  return (
    <Component
      {...props}
      state={state}
      language={language}
      preview={preview}
      previewActive={previewActive}
      handlePreview={handlePreview}
      templateId={templateId}
      configurationId={configurationId}
    />
  )
}

export default withTemplatePreview
