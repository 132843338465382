import styled from "styled-components"
import { Typography } from "@elevate_security/elevate-component-library"

const { Text } = Typography

export const EmptyListMessage = styled(Text)`
  font-size: 17px;

  [class^="DragosaurWithUmbrellastyles"] {
    padding-top: 70px !important;
    span {
      border-bottom: none !important;
      margin-left: 30px;
    }
  }
`
export const HeadingWrapper = styled(Text)`
  h1 {
    margin: 16px 0 24px 0;
    line-height: normal;
  }
`
export const Wrapper = styled.div`
  max-height: calc(100vh - 253px);
  overflow-y: scroll;
  overflow-x: hidden;
`
