import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Typography, Modal, Header } from "@elevate_security/elevate-component-library"
import { useDispatch } from "react-redux"
import TemplatePreview from "../TemplatePreview"
import ConfiguratorStateSelector from "../ConfiguratorStateSelector"
import {
  setPreviewLanguage,
  setPreviewState,
  setPreviewConfigurationId,
  setPreviewTemplateId
} from "../../sagas/configuratorSaga/reducer"

const StyledModalContainer = styled.div`
  [class^="Modalstyles__ModalFooter"] {
    display: none;
  }
  [class^="Modalstyles__ModalContent"] {
    padding: 0;
  }
`

const BodyContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  margin: ${(props) => props.verticalMargin} auto;
`
const HelpText = styled.span`
  font-size: 16px;
  color: #565d66;
`

const { H2 } = Typography

const PreviewModal = ({
  configurationId,
  active,
  title,
  templateId,
  preview,
  languages,
  state,
  language,
  loading,
  onClose
}) => {
  const dispatch = useDispatch()
  /**
   * Update the store on every state or language change
   *
   * @param type
   * @returns {function(...[*]=)}
   */
  const handleChange = (type) => (value) => {
    dispatch(setPreviewConfigurationId(configurationId))
    dispatch(setPreviewTemplateId(templateId))
    setTimeout(() => {
      if (type === "state") {
        dispatch(setPreviewState(value))
      }
      if (type === "language") {
        console.log(value)
        dispatch(setPreviewLanguage(value))
      }
    })
  }

  const modalTitle = (
    <Header>
      <H2 color="900" fontWeight="bold">
        {title || "Preview Template"}
      </H2>
      <HelpText>
        Preview by behavior score to test all button link URL’s and text updates from
        configurations.
      </HelpText>
    </Header>
  )

  return (
    <StyledModalContainer>
      <Modal
        style={{ maxWidth: "1158px", height: "95%", maxHeight: "95%" }}
        header={modalTitle}
        isOpen={active}
        onClose={onClose}
      >
        <BodyContainer>
          <ConfiguratorStateSelector
            templateId={templateId}
            configurationId={configurationId}
            languages={languages}
            onStateChange={handleChange("state")}
            onLanguageChange={handleChange("language")}
            state={state}
            language={language}
          />
          <TemplatePreview loading={loading} preview={preview} />
        </BodyContainer>
      </Modal>
    </StyledModalContainer>
  )
}

PreviewModal.propTypes = {
  loading: PropTypes.bool.isRequired,
  active: PropTypes.bool.isRequired,
  preview: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  configurationId: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  state: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  language: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  onClose: PropTypes.func
}

PreviewModal.defaultProps = {
  onClose: () => null
}

export default PreviewModal
