import styled from "styled-components"

import { Icon, Typography } from "@elevate_security/elevate-component-library"
import { CAMPAIGN_STATUS } from "../../../../constants"
import theme from "../../../../css/theme"

const { colors } = theme
const { H2, Small } = Typography

const TAG_COLOR = {
  [CAMPAIGN_STATUS.SCHEDULED]: colors.tag.secondary,
  [CAMPAIGN_STATUS.DRAFT]: colors.tag.default,
  [CAMPAIGN_STATUS.COMPLETED]: colors.tag.primary
}

export const Heading = styled(H2).attrs({
  fontWeight: "bold"
})`
  color: ${colors.text.link};
  font-size: 18px;
  :hover {
    text-decoration: underline;
    cursor: pointer;
    color: ${colors.text.linkHover};
  }
`

export const ItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${colors.border.default};
  padding: 1.43rem 2rem 1.43rem 0;

  :first-child {
    border-top: none;
  }
`

export const Status = styled(Small).attrs({
  fontWeight: "normal"
})`
  color: #565d66;
  font-size: 13px;
  padding: 4px 8px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 8px;
  border-radius: 35px;
  background-color: ${(props) => TAG_COLOR[props.status]};
`

export const TestCampaign = styled(Small).attrs({
  fontWeight: "normal"
})`
  color: #565d66;
  padding: 4px 8px;
  font-size: 13px;
  text-align: center;
  display: inline-block;
  margin-left: 8px;
  border-radius: 35px;
  background-color: ${colors.tag.test};
`

export const FlexSpacer = styled.div`
  flex: 1 0 50%;
  display: flex;
  justify-content: space-between;
`

export const RecipientsContainer = styled.div`
  display: flex;
  flex: 0 1 150px;
  flex-direction: column;
`

export const RecipientCount = styled.div`
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  color: #565d66;
  margin-bottom: 8px;
`

export const RecipientProgressBar = styled.div`
  display: block;
  height: 4px;
  border-radius: 2px;
  background-color: ${colors.athens_gray};

  &:before {
    display: absolute;
    top: 0;
    content: " ";
    border-radius: 2px ${(props) => (props.progress > 99 ? `2px 2px` : "0px 0px")} 2px;
    background-color: ${colors.surfie_green};
    width: ${(props) => props.progress}%;
    height: 4px;
    display: block;
  }
`

export const RecipientLegend = styled.div`
  font-size: 14px;
  line-height: 24px;

  display: flex;
  align-items: center;
  color: ${colors.rolling_stone};
`

export const ContentRight = styled.div`
  flex: 1 0 50%;
  display: flex;
  justify-content: end;

  & > div {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
`

export const ActionIcon = styled(Icon).attrs({
  size: "sm",
  stroke: colors.icon.default,
  fill: colors.icon.default
})`
  :hover {
    path {
      fill: ${colors.icon.hover};
      stroke: ${colors.icon.hover};
    }
  }
`
