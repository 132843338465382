import * as actions from "./actions"
import FormManager from "./form"
import { trackSelected } from "./helpers/utils"

const defaultStateObject = { value: "flimsy", label: "Flimsy" }
const defaultLanguageObject = {
  value: "en_US",
  label: "English",
  name: "ENGLISH"
}

export const updateForm = (values) => ({
  type: actions.UPDATE_CONFIGURATOR_FORM,
  data: values
})

export const listConfigurations = () => ({
  type: actions.LIST_CONFIGURATIONS
})

export const fetchGroups = () => ({
  type: actions.GET_GROUPS
})

export const getConfiguration = (payload, callback) => ({
  type: actions.GET_CONFIGURATION,
  payload,
  callback
})

export const getScoreConfiguration = () => ({
  type: actions.GET_SCORE_CONFIGURATION
})

export const resetConfiguration = () => ({
  type: actions.RESET_CONFIGURATION
})

export const patchConfiguration = (payload) => ({
  type: actions.PATCH_CONFIGURATION,
  payload
})

export const deleteConfiguration = (payload) => ({
  type: actions.DELETE_CONFIGURATION,
  payload
})

export const createConfiguration = (payload, callback) => ({
  type: actions.CREATE_CONFIGURATION,
  payload,
  callback
})

export const copyConfiguration = (payload) => ({
  type: actions.COPY_CONFIGURATION,
  payload
})

export const getTemplatePreview = (payload) => ({
  type: actions.GET_TEMPLATE_PREVIEW,
  payload
})

export const sendTestEmail = (payload) => ({
  type: actions.SEND_EMAIL,
  payload
})

export const resetTestEmailState = () => ({
  type: actions.SEND_EMAIL_RESET_STATE
})

export const setPreviewConfigurationId = (payload) => ({
  type: actions.SET_PREVIEW_CONFIGURATION_ID,
  payload
})

export const setPreviewTemplateId = (payload) => ({
  type: actions.SET_PREVIEW_TEMPLATE_ID,
  payload
})

export const setPreviewState = (payload) => ({
  type: actions.SET_PREVIEW_STATE,
  payload
})

export const setPreviewLanguage = (payload) => ({
  type: actions.SET_PREVIEW_LANGUAGE,
  payload
})

export const setPreviewRef = (payload) => ({
  type: actions.SET_PREVIEW_REF,
  payload
})

export const setScrollPosition = () => ({
  type: actions.SET_CURRENT_SCROLL_POSITION
})

export const toggleCreateConfigurationModal = (payload) => ({
  type: actions.TOGGLE_CREATE_CONFIGURATION_FORM,
  payload
})

export const trackReadOnlyConfigurationBlock = (payload) => ({
  type: actions.TRACK_READ_ONLY_CONFIGURATION_BLOCK,
  payload
})

const computeCurrentScrollLocation = (previewRef) => previewRef?.contentWindow?.scrollY

export const setLanguagesOptions = (languages) => ({
  type: actions.SET_LANGUAGES_OPTIONS,
  languages
})

const ACTION_HANDLERS = {
  [actions.UPDATE_CONFIGURATOR_FORM]: (state, { data }) => {
    const current = { ...state.current, ...data }
    return {
      ...state,
      current,
      form: new FormManager(current)
    }
  },
  [actions.LIST_CONFIGURATIONS]: (state) => ({
    ...state,
    isConfigurationLoading: true
  }),
  [actions.LIST_CONFIGURATIONS_SUCCESS]: (state, { data }) => ({
    ...state,
    configurations: data,
    isConfigurationLoading: false
  }),
  [actions.LIST_CONFIGURATIONS_FAILED]: (state, { error }) => ({
    ...state,
    error,
    current: null
  }),

  [actions.GET_GROUPS]: (state) => ({
    ...state,
    loading: true
  }),
  [actions.GET_GROUPS_SUCCESS]: (state, { data }) => ({
    ...state,
    groups: data,
    loading: false
  }),
  [actions.GET_GROUPS_FAILED]: (state, { error }) => ({
    ...state,
    error,
    loading: false,
    current: null
  }),

  [actions.RESET_CONFIGURATION]: (state) => ({
    ...state,
    current: null,
    form: new FormManager(),
    isNewTemplate: false,
    editing: [],
    state: defaultStateObject,
    language: defaultLanguageObject
  }),
  [actions.GET_CONFIGURATION]: (state) => ({
    ...state,
    isGetConfigLoading: true
  }),
  [actions.GET_CONFIGURATION_SUCCESS]: (state, { data }) => ({
    ...state,
    form: new FormManager(data),
    current: data,
    isGetConfigLoading: false
  }),
  [actions.GET_CONFIGURATION_FAILED]: (state, { error }) => ({
    ...state,
    error,
    current: null,
    isGetConfigLoading: false
  }),

  [actions.GET_SCORE_CONFIGURATION]: (state) => ({
    ...state,
    loading: true
  }),
  [actions.GET_SCORE_CONFIGURATION_SUCCESS]: (state, { data }) => ({
    ...state,
    scoreConfiguration: {
      includedBehaviors: data
    },
    loading: false
  }),
  [actions.CREATE_CONFIGURATION]: (state) => ({
    ...state,
    loading: true
  }),
  [actions.CREATE_CONFIGURATION_SUCCESS]: (state, { data }) => {
    const form = new FormManager(data)

    return {
      ...state,
      current: data,
      form,
      loading: false,
      editing: [
        ...form.groupsArray().filter((g) => g.get("type") === "group"),
        ...Array.from(form.get("behaviors").get("controls").values()).filter(
          (g) => g.get("type") === "group"
        )
      ],
      isCreating: false,
      isNewTemplate: true
    }
  },
  [actions.CREATE_CONFIGURATION_FAILED]: (state, { error }) => ({
    ...state,
    form: null,
    error,
    loading: false
  }),

  [actions.COPY_CONFIGURATION]: (state) => ({
    ...state,
    loading: true
  }),
  [actions.COPY_CONFIGURATION_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    isCreating: false
  }),
  [actions.COPY_CONFIGURATION_FAILED]: (state, { error }) => ({
    ...state,
    form: null,
    error,
    loading: false
  }),

  [actions.PATCH_CONFIGURATION]: (state) => ({
    ...state,
    loading: true,
    error: null
  }),
  [actions.PATCH_CONFIGURATION_SUCCESS]: (state, { data, name }) => ({
    ...state,
    form: new FormManager(data),
    current: data,
    loading: false,
    editing: name ? state.editing.filter((item) => item.get("name") !== name) : [],
    isNewTemplate: false
  }),
  [actions.PATCH_CONFIGURATION_FAILED]: (state, { error }) => ({
    ...state,
    error,
    loading: false
  }),

  [actions.DELETE_CONFIGURATION]: (state) => ({
    ...state,
    loading: true
  }),
  [actions.DELETE_CONFIGURATION_SUCCESS]: (state) => ({
    ...state,
    loading: false
  }),
  [actions.DELETE_CONFIGURATION_FAILED]: (state, { error }) => ({
    ...state,
    error,
    loading: false
  }),

  [actions.GET_TEMPLATE_PREVIEW]: (state) => ({
    ...state,
    loading: true
  }),
  [actions.GET_TEMPLATE_PREVIEW_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    preview: action.data
  }),
  [actions.GET_TEMPLATE_PREVIEW_FAILED]: (state, { error }) => ({
    ...state,
    error,
    loading: false,
    preview: null
  }),

  [actions.SEND_EMAIL]: (state) => ({
    ...state,
    email: {
      ...state.email,
      sending: true,
      success: false,
      error: null
    }
  }),
  [actions.SEND_EMAIL_SUCCESS]: (state) => ({
    ...state,
    testEmailState: {
      ...state.testEmailState,
      sending: false,
      success: true,
      error: null
    }
  }),
  [actions.SEND_EMAIL_FAILED]: (state, { error }) => ({
    ...state,
    testEmailState: {
      ...state.testEmailState,
      sending: false,
      success: false,
      error
    }
  }),
  [actions.SEND_EMAIL_RESET_STATE]: (state) => ({
    ...state,
    testEmailState: {
      ...state.testEmailState,
      sending: false,
      success: false,
      error: null
    }
  }),

  [actions.SET_PREVIEW_CONFIGURATION_ID]: (state, action) => ({
    ...state,
    configurationId: action.payload,
    scrollPosition: computeCurrentScrollLocation(state.previewRef)
  }),

  [actions.SET_PREVIEW_TEMPLATE_ID]: (state, action) => ({
    ...state,
    templateId: action.payload,
    scrollPosition: computeCurrentScrollLocation(state.previewRef)
  }),

  [actions.SET_PREVIEW_STATE]: (state, action) => ({
    ...state,
    state: action.payload,
    scrollPosition: computeCurrentScrollLocation(state.previewRef)
  }),

  [actions.SET_PREVIEW_LANGUAGE]: (state, action) => ({
    ...state,
    language: action.payload,
    scrollPosition: computeCurrentScrollLocation(state.previewRef)
  }),

  [actions.SET_PREVIEW_REF]: (state, action) => ({
    ...state,
    previewRef: action.payload
  }),

  [actions.SET_CURRENT_SCROLL_POSITION]: (state, action) => ({
    ...state,
    scrollPosition: action.payload || computeCurrentScrollLocation(state.previewRef)
  }),

  [actions.TOGGLE_CREATE_CONFIGURATION_FORM]: (state, action) => ({
    ...state,
    isCreating: action.payload
  }),

  [actions.SET_LANGUAGES_OPTIONS]: (state, action) => ({
    ...state,
    languages: action.languages
  }),

  [actions.TRACK_READ_ONLY_CONFIGURATION_BLOCK]: (state, action) => ({
    ...state,
    editing: trackSelected(Array.from(state.editing || []), action.payload.on, action.payload.group)
  })
}

export const defaultState = {
  /**
   * Used as a global activity indicator
   */
  loading: false,
  /**
   * The tru version of the current configurator form.
   * Other forms like the one in the create configuration modal
   * are tracked locally by their respective components.
   */
  form: new FormManager(),
  /**
   * The preview html data
   */
  preview: null,
  /**
   * The preview iframe reference, this is used to do things like
   * scroll withing the Iframe.
   */
  previewRef: null,
  /**
   * The list of configurations, used to build the configurations
   * landing page.
   */
  configurations: [],
  isConfigurationLoading: false,
  /**
   * The list of EP groups
   */
  groups: [],
  /**
   * The current configuration object, same object that was used to
   * hydrate the FormManager on load.
   */
  current: null,
  scrollPosition: 0,
  isGetConfigLoading: false,
  /**
   * Default current state object.
   */
  state: defaultStateObject,
  /**
   * Default current language object.
   */
  language: defaultLanguageObject,
  /**
   * List of available states, if this grows any bigger
   * a Pulse api endpoint would work best.
   */
  states: [
    { value: "flimsy", label: "Flimsy" },
    { value: "tenuous", label: "Tenuous" },
    { value: "sturdy", label: "Sturdy" },
    { value: "fortified", label: "Fortified" },
    { value: "indestructible", label: "Indestructible" },
    { value: "no-data", label: "No Data" }
  ],
  /**
   * List of available languages, if this grows any bigger
   * a Pulse api endpoint would work best.
   */
  languages: [],
  /**
   * An array that tracks which block in the configurator form
   * is in edit mode.
   */
  editing: [],
  /**
   * Used mainly by the create configuration modal logic.
   */
  isCreating: false,
  /**
   * Used to detect if configurations screen is for new template or edit template
   */
  isNewTemplate: false,
  testEmailState: {
    sending: false,
    success: false,
    error: false
  },
  /**
   * Used to check minimun scores to render a template block
   */
  scoreConfiguration: {
    includedBehaviors: []
  }
}

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
