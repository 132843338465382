/* eslint-disable no-param-reassign */

import { cloneDeep } from "lodash"
import {
  EMAIL_SUBJECT_DEFAULTS,
  SECURITY_SNAPSHOT_MATCHER,
  DEFAULT_TEMPLATE_CONFIG,
  CONTROLS_FILE_NAME
} from "../constants"

/**
 * Converts the settings group values to the
 * `show_behavior` payload key to satisfy the
 * the payload format.
 *
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The data object coming from the form
 * @returns {Object} A new data object
 */
const normalizeSettings = (member, data) => {
  const mappingFile = Object.keys(DEFAULT_TEMPLATE_CONFIG).map(
    (template_id) => DEFAULT_TEMPLATE_CONFIG[template_id][CONTROLS_FILE_NAME]
  )
  if (mappingFile.includes(member.get("name"))) {
    const nonBehavior = [
      "show_post_header",
      "show_real_world_phishing_no_data_block",
      "show_real_world_phishing_clicked",
      "show_real_world_phishing_reported",
      "show_real_world_phishing_breakdown",
      "phishing_show_period_day",
      "show_phishing_and_reporting_comparison_to_department",
      "show_phishing_and_reporting_graphs"
    ]

    return Object.keys(data).reduce((updatedPayload, key) => {
      if (nonBehavior.indexOf(key) < 0) {
        const value = updatedPayload[key]
        delete updatedPayload[key]

        /**
         * If `show_standard_password_manager` is `false` then also
         * force `show_password_manager_30_days_usage` to `false` so the
         * Password Manager block does not show up
         */
        if (
          key === "show_password_manager_30_days_usage" &&
          value === true &&
          updatedPayload.show_standard_password_manager === false
        ) {
          return {
            ...updatedPayload,
            show_behaviors: {
              ...(updatedPayload.show_behaviors || {}),
              [key]: false
            }
          }
        }
        /**
         * If `show_training_department_comparison` is `false` then also
         * force `show_training_completion_date` to `false` so the
         * Training block does not show up
         */
        if (
          key === "show_training_completion_date" &&
          value === true &&
          updatedPayload.show_training_department_comparison === false
        ) {
          return {
            ...updatedPayload,
            show_behaviors: {
              ...(updatedPayload.show_behaviors || {}),
              [key]: false
            }
          }
        }
        // otherwise assign the value
        return {
          ...updatedPayload,
          show_behaviors: {
            ...(updatedPayload.show_behaviors || {}),
            [key]: value
          }
        }
      }

      return updatedPayload
    }, data)
  }
  return data
}

/**
 * Adds the proper `value_[LANGUAGE]` to the payload
 * based on the `language_source` language choice.
 *
 * This normalizer also removes the value of the language_source
 * field since it's a functional field and it's value is not
 * required in the payload.
 *
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The data object coming from the form
 * @returns {Object} A new data object
 */
const normalizeLanguage = (member, data) => {
  const keys = Object.keys(data)
  const languageKey = keys.reduce((target, key) => {
    const control = member.get("controls").get(key)

    if (!control) {
      return null
    }

    if (control.get("type") === "language") {
      return control.get("name")
    }

    if (control.has("language_source")) {
      const language = data[control.get("language_source")]
      data[`${key}_${language}`] = data[key]
      delete data[key]
      return control.get("language_source")
    }

    return null
  }, null)

  if (languageKey) {
    delete data[languageKey]
  }

  return data
}

/**
 * Any value from controls with a `functional` property set to
 * `true` is removed by this normalizer.
 *
 * Functional controls are there just to add functionality and
 * their values are not required in the payload.
 *
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The data object coming from the form
 * @returns {Object} A new data object
 */
const removeFunctionalFieldValues = (member, data) => {
  if (member.get("type") === "group") {
    const keys = Object.keys(data)

    return keys.reduce((nData, key) => {
      const control = member.get("controls").get(key)

      // langauge will be handled with normalizeLanguage normalizer
      if (control && control.get("type") === "language") {
        return nData
      }

      if (control && control.get("functional") === true) {
        delete nData[key]
      }

      return nData
    }, data)
  }

  return data
}

/**
 * Any value from controls with a `url-group-module` type is
 * normalized from Array of Object to an Object with key, value
 * pairs.
 * Training url module is normalized from Array to json string
 *
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The data object coming from the form
 * @returns {Object} A new data object
 */
const normalizeModuleValues = (member, data) => {
  if (member.get("type") === "group") {
    const keys = Object.keys(data)

    return keys.reduce((nData, key) => {
      const control = member.get("controls").get(key)

      // convert array of objects to one object with key, value pairs
      if (
        control &&
        control.get("type") === "url-group-module" &&
        nData[key] &&
        Array.isArray(nData[key])
      ) {
        nData[key] = nData[key].reduce(
          (accumulator, { group, url }) => (group ? { ...accumulator, [group]: url } : {}),
          {}
        )
      }
      // convert array of objects to json string
      if (
        control &&
        control.get("type") === "training-url" &&
        nData[key] &&
        Array.isArray(nData[key])
      ) {
        const data = nData[key]
        nData[key] = JSON.stringify(data)
      }
      return nData
    }, data)
  }

  return data
}

/**
 * Map the value for field `email_subject` from simple Text to Jinja
 * string. (Replaces the SECURITY_SNAPSHOT_MATCHER with new value
 * in the EMAIL_SUBJECT_DEFAULTS strings)
 *
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The data object coming from the form
 * @returns {Object} A new data object
 *
 * Example value from form:
 * Security Snapshot
 *
 * Example normalized value:
 * {% if (employee_name) %}{{ employee_name|safe }}: {% endif %}Your {{organization_name|safe}} Security Snapshot
 */
const normalizeEmailSubjectTitle = (member, data) => {
  if (member.get("type") === "group") {
    const keys = Object.keys(data)

    return keys.reduce((nData, key) => {
      const control = member.get("controls").get(key)

      if (control && key === "email_subject" && nData[key] && typeof nData[key] === "string") {
        if (control.has("language_source")) {
          const language = nData[control.get("language_source")]
          // Replaces the matcher SECURITY_SNAPSHOT_MATCHER with new value in the EMAIL_SUBJECT_DEFAULTS strings
          nData[key] = EMAIL_SUBJECT_DEFAULTS[language].replace(
            SECURITY_SNAPSHOT_MATCHER,
            nData[key]
          )
        }
      }
      return nData
    }, data)
  }

  return data
}

/**
 * Any value from controls with a `text` or `textarea` type is
 * normalized from `null` to empty string ""
 *
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The data object coming from the form
 * @returns {Object} A new data object
 */
const normalizeEmptyTextValues = (member, data) => {
  if (member.get("type") === "group") {
    const keys = Object.keys(data)

    return keys.reduce((nData, key) => {
      const control = member.get("controls").get(key)

      // convert null to empty string
      if (
        control &&
        (control.get("type") === "text" || control.get("type") === "textarea") &&
        nData[key] === null
      ) {
        nData[key] = ""
      }
      return nData
    }, data)
  }

  return data
}

/**
 * If `score_graphics_type` is changed to value other than `CUSTOM`
 * (e.g. DRAGOSAURS or DIAL) then remove urls for custom images for all levels
 *
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The data object coming from the form
 * @returns {Object} A new data object
 */
const normalizeSecurityScoreImages = (member, data) => {
  if (member.get("type") === "group") {
    const keys = Object.keys(data)

    return keys.reduce((nData, key) => {
      const control = member.get("controls").get(key)

      if (control && key === "score_graphics_type" && nData[key] !== "CUSTOM") {
        nData.image_level_0 = ""
        nData.image_level_1 = ""
        nData.image_level_2 = ""
        nData.image_level_3 = ""
        nData.image_level_4 = ""
        nData.image_level_5 = ""
      }
      return nData
    }, data)
  }

  return data
}

/**
 * Normalizers translates the data from the form into an object
 * that the backend api expects.
 *
 * If you create a custom control and need to change/update
 * it's value before submitting, this is where you should do
 * it.
 *
 * Each normalizer receives the current form member Map instance
 * which can be a group or a control, and the data object, once
 * the necessary changes are made, return the updated data object.
 *
 * @param member The current form member, it can be a group or a control,
 * but it will always be a Map instance.
 * @param data The data object coming from the form
 * @returns {Object} A new data object
 */
const run = (member, data) =>
  [
    normalizeSettings,
    normalizeModuleValues,
    normalizeSecurityScoreImages,
    normalizeEmailSubjectTitle,
    normalizeEmptyTextValues,
    removeFunctionalFieldValues,
    normalizeLanguage
  ].reduce((result, callback) => callback(member, { ...result }, data), { ...cloneDeep(data) })

export default run
