/* eslint-disable react/no-danger */
/* eslint-disable react/forbid-prop-types */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import styled from "styled-components"
import dompurify from "dompurify"

import { hideAlert } from "../../sagas/alertsSaga/reducer"
import theme from "../../css/theme"

const { colors } = theme

const Message = styled.div.attrs({
  className: "alert"
})`
  && {
    box-sizing: border-box;
    border-radius: 4px;
    position: absolute;
    font-size: 14px;
    min-width: 700px;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50px;
  }
`

const CloseButton = styled.button.attrs({
  type: "button",
  className: "close"
})`
  && {
    color: ${colors.text.link};
    outline: none;
    :hover {
      color: ${colors.text.linkHover};
    }
  }
`

class Alerts extends React.Component {
  constructor(props) {
    super(props)
    this.state = null
  }

  render() {
    const errorStyle = {
      display: "flex",
      justifyContent: "space-between",
      border: `1px solid ${colors.border.error}`,
      backgroundColor: `${colors.bg.error}`,
      boxShadow: `0 2px 8px 0 ${colors.bg.error}`
    }
    const successStyle = {
      display: "flex",
      justifyContent: "space-between",
      border: `1px solid ${colors.border.success}`,
      backgroundColor: `${colors.bg.success}`,
      boxShadow: `0 2px 8px 0 ${colors.bg.success}`
    }
    const { messages, hideAlertFunc } = this.props
    const sanitizer = dompurify.sanitize
    return (
      <>
        {messages
          ? messages.map((message) => (
              <Message
                role="alert"
                key={message.id}
                style={message.isError ? errorStyle : successStyle}
              >
                {message && (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: sanitizer(message.text)
                    }}
                  />
                )}
                <CloseButton onClick={() => hideAlertFunc(message.id)}>
                  <span aria-hidden="true">&times;</span>
                </CloseButton>
              </Message>
            ))
          : null}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  messages: state.alerts.messages
})

const mapDispatchToProps = {
  hideAlertFunc: hideAlert
}

Alerts.propTypes = {
  messages: PropTypes.array,
  hideAlertFunc: PropTypes.func
}

Alerts.defaultProps = {
  messages: [],
  hideAlertFunc: null
}

export default connect(mapStateToProps, mapDispatchToProps)(Alerts)
