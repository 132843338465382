import styled from "styled-components"

export const TypeAheadContainer = styled.div`
  .form-control {
    border-radius: 5px;

    input {
      height: 24px;
    }
  }

  .rbt-token {
    background-color: #b8e5e3;
    color: #34383c;
    border-radius: 2px;
    padding: 6px 25px 6px 12px;

    .rbt-token-remove-button {
      transform: scale(1.5);
      right: 4px;
      top: -4px;
      color: #035257;
    }
  }

  .rbt-input {
    border: 1px solid
      ${(props) => (props.invalid ? "rgb(199, 52, 52)" : "rgba(189, 197, 209, 0.5)")};

    &:not(.rbt-input-multi) {
      height: 24x;
      line-height: 24px;
    }

    &:hover {
      border: 1px solid rgba(58, 70, 85, 0.3);
    }

    &.focus {
      border: 1px solid rgb(12, 125, 132);
      box-shadow: rgb(12, 125, 132) 0px 0px 3px;
    }
  }

  .rbt-token-removeable {
    font-size: 13px;
  }

  .dropdown-menu {
    border: 0px solid #fff;
    border-radius: 4px;
    transition: border-color 250ms ease;
    margin: 0;
  }

  .dropdown-menu.show {
    border: 1px solid #dde1e7;
    box-shadow: 0 2px 8px 0 #dde1e7;
  }
`
