/* eslint-disable react/no-unused-prop-types */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import { Button, Icon, theme } from "@elevate_security/elevate-component-library"
import { bindActionCreators } from "redux"
import {
  patchConfiguration as patchConfigurationAction,
  getConfiguration as getConfigurationAction,
  resetConfiguration as resetConfigurationAction,
  trackReadOnlyConfigurationBlock as trackReadOnlyConfigurationBlockAction,
  updateForm as updateFormAction,
  fetchGroups as fetchGroupsAction,
  getScoreConfiguration as getScoreConfigurationAction,
  setLanguagesOptions
} from "../../../sagas/configuratorSaga/reducer"
import FormManager from "../../../sagas/configuratorSaga/form"
import PreviewModal from "../../../components/PreviewModal"
import FieldError from "../../../components/FieldError"
import nomalizer from "../../../sagas/configuratorSaga/normalizers"
import {
  ConfigurationHeader,
  ConfiguratorContainer,
  ConfiguratorMain,
  ConfiguratorPage,
  ErrorWrapper
} from "../styles"
import ConfigurationSidebar from "./components/Sidebar"
import FormBlock from "./components/FormBlock"
import TitleForm from "./components/TitleForm"
import Breadcrumbs from "../../../components/Breadcrumbs"
import CreateModal from "../Configurations/components/CreateModal"
import { getEmailTemplates as getEmailTemplatesAction } from "../../../sagas/templatesSaga/reducer"
import withTemplatePreview from "./withTemplatePreview"
import {
  toObject,
  getInputdValidationError,
  getHiddenGroups,
  getOrderedGroups
} from "../../../sagas/configuratorSaga/helpers/utils"
import { getLanguagesOptions, LANGUAGES_WITH_VALUES, parseTitle } from "../../../utils/utils"
import ConfirmationModal from "../../../components/ConfirmationModal"

const AVAILABLE_BEHAVIORS = {
  show_phishing_and_reporting: "phishing",
  show_real_world_phishing: "real_world_phishing",
  show_secure_browsing: "secure_browsing",
  show_device_security: "device_security",
  show_standard_password_manager: "password_manager",
  show_training_department_comparison: "training",
  show_malware: "malware",
  show_sensitive_data_handling: "sensitive_data_handling",
  show_clean_desk: "clean_desk",
  show_password_security: "password_security",
  show_password_strength: "password_strength",
  show_physical_security: "physical_security",
  show_optional_activities: "optional_activities"
}

const AVAILABLE_BEHAVIORS_TITLES = {
  phishing: "Phishing",
  real_world_phishing: "Actual Phishing",
  secure_browsing: "Secure Browsing",
  device_security: "Device Security",
  password_manager: "Password Manager",
  training: "Training",
  malware: "Malware",
  sensitive_data_handling: "Sensitive Data Handling",
  clean_desk: "Clean Desk",
  password_security: "Password Security",
  password_strength: "Password Strength",
  physical_security: "Physical Security",
  optional_activities: "Optional Activities"
}

class Configurator extends Component {
  formRef

  constructor(props) {
    super(props)
    this.state = {
      forms: {},
      editingTitle: false,
      isInvalidForm: false,
      showConfirmationModal: false,
      onHoldSettings: {}
    }
    this.formRef = React.createRef()
  }

  componentDidMount() {
    const {
      templates,
      groups,
      getConfiguration,
      getScoreConfiguration,
      getEmailTemplates,
      fetchGroups,
      updateLanguages,
      match: { params },
      history
    } = this.props

    getConfiguration(
      params.id,
      (success) => !success && history.push("/engagement/pulse/templates")
    )

    getScoreConfiguration()

    if (!templates || !templates.length) {
      getEmailTemplates()
    }
    if (!groups || !groups.length) {
      fetchGroups()
    }
    if (!this.props.languages.length) {
      const languagesOptions = getLanguagesOptions()
      const languagesKeys = Object.keys(languagesOptions)
      const languagesArray = LANGUAGES_WITH_VALUES.filter((item) =>
        languagesKeys.includes(item.label)
      )
      updateLanguages(languagesArray)
    }
  }

  componentWillUnmount() {
    const { resetConfiguration } = this.props
    resetConfiguration()
  }

  /**
   * Return true if either the template or the language has changed
   * since the last iteration.
   *
   * @param prevProps The previous props object
   * @returns {boolean} True if changed, false otherwise
   */
  hasTemplateStateChanged(prevProps) {
    const { state: previousState, language: previousLanguage } = prevProps
    const { state, language } = this.props
    return previousState.value !== state.value || previousLanguage.value !== language.value
  }

  /**
   * Saves data when there is no need for normalizers.
   *
   * @param id {string} The configuration id
   * @param data {object} The partial configuration properties to update
   */
  handleSave(id, data) {
    const { patchConfiguration } = this.props
    patchConfiguration({ id, data })
    this.setState({ editingTitle: false })
  }

  /**
   *
   * @param values {object} Values for each form field
   * @param included {array} All included behaviors for the organization
   * @returns
   */
  getBehaviorsDifference(values, included = []) {
    const selectedBehaviors = []
    Object.keys(values).forEach((value) => {
      if (value in AVAILABLE_BEHAVIORS && values[value])
        selectedBehaviors.push(AVAILABLE_BEHAVIORS[value])
    })
    return selectedBehaviors.filter((behavior) => !included.includes(behavior))
  }

  /**
   * Handles the form submission.
   * Configurations a re saved on a group basis, so
   * we only process one group at a time.
   *
   * @param group {object} The group to submit
   * @param values {object} The values to submit
   */
  handleSubmit(group, values) {
    const { form, patchConfiguration, includedBehaviors } = this.props

    if (group.get("name") === "settings") {
      const difference = this.getBehaviorsDifference(values, includedBehaviors)
      if (difference.length !== 0) {
        this.setState({
          showConfirmationModal: true,
          onHoldSettings: { group, values, difference }
        })
        return null
      }
    }
    patchConfiguration({
      id: form.controls.get("id").get("value"),
      data: nomalizer(group, values),
      name: group.get("name")
    })
  }

  handleSubmitAll() {
    const { form, current, patchConfiguration, includedBehaviors } = this.props
    const { id, ...currentData } = current
    const { forms } = this.state
    let isInvalid = false
    // difference: blocks enabled that have no data.
    let difference = []

    const data = [
      ...form.groupsArray(),
      ...Array.from(form.get("behaviors").get("controls").values())
    ].reduce((payload, group) => {
      const instance = forms[group.get("name")]

      if (instance) {
        const { values } = instance.getState()

        if (group.get("name") === "settings") {
          difference = this.getBehaviorsDifference(values, includedBehaviors)
        }

        const formFields = Array.from(group.get("controls").values()).map(toObject)
        const isValidForm = formFields.reduce(
          (valid, field) =>
            valid &&
            !getInputdValidationError(values[field.name], null, field.required, field.isURL),
          true
        )

        if (!isValidForm) isInvalid = !isValidForm

        return {
          ...payload,
          ...nomalizer(group, values)
        }
      }

      return payload
    }, currentData || {})

    this.setState({
      isInvalidForm: isInvalid
    })

    if (!isInvalid) {
      if (difference.length !== 0) {
        this.setState({
          showConfirmationModal: true,
          onHoldSettings: { id, data, difference, allSubmit: true }
        })
        return
      }
      patchConfiguration({ id, data })
    }
  }

  handleEditBlock(group, on) {
    const { trackReadOnlyConfigurationBlock } = this.props
    trackReadOnlyConfigurationBlock({ on, group })
  }

  handleEditTitle(editingTitle) {
    this.setState({ editingTitle })
  }

  onToggleConfirmationModal() {
    this.setState({ showConfirmationModal: false })
  }

  /**
   * Handles the accept on the confirmation modal
   */
  handleConfirmUpdateTemplate() {
    const { patchConfiguration, form } = this.props
    // if allSubmit exists and its true then we must be creating a new template
    if ("allSubmit" in this.state.onHoldSettings && this.state.onHoldSettings.allSubmit) {
      const { id, data } = this.state.onHoldSettings
      patchConfiguration({ id, data })
    } else {
      const { group, values } = this.state.onHoldSettings
      patchConfiguration({
        id: form.controls.get("id").get("value"),
        data: nomalizer(group, values),
        name: group.get("name")
      })
    }
    this.setState({ showConfirmationModal: false, onHoldSettings: {} })
  }

  /**
   * Renders the body of the confirmation modal for behaviors without data.
   */
  renderMissingBehaviors() {
    const { difference } = this.state.onHoldSettings
    if (!difference) return null
    return (
      <div>
        <div>Data missing or invalid for the following blocks:</div>
        <ul>
          {difference.map((behavior) => (
            <li>&bull; {AVAILABLE_BEHAVIORS_TITLES[behavior]}</li>
          ))}
        </ul>
      </div>
    )
  }

  /**
   * As forms blocks load, this tracks
   * each form under a state property
   * for easy access.
   *
   * @param name {string} The name of the form to track
   * @param form {object} A form instance
   */
  trackForm(name, form) {
    const { forms } = this.state

    if (!(name in forms)) {
      forms[name] = form

      this.setState({ forms })
    }
  }

  render() {
    const {
      // eslint-disable-next-line max-len
      loading,
      isGetConfigLoading,
      form,
      editing,
      isNewTemplate,
      preview,
      previewActive,
      state,
      language,
      languages,
      previewRef,
      templates,
      handlePreview
    } = this.props
    const { forms, isInvalidForm, showConfirmationModal } = this.state
    const { editingTitle } = this.state
    const { controls } = form
    const id = controls.get("id").get("value")
    const templateId = controls.get("template_id").get("value")
    const isDefaultTemplate = controls.get("default").get("value")
    const templateName = parseTitle(controls.get("name").get("value"), isDefaultTemplate)

    return (
      <ConfiguratorPage>
        <ConfirmationModal
          isOpen={showConfirmationModal}
          headerText={`Warning`}
          bodyText={this.renderMissingBehaviors()}
          helpText="Are you sure you want to perform this action?"
          confirmText={`Yes, Save Template`}
          dismissText={`Cancel`}
          onClose={() => this.onToggleConfirmationModal()}
          onConfirm={() => this.handleConfirmUpdateTemplate()}
        />
        <ConfigurationHeader>
          <Breadcrumbs
            routes={[
              ["Pulse", "campaigns"],
              ["Templates", "templates"],
              [templateName, `templates/${id}`]
            ]}
          />
          <TitleForm
            title={templateName}
            isDefaultTemplate={isDefaultTemplate}
            templateId={templateId}
            loading={isGetConfigLoading}
            disabled={isGetConfigLoading}
            editing={editingTitle}
            onEdit={(on) => this.handleEditTitle(on)}
          />
          <div>
            {isNewTemplate && (
              <Button
                theme="primary"
                disabled={loading}
                style={{ marginRight: "16px" }}
                onClick={() => this.handleSubmitAll()}
              >
                {" "}
                Save All
              </Button>
            )}
            <Button
              theme="secondary"
              onClick={(e) => handlePreview(e, { active: true, id, templateId })}
              disabled={isGetConfigLoading}
            >
              <Icon name="Eye" fill={theme.colors.primary["500"]} size="xsm" /> Preview Template
            </Button>
            {isInvalidForm && (
              <ErrorWrapper>
                <Icon name="CircleExclamation" fill={theme.text.color.error} size="xsm" />
                <FieldError
                  touched
                  error="Your form has errors. Please fix them and submit again."
                />
              </ErrorWrapper>
            )}
          </div>
        </ConfigurationHeader>
        <ConfiguratorContainer scroll>
          <ConfigurationSidebar
            formRef={this.formRef}
            form={form}
            loading={isGetConfigLoading}
            previewRef={previewRef}
          />
          <ConfiguratorMain ref={this.formRef}>
            {getOrderedGroups(form.groupsArray())?.map((group) => {
              // hide certain groups for managers template
              const hiddenGroups = getHiddenGroups(templateId)
              if (hiddenGroups.includes(group.get("name"))) return null
              if (group.get("type") === "group") {
                return (
                  <FormBlock
                    key={group.get("name")}
                    group={group}
                    form={forms[group.get("name")]}
                    loading={loading}
                    editing={editing}
                    isNewTemplate={isNewTemplate}
                    onToggle={(on) => this.handleEditBlock(group, on)}
                    onSave={(values) => this.handleSubmit(group, values)}
                    onLoad={(instance) => this.trackForm(group.get("name"), instance)}
                    showValidations={isInvalidForm}
                  />
                )
              }

              if (group.get("type") === "section") {
                return Array.from(group.get("controls").values()).map((control) => (
                  <FormBlock
                    key={control.get("name")}
                    group={control}
                    form={forms[control.get("name")]}
                    loading={loading}
                    editing={editing}
                    isNewTemplate={isNewTemplate}
                    onToggle={(on) => this.handleEditBlock(control, on)}
                    onSave={(values) => this.handleSubmit(control, values)}
                    onLoad={(instance) => this.trackForm(control.get("name"), instance)}
                    showValidations={isInvalidForm}
                  />
                ))
              }

              return null
            })}
          </ConfiguratorMain>
        </ConfiguratorContainer>
        <PreviewModal
          configurationId={id}
          loading={loading}
          active={previewActive}
          preview={preview}
          title={controls.get("name").get("value")}
          templateId={templateId}
          languages={languages}
          state={state}
          language={language}
          onClose={() => handlePreview(null, { active: false, id, templateId })}
        />
        {editingTitle && (
          <CreateModal
            loading={loading}
            active
            mode="edit"
            values={{
              name: form.controls.get("name").get("value"),
              template_id: templateId
            }}
            templates={templates}
            onCreate={(values) => this.handleSave(id, values)}
            onClose={() => this.handleEditTitle(false)}
          />
        )}
      </ConfiguratorPage>
    )
  }
}

const mapStateToProps = (state) => ({
  loading: state.configurator.loading,
  isGetConfigLoading: state.configurator.isGetConfigLoading,
  editing: state.configurator.editing,
  isNewTemplate: state.configurator.isNewTemplate,
  form: state.configurator.form,
  current: state.configurator.current,
  error: state.configurator.error,
  previewRef: state.configurator.previewRef,
  scrollPosition: state.configurator.scrollPosition,
  languages: state.configurator.languages,
  groups: state.configurator.groups,
  templates: state.templates.templates,
  includedBehaviors: state.configurator.scoreConfiguration.includedBehaviors
})

const mapDispatchToProps = (dispatch) => ({
  dispatch,
  ...bindActionCreators(
    {
      updateLanguages: setLanguagesOptions,
      updateForm: updateFormAction,
      getConfiguration: getConfigurationAction,
      resetConfiguration: resetConfigurationAction,
      patchConfiguration: patchConfigurationAction,
      trackReadOnlyConfigurationBlock: trackReadOnlyConfigurationBlockAction,
      getEmailTemplates: getEmailTemplatesAction,
      fetchGroups: fetchGroupsAction,
      getScoreConfiguration: getScoreConfigurationAction
    },
    dispatch
  )
})

Configurator.propTypes = {
  loading: PropTypes.bool.isRequired,
  isGetConfigLoading: PropTypes.bool.isRequired,
  form: PropTypes.instanceOf(FormManager).isRequired,
  current: PropTypes.instanceOf(Object),
  state: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  language: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }).isRequired,
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  templates: PropTypes.array,
  editing: PropTypes.arrayOf(PropTypes.instanceOf(Map)),
  isNewTemplate: PropTypes.bool,
  preview: PropTypes.string.isRequired,
  previewActive: PropTypes.bool.isRequired,
  scrollPosition: PropTypes.number,
  previewRef: PropTypes.element,
  handlePreview: PropTypes.func.isRequired,
  updateForm: PropTypes.func.isRequired,
  getConfiguration: PropTypes.func.isRequired,
  resetConfiguration: PropTypes.func.isRequired,
  getTemplatePreview: PropTypes.func.isRequired,
  getEmailTemplates: PropTypes.func.isRequired,
  patchConfiguration: PropTypes.func.isRequired,
  fetchGroups: PropTypes.func.isRequired,
  trackReadOnlyConfigurationBlock: PropTypes.func.isRequired,
  match: PropTypes.shape({
    params: PropTypes.object
  }).isRequired,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  getScoreConfiguration: PropTypes.func.isRequired
}

Configurator.defaultProps = {
  current: null,
  templates: [],
  editing: [],
  isNewTemplate: false,
  previewRef: null,
  scrollPosition: null
}

export default withTemplatePreview(connect(mapStateToProps, mapDispatchToProps)(Configurator))
