import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { Menu, MenuItem } from "react-bootstrap-typeahead"

const RowFlex = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`

const ColFlex = styled.div`
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:first-child {
    width: 45%;
    margin-right: 20px;
  }
  &:nth-child(2) {
    width: 65%;
  }
`

const ShowMoreButton = styled.div`
  display: flex;
  justify-content: center;
`

const GroupsMenu = ({ results, menuProps, width, labels }) => (
  <Menu {...menuProps} maxHeight="200px" style={{ width }}>
    {results.map((result, index) => (
      <MenuItem option={result} position={index}>
        <RowFlex>
          <ColFlex>{labels.map((label) => result[label]).join(" ")}</ColFlex>
        </RowFlex>
      </MenuItem>
    ))}
  </Menu>
)

GroupsMenu.propTypes = {
  results: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  menuProps: PropTypes.shape({}).isRequired,
  width: PropTypes.string
}

GroupsMenu.defaultProps = {
  width: "100%"
}

export default GroupsMenu
