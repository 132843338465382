export const GET_RECURRING_CAMPAIGNS = "GET_RECURRING_CAMPAIGNS"
export const GET_RECURRING_CAMPAIGNS_SUCCESS = "GET_RECURRING_CAMPAIGNS_SUCCESS"
export const GET_RECURRING_CAMPAIGNS_FAILED = "GET_RECURRING_CAMPAIGNS_FAILED"

export const GET_RECURRING_CAMPAIGN_BY_ID = "GET_RECURRING_CAMPAIGN_BY_ID"
export const GET_RECURRING_CAMPAIGN_BY_ID_SUCCESS = "GET_RECURRING_CAMPAIGN_BY_ID_SUCCESS"
export const GET_RECURRING_CAMPAIGN_BY_ID_FAILED = "GET_RECURRING_CAMPAIGN_BY_ID_FAILED"

export const CREATE_RECURRING_CAMPAIGN = "CREATE_RECURRING_CAMPAIGNS"
export const CREATE_RECURRING_CAMPAIGN_SUCCESS = "CREATE_RECURRING_CAMPAIGN_SUCCESS"
export const CREATE_RECURRING_CAMPAIGN_FAILED = "CREATE_RECURRING_CAMPAIGN_FAILED"

export const UPDATE_RECURRING_CAMPAIGN = "UPDATE_RECURRING_CAMPAIGN"
export const UPDATE_RECURRING_CAMPAIGN_SUCCESS = "UPDATE_RECURRING_CAMPAIGN_SUCCESS"
export const UPDATE_RECURRING_CAMPAIGN_FAILED = "UPDATE_RECURRING_CAMPAIGN_FAILED"

export const DELETE_RECURRING_CAMPAIGN = "DELETE_RECURRING_CAMPAIGN"
export const DELETE_RECURRING_CAMPAIGN_SUCCESS = "DELETE_RECURRING_CAMPAIGN_SUCCESS"
export const DELETE_RECURRING_CAMPAIGN_FAILED = "DELETE_RECURRING_CAMPAIGN_FAILED"

export const SET_RECURRING_CAMPAIGN = "SET_RECURRING_CAMPAIGN"
export const MOVE_RECURRING_CAMPAIGN_WIZARD_TO_STEP = "MOVE_RECURRING_CAMPAIGN_WIZARD_TO_STEP"
export const RESET_RECURRING_CAMPAIGN = "RESET_RECURRING_CAMPAIGN"

export const GET_RECIPIENTS_COUNT_BY_ID = "GET_RECIPIENTS_COUNT_BY_ID"
export const GET_RECIPIENTS_COUNT_BY_ID_SUCCESS = "GET_RECIPIENTS_COUNT_BY_ID_SUCCESS"
export const GET_RECIPIENTS_COUNT_BY_ID_FAILED = "GET_RECIPIENTS_COUNT_BY_ID_FAILED"

export const SET_BREADCRUMBS = "SET_BREADCRUMBS"

export const GET_METADATA_TASK_STATUS = "GET_METADATA_TASK_STATUS"
export const GET_METADATA_TASK_STATUS_SUCCESS = "GET_METADATA_TASK_STATUS_SUCCESS"
export const GET_METADATA_TASK_STATUS_FAILED = "GET_METADATA_TASK_STATUS_FAILED"

export const GET_CAMPAIGN_EMAIL_SUBJECT = "GET_CAMPAIGN_EMAIL_SUBJECT"
export const GET_CAMPAIGN_EMAIL_SUBJECT_SUCCESS = "GET_CAMPAIGN_EMAIL_SUBJECT_SUCCESS"
export const GET_CAMPAIGN_EMAIL_SUBJECT_FAILED = "GET_CAMPAIGN_EMAIL_SUBJECT_FAILED"

export const GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN = "GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN"
export const GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN_SUCCESS =
  "GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN_SUCCESS"
export const GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN_FAILED =
  "GET_CAMPAIGNS_OF_RECURRING_CAMPAIGN_FAILED"

export const SET_SEARCH_RECURRING_CAMPAIGNS = "SET_SEARCH_RECURRING_CAMPAIGNS"
