/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react"
import styled from "styled-components"
import {
  Breadcrumb,
  Typography,
  Button,
  Modal,
  Header,
  ButtonLink
} from "@elevate_security/elevate-component-library"
import PropTypes from "prop-types"
import { connect } from "react-redux"

import CampaignInfo from "./Info"
import SelectUsersReduxConnected from "./SelectUsers"
import LaunchDate from "./LaunchDate"
import Rollout from "./Rollout"
import { campaignType } from "../../../types"
import theme from "../../../css/theme"

const { colors } = theme
const { Text, H2, Small } = Typography

const BodyContainer = styled.div`
  width: 60%;
  margin: ${(props) => props.verticalMargin} auto;
`

const BreadcrumbText = styled(Text).attrs((props) => ({
  fontWeight: props.currentStep === props.index + 1 ? "bold" : "normal"
}))`
  font-size: 13px;
  color: ${(props) =>
    props.index + 1 === props.currentStep
      ? "black"
      : props.index + 1 < props.wizardStep && colors.text.link};
  :hover {
    color: ${(props) => props.index + 1 < props.wizardStep && colors.text.linkHover};
  }
`

const titles = [
  "Enter recurring campaign info",
  "Choose recipients & enter sender info",
  "Set campaign launch date",
  "Confirm and schedule your campaign"
]

export const steps = ["Campaign Info", "Recipients & Sender Info", "Launch Date", "Confirm"]

export const CampaignModal = (props) => {
  const { campaignInfo, currentStep, onContinue, onClose, stepTo, organizations } = props

  const wizardStep = campaignInfo ? campaignInfo.wizard_step : 1
  const [checkValue, setCheckValue] = useState(false)

  const handleSubmit = (formData, noStep) => {
    onContinue(formData, noStep)
  }
  const handleCheck = (value) => setCheckValue(value)

  const components = {
    "Campaign Info": (
      <CampaignInfo
        handleSubmit={handleSubmit}
        campaign={campaignInfo}
        organizations={organizations}
      />
    ),
    "Recipients & Sender Info": (
      <SelectUsersReduxConnected handleSubmit={handleSubmit} campaign={campaignInfo} />
    ),
    "Launch Date": <LaunchDate handleSubmit={handleSubmit} campaign={campaignInfo} />,
    Confirm: (
      <Rollout
        handleEdit={stepTo}
        handleSubmit={handleSubmit}
        campaign={campaignInfo}
        handleCheck={handleCheck}
      />
    )
  }

  const modalTitle = (
    <Header>
      <Small
        color="500"
        fontWeight="bold"
        className="text-uppercase mb-2"
      >{`Create a Pulse Campaign ${currentStep || 1} of ${steps.length}`}</Small>
      <H2 color="900" fontWeight="bold">
        {titles[currentStep - 1]}
      </H2>
    </Header>
  )

  const closeButton = (
    <ButtonLink onClick={onClose} className="finish-later-btn" style={{ visibility: "hidden" }}>
      {campaignInfo && campaignInfo.status === "COMPLETED" ? "Close" : "Save & Finish Later"}
    </ButtonLink>
  )

  const breadcrumb = (
    <Breadcrumb>
      {steps.map((name, index) => (
        <ButtonLink onClick={() => stepTo(index + 1)} key={name}>
          <BreadcrumbText currentStep={currentStep} wizardStep={wizardStep} index={index}>
            {name}
          </BreadcrumbText>
        </ButtonLink>
      ))}
    </Breadcrumb>
  )

  const scheduleBtnText =
    campaignInfo && campaignInfo.is_external_test ? "Schedule a Test Campaign" : "Schedule Campaign"

  const rightButtons = [
    <Button
      type="submit"
      form="submit-form"
      key="next_btn"
      disabled={currentStep === 4 ? !checkValue : false}
    >
      {currentStep === 4 ? scheduleBtnText : "Save & Continue"}
    </Button>
  ]

  return (
    <Modal
      key="modal"
      style={{ maxWidth: "1096px", maxHeight: "780px", height: "calc(100% - 20px)" }}
      onClose={onClose}
      header={modalTitle}
      rightButtons={rightButtons}
      closeButton={closeButton}
      breadcrumb={breadcrumb}
      isOpen
    >
      <BodyContainer verticalMargin={currentStep === 4 ? "0.5rem" : "2.5rem"}>
        {components[steps[currentStep - 1]]}
      </BodyContainer>
    </Modal>
  )
}

const mapStateToProps = (state) => ({
  campaignInfo: state.recurring_campaigns.campaignInfo
})

CampaignModal.propTypes = {
  campaignInfo: campaignType,
  currentStep: PropTypes.number,
  onContinue: PropTypes.func,
  onClose: PropTypes.func,
  stepTo: PropTypes.func,
  organizations: PropTypes.array
}

CampaignModal.defaultProps = {
  campaignInfo: null,
  currentStep: 1,
  onContinue: null,
  onClose: null,
  stepTo: null,
  organizations: null
}

export default connect(mapStateToProps)(CampaignModal)
