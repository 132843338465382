import { findMember } from "../helpers/utils"

const deleteGroup = (group, groups, controlledBy, negation = false) => {
  (Array.isArray(controlledBy) ? controlledBy : [controlledBy]).forEach((keyName) => {
    const controller = findMember(keyName, groups)
    if (controller && (negation ? controller.get("value") : !controller.get("value"))) {
      if (groups.has(group.get("name"))) {
        groups.delete(group.get("name"))
      }

      if (group.has("parent")) {
        const realParent = findMember(group.get("parent"), groups)

        if (realParent && realParent.get("controls").has(group.get("name"))) {
          realParent.get("controls").delete(group.get("name"))
        }
      }
    }
  })
}

/**
 * Enables and disables form groups based on setting values.
 * A group can be `controlled-by` a settings property from the
 * `show_behaviors` object.
 *
 * A group can also be controlled by `controlled-by-negation`
 * a setting property from the `show_behaviors` object.
 *
 * This will keep the form in sync when the settings value changes.
 *
 * @param {Map} group The group to enable or disable
 * @param {Map} parent The parent group
 * @param {Map} groups The groups Map
 * @param {Map} settings The settings Map
 * @returns {Map} The group Map
 */
const enforceControlledBy = (group, parent, groups) => {
  if (group.has("controlled-by")) {
    const controlledBy = group.get("controlled-by")
    if (controlledBy) {
      deleteGroup(group, groups, controlledBy)
    }
  }

  // an example is to hide Upcoming optional activities if `show_optional_activities_recap` is true
  if (group.has("controlled-by-negation")) {
    const controlledByNegation = group.get("controlled-by-negation")
    if (controlledByNegation) {
      deleteGroup(group, groups, controlledByNegation, true)
    }
  }
}

export default enforceControlledBy
