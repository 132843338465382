/* eslint-disable no-undef */
import React, { useState } from "react"
import PropTypes from "prop-types"
import { Field } from "react-final-form"
import { FieldContainer, InputContainer, InputText } from "../styles"
import Dropzone from "./components/Dropzone"
import DropzoneReadOnly from "./components/DropzoneReadOnly"
import FieldExampleLink from "../../../FieldExampleLink"
import FieldLabel from "../../../FieldLabel"

const ImageUpload = (props) => {
  const [files, setFiles] = useState([])
  const { control } = props
  const { name, type, value, example, props: controlProps, hidden } = control
  const { accept, required, disabled } = controlProps || {}

  const handleFileChange = (input, newFiles) => {
    input.onChange(newFiles[0])
    setFiles(newFiles)
  }

  return (
    <Field name={name} type={type} required={required} initialValue={value}>
      {({ input }) => (
        <FieldContainer hidden={hidden}>
          <div>
            <FieldLabel control={control} />
            <InputContainer>
              {!disabled && (
                <Dropzone
                  name={name}
                  disabled={disabled}
                  files={files}
                  accept={accept}
                  currentUrl={value}
                  onDrop={(newFiles) => handleFileChange(input, newFiles)}
                  onSelect={(newFiles) => handleFileChange(input, newFiles)}
                />
              )}
              {disabled && !value && <InputText>N/A</InputText>}
              {disabled && value && <DropzoneReadOnly file={value} />}
            </InputContainer>
            {!disabled && example !== null && <FieldExampleLink control={control} />}
          </div>
        </FieldContainer>
      )}
    </Field>
  )
}

ImageUpload.propTypes = {
  control: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool,
    helpText: PropTypes.string,
    example: PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string,
      description: PropTypes.string
    }),
    props: PropTypes.shape({
      accept: PropTypes.string,
      btnText: PropTypes.string,
      required: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired
}

export default ImageUpload
