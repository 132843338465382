/* eslint-disable import/prefer-default-export */
import styled from "styled-components"

export const Image = styled.img``

export const InfoIcon = styled.div`
  width: 16px;
`

export const Legend = styled.span`
  color: #565d66;
  font-size: 13px;
  margin-right: 5px;
`

export const LegendWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const Container = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
