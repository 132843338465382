import React, { useState } from "react"
import PropTypes from "prop-types"
import dompurify from "dompurify"
import { Modal, Typography } from "@elevate_security/elevate-component-library"
import { CustomModalContainer, ImageContainer, ModalHeader } from "./styles"

const { H2 } = Typography

const FieldExampleModal = ({ url, title, description, onClose }) => {
  const [loaded, setLoaded] = useState(false)
  const sanitizer = dompurify.sanitize
  if (!url) return null

  const modalTitle = (
    <ModalHeader>
      <H2>{title}</H2>
      {description !== null && (
        // eslint-disable-next-line react/no-danger
        <p dangerouslySetInnerHTML={{ __html: sanitizer(description) }} />
      )}
    </ModalHeader>
  )

  return (
    <CustomModalContainer>
      <Modal header={modalTitle} isOpen onClose={onClose}>
        <ImageContainer loaded={loaded}>
          <img src={url} alt={title} onLoad={() => setLoaded(true)} />
        </ImageContainer>
      </Modal>
    </CustomModalContainer>
  )
}

FieldExampleModal.propTypes = {
  url: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  onClose: PropTypes.func
}

FieldExampleModal.defaultProps = {
  url: null,
  title: null,
  description: null,
  onClose: () => null
}

export default FieldExampleModal
