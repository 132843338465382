/* eslint-disable import/prefer-default-export */
import styled from "styled-components"

export const RecipientsTitle = styled.h2`
  margin-top: 64px;
  margin-bottom: 24px;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #34383c;

  svg {
    margin-left: 8px;
    :hover {
      path {
        stroke: #039ba1;
      }
    }
  }
`
export const NewHiresSection = styled.div`
  display: flex;

  .CircularProgressbar-trail {
    stroke: #dde1e7 !important;
  }

  .CircularProgressbar-path {
    stroke: #0c7d84 !important;
  }

  div > div > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: -10px;
    font-size: 18px;
    line-height: 20px;

    span {
      font-size: 12px;
      white-space: nowrap;
      color: #7b828a;
      margin-left: -8px;
      font-weight: bold;
    }
  }

  svg {
    width: 168px;
    height: 168px;
  }

  ul {
    padding: 50px 0px 0px 60px;
    line-height: 2px;

    li {
      height: 40px;
      font-size: 14px;
      color: #565d66;
      ::before {
        content: "•";
        color: #dde1e7;
        display: flex;
        width: 1em;
        margin-left: -0.5em;
        font-size: 50px;
      }
      :first-child:before {
        color: #0c7d84;
      }
    }
  }
`
export const Container = styled.div`
  margin-bottom: 30px;
`
