import { call, put, takeLatest } from "redux-saga/effects"

import { showAlert } from "../alertsSaga/reducer"
import * as actions from "./actions"
import * as api from "./api"
import { getAvailableTemplateTypes, getErrorMessage, setManagerLabel } from "../../utils/utils"

export function* getEmailTemplates() {
  try {
    const response = yield call(api.getEmailTemplates)
    const data = setManagerLabel(response?.data || [])

    // getting available template-type
    const template_types = getAvailableTemplateTypes()

    const availableTemplateType =
      data.filter((item) => template_types.includes(item.template_type)) || []

    yield put({ type: actions.GET_TEMPLATES_SUCCESS, data: availableTemplateType })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_TEMPLATES_FAILED, error: e })
  }
}

export default function* templateSaga() {
  yield takeLatest(actions.GET_TEMPLATES, getEmailTemplates)
}
