import React from "react"
import { Route, Switch, withRouter } from "react-router-dom"
// eslint-disable-next-line import/no-named-as-default
import Campaigns from "./Campaigns"
import CampaignSummary from "./CampaignSummary"
import Breadcrumbs from "../../components/Breadcrumbs"
import { PageContainer } from "../../styles"

const OldDashboard = ({ baseCampaignsPath }) => (
  <PageContainer className="mb-4" style={{ minWidth: "996px" }}>
    <Breadcrumbs routes={[["Pulse", "campaigns"], ["Campaigns"]]} />
    <Switch>
      <Route
        exact
        path={[
          `${baseCampaignsPath}`,
          `${baseCampaignsPath}/new-campaign`,
          `${baseCampaignsPath}/:id`
        ]}
        render={(props) => <Campaigns {...props} base_path={baseCampaignsPath} />}
      />
      <Route
        exact
        path={`${baseCampaignsPath}/:id/overview`}
        render={(props) => <CampaignSummary {...props} base_path={baseCampaignsPath} />}
      />
      <Route
        exact
        path={`${baseCampaignsPath}/:id/overview-mvp`}
        render={(props) => <CampaignSummary {...props} isMVP base_path={baseCampaignsPath} />}
      />
    </Switch>
  </PageContainer>
)

export default withRouter(OldDashboard)
