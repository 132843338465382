/* eslint-disable react/forbid-prop-types,import/no-named-as-default */
/* eslint-disable react/destructuring-assignment */

import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  Button,
  Typography,
  DragosaurWithUmbrella
} from "@elevate_security/elevate-component-library"
import CampaignRow from "./CampaignRow"
import CampaignWizard from "../wizard"
import {
  getCampaigns,
  getCampaignById,
  resetCampaign,
  setBreadcrumbs,
  getMetadataTaskStatus
} from "../../../sagas/campaignSaga/reducer"
import Filter from "../../../components/Filter"
import { EmptyListMessage, HeadingWrapper, Wrapper } from "../styles"
import { CAMPAIGN_STATUS } from "../../../constants"
import ConfiguratorPageSkeletonLoader from "../../configurator/Configurations/ConfiguratorPageSkeletonLoader"

const { H1 } = Typography

const FETCH_CAMPAIGNS_INTERVAL = 20000

export class Campaigns extends React.Component {
  constructor(props) {
    super(props)
    this.base_path = props.base_path
    this.state = {
      modalOpen: false,
      currentTab: "ALL",
      fetchInterval: false
    }
  }

  componentDidMount() {
    const {
      match: {
        params: { id }
      },
      setBreadcrumbsFunc
    } = this.props

    this.loadCampaigns()
    this.setState({
      fetchInterval: setInterval(() => this.loadCampaigns(), FETCH_CAMPAIGNS_INTERVAL)
    })
    if (id && id.length) {
      this.loadCampaignById(id)
    }
    setBreadcrumbsFunc([])
  }

  componentDidUpdate(prevProps) {
    const {
      match: {
        params: { id: prevId }
      }
    } = prevProps
    const {
      match: {
        params: { id }
      }
    } = this.props

    if (this.state.modalOpen && this.state.fetchInterval) {
      clearInterval(this.state.fetchInterval)
      this.setState({ fetchInterval: false })
    }
    if (!this.state.modalOpen && !this.state.fetchInterval) {
      this.setState({
        fetchInterval: setInterval(() => this.loadCampaigns(), FETCH_CAMPAIGNS_INTERVAL)
      })
    }
    if (id !== prevId) {
      this.loadCampaignById(id)
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.fetchInterval)
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const { modalOpen } = prevState
    const {
      match: {
        url,
        params: { id }
      }
    } = nextProps
    const isNewCampaignPath = url.endsWith("/new-campaign")

    if (!id && isNewCampaignPath !== modalOpen) {
      return {
        modalOpen: !modalOpen
      }
    }
    return null
  }

  loadCampaigns = () => {
    const { getCampaignsFunc, getMetadataTaskStatusFunc } = this.props
    getCampaignsFunc()
    getMetadataTaskStatusFunc()
  }

  loadCampaignById = (id) => {
    const { getCampaignByIdFunc, resetCampaignFunc } = this.props
    if (id && id.length) {
      getCampaignByIdFunc(id, (campaign) => {
        if (campaign.status === CAMPAIGN_STATUS.COMPLETED) this.openCampaignOverview(id)
        else {
          this.setState({
            modalOpen: true
          })
        }
      })
    } else {
      this.setState({
        modalOpen: false
      })
      resetCampaignFunc()
    }
  }

  newCampaign = () => {
    const { history, resetCampaignFunc } = this.props
    resetCampaignFunc()
    history.push(`${this.base_path}/new-campaign`)
  }

  openCampaign = (campaign) => {
    const { history } = this.props
    if (campaign.status === CAMPAIGN_STATUS.COMPLETED) {
      this.openCampaignOverview(campaign.id)
    } else history.push(`${this.base_path}/${campaign.id}`)
  }

  openCampaignOverview = (id) => {
    const { history } = this.props
    history.push(`${this.base_path}/${id}/overview`)
  }

  closeCampaign = () => {
    const { history } = this.props
    history.push(this.base_path)
  }

  setCurrentTab = (tab) => {
    this.setState({
      currentTab: tab
    })
  }

  filterCampaigns = (status) => {
    const { campaigns } = this.props
    return campaigns.filter((campaign) => campaign.status === status)
  }

  render() {
    const { campaigns, metadataTask, isCampaignsLoading } = this.props
    const { modalOpen, currentTab } = this.state
    const campaignListFiltered = {
      ALL: campaigns,
      DRAFT: this.filterCampaigns(CAMPAIGN_STATUS.DRAFT),
      SCHEDULED: this.filterCampaigns(CAMPAIGN_STATUS.SCHEDULED),
      COMPLETED: this.filterCampaigns(CAMPAIGN_STATUS.COMPLETED),
      ERROR: this.filterCampaigns(CAMPAIGN_STATUS.ERROR)
    }

    const CampaignList = () => {
      const hasCampaignList =
        campaignListFiltered[currentTab] && !!campaignListFiltered[currentTab]?.length

      if (isCampaignsLoading && !hasCampaignList) return <ConfiguratorPageSkeletonLoader />

      if (!hasCampaignList) {
        return (
          <EmptyListMessage>
            <DragosaurWithUmbrella message="No data available" showTooltip={false} />
          </EmptyListMessage>
        )
      }

      return (
        <Wrapper>
          {campaignListFiltered[currentTab].map((campaign) => (
            <CampaignRow
              key={campaign.id}
              campaign={campaign}
              onOpenCampaign={() => this.openCampaign(campaign)}
              reloadCampaigns={this.loadCampaigns}
              metadataTaskStatus={metadataTask}
            />
          ))}
        </Wrapper>
      )
    }

    return (
      <div>
        <HeadingWrapper>
          <H1 className="mt-3" color="900" fontWeight="heavy">
            Campaigns
          </H1>
        </HeadingWrapper>
        <Button type="secondary" onClick={() => this.newCampaign()} className="mt-2">
          Create New Campaign
        </Button>
        <Filter campaigns={campaignListFiltered} onChange={(tab) => this.setCurrentTab(tab)} />
        <CampaignList />
        {modalOpen && (
          <CampaignWizard modalOpen={modalOpen} toggleModal={() => this.closeCampaign()} />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  campaigns: state.campaigns.data,
  error: state.campaigns.error,
  metadataTask: state.campaigns.metadataTask,
  isCampaignsLoading: state.campaigns.isCampaignsLoading
})

const mapDispatchToProps = {
  getCampaignsFunc: getCampaigns,
  getMetadataTaskStatusFunc: getMetadataTaskStatus,
  getCampaignByIdFunc: getCampaignById,
  resetCampaignFunc: resetCampaign,
  setBreadcrumbsFunc: setBreadcrumbs
}

Campaigns.propTypes = {
  getCampaignsFunc: PropTypes.func,
  getMetadataTaskStatusFunc: PropTypes.func,
  getCampaignByIdFunc: PropTypes.func.isRequired,
  resetCampaignFunc: PropTypes.func.isRequired,
  setBreadcrumbsFunc: PropTypes.func.isRequired,
  campaigns: PropTypes.array,
  history: PropTypes.shape({
    push: PropTypes.func
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string
    }),
    url: PropTypes.string
  }).isRequired,
  metadataTask: PropTypes.object
}

Campaigns.defaultProps = {
  getCampaignsFunc: null,
  getMetadataTaskStatusFunc: null,
  campaigns: [],
  metadataTask: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Campaigns)
