/* eslint-disable import/prefer-default-export */
/* eslint-disable no-undef */
import axios from "../../api"

export function getEmailTemplates() {
  return axios.get(`${REACT_APP_PULSE_API_URL}/templates/all`)
}

export function fetchOrgEmailsList(searchValue, templateId) {
  const params = {
    is_active: true,
    ...(templateId.includes("managers") ? { is_manager: true } : {}), // sending is_manager in params for mangers and managersV2
    filter: `[[{"filter_class":"ByEmployeeFilter","query":{"email__icontains":"${searchValue}"}}]]`
  }

  return axios.get(`${REACT_APP_EP_API_URL}/employees`, { params })
}

export async function getEmailListData(searchValue, templateId) {
  try {
    const response = await fetchOrgEmailsList(searchValue, templateId)
    return response?.data?.results.map((item) => ({ name: item?.email, id: item?.email })) || []
  } catch {
    return []
  }
}
