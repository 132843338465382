import React from "react"
import PropTypes from "prop-types"
import { Field } from "react-final-form"
import { FieldContainer, HelpText } from "../styles"
import { ControlBoolProps } from "../../types"
import FieldLabel from "../../../FieldLabel"

const Checkbox = (props) => {
  const { control } = props
  const { name, value, helpText, props: controlProps, hidden } = control
  const { required } = controlProps || {}
  return (
    <Field name={name} required={required} value={value}>
      {({ input }) => (
        <FieldContainer hidden={hidden}>
          <FieldLabel control={control} />
          <input {...input} onChange={(e) => input.onChange(e)} />
          <HelpText>{helpText}</HelpText>
        </FieldContainer>
      )}
    </Field>
  )
}

Checkbox.propTypes = {
  control: ControlBoolProps.isRequired,
  onChange: PropTypes.func.isRequired
}

export default Checkbox
