/* eslint-disable no-restricted-globals */
import React from "react"
import PropTypes from "prop-types"
import { CircularProgressbarWithChildren } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"
import { NewHiresSection, RecipientsTitle, Container } from "./styles"
import { recipientHelpText } from "../utils"

const NewHiresRatio = (props) => {
  const { totalEmployees, totalNewHires, hiresRatio } = props
  const isNA = (value) => value === null || value === undefined
  const tenuredIndividuals =
    isNA(totalEmployees) || isNA(totalNewHires) ? "N/A" : totalEmployees - totalNewHires
  const newHiresRatio = (ratio) =>
    isNA(ratio) || isNaN(ratio) ? 0 : parseFloat((ratio * 100).toFixed(2))
  return (
    <div>
      <Container>
        <RecipientsTitle>
          Recipients - New Hires Ratio
          <Tooltip body={recipientHelpText} placement="top" readOnly size="sm">
            <Icon name="CircleExclamation" fill="#959DA8" size="xsm" />
          </Tooltip>
        </RecipientsTitle>
        <NewHiresSection>
          <CircularProgressbarWithChildren value={newHiresRatio(hiresRatio)} strokeWidth={4}>
            <div className="ratio-percentage">
              <strong>{newHiresRatio(hiresRatio)}%</strong> <span>NEW HIRES</span>
            </div>
          </CircularProgressbarWithChildren>
          <ul>
            <li>
              New Hires: <b>{isNA(totalNewHires) ? "N/A" : totalNewHires}</b>
            </li>
            <li>
              Tenured Individuals:{" "}
              <b>
                {isNA(tenuredIndividuals) || tenuredIndividuals < 0 ? "N/A" : tenuredIndividuals}
              </b>
            </li>
          </ul>
        </NewHiresSection>
      </Container>
    </div>
  )
}

NewHiresRatio.propTypes = {
  totalEmployees: PropTypes.number.isRequired,
  totalNewHires: PropTypes.number.isRequired,
  hiresRatio: PropTypes.number.isRequired
}

export default NewHiresRatio
