/* eslint-disable no-undef */
import axios from "../../api"

export function fetchGroupsList(searchValue) {
  return axios.get(
    `${REACT_APP_PULSE_API_URL}/organizations/groups${searchValue ? `?search=${searchValue}` : ""}`
  )
}

export function fetchGroupEmployees(id, campaignType = null) {
  let url = `${REACT_APP_EP_API_URL}/employees/?limit=1&is_active=true&groups=["${id}"]`
  if (campaignType && campaignType === "MANAGERS") url = url.concat("&is_manager=true")
  return axios.get(url)
}

export function fetchSenderEmailsList() {
  return axios.get(`${REACT_APP_PULSE_API_URL}/organizations/sender-signatures`)
}

export function fetchRecipientsListById(id, orgId) {
  return axios.get(
    `${REACT_APP_EP_API_URL}/employees?organization=${orgId}&is_active=true&limit=25&ordering=last_name&tags__key__in=pulse-campaign:${id}`
  )
}
