/* eslint-disable no-undef */
/* eslint-disable react/no-this-in-sfc */
/* eslint-disable no-restricted-properties */
/* eslint-disable radix */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Text } from "@elevate_security/elevate-component-library/dist/Typography"
import { FilenameAndSize } from "../Dropzone/styles"

function bytesToSize(bytes) {
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
  if (bytes === 0) return "0 Byte"
  const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))
  return `${Math.round(bytes / Math.pow(1024, i), 2)} ${sizes[i]}`
}

const FilenameSize = ({ file, breakLine }) => {
  const [size, setSize] = useState(null)
  useEffect(() => {
    const http = new XMLHttpRequest()
    function handleReadyStateChange() {
      if (this.readyState === this.DONE && this.status === 200) {
        const fileSize = this.getResponseHeader("content-length")
        setSize(bytesToSize(fileSize))
      }
    }
    http.onreadystatechange = handleReadyStateChange
    http.open("HEAD", `https://cors-anywhere.herokuapp.com/${file}`, true)
    http.send()
  }, [file])

  if (!file) {
    return null
  }

  return (
    <FilenameAndSize>
      <Text fontWeight="bold">{file.split("/").pop()}</Text>
      {!breakLine ? " " : <br />}
      {size && `(${size})`}
    </FilenameAndSize>
  )
}

FilenameSize.propTypes = {
  // eslint-disable-next-line no-undef
  file: PropTypes.instanceOf(File).isRequired,
  breakLine: PropTypes.bool
}

FilenameSize.defaultProps = {
  breakLine: false
}

export default FilenameSize
