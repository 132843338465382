/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"

/**
 * This helper listens to the language source field
 * and updates the current field value based on the
 * selected language.
 *
 * Useful for custom text fields that support multiple
 * languages.
 *
 * @param Component
 * @returns {function(*): *}
 */
const withLanguageSupport = (Component) => (props) => {
  const { form, control } = props
  const { name, language_source } = control
  const languageState = language_source ? form.getFieldState(language_source) : null
  const [listener, setListener] = useState(null)
  const [language, setLanguage] = useState(languageState ? languageState.value : null)

  useEffect(() => {
    if (language_source) {
      setListener(
        form.subscribe(
          ({ values }) => {
            if (language_source in values && values[language_source] !== language) {
              setLanguage(values[language_source])
              form.change(name, control[`value_${values[language_source]}`])
            }
          },
          { values: true, modified: true }
        )
      )
    }

    return () => listener && listener()
  }, [control])

  return <Component {...{ ...props, language }} />
}

export default withLanguageSupport
