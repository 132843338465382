import React from "react"
import PropTypes from "prop-types"
import { HeadingText, FieldContainer } from "../styles"

const Heading = (props) => {
  const {
    control: { label, hidden }
  } = props
  return (
    <FieldContainer style={{ paddingBottom: 2 }} hidden={hidden}>
      <HeadingText>{label}</HeadingText>
    </FieldContainer>
  )
}

Heading.propTypes = {
  control: PropTypes.shape({
    type: PropTypes.string,
    label: PropTypes.string,
    props: PropTypes.shape({
      required: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired
}

export default Heading
