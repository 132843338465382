import React from "react"
import PropTypes from "prop-types"
import { useField } from "react-final-form"
import styled from "styled-components"
import { FieldContainer } from "../styles"
import FieldLabel from "../../../FieldLabel"

const CheckboxItem = styled.div`
  display: flex;
  align-items: center;

  & > span {
    flex: 1;
    margin-left: 6px;
  }

  & > * {
    line-height: 24px;
  }
`

const CheckboxGroup = (props) => {
  const { control } = props
  const { name, type, value, props: controlProps, options, hidden } = control
  const { disabled } = controlProps
  const { input } = useField(name, {
    name,
    type,
    initialValue: value
  })

  return (
    <FieldContainer hidden={hidden}>
      <FieldLabel control={control} />
      <div>
        {options.map((option) => (
          <CheckboxItem key={option.value}>
            <input
              {...input}
              type="checkbox"
              disabled={disabled}
              onChange={(e) => input.onChange(e)}
            />
            <span> {option.label}</span>
          </CheckboxItem>
        ))}
      </div>
    </FieldContainer>
  )
}

CheckboxGroup.propTypes = {
  control: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.arrayOf(PropTypes.string),
    helpText: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.any
      })
    ),
    props: PropTypes.shape({
      required: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired
}

export default CheckboxGroup
