import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Label } from "../Form/fields/styles"

const ModuleLabelWrapper = styled.div`
  display: flex;
  > span {
    flex: 1;
    &:first-child {
      margin-right: 5px;
    }
    &:last-child {
      margin-left: 5px;
    }
  }
`

const ModuleLabel = ({ control: { index }, subindex, label1, label2 }) => (
  <ModuleLabelWrapper>
    <Label>
      {index > 0 && subindex >= 0 ? `${index}${String.fromCharCode(97 + subindex)}. ` : null}
      {label1}
      {"  "}
    </Label>
    <Label>
      {label2}
      {"  "}
    </Label>
  </ModuleLabelWrapper>
)

ModuleLabel.propTypes = {
  control: PropTypes.shape({
    index: PropTypes.number,
    subindex: PropTypes.number,
    label1: PropTypes.string.isRequired,
    label2: PropTypes.string.isRequired
  }).isRequired
}

export default ModuleLabel
