import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Icon } from "@elevate_security/elevate-component-library"
import { useDispatch } from "react-redux"
import { ChooseFileLink, DropZone, PreviewContainer, UploadText } from "./styles"
import { showAlert } from "../../../../../../../../../sagas/alertsSaga/reducer"
import {
  ALLOWED_EXTENSIONS_IMAGES_UPLOAD,
  FILE_SIZE_VALIDATION_TEXT
} from "../../../../../../../../../sagas/configuratorSaga/constants"

const TWO_MEGA_BYTE = 2097152

const Dropzone = ({ name, disabled, currentUrl, files, accept, onSelect, onDrop }) => {
  const [preview, setPreview] = useState(currentUrl)
  const [dragging, setDragging] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if (files && files.length) {
      // eslint-disable-next-line no-undef
      const reader = new FileReader()

      reader.onload = (e) => setPreview(e.target.result)

      reader.readAsDataURL(files[0])
    }
  }, [files])

  const handleDrop = (e) => {
    e.preventDefault()
    e.stopPropagation()

    const newFiles = [...(e?.dataTransfer?.files || {})]

    if (!ALLOWED_EXTENSIONS_IMAGES_UPLOAD.test(newFiles[0]?.name || "")) {
      dispatch(showAlert("Unsupported file format", true))
      return
    }

    if (newFiles && newFiles.length > 1) {
      // prevent multiple
    }

    if (newFiles && newFiles.length > 0) {
      e.dataTransfer.clearData()
      if (newFiles[0].size > TWO_MEGA_BYTE) {
        dispatch(showAlert(FILE_SIZE_VALIDATION_TEXT, true))
        return
      }
      onDrop(newFiles)
    }
  }

  const handleSelect = (e) => {
    const newFiles = [...e.target.files]

    if (!ALLOWED_EXTENSIONS_IMAGES_UPLOAD.test(newFiles[0]?.name || "")) {
      dispatch(showAlert("Unsupported file format", true))
      return
    }

    if (newFiles && newFiles.length > 1) {
      // prevent multiple
    }

    if (newFiles && newFiles.length > 0) {
      if (newFiles[0].size > TWO_MEGA_BYTE) {
        dispatch(showAlert(FILE_SIZE_VALIDATION_TEXT, true))
        return
      }
      onSelect(newFiles)
    }
  }

  const handleDragEnter = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <DropZone
      disabled={disabled}
      dragging={dragging}
      onDragOver={handleDragOver}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {!preview && <Icon name="Upload" size="lg" />}
      {preview && (
        <PreviewContainer>
          {/* eslint-disable-next-line jsx-a11y/alt-text */}
          <img src={preview} />
        </PreviewContainer>
      )}
      <UploadText>
        {files[0] === null && "Drag & Drop .PNG or .JPG file here or"}
        {files[0] !== null && "Drag & Drop to replace or"}
        <ChooseFileLink>
          browse for a file
          <input
            name={name}
            type="file"
            disabled={disabled}
            accept={accept || "image/*"}
            onChange={handleSelect}
          />
        </ChooseFileLink>
      </UploadText>
    </DropZone>
  )
}

Dropzone.propTypes = {
  // eslint-disable-next-line no-undef
  files: PropTypes.arrayOf(PropTypes.instanceOf(File)),
  name: PropTypes.string.isRequired,
  accept: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onSelect: PropTypes.func.isRequired,
  onDrop: PropTypes.func.isRequired,
  currentUrl: PropTypes.string
}

Dropzone.defaultProps = {
  files: [],
  disabled: false,
  currentUrl: null
}

export default Dropzone
