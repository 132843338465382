import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Typography, theme } from "@elevate_security/elevate-component-library"

const { Small } = Typography

const ErrorMessage = styled(Small)`
  display: block;
  margin-top: 4px;
  line-height: 16px;
  color: ${theme.text.color.error};
  font-size: 12px !important;
  font-weight: 400 !important;
  ${({ customStyles }) => customStyles || {}}
`

const FieldError = ({ touched, error, customStyles }) => (
  <>
    {error && touched && (
      <ErrorMessage fontWeight={300} customStyles={customStyles}>
        {error}
      </ErrorMessage>
    )}
  </>
)

FieldError.propTypes = {
  touched: PropTypes.bool,
  error: PropTypes.string,
  customStyles: PropTypes.shape({})
}

FieldError.defaultProps = {
  touched: false,
  error: null,
  customStyles: null
}

export default FieldError
