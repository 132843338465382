import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import TourTooltip from "../TourTooltip"
import TemplateActions from "../TemplateActions"
import { ACTION_TYPE } from "../TemplateActions/utils"

const ActionRow = ({ row, onPreview, onCopy, onDelete, hasTourTooltips }) => {
  const history = useHistory()
  const handleEdit = (targetRow) => {
    history.push(targetRow.editUrl)
  }

  const renderIcons = () => (
    <div className="actions">
      <TemplateActions
        template={row}
        onEdit={handleEdit}
        onCopy={onCopy}
        onDelete={onDelete}
        onPreview={onPreview}
        options={{
          hideActions: [...[row.default ? ACTION_TYPE.DELETE : {}]]
        }}
      />
    </div>
  )

  const renderActionsIcons = () =>
    hasTourTooltips ? <TourTooltip stepNo={4}>{renderIcons()}</TourTooltip> : renderIcons()

  return <>{renderActionsIcons()}</>
}

ActionRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    editUrl: PropTypes.string,
    default: PropTypes.bool
  }).isRequired,
  onPreview: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  hasTourTooltips: PropTypes.bool
}

ActionRow.defaultProps = {
  onPreview: () => null,
  onDelete: () => null,
  onCopy: () => null,
  hasTourTooltips: false
}

export default ActionRow
