import addMetaProperties from "./add-meta-properties"
import enforceControlledBy from "./enforce-controlled-by"
import updateScoreGraphics from "./update-score-graphics"

const transformers = [addMetaProperties, enforceControlledBy, updateScoreGraphics]

/**
 * Transform groups memoizer
 *
 * @param {Map} settings The settings Map, where you can get settings from
 * @param {Map} groups The groups map
 * @returns {function(*=): function(*, *, *): *}
 */
const transformGroup =
  (settings, groups) =>
  /**
   * Puts a group Map through the transformers for context updates
   *
   * @param {Map} group
   * @returns {Map} The transformed group Map
   */
  (group) => {
    transformers.forEach((transformer) => transformer(group, null, groups, settings), group)
  }

/**
 * Transform controls memoizer
 *
 * @param {Map} settings The settings Map, where you can get settings from
 * @param {Map} groups The groups map
 * @returns {function(Map): Map}
 */
const transformControls =
  (settings, groups) =>
  /**
   * Puts a control Map through the transformers for context updates
   *
   * @param {Map} group The group with controls to be transformed
   * @returns {Map} The transformed group Map
   */
  (group) => {
    if (group) {
      const controls = group.get("controls")

      if (controls) {
        Array.from(controls.keys()).forEach((key) => {
          transformers.forEach(
            (transformer) => transformer(controls.get(key), group, groups, settings),
            controls.get(key)
          )
        })
      }
    }
  }

const transformShowBehaviors = (settings, groups) => (group) => {
  if (group && group.get("name") === "behaviors") {
    Array.from(group.get("controls").keys()).forEach((behaviorName) => {
      [transformGroup(settings, groups), transformControls(settings, groups)].forEach(
        (callback) => {
          callback(group.get("controls").get(behaviorName))
        }
      )
    })
  }
}

/**
 * transformers are responsible to make structural changes to
 * the a FormManage instance.
 *
 * Things like adding fields or removing fields from the
 * form, changing properties, anything that changes the
 * structure of the form or any group or field in it should
 * be done via transformer functions.
 *
 * Each member (group or control) of the form is passed into
 * the transformer pipeline when a FormManage instance is created.
 *
 * Use the member type property to target specific fields and
 * groups.
 *
 * @param {Map} groups The groups Map containing all of the groups in the configurator
 * @param {Map} settings The settings Map for easier settings access
 * @returns {Map} The transformed groups Map
 */
const transform = (groups, settings) => {
  Array.from(groups.keys()).forEach((groupName) => {
    [
      transformGroup(settings, groups),
      transformControls(settings, groups),
      transformShowBehaviors(settings, groups)
    ].forEach((callback) => {
      callback(groups.get(groupName))
    })
  })

  return groups
}

export default transform
