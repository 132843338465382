import moment from "moment"
import { getDefaultTimezone } from "../sagas/companySaga/selectors"

export function formatDate({ date, format = "YYYY-MM-DD" }) {
  let mdate = moment(date)
  const timezoneInDate = mdate.tz()

  // Set UTC timezone if the date does not have tz info
  if (!timezoneInDate) {
    mdate = mdate.tz("UTC", true)
  }

  return mdate.tz(getDefaultTimezone()).format(format)
}
