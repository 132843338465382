import styled from "styled-components"
import { Layout } from "@elevate_security/elevate-component-library"

export const ActionContainer = styled(Layout)`
  display: flex;
  justify-self: flex-end;
  justify-content: flex-end;
  padding: 0 30px;
`

export const IconWrapper = styled.span`
  margin-left: 24px;
  flex-shrink: 0;
`
