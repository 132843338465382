/* eslint-disable camelcase */
/* eslint-disable react/prop-types */
import React from "react"
import round from "lodash/round"
import PropTypes from "prop-types"

// Components
import {
  TrackingPlatformWrapper,
  Row,
  LabelBar,
  ProgressBar,
  ScoreValue,
  LeftColumn,
  RightColumn,
  Title
} from "./styles"

function TrackingPlatformsChart(props) {
  const { trackingPlatforms } = props

  const { open_platforms, click_platforms } = trackingPlatforms || {}
  const isNA = (value) => value === null || value === undefined
  const getPercentage = (value, sum) =>
    value + sum === 0 || isNA(value) || isNA(sum) ? "N/A" : round((value / sum) * 100)
  const checkScore = (score) => (score !== "N/A" ? `${score}%` : score)
  const reducer = (accumulator, currentValue) => accumulator + currentValue
  const remove1stElement = ([x, ...y]) => y

  const openPlatforms = Object.entries(open_platforms || {})
  const openValues = isNA(open_platforms) ? {} : Object.values(open_platforms)
  const clickPlatforms = Object.entries(click_platforms || {})
  const clickValues = isNA(click_platforms) ? null : Object.values(click_platforms)
  const clickArguments = isNA(clickValues) ? null : remove1stElement(clickValues)
  const openArguments = isNA(openValues) ? null : remove1stElement(openValues)
  const sumOfClickValues = isNA(clickArguments) ? 0 : clickArguments.reduce(reducer, 0)
  const sumOfOpenValues = isNA(openArguments) ? 0 : openArguments.reduce(reducer, 0)

  return (
    <TrackingPlatformWrapper>
      {sumOfOpenValues !== 0 && (
        <LeftColumn>
          <Title>Open Tracking - Platforms</Title>
          {openPlatforms.map(([key, value], index) => {
            if (index > 0) {
              return (
                <>
                  <Row>
                    <LabelBar>{key}</LabelBar>
                    <ScoreValue>{checkScore(getPercentage(value, sumOfOpenValues))}</ScoreValue>
                  </Row>
                  <ProgressBar value={getPercentage(value, sumOfOpenValues)} />
                </>
              )
            }
            return []
          })}
        </LeftColumn>
      )}
      {sumOfClickValues !== 0 && (
        <RightColumn>
          <Title>Link Tracking - Platforms</Title>
          {clickPlatforms.map(([key, value], index) => {
            if (index > 0) {
              return (
                <>
                  <Row>
                    <LabelBar>{key}</LabelBar>
                    <ScoreValue>{checkScore(getPercentage(value, sumOfClickValues))}</ScoreValue>
                  </Row>
                  <ProgressBar value={getPercentage(value, sumOfClickValues)} />
                </>
              )
            }
            return []
          })}
        </RightColumn>
      )}
    </TrackingPlatformWrapper>
  )
}

TrackingPlatformsChart.propTypes = {
  trackingPlatforms: PropTypes.shape({
    open_platforms: PropTypes.shape({
      Desktop: PropTypes.number,
      Mobile: PropTypes.number,
      WebMail: PropTypes.number
    }),
    click_platforms: PropTypes.shape({
      Desktop: PropTypes.number,
      Mobile: PropTypes.number
    })
  }).isRequired
}

export default TrackingPlatformsChart
