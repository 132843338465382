import { useState, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { Table, Link } from "@elevate_security/elevate-component-library"
import { selectCampaignsOfRecurringCampaign } from "../../../sagas/recurringCampaignSaga/selectors"
import { getCampaignsOfRecurringCampaign } from "../../../sagas/recurringCampaignSaga/reducer"
import { getCampaignById } from "../../../sagas/campaignSaga/reducer"

import { SkeletonLoader } from "../../../components/skeleton"
import { formatDate } from "../../../utils/dates"

const DEFAULT_PAGE_SIZE = 10

export const CampaignsTable = ({ recurring_campaign, base_path }) => {
  const dispatch = useDispatch()
  const history = useHistory()

  const [pagination, setPagination] = useState({ offset: 0, limit: DEFAULT_PAGE_SIZE })

  const customSelector = selectCampaignsOfRecurringCampaign(recurring_campaign.id)
  const campaignsData = useSelector(customSelector)
  const campaignsPage = campaignsData?.data ?? {}
  const campaigns = campaignsData?.data?.results ?? []

  const showCampaignStats = (campaign_id) => {
    dispatch(
      getCampaignById(campaign_id, () => {
        history.push(`${base_path}/campaign/${campaign_id}/overview`)
      })
    )
  }

  const columns = [
    {
      key: "status",
      header: "Event",
      render: (val, row) => {
        if (val !== "COMPLETED") {
          return val
        }

        return (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <Link
            onClick={() => {
              showCampaignStats(row.id)
            }}
          >
            {val}
          </Link>
        )
      }
    },
    {
      key: "launch_date",
      header: "Launch",
      render: (val) => {
        const formatedDate = val ? formatDate({ date: val, format: "MMM DD, YYYY - HH:mm" }) : "--"
        return formatedDate
      }
    },
    {
      key: "completed_date",
      header: "Completed",
      render: (val) => {
        const formatedDate = val ? formatDate({ date: val, format: "MMM DD, YYYY - HH:mm" }) : "--"
        return formatedDate
      }
    },
    {
      key: "delivery_status",
      header: "Recipients",
      render: (val) => {
        const recipients_count = val?.recipient_count
        return recipients_count || "-"
      }
    }
  ]

  useEffect(() => {
    dispatch(getCampaignsOfRecurringCampaign(recurring_campaign.id, 1, DEFAULT_PAGE_SIZE))
  }, [recurring_campaign.id])

  const handleOnTableChange = (data) => {
    const newPagination = data.pagination
    const next_page = newPagination.offset / newPagination.limit + 1
    const hasLimitChanged = pagination.limit !== newPagination.limit
    if (next_page === campaignsPage.page_number && !hasLimitChanged) {
      return
    }
    setPagination(newPagination)
    dispatch(getCampaignsOfRecurringCampaign(recurring_campaign.id, next_page, pagination.limit))
  }

  const renderLoading = () => (
    <div>
      <SkeletonLoader height={20} marginTop={20} />
      <SkeletonLoader height={100} marginTop={10} />
    </div>
  )

  if (campaigns?.length === 0) {
    return <p>No data available</p>
  }

  if (campaigns.length > 0) {
    return (
      <Table
        manual
        totalCount={campaignsPage.total_elements}
        data={campaigns}
        defaultPage={campaignsPage.page_number}
        defaultPageSize={DEFAULT_PAGE_SIZE}
        pageSizeOptions={[10, 25, 50]}
        onChangeTable={handleOnTableChange}
        columns={columns}
        // Disable all sorting
        excludeSortColumn={columns.map((c) => c.key)}
      />
    )
  }

  return renderLoading()
}
