import styled from "styled-components"
import { Typography, theme } from "@elevate_security/elevate-component-library"

const { H2, Text, Small } = Typography

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dde1e7;
  padding: 24px 0;
  align-items: center;

  &:not(:first-child) {
    border-top: 1px solid #dde1e7;
  }

  & > div:first-child {
    flex: 1;
  }

  .actions {
    & > a {
      display: inline-block;
      padding: 8px;
    }
  }
`

export const Heading = styled(H2)`
  color: ${theme.text.color.link};
  display: flex;
  font-weight: bold;
  font-size: 20px;
  [class^="Badgestyles__"] {
    margin-left: 8px;
  }
`

export const CreationDate = styled(Text).attrs({
  fontWeight: "normal"
})`
  color: ${theme.text.color["300"]};
`
