import * as campaignActions from "./actions"

export const getGroupsList = (searchValue) => ({
  type: campaignActions.GET_GROUPS_LIST,
  searchValue
})

export const getGroupEmployees = (id, campaignType) => ({
  type: campaignActions.GET_GROUP_EMPLOYEES,
  id,
  campaignType
})

export const getSenderEmailsList = () => ({
  type: campaignActions.GET_SENDER_EMAILS_LIST
})

export const getRecipientsListById = (id, orgId) => ({
  type: campaignActions.GET_RECIPIENTS_LIST,
  id,
  orgId
})

const ACTION_HANDLERS = {
  [campaignActions.GET_GROUPS_LIST]: (state) => ({ ...state, loading: true }),
  [campaignActions.GET_GROUPS_LIST_SUCCESS]: (state, action) => ({
    ...state,
    groups: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_GROUPS_LIST_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.GET_GROUP_EMPLOYEES]: (state) => ({
    ...state,
    loading: true
  }),
  [campaignActions.GET_GROUP_EMPLOYEES_SUCCESS]: (state, action) => ({
    ...state,
    groups_data: {
      ...state.groups_data,
      [action.group_id]: { members_count: action.data.count }
    },
    employees: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_GROUP_EMPLOYEES_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.GET_SENDER_EMAILS_LIST]: (state) => ({
    ...state,
    loading: true
  }),
  [campaignActions.GET_SENDER_EMAILS_LIST_SUCCESS]: (state, action) => ({
    ...state,
    senderEmails: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_SENDER_EMAILS_LIST_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.GET_RECIPIENTS_LIST]: (state) => ({
    ...state,
    loading: true
  }),
  [campaignActions.GET_RECIPIENTS_LIST_SUCCESS]: (state, action) => ({
    ...state,
    recipients: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_RECIPIENTS_LIST_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  })
}

const defaultState = {
  data: [],
  groups_data: {}
}

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
