import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"

import {
  Typography,
  Icon,
  theme,
  Modal,
  IconAction,
  Header,
  Button
} from "@elevate_security/elevate-component-library"
import {
  StyledDiv,
  TitleStatusContainer,
  CampaignTitle,
  ItemStatus,
  DateAndTrashContainer,
  DateDiv,
  ActionsRow,
  DropdownWrapper,
  DropdownItem,
  DropdownItemContent,
  DropdownTitleWrapper,
  Column,
  DropdownButton,
  StyledDropDownOpenContent,
  StrongTitle,
  RecipientsLabel,
  RecipientsContainer,
  RecipientCount,
  RecipientLegend,
  RecipientProgressBar
} from "../styles"
import { deleteRecurringCampaign } from "../../../sagas/recurringCampaignSaga/reducer"
import { getGroupEmployees } from "../../../sagas/organizationsSaga/reducer"
import { createGroupMembersCountSelector } from "../../../sagas/organizationsSaga/selectors"

import { formatDate } from "../../../utils/dates"
import { CAMPAIGN_STATUS, CAMPAIGN_STATUS_NAME } from "../../../constants"

import { SpaceBetween } from "../../../components/SpaceBetween"
import { SkeletonLoader } from "../../../components/skeleton"
import { CampaignsTable } from "./CampaignsTable"
import { ScheduleRender } from "../ScheduleRender"

const { H2, Text } = Typography

const STATE_LABEL_STYLES = {
  [CAMPAIGN_STATUS.DRAFT]: {
    backgroundColor: theme.badge.bg.blue
  },
  [CAMPAIGN_STATUS.SCHEDULED]: {
    backgroundColor: theme.badge.bg.yellow
  },
  [CAMPAIGN_STATUS.RUNNING]: {
    backgroundColor: theme.colors.primary["500"],
    color: theme.colors.white
  },
  [CAMPAIGN_STATUS.COMPLETED]: {
    backgroundColor: theme.badge.bg.gray
  },
  [CAMPAIGN_STATUS.ERROR]: {
    backgroundColor: theme.colors.bg.errorInverse,
    color: theme.colors.white
  }
}

const GroupLabel = ({ group_id, group_name, campaign_type }) => {
  const dispatch = useDispatch()
  dispatch(getGroupEmployees(group_id, campaign_type))
  const groupMembersCountSelector = createGroupMembersCountSelector(group_id)
  const members_count = useSelector(groupMembersCountSelector)
  const isValidNumber = Number.isInteger(members_count)
  if (!isValidNumber) {
    return <RecipientsLabel>Loading ...</RecipientsLabel>
  }
  return (
    <RecipientsLabel>
      {group_name} {members_count} recipents
    </RecipientsLabel>
  )
}

const DropDownOpenContent = ({ campaign, base_path }) => (
  <StyledDropDownOpenContent>
    {campaign.group_name && (
      <GroupLabel
        group_id={campaign.group_id}
        group_name={campaign.group_name}
        campaign_type={campaign.campaign_type}
      />
    )}
    <div>
      <Text>
        <b>Language: </b>
      </Text>
      <Text>{campaign.language}</Text>
    </div>
    <div>
      <Text>
        <b>Schedule:</b>
      </Text>
      <Text>{campaign.schedule && <ScheduleRender schedule={campaign.schedule} />}</Text>
    </div>

    <CampaignsTable recurring_campaign={campaign} base_path={base_path} />
  </StyledDropDownOpenContent>
)

export const RecurringCampaignRow = ({ campaign, base_path }) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const dispatch = useDispatch()
  const history = useHistory()

  const editableStatus = new Set(["DRAFT", "SCHEDULED"])
  const canBeEdited = editableStatus.has(campaign.status)

  const handleOnToggleItem = () => {
    setIsOpen(!isOpen)
  }

  const handleEdit = (campaignParam) => {
    history.push(`${base_path}/${campaignParam.id}`)
  }

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal)
  }

  const handleClickDelete = () => {
    setShowDeleteModal(true)
  }

  const handleDelete = () => {
    dispatch(deleteRecurringCampaign(campaign.id))
    setShowDeleteModal(false)
  }

  const setDateInfoLine = (dateInfo) => {
    if (!dateInfo) return "N/A"
    return formatDate({
      date: dateInfo,
      format: "MMM DD, YYYY - HH:mm"
    })
  }

  const renderDeleteModal = () => (
    <Modal
      key="confirmationModal"
      style={{ maxWidth: "700px", maxHeight: "350px" }}
      onClose={toggleDeleteModal}
      header={
        <Header>
          <H2 color="900">Delete Campaign</H2>
        </Header>
      }
      leftButtons={[
        <Button key="no" theme="ghost" onClick={toggleDeleteModal}>
          No, Don&#39;t Delete
        </Button>,
        <Button key="yes" onClick={handleDelete}>
          Yes, Delete Campaign
        </Button>
      ]}
      isOpen={showDeleteModal}
    >
      <div>
        <Text fontWeight="normal">
          You&#39;re about to remove <b>{campaign.name}</b> from your Campaigns list.
        </Text>
        <br />
        <br />
        <Text fontWeight="normal">Are you sure you want to perform this action?</Text>
      </div>
    </Modal>
  )

  const renderTitle = (item) => (
    <StyledDiv>
      <TitleStatusContainer>
        <CampaignTitle>{item.name}</CampaignTitle>
        <ItemStatus style={STATE_LABEL_STYLES[item?.status]}>
          {CAMPAIGN_STATUS_NAME[item?.status]}
        </ItemStatus>
        {item.is_test && (
          <ItemStatus style={{ backgroundColor: theme.badge.bg.yellow }}>Test Campaign</ItemStatus>
        )}
      </TitleStatusContainer>
      <DateAndTrashContainer>
        <DateDiv>
          Last updated <b>{setDateInfoLine(item.updated_date)}</b>
        </DateDiv>
        <ActionsRow>
          {canBeEdited && (
            <span
              className="btn-edit"
              onClick={(e) => {
                e.stopPropagation()
                handleEdit(item)
              }}
            >
              <IconAction name={"Pencil"} size="xsm" fill="#959DA8" tooltipText="Edit Campaign" />
            </span>
          )}
          <span
            className="btn-delete"
            onClick={(e) => {
              e.stopPropagation()
              handleClickDelete(item)
            }}
          >
            <IconAction name={"Trash"} size="xsm" fill="#959DA8" tooltipText="Delete Campaign" />
          </span>
        </ActionsRow>
      </DateAndTrashContainer>
    </StyledDiv>
  )

  return (
    <DropdownWrapper data-test-id="campaign-row">
      <DropdownItem>
        <DropdownTitleWrapper
          onClick={(event) =>
            handleOnToggleItem({
              event
            })
          }
        >
          {renderTitle(campaign)}
          <Column>
            <DropdownButton onClick={(event) => handleOnToggleItem({ event })} isOpen={isOpen}>
              <Icon name={"CaretUp"} size="xxsm" fill={theme.colors.gray.opaque[500]} />
            </DropdownButton>
          </Column>
        </DropdownTitleWrapper>
        <SpaceBetween size="sm" gap={!isOpen && "0px"}>
          <SpaceBetween direction="row">
            <SpaceBetween alignItems="flex-start" size="xs">
              {campaign.description && <StrongTitle>{campaign.description}</StrongTitle>}
              {campaign.next_run_at && (
                <StrongTitle>Next run at: {setDateInfoLine(campaign.next_run_at)}</StrongTitle>
              )}
            </SpaceBetween>
          </SpaceBetween>
          {campaign.status === CAMPAIGN_STATUS.RUNNING && campaign?.realtime_stats && (
            <SpaceBetween size="sm">
              <RecipientsContainer>
                <RecipientCount>
                  {`${campaign?.realtime_stats || 0} of ${campaign?.realtime_stats_total}`}
                </RecipientCount>
                <RecipientProgressBar
                  progress={
                    ((campaign?.realtime_stats || 0) / (campaign?.realtime_stats_total || 1)) * 100
                  }
                />
                <RecipientLegend>Recipients processed</RecipientLegend>
              </RecipientsContainer>
            </SpaceBetween>
          )}
          <DropdownItemContent isOpen={isOpen}>
            {isOpen && <DropDownOpenContent campaign={campaign} base_path={base_path} />}
            {!isOpen && (
              <>
                <SkeletonLoader height={20} marginTop={20} />
                <SkeletonLoader height={100} marginTop={10} />
              </>
            )}
          </DropdownItemContent>
        </SpaceBetween>

        {renderDeleteModal()}
      </DropdownItem>
    </DropdownWrapper>
  )
}
