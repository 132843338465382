import React from "react"

import { Typography, Button, Icon } from "@elevate_security/elevate-component-library"

import {
  ModalContent,
  WelcomeModalImage,
  WelcomeModalBodyContent,
  ModalOverLayer,
  CloseButton
} from "./styles"

const imagWelcome =
  "https://hm-assets.elevatesecurity.com/master/pulse/img-welcome-pulse-template-manager.png"

// eslint-disable-next-line react/prop-types
function ConfigurationsOnboardModal({ onLetsGoClick, onClose }) {
  return (
    <>
      <ModalOverLayer onClick={() => {}} />
      <ModalContent>
        <CloseButton onClick={onClose}>
          <Icon name="Close" size="lg" fill="red" />
        </CloseButton>
        <WelcomeModalImage src={imagWelcome} alt="Wecolme pulse template manager" />
        <WelcomeModalBodyContent>
          <Typography.H1>Welcome to Pulse templates manager</Typography.H1>
          <Typography.Body fontWeight="normal">
            Create, configure, and manage multiple Pulse templates.
          </Typography.Body>
          <Typography.Body fontWeight="normal">Ready for a quick walk through?</Typography.Body>
          <Button onClick={onLetsGoClick}>Let&apos;s go</Button>
        </WelcomeModalBodyContent>
      </ModalContent>
    </>
  )
}

export default ConfigurationsOnboardModal
