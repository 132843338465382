export const GET_GROUPS_LIST = "GET_GROUPS_LIST"
export const GET_GROUPS_LIST_SUCCESS = "GET_GROUPS_LIST_SUCCESS"
export const GET_GROUPS_LIST_FAILED = "GET_GROUPS_LIST_FAILED"

export const GET_GROUP_EMPLOYEES = "GET_GROUP_EMPLOYEES"
export const GET_GROUP_EMPLOYEES_SUCCESS = "GET_GROUP_EMPLOYEES_SUCCESS"
export const GET_GROUP_EMPLOYEES_FAILED = "GET_GROUP_EMPLOYEES_FAILED"

export const GET_SENDER_EMAILS_LIST = "GET_SENDER_EMAILS_LIST"
export const GET_SENDER_EMAILS_LIST_SUCCESS = "GET_SENDER_EMAILS_LIST_SUCCESS"
export const GET_SENDER_EMAILS_LIST_FAILED = "GET_SENDER_EMAILS_LIST_FAILED"

export const GET_RECIPIENTS_LIST = "GET_RECIPIENTS_LIST"
export const GET_RECIPIENTS_LIST_SUCCESS = "GET_RECIPIENTS_LIST_SUCCESS"
export const GET_RECIPIENTS_LIST_FAILED = "GET_RECIPIENTS_LIST_FAILED"
