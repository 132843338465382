import * as actions from "./actions"

export const showAlert = (text, isError) => ({
  type: actions.SHOW_ALERT,
  text,
  isError
})

export const hideAlert = (id) => ({
  type: actions.HIDE_ALERT,
  id
})

const ACTION_HANDLERS = {
  [actions.SHOW_ALERT]: (state, action) => ({
    ...state,
    messages: [
      ...state.messages,
      {
        id: state.nextId,
        text: action.text,
        isError: action.isError
      }
    ],
    nextId: state.nextId + 1
  }),
  [actions.HIDE_ALERT]: (state, action) => ({
    ...state,
    messages: state.messages.filter((message) => message.id !== action.id)
  })
}

const defaultState = {
  nextId: 1,
  messages: []
}

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
