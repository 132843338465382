import React, { useEffect, useState, useCallback, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Redirect, useParams, useHistory } from "react-router-dom"
import { Button } from "@elevate_security/elevate-component-library"
import PropTypes from "prop-types"
import Breadcrumbs from "../../../components/Breadcrumbs"
import { ConfigurationHeader, ConfiguratorContainer, ConfiguratorPage } from "../styles"
import {
  getTemplatesData,
  getConfigurations,
  getGroups,
  isCreating,
  isLoading,
  getLanguages
} from "../../../sagas/configuratorSaga/selectors"
import {
  deleteConfiguration,
  listConfigurations,
  fetchGroups,
  createConfiguration,
  copyConfiguration,
  toggleCreateConfigurationModal,
  setLanguagesOptions
} from "../../../sagas/configuratorSaga/reducer"
import { getEmailTemplates } from "../../../sagas/templatesSaga/reducer"
import ConfigurationsList from "../../../components/List"
import CreateModal from "./components/CreateModal"
import PreviewModal from "../../../components/PreviewModal"
import withTemplatePreview from "../Template/withTemplatePreview"
import { ConfigurationsTitle } from "./styles"
import TourTooltip from "../../../components/TourTooltip"
import { TOUR_TEMPLATE_KEY } from "../../../constants"
import tourTooltips from "../../tour-tooltips"
import {
  TooltipTourProvider,
  useTooltipTourDispatch
} from "../../../components/TourTooltip/TooltipTourContext"
import ConfigurationsOnboardModal from "../ConfigurationsOnboardModal"
import { getLanguagesOptions, LANGUAGES_WITH_VALUES } from "../../../utils/utils"

const Configurations = ({
  preview,
  state,
  language,
  previewActive,
  handlePreview,
  templateId,
  configurationId
}) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { id } = useParams()
  const configurations = useSelector(getConfigurations)
  const groups = useSelector(getGroups)
  const templates = useSelector(getTemplatesData)
  const languages = useSelector(getLanguages)
  const loading = useSelector(isLoading)
  const creating = useSelector(isCreating)
  const { onTourKeyChange, onStepChange } = useTooltipTourDispatch()
  const [isOpenModalWelcomeDemo, setIsOpenModalWelcomeDemo] = useState(false)

  useEffect(() => {
    if (!languages.length) {
      const languagesOptions = getLanguagesOptions()
      const languagesKeys = Object.keys(languagesOptions)
      const languagesArray = LANGUAGES_WITH_VALUES.filter((item) =>
        languagesKeys.includes(item.label)
      )
      dispatch(setLanguagesOptions(languagesArray))
    }
    onTourKeyChange(TOUR_TEMPLATE_KEY, tourTooltips)
    // eslint-disable-next-line no-undef
    setIsOpenModalWelcomeDemo(!localStorage.getItem(TOUR_TEMPLATE_KEY))
  }, [])

  useEffect(() => {
    if (!templates || !templates.length) {
      dispatch(getEmailTemplates())
    }
  }, [templates])

  useEffect(() => {
    if (!configurations || !configurations.length) {
      dispatch(listConfigurations())
    }
  }, [configurations])

  useEffect(() => {
    if (!groups || !groups.length) {
      dispatch(fetchGroups())
    }
  }, [groups])

  const handleToggleForm = (open) => dispatch(toggleCreateConfigurationModal(open))

  const handleDelete = useCallback(
    (configurationToDelete) => dispatch(deleteConfiguration(configurationToDelete.id)),
    []
  )

  const handleCreate = (values) =>
    dispatch(
      createConfiguration(values, (conf) => history.push(`/engagement/pulse/templates/${conf.id}`))
    )

  const handleCopy = useCallback(
    ({ id: targetId }) => {
      const configuration = { ...configurations.find((template) => template.id === targetId) }
      delete configuration.id
      const { name, ...rest } = configuration
      dispatch(copyConfiguration({ name: `${name} (Copy)`, ...rest, default: false }))
    },
    [configurations, copyConfiguration]
  )

  const onPreview = useCallback(
    (row) => handlePreview(null, { active: true, id: row.id, templateId: row.template_id }),
    [handlePreview]
  )

  const handleLetsGoClick = useCallback(() => {
    onStepChange(1)
    setIsOpenModalWelcomeDemo(false)
  }, [])

  const handleCloseModal = () => {
    onStepChange(-1)
    setIsOpenModalWelcomeDemo(false)
  }

  /**
   * If there is a form, and isCreating is false, the user just created a new configuration
   * and so we redirect to the edit page.
   */
  if (id) {
    return <Redirect to={`/engagement/pulse/templates/${id}`} />
  }

  const rows = useMemo(
    () =>
      configurations
        .filter((t) => t.template_id !== "static/default")
        .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at))
        .sort((a) => (a.default ? -1 : 1))
        .map((config) => ({
          ...config,
          id: config.id,
          title: config.name,
          editUrl: `/engagement/pulse/templates/${config.id}`
        })),
    [configurations]
  )

  return (
    <ConfiguratorPage scroll>
      <ConfigurationHeader>
        <Breadcrumbs
          routes={[
            ["Pulse", "campaigns"],
            ["Templates", "templates"]
          ]}
        />
        <ConfigurationsTitle>
          <span>Templates</span>
        </ConfigurationsTitle>
        <TourTooltip stepNo={1}>
          <Button onClick={() => handleToggleForm(true)}>Create New Template</Button>
        </TourTooltip>
      </ConfigurationHeader>
      <ConfiguratorContainer scroll>
        <ConfigurationsList
          rows={rows}
          onPreview={onPreview}
          onDelete={handleDelete}
          onCopy={handleCopy}
        />
      </ConfiguratorContainer>
      <PreviewModal
        configurationId={id || configurationId}
        loading={loading}
        active={previewActive}
        preview={preview}
        templateId={templateId}
        languages={languages}
        state={state}
        language={language}
        onClose={() => handlePreview(null, false)}
      />
      {creating && (
        <CreateModal
          active
          loading={loading}
          templates={templates}
          onCreate={handleCreate}
          onClose={() => handleToggleForm(false)}
        />
      )}
      {isOpenModalWelcomeDemo && (
        <ConfigurationsOnboardModal onLetsGoClick={handleLetsGoClick} onClose={handleCloseModal} />
      )}
    </ConfiguratorPage>
  )
}

Configurations.propTypes = {
  state: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired,
  preview: PropTypes.string.isRequired,
  previewActive: PropTypes.bool.isRequired,
  handlePreview: PropTypes.func.isRequired,
  templateId: PropTypes.number.isRequired
}

const ConfigurationsWithOnboarding = (props) => (
  <TooltipTourProvider>
    <Configurations {...props} />
  </TooltipTourProvider>
)

export default withTemplatePreview(ConfigurationsWithOnboarding)
