/* eslint-disable react/no-danger */
/* eslint-disable jsx-a11y/label-has-for,react/no-unused-prop-types */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field } from "react-final-form"
import dompurify from "dompurify"
import { RadioButton, Typography } from "@elevate_security/elevate-component-library"

import { FieldContainer } from "../styles"
import FieldLabel from "../../../FieldLabel"

const withForm = (Component) => (props) => {
  const { name, value } = props.control

  return (
    // currently only supports boolean values for the radio buttons
    // therefore Field type is checkbox
    <Field name={name} type="checkbox" initialValue={value}>
      {({ input }) => <Component {...props} input={input} />}
    </Field>
  )
}

const { Text } = Typography

const RadioInput = ({ control, input, form, isToggleGroups }) => {
  const [isSelected, setIsSelected] = useState(false)
  const { name, value, options, props: controlProps, hidden, parent, ...radioProps } = control
  const { vertical, invalid, tooltip, controlled } = radioProps
  const { disabled } = controlProps || {}
  const sanitizer = dompurify.sanitize

  const formValues = form?.getState()?.values || {}

  useEffect(() => {
    if (isToggleGroups && parent) {
      setIsSelected(!formValues[parent])
    }
  }, [formValues])

  return (
    <FieldContainer hidden={hidden}>
      {control.label && <FieldLabel control={control} />}
      <div>
        <RadioButton
          {...input}
          {...radioProps}
          name={name}
          disabled={disabled || isSelected}
          // currently only supports boolean values for the radio buttons
          radioSelected={input.checked}
          onChange={(value) => {
            input.onChange(value)
          }}
          options={options.map(({ tooltipText, ...rest }) => ({
            ...rest,
            ...(tooltipText
              ? {
                  tooltipText: (
                    <Text
                      style={{ fontSize: "13px" }}
                      fontWeight="normal"
                      dangerouslySetInnerHTML={{ __html: sanitizer(tooltipText) }}
                    />
                  )
                }
              : {})
          }))}
          vertical={vertical}
          invalid={invalid}
          tooltip={tooltip}
          controlled={controlled}
        />
      </div>
    </FieldContainer>
  )
}

RadioInput.propTypes = {
  control: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    helpText: PropTypes.string,
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        helpText: PropTypes.string
      })
    ),
    props: PropTypes.shape({
      selected: PropTypes.string,
      required: PropTypes.bool,
      disabled: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired,
  orientation: PropTypes.string,
  isToggleGroups: PropTypes.bool
}

RadioInput.defaultProps = {
  orientation: "",
  isToggleGroups: false
}

export default withForm(RadioInput)
