import React from "react"
import { BrowserRouter as Router } from "react-router-dom"
import Routes from "./routes"
import Alerts from "./components/Alerts/Alerts"
import "react-datepicker/dist/react-datepicker.css"
import "./css/style.krem.css"
import "./index.css"
import { PulseContainer } from "./styles"

class App extends React.Component {
  state = {
    hasError: false
  }

  componentDidCatch() {
    this.setState({ hasError: true })
  }

  render() {
    const { hasError } = this.state
    return hasError ? (
      <div className="root error">Error</div>
    ) : (
      <Router>
        <PulseContainer>
          <Alerts />
          <Routes />
        </PulseContainer>
      </Router>
    )
  }
}

export default App
