import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Breadcrumb, Typography, Link, theme } from "@elevate_security/elevate-component-library"

const { Text } = Typography

const StyledBreadcrumbs = styled.div`
  svg {
    display: inline-block;
    margin: 0 3px;
    width: 9px;
    height: 9px;
    position: relative;
    top: 1px;
    path {
      fill: #7b828a;
    }
  }
  a span {
    font-size: 13px;
  }
`

const Breadcrumbs = ({ routes }) => (
  <StyledBreadcrumbs>
    <Breadcrumb>
      {routes.map((route) => (
        <Link to={`/engagement/pulse/${route[1]}`} key={route[1]}>
          <Text
            style={{
              color: theme.breadcrumb.currentPageColor
            }}
          >
            {route[0]}
          </Text>
        </Link>
      ))}
    </Breadcrumb>
  </StyledBreadcrumbs>
)

Breadcrumbs.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)).isRequired
}

export default Breadcrumbs
