import PropTypes, { number, string } from "prop-types"
import React from "react"
import moment from "moment"

import { Row, Column, ScoreTooltipWrapper, ScoreNumber, ScoreMonth, ScoreLabel } from "./styles"

export default function ScoreTooltip(props) {
  const { date, score, barType } = props
  return (
    <ScoreTooltipWrapper>
      <Row>
        <Column padding="0 16px 0 0">
          <ScoreNumber>{score === null ? "N/A" : score}</ScoreNumber>
        </Column>
        <Column>
          <ScoreLabel>{`${barType} links tracked`}</ScoreLabel>
          <ScoreMonth>{moment(date).format("LL")}</ScoreMonth>
        </Column>
      </Row>
    </ScoreTooltipWrapper>
  )
}

ScoreTooltip.defaultProps = {
  score: number,
  date: string,
  barType: string
}
ScoreTooltip.propTypes = {
  score: PropTypes.number,
  date: PropTypes.string,
  barType: PropTypes.string
}
