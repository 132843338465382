/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
import * as React from "react"

const TooltipTourStateContext = React.createContext()
const TooltipTourDispatchContext = React.createContext()

function tourTooltipReducer(state, action) {
  switch (action.type) {
    case "setTourStep":
      return {
        ...state,
        tourStep: action.tourStep
      }
    case "setTourKey":
      return {
        ...state,
        tourKey: action.tourKey,
        tooltips: action.tooltips
      }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const initialValues = {
  tourStep: -1,
  tourKey: null,
  tooltips: []
}

export const setTourStep = (tourStep) => ({ type: "setTourStep", tourStep })
export const setTourKey = (tourKey, tooltips) => ({ type: "setTourKey", tourKey, tooltips })

function TooltipTourProvider({ children }) {
  const [state, dispatch] = React.useReducer(tourTooltipReducer, initialValues)

  const getLocalStorage = (key) => (localStorage ? JSON.parse(localStorage.getItem(key)) : null)
  const setLocalStorage = (key, value) => localStorage && localStorage.setItem(key, value)

  React.useEffect(() => {
    const tour = getLocalStorage(state.tourKey)
    dispatch(setTourStep(tour === null ? 1 : tour))
  }, [state.tourKey])

  function onStepChange(nextStep) {
    dispatch(setTourStep(nextStep))
    setLocalStorage(state.tourKey, nextStep)
  }

  function onTourKeyChange(tourKey, tooltips) {
    dispatch(setTourKey(tourKey, tooltips))
  }

  return (
    <TooltipTourStateContext.Provider value={state}>
      <TooltipTourDispatchContext.Provider value={{ onStepChange, onTourKeyChange }}>
        {children}
      </TooltipTourDispatchContext.Provider>
    </TooltipTourStateContext.Provider>
  )
}

function useTooltipTourState() {
  const context = React.useContext(TooltipTourStateContext)
  if (context === undefined) {
    throw new Error("useTooltipTourState must be used within a TooltipTourProvider")
  }
  return context
}

function useTooltipTourDispatch() {
  const context = React.useContext(TooltipTourDispatchContext)
  if (context === undefined) {
    throw new Error("useCountDispatch must be used within a TooltipTourProvider")
  }
  return context
}

export { TooltipTourProvider, useTooltipTourState, useTooltipTourDispatch }
