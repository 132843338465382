/* eslint-disable camelcase */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Field, FormApi } from "react-final-form"
import { Input } from "@elevate_security/elevate-component-library"

import { resolveValidations } from "../../../../../../../sagas/configuratorSaga/validations"
import { getInputdValidationError } from "../../../../../../../sagas/configuratorSaga/helpers/utils"
import FieldError from "../../../../../../../components/FieldError"
import { FieldContainer, InputText, InputContainer } from "../styles"
import FieldExampleLink from "../../../FieldExampleLink"
import { ControlProps } from "../../types"
import FieldLabel from "../../../FieldLabel"

/**
 * Multi language text input
 *
 * This input will listen for changes to it's
 * "language_source" sibling input, a control in
 * the same group of type "language", it will use
 * the language value of this control to switch
 * it's contents to the right language.
 *
 * @param form
 * @param control
 * @returns {JSX.Element}
 * @constructor
 */
const Text = ({ form, control, showErrors }) => {
  const {
    name,
    type,
    language_source,
    value,
    constrains,
    suppressFeedback,
    separator,
    example,
    defaultValue,
    placeholder,
    required,
    isURL,
    container_styles,
    props: controlProps,
    hidden
  } = control
  const { disabled } = controlProps || {}
  const languageState = language_source ? form.getFieldState(language_source) : null
  const [languageListener, setLanguageListener] = useState(null)
  const [language, setLanguage] = useState(languageState ? languageState.value : null)

  useEffect(() => {
    if (language_source) {
      setLanguageListener(
        form.subscribe(
          ({ values }) => {
            if (values[language_source] !== language) {
              setLanguage(values[language_source])
              form.change(name, control[`value_${values[language_source]}`])
            }
          },
          { values: true }
        )
      )
    }
    return () => languageListener && languageListener()
  })

  const geDefaultValue = () => {
    if (language_source) {
      const valueKey = `value_${language || "en_US"}`

      if (valueKey in control && control[valueKey]) {
        return control[valueKey]
      }

      if (defaultValue && language && language in defaultValue) {
        return defaultValue[valueKey]
      }
    }

    return value
  }

  const errorProp = (input, meta) =>
    (showErrors || meta.touched) &&
    getInputdValidationError(input.value, control.label, required, isURL)

  const hasExampleLink = !disabled && example !== null && typeof example === "object"

  // getting score_graphics_type dropdown value from form
  const scoreGraphTypeValue = example ? form.getFieldState("score_graphics_type")?.value : null

  return (
    <Field
      name={name}
      type={type}
      initialValue={geDefaultValue()}
      validate={resolveValidations(constrains)}
    >
      {({ input, meta }) => (
        <FieldContainer
          container_styles={container_styles}
          separator={separator}
          hasExampleLink={hasExampleLink}
          hidden={hidden}
        >
          <FieldLabel control={control} />
          <InputContainer
            style={
              disabled
                ? {
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis"
                  }
                : {}
            }
          >
            {!disabled && (
              <Input
                {...(controlProps || {})}
                {...input}
                disabled={disabled}
                placeholder={placeholder}
                onChange={(newValue) => input.onChange(newValue || null)}
                invalid={!!errorProp(input, meta)}
              />
            )}
            {disabled && <InputText>{input.value || geDefaultValue() || "N/A"}</InputText>}
          </InputContainer>
          {!suppressFeedback && !disabled && (
            <FieldError
              touched
              error={errorProp(input, meta)}
              customStyles={{ marginBottom: "-20px" }}
            />
          )}
          {hasExampleLink && (
            <FieldExampleLink control={{ ...control, value: scoreGraphTypeValue }} />
          )}
        </FieldContainer>
      )}
    </Field>
  )
}

Text.propTypes = {
  form: PropTypes.instanceOf(FormApi).isRequired,
  control: ControlProps.isRequired,
  validations: PropTypes.arrayOf(PropTypes.func),
  showErrors: PropTypes.bool
}

Text.defaultProps = {
  validations: [],
  showErrors: false
}

export default Text
