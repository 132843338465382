import styled, { keyframes } from "styled-components"

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`

export const SpinnerBackdrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${(props) => ` rgba(255, 255, 255, ${props.opacity || 0.8})`};
  transition: opacity 300ms;
  opacity: 1;
  z-index: 1000;
`

export const Spinner = styled.div`
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: ${(props) => props.size};

  ${(props) => {
    switch (props.position) {
      case "top-left":
      case "top-right":
        return `height: ${props.size};`
      default:
        return `height: calc(${props.size} + calc(${props.size} / 4) + 22px);`
    }
  }}

  margin: ${(props) => props.margin || "auto"};
  display: flex;
  flex-direction: column;

  ${(props) => {
    switch (props.position) {
      case "top-center":
        return `
          top: calc(${props.size} / 2);
          bottom: auto;
        `
      case "top-left":
        return `
            width: auto;
            height: ${props.size};
            top: calc(${props.size} / 2);
            left: calc(${props.size} / 2);
            right: auto;
            bottom: auto;
            flex-direction: row;
          `
      case "top-right":
        return `
            width: auto;
            height: ${props.size};
            top: calc(${props.size} / 2);
            left: auto;
            right: calc(${props.size} / 2);
            bottom: auto;
            flex-direction: row-reverse;
          `
      default:
        return ""
    }
  }}

  & > div {
    width: ${(props) => props.size};
    height: ${(props) => props.size};

    ${(props) => {
      if (props.hasMessage) {
        if (props.position === "top-left") {
          return `margin-right: calc(${props.size} / 4);`
        }
        if (props.position === "top-right") {
          return `margin-left: calc(${props.size} / 4);`
        }
        return `margin-bottom: calc(${props.size} / 4);`
      }
      return ""
    }}

    div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: ${(props) => props.size};
      height: ${(props) => props.size};
      margin: 0;
      border: ${(props) => props.border} solid #0c7d84;
      border-radius: 50%;
      animation: ${spin} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #0c7d84 transparent transparent transparent;
    }
    div:nth-child(1) {
      animation-delay: -0.45s;
    }
    div:nth-child(2) {
      animation-delay: -0.3s;
    }
    div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  & > span {
    display: block;

    ${(props) => {
      if (props.position === "top-left") {
        return `
        line-height: ${props.size};
      `
      }
      if (props.position === "top-right") {
        return `line-height: ${props.size};`
      }

      return `
      line-height: 22px;
      text-align: center;
    `
    }}
  }
`
