import React from "react"
import { Tabs, Tab, Typography } from "@elevate_security/elevate-component-library"

import { TabsContainer } from "./styles"
import { CAMPAIGN_STATUS } from "../../constants"

const { Text } = Typography
const Filter = (props) => {
  const { campaigns } = props
  const tabs = Object.keys(CAMPAIGN_STATUS)

  const handleOnChange = (key) => {
    const { onChange } = props
    onChange(key)
  }
  const statusCount = {
    ALL: campaigns.ALL.length,
    DRAFT: campaigns.DRAFT.length,
    SCHEDULED: campaigns.SCHEDULED.length,
    COMPLETED: campaigns.COMPLETED.length,
    ERROR: campaigns.ERROR.length
  }

  const title = (key) => (
    <Text>
      {CAMPAIGN_STATUS[key].toLowerCase()}
      <Text padding="0 0 0 2px">({statusCount && statusCount[key] ? statusCount[key] : 0})</Text>
    </Text>
  )

  return (
    <TabsContainer>
      <Tabs onChange={(index) => handleOnChange(tabs[index])}>
        {tabs.map((tab) => (
          <Tab title={title(tab)} key={tab} />
        ))}
      </Tabs>
    </TabsContainer>
  )
}

export default Filter
