/* eslint-disable react/forbid-prop-types */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import { getSenderEmailsList } from "../../../sagas/organizationsSaga/reducer"

import {
  createCampaign,
  updateCampaign,
  resetCampaign,
  moveCampaignWizardToStep
} from "../../../sagas/recurringCampaignSaga/reducer"
import CampaignModalReduxConnected from "./Modal"
import { campaignType } from "../../../types"

export class CampaignWizard extends React.Component {
  componentDidMount() {
    const { getSenderEmailsFunc } = this.props
    if (!getSenderEmailsFunc) {
      return
    }
    getSenderEmailsFunc()
  }

  onPreviousStep = () => {
    const { currentStep, moveCampaignWizardToStepFunc } = this.props
    if (currentStep > 1) {
      moveCampaignWizardToStepFunc(currentStep - 1)
    }
  }

  onNextStep = (formData, noStep) => {
    const { campaignInfo, createCampaignFunc, updateCampaignFunc, currentStep, toggleModal } =
      this.props
    const payload = {
      ...formData,
      wizard_step: !noStep ? currentStep + 1 : currentStep
    }
    if (!campaignInfo) {
      createCampaignFunc(payload)
    } else {
      if (currentStep < campaignInfo.wizard_step || currentStep === 4) delete payload.wizard_step
      updateCampaignFunc(campaignInfo.id, payload, currentStep, noStep, toggleModal)
    }
  }

  closeModal = () => {
    const { resetCampaignFunc, toggleModal } = this.props
    toggleModal()
    resetCampaignFunc()
  }

  stepTo = (step) => {
    const { campaignInfo, moveCampaignWizardToStepFunc } = this.props
    if (campaignInfo && step <= campaignInfo.wizard_step) {
      moveCampaignWizardToStepFunc(step)
    }
  }

  render() {
    const { currentStep } = this.props
    return (
      <div>
        <CampaignModalReduxConnected
          title="Enter campaign info"
          currentStep={currentStep}
          onClose={this.closeModal}
          onBack={this.onPreviousStep}
          onContinue={this.onNextStep}
          stepTo={this.stepTo}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  campaignInfo: state.recurring_campaigns.campaignInfo,
  currentStep: state.recurring_campaigns.currentStep
})

const mapDispatchToProps = {
  createCampaignFunc: createCampaign,
  resetCampaignFunc: resetCampaign,
  updateCampaignFunc: updateCampaign,
  getSenderEmailsFunc: getSenderEmailsList,
  moveCampaignWizardToStepFunc: moveCampaignWizardToStep
}

CampaignWizard.propTypes = {
  campaignInfo: campaignType,
  currentStep: PropTypes.number,
  createCampaignFunc: PropTypes.func,
  resetCampaignFunc: PropTypes.func,
  getSenderEmailsFunc: PropTypes.func,
  updateCampaignFunc: PropTypes.func,
  moveCampaignWizardToStepFunc: PropTypes.func,
  toggleModal: PropTypes.func
}

CampaignWizard.defaultProps = {
  campaignInfo: null,
  currentStep: null,
  createCampaignFunc: null,
  getSenderEmailsFunc: null,
  resetCampaignFunc: null,
  updateCampaignFunc: null,
  moveCampaignWizardToStepFunc: null,
  toggleModal: null
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignWizard)
