import styled from "styled-components"

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 264px;
  min-width: 264px;
  background: #fff;
  border: 1px solid rgba(189, 197, 209, 0.3);
  border-radius: 5px;
  padding: 25px;
  margin-top: 25px;
  hr {
    width: 100%;
  }
`
