import React from "react"
import PropTypes from "prop-types"
import { MenuLink } from "../../styles"

const MenuItem = ({ group, selected, sub, onSelect }) => {
  const handleSelect = (e) => {
    onSelect(e, group, true)
  }

  if (["group", "section"].indexOf(group.get("type")) > -1) {
    return (
      <li>
        <MenuLink
          href={`#cfg-${group.get("name").replace(new RegExp("_", "g"), "-")}`}
          onClick={handleSelect}
          selected={selected === group.get("name")}
        >
          <span>
            <b>{sub ? String.fromCharCode(8226) : ""}</b>
            {group.get("label")}
          </span>
        </MenuLink>
      </li>
    )
  }

  return null
}

MenuItem.propTypes = {
  sub: PropTypes.bool,
  group: PropTypes.instanceOf(Map).isRequired,
  selected: PropTypes.string,
  onSelect: PropTypes.func.isRequired
}

MenuItem.defaultProps = {
  sub: false,
  selected: null
}

export default MenuItem
