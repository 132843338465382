import React from "react"
import PropTypes from "prop-types"
import FieldHelpToolTip from "../FieldHelpToolTip"
import { Label } from "../Form/fields/styles"

const FieldLabel = ({ control: { index, helpText, helpTextWarning, label, subIndex } }) => (
  <Label className="field-label_label">
    {index && !subIndex && index > 0 ? `${index}. ` : null}
    {index && subIndex && index > 0 && index > 0
      ? `${index}${String.fromCharCode(96 + subIndex)}. `
      : null}
    {label}
    {"  "}
    <FieldHelpToolTip text={helpText} danger={helpTextWarning} placement="right" />
  </Label>
)

FieldLabel.propTypes = {
  control: PropTypes.shape({
    index: PropTypes.number,
    subIndex: PropTypes.number,
    helpText: PropTypes.string,
    helpTextWarning: PropTypes.bool,
    label: PropTypes.string.isRequired
  }).isRequired
}

export default FieldLabel
