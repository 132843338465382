import React from "react"
import PropTypes from "prop-types"
import moment from "moment"
import { Badge, Link } from "@elevate_security/elevate-component-library"
import TourTooltip from "../TourTooltip"
import { Row, Heading, CreationDate } from "./styles"
import ActionRow from "./ActionRow"
import { parseTitle, renderTemplateType } from "../../utils/utils"

const ListRow = ({ row, onPreview, onCopy, onDelete, onEdit, children, hasTourTooltips }) => {
  const handleEdit = (e, targetRow) => {
    if (!targetRow.editUrl) {
      e.preventDefault()
      e.stopPropagation()
      onEdit(targetRow)
    }
  }

  const renderHeadingTitle = () =>
    hasTourTooltips ? (
      <TourTooltip stepNo={2}>
        <Link to={row.editUrl} onClick={(e) => handleEdit(e, row)}>
          {parseTitle(row.title, row.default) || "Untitled Template"}
        </Link>
      </TourTooltip>
    ) : (
      <Link to={row.editUrl} onClick={(e) => handleEdit(e, row)}>
        {parseTitle(row.title, row.default) || "Untitled Template"}
      </Link>
    )

  const renderStatus = () => {
    if (!row.default) return null
    return hasTourTooltips ? (
      <TourTooltip stepNo={3}>
        <Badge text="Default" theme="gray" />
      </TourTooltip>
    ) : (
      <Badge text="Default" theme="gray" />
    )
  }

  const renderIcons = () => (
    <ActionRow
      row={row}
      onPreview={onPreview}
      onCopy={onCopy}
      onDelete={onDelete}
      onEdit={onEdit}
      hasTourTooltips={hasTourTooltips}
    />
  )

  const renderActionsIcons = () =>
    hasTourTooltips ? <TourTooltip stepNo={4}>{renderIcons()}</TourTooltip> : renderIcons()

  return (
    <Row>
      <div>
        <Heading>
          {renderHeadingTitle()}
          {renderTemplateType(row.template_id)}
          {renderStatus()}
        </Heading>
        <CreationDate>
          {row.updated_at
            ? moment(row.updated_at).format("[Updated on] MMMM DD, YYYY [at] hh:mm A [UTC]")
            : null}
        </CreationDate>
      </div>
      {children && <div>{{ children }}</div>}
      {renderActionsIcons()}
    </Row>
  )
}

ListRow.propTypes = {
  row: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    editUrl: PropTypes.string,
    default: PropTypes.bool,
    updated_at: PropTypes.string,
    template_id: PropTypes.string
  }).isRequired,
  children: PropTypes.element,
  onPreview: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
  hasTourTooltips: PropTypes.bool
}

ListRow.defaultProps = {
  onPreview: () => null,
  onEdit: () => null,
  onDelete: () => null,
  onCopy: () => null,
  children: null,
  hasTourTooltips: false
}

export default ListRow
