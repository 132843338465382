import React, { useEffect, useRef } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { useDispatch } from "react-redux"
import { DragosaurWithUmbrella } from "@elevate_security/elevate-component-library"
import ActivityIndicator from "../ActivityIndicator"
import { setPreviewRef } from "../../sagas/configuratorSaga/reducer"

export const PreviewContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0 -20px 0 0;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const StyledIframe = styled.iframe`
  opacity: ${(props) => (props.loading ? 0.6 : 1)};
`

/**
 * The preview component expects a source html string, fetched
 * from the template preview endpoint to render a iframe with the
 * template.
 *
 * It also propagates the iframe reference to the store on every load.
 *
 * @param source The html page fetched from the templates preview endpoint.
 * @param loading The loading flag from the store.
 * @param scrollPosition The last scroll position to scroll to when the iframe re-loads.
 * @returns {*}
 * @constructor
 */
const TemplatePreview = ({ preview, loading, scrollPosition }) => {
  const frame = useRef()
  const dispatch = useDispatch()

  /**
   * We propagate the ifram reference up so other components
   * like the menu can work with it.
   *
   * Currently the menu uses this reference to scroll the iframe
   * when user clicks on a menu item.
   */
  useEffect(() => {
    const { current } = frame

    if (current) {
      dispatch(setPreviewRef(current))
    }
  }, [frame])

  if (!loading && !preview) {
    return (
      <PreviewContainer>
        <DragosaurWithUmbrella message="No Record Found" showTooltip={false} />
      </PreviewContainer>
    )
  }

  /**
   * When the iframe reloads because of state or language
   * changes, we scroll back to the last scroll position to
   * keep the user in context.
   */
  const onLoad = () => {
    if (scrollPosition && scrollPosition > 0) {
      const { contentWindow } = frame.current

      contentWindow.scrollTo({
        top: scrollPosition,
        left: 0
      })
    }
  }

  return (
    <PreviewContainer>
      <StyledIframe
        ref={frame}
        loading={loading}
        onLoad={onLoad}
        title="Preview"
        srcDoc={preview}
        width="100%"
        height="100%"
      />
      <ActivityIndicator
        message="Reloading"
        position="top-left"
        delay={500}
        size={20}
        border={2}
        opacity={0.8}
        active={loading}
      />
    </PreviewContainer>
  )
}

TemplatePreview.propTypes = {
  loading: PropTypes.bool.isRequired,
  preview: PropTypes.string.isRequired,
  scrollPosition: PropTypes.number
}

TemplatePreview.defaultProps = {
  scrollPosition: null
}

export default TemplatePreview
