import React from "react"
import PropTypes from "prop-types"
import { Field } from "react-final-form"
import { CustomSelect } from "@elevate_security/elevate-component-library"
import { useSelector } from "react-redux"
import { FieldContainer, InputContainer, Label } from "../styles"
import { getLanguages } from "../../../../../../../sagas/configuratorSaga/selectors"
import FieldHelpToolTip from "../../../FieldHelpToolTip"

const Language = (props) => {
  const { control } = props
  const { name, type, label, value, props: controlProps, helpText, hidden } = control
  const { disabled } = controlProps || {}
  const languages = useSelector(getLanguages)

  if (disabled) {
    return null
  }

  return (
    <Field name={name} type={type} initialValue={value || languages[0].value}>
      {({ input }) => (
        <FieldContainer hidden={hidden}>
          <Label>
            {label} <FieldHelpToolTip text={helpText} placement="right" />
          </Label>
          <InputContainer>
            <CustomSelect
              {...input}
              disabled={disabled}
              options={languages}
              defaultValue={input.value}
              onChange={(e) => {
                const { value: controlValue } = e
                input.onChange(controlValue)
              }}
            />
          </InputContainer>
        </FieldContainer>
      )}
    </Field>
  )
}

Language.propTypes = {
  control: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.bool,
    helpText: PropTypes.string,
    index: PropTypes.string,
    props: PropTypes.shape({
      required: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired
}

export default Language
