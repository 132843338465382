/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FormApi } from "final-form"
import { FieldContainer } from "../styles"
import Toggle from "../Toggle"
import RadioInput from "../RadioInput"
import FieldLabel from "../../../FieldLabel"
import Dropdown from "../Dropdown"

const ToggleGroupContainer = styled(FieldContainer)`
  padding-bottom: 0;
  [class^="FieldContainer"] {
    border: none;
    padding: 0;
    margin-bottom: 8px;
    & label {
      & div {
        & svg {
          margin-bottom: 3px;
        }
      }
    }
  }
  & > div:last-child {
    [direction="row-reverse"] {
      & > span {
        font-weight: 400;
        color: #565d66;
        margin: 0 0 0 10px;
        opacity: ${({ disabled }) => (disabled ? 0.7 : 1)};
      }
      &:last-child {
        margin-bottom: 16px;
      }
    }
  }
  & > span:first-child {
    margin-bottom: 16px;
  }
  [class^="RadioButtonstyles__Label"] {
    [class^="RadioButtonstyles__CheckMark"] {
      top: 3px;
    }
  }
`

/**
 * Toggle group component
 *
 * This components have different modes that impact their behavior that you
 * can configure using the `toggleable` property:
 *  - toggleable=self: The control it self becomes a registered toggle
 *    input along with each of it's options, the value of the control
 *    is treated as any other control in the form.
 *  - toggleable=auto: The control it self becomes a toggle to allow or
 *    not allow to change it's sub-options, the value of the control is
 *    calculated based on it's selected options.
 *  - toggleable=false: disables the main toggle and renders only it's options.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ToggleGroup = ({ form, control }) => {
  const [previousValues, setPreviousValues] = useState({})
  const [listener, setListener] = useState(null)
  const { name, toggleable, controls, separator, props: controlProps, hidden } = control

  const changed = (fields, values, pValues) => {
    const changes = Object.keys(values).reduce((ac, cr) => {
      if (fields.indexOf(cr) > -1) {
        if (values[cr] !== pValues[cr]) {
          return { ...ac, [cr]: values[cr] }
        }
      }
      return ac
    }, {})
    return Object.keys(changes).length > 0 ? changes : false
  }

  useEffect(() => {
    if (toggleable === "auto" && form) {
      setListener(
        form.subscribe(
          ({ values }) => {
            const changes = changed(Object.keys(controls), values, previousValues)

            if (changes) {
              const update = Object.keys(controls).reduce(
                (enabled, key) => (!enabled ? values[key] : enabled),
                false
              )

              if (update !== values[name]) {
                form.change(name, update)
              }
            }

            setPreviousValues(values)
          },
          { values: true }
        )
      )
    }
    return () => listener && listener()
  })

  return (
    <ToggleGroupContainer
      direction="column"
      separator={separator}
      disabled={controlProps.disabled}
      hidden={hidden}
    >
      {(toggleable === "self" || toggleable === "auto") && <Toggle control={control} />}
      {!toggleable && <FieldLabel control={control} />}
      <div>
        {Object.keys(controls).map((controlName) => (
          <div>
            {controls[controlName].type === "radio" && (
              <RadioInput
                form={form}
                key={controls[controlName].value}
                isToggleGroups
                control={{
                  ...controls[controlName],
                  props: controlProps
                }}
              />
            )}
            {controls[controlName].type === "toggle" && (
              <Toggle
                form={form}
                key={controls[controlName].value}
                mode="short"
                isToggleGroups
                control={{
                  ...controls[controlName],
                  props: controlProps
                }}
              />
            )}
            {controls[controlName].type === "dropdown" && (
              <Dropdown
                key={controls[controlName].value}
                form={form}
                isToggleGroups
                control={{
                  ...controls[controlName],
                  props: controlProps
                }}
              />
            )}
          </div>
        ))}
      </div>
    </ToggleGroupContainer>
  )
}

ToggleGroup.propTypes = {
  form: PropTypes.instanceOf(FormApi).isRequired,
  control: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    toggleable: PropTypes.oneOf(["self", "auto", false]),
    separator: PropTypes.bool,
    helpText: PropTypes.string,
    controls: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.string,
        helpText: PropTypes.string,
        controls: PropTypes.arrayOf(
          PropTypes.shape({
            value: PropTypes.string.isRequired,
            label: PropTypes.string.isRequired,
            helpText: PropTypes.string
          })
        ),
        props: PropTypes.shape({
          selected: PropTypes.string,
          required: PropTypes.bool,
          disabled: PropTypes.bool
        })
      })
    ),
    props: PropTypes.shape({
      selected: PropTypes.string,
      required: PropTypes.bool,
      disabled: PropTypes.bool
    }),
    hidden: PropTypes.bool
  }).isRequired
}

ToggleGroup.defaultProps = {}

export default ToggleGroup
