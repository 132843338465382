import { all, fork } from "redux-saga/effects"
import { combineReducers } from "redux"

import alertsSaga from "./alertsSaga/saga"
import campaignSaga from "./campaignSaga/saga"
import recurringCampaignSaga from "./recurringCampaignSaga/saga"
import configuratorSaga from "./configuratorSaga/saga"
import templatesSaga from "./templatesSaga/saga"
import organizationsSaga from "./organizationsSaga/saga"
import testSaga from "./testSaga/saga"

import { reducer as alerts } from "./alertsSaga/reducer"
import { reducer as campaigns } from "./campaignSaga/reducer"
import { reducer as recurring_campaigns } from "./recurringCampaignSaga/reducer"
import { reducer as configurator } from "./configuratorSaga/reducer"
import { reducer as templates } from "./templatesSaga/reducer"
import { reducer as organization } from "./organizationsSaga/reducer"
import { reducer as test } from "./testSaga/reducer"

export default function* root() {
  yield all([fork(testSaga)])
  yield all([
    alertsSaga(),
    campaignSaga(),
    recurringCampaignSaga(),
    configuratorSaga(),
    organizationsSaga(),
    templatesSaga()
  ])
}

export const reducers = combineReducers({
  alerts,
  campaigns,
  recurring_campaigns,
  configurator,
  templates,
  organization,
  test
})
