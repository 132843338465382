import { connect } from "react-redux"
import { Typography, Input, Button } from "@elevate_security/elevate-component-library"
import PropTypes from "prop-types"
import React, { Component } from "react"
import styled from "styled-components"

import { sendTestEmail } from "../../../sagas/campaignSaga/reducer"
import theme from "../../../css/theme"

const { colors } = theme
const { Text, Small } = Typography

const ButtonLink = styled.div`
  cursor: pointer;
  font-size: 15px;
  color: ${colors.text.link};
  :hover {
    text-decoration: underline;
    color: ${colors.text.linkHover};
  }
`
const FieldWidth = styled.div`
  flex-grow: 1;
  margin-right: 1rem;
`

export class TestEmail extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: props.visible || false,
      sent: false,
      emails: ""
    }
  }

  open = () => {
    this.setState({
      visible: true
    })
  }

  handleChange = (value) => {
    this.setState({
      emails: value
    })
  }

  sendTestEmail = () => {
    const { language, sendTestEmailFunc, template, templateType, configurationId } = this.props

    const { emails } = this.state
    if (emails.length) {
      sendTestEmailFunc({
        emails,
        language,
        template,
        type: templateType.toUpperCase(),
        template_data_id: configurationId
      })
      this.setState({
        sent: true
      })
      setTimeout(() => {
        this.setState({
          sent: false
        })
      }, 1000)
    }
  }

  render() {
    const { visible, sent, emails } = this.state
    const btnTitle = sent ? "Sent!" : "Send Email Template"
    const label = "Send a sample email template to :"

    return visible ? (
      <>
        <Text color="700" fontWeight="bold">
          {label}
        </Text>
        <div className="d-flex email-form">
          <FieldWidth>
            <Input width="full" value={emails} onChange={this.handleChange} />
          </FieldWidth>
          <Button
            onClick={this.sendTestEmail}
            disabled={sent}
            type="button"
            style={{
              minWidth: "157px",
              borderRadius: "4px",
              backgroundColor: "#E0EFF0",
              color: "#0C7D84",
              fontWeight: "bold"
            }}
          >
            {btnTitle}
          </Button>
        </div>
        <Small color="500" fontWeight="normal">
          If sending to multiple emails, separate by comma
        </Small>
      </>
    ) : (
      <div className="mt-2" style={{ display: "flex" }}>
        <ButtonLink
          className="btn-show"
          onClick={this.open}
          style={{
            marginLeft: "auto",
            textDecoration: "underline",
            color: "#039BA1",
            cursor: "pointer"
          }}
        >
          Send a sample email template
        </ButtonLink>
      </div>
    )
  }
}

const mapDispatchToProps = {
  sendTestEmailFunc: sendTestEmail
}

TestEmail.propTypes = {
  language: PropTypes.string,
  sendTestEmailFunc: PropTypes.func,
  templateType: PropTypes.string,
  template: PropTypes.string,
  configurationId: PropTypes.string,
  visible: PropTypes.bool
}

TestEmail.defaultProps = {
  language: "ENGLISH",
  sendTestEmailFunc: null,
  templateType: null,
  template: null,
  configurationId: null,
  visible: false
}

export default connect(null, mapDispatchToProps)(TestEmail)
