/* eslint-disable no-undef */
import axios from "../../api"

export function listConfiguration() {
  return axios.get(`${REACT_APP_PULSE_API_URL}/configurations`)
}

export function fetchGroups() {
  return axios.get(`${REACT_APP_PULSE_API_URL}/organizations/groups`)
}

export function getConfiguration(id) {
  return axios.get(`${REACT_APP_PULSE_API_URL}/configurations/${id}`)
}

export function getScoreConfiguration() {
  return axios.get(`${REACT_APP_EP_API_URL}/scores/v1/config?limit=1&is_admin=false`)
}

export function createConfiguration(payload) {
  return axios.post(`${REACT_APP_PULSE_API_URL}/configurations`, payload)
}

export function patchConfiguration(id, payload) {
  return axios.patch(`${REACT_APP_PULSE_API_URL}/configurations/${id}`, payload)
}

export function deleteConfiguration(id) {
  return axios.delete(`${REACT_APP_PULSE_API_URL}/configurations/${id}`)
}

export function getTemplatePreview(
  type,
  variation = "default",
  state = "flimsy",
  configurationId = null,
  language = "en_US",
  recipientEmail = null
) {
  let endpoint = [{ configurationId }, { language }].reduce(
    (url, { configurationId: confId, language: lang }) => {
      if (confId) {
        return url.concat(`${url.indexOf("language") > -1 ? "&" : ""}template_data_id=${confId}`)
      }

      if (lang) {
        return url.concat(`${url.indexOf("template_data_id") > -1 ? "&" : ""}language=${lang}`)
      }

      return url
    },
    `${REACT_APP_PULSE_API_URL}/templates/preview/${type}/${variation}/${state}${
      (configurationId || language) && "?"
    }`
  )

  if (recipientEmail && endpoint.indexOf("?") > -1)
    endpoint = endpoint.concat(`&recipient_email=${recipientEmail}`)
  return axios.get(endpoint)
}

export function sendTestEmail(payload) {
  return axios.post(`${REACT_APP_PULSE_API_URL}/campaigns/send-test-email`, payload)
}
