import styled from "styled-components"
import { theme, Typography } from "@elevate_security/elevate-component-library"

const { Body, Small } = Typography

export const DropZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 144px;
  border: 1px ${(props) => (props.active ? "solid" : "dashed")}
    ${(props) => (props.dragging ? theme.colors.primary["500"] : theme.colors.gray.opaque["300"])};
  box-sizing: border-box;
  border-radius: 4px;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};

  & > svg {
    width: 39px;
    path {
      fill: ${theme.colors.gray.opaque["300"]};
    }
  }
`

export const ChooseFileLink = styled.span`
  position: relative;
  display: inline-block;
  margin-left: 8px;
  color: ${theme.text.color.link};

  &:hover {
    color: ${theme.text.color.linkHover};
    text-decoration: underline;
  }

  input[type="file"] {
    opacity: 0;
    font-size: 100px;
    position: absolute;
    left: 0;
    top: 0;
    top: 0;
    bottom: 0;
    z-index: 2;
  }
`

export const FilenameAndSize = styled(Small)`
  margin-top: 11px;
  font-weight: 400;
`

export const UploadText = styled(Body)`
  margin-top: 8px;
  font-weight: 400;
`

export const PreviewContainer = styled.div`
  display: flex:
  flex-direction: row;
  align-items: center;
  justify-content: center;
  
  & > img {
    max-width: 80px;
    max-height: 60px;
  }
`
