import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { Icon } from "@elevate_security/elevate-component-library"
import { normalizeTarget } from "../../../../../../../sagas/configuratorSaga/helpers/utils"
import { MenuLink } from "../../styles"
import MenuItem from "../MenuItem"

const MenuGroup = ({ group, selected, behaviorSelected, onSelect }) => {
  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!open && behaviorSelected) setOpen(true)
  }, [behaviorSelected])

  const handleSelect = (e, targetGroup, ignoreToggle) => {
    e.preventDefault()
    e.stopPropagation()

    if (targetGroup.get("type") !== "section") {
      onSelect(targetGroup)
    }

    if (!ignoreToggle) {
      setOpen(!open)
    }
  }

  const getControlsTypeGroup = (controls) =>
    Array.from(controls.keys()).filter(
      (controlName) => ["group", "section"].indexOf(controls.get(controlName).get("type")) > -1
    )

  const renderControlsTypeGroup = (controls) =>
    getControlsTypeGroup(controls).map((controlName) => (
      <MenuItem selected={selected} group={controls.get(controlName)} onSelect={handleSelect} sub />
    ))

  if (!group.get("controls").size) {
    return null
  }

  return (
    <li>
      <MenuLink
        href={normalizeTarget(group.get("name"))}
        onClick={(e) => handleSelect(e, group)}
        selected={selected === group.get("name")}
      >
        <span>{group.get("label")}</span>
        {!open && group.get("type") === "section" && <Icon name="CaretRight" size={10} />}
        {open && group.get("type") === "section" && <Icon name="CaretDown" size={10} />}
      </MenuLink>
      {open && group.has("controls") && getControlsTypeGroup(group.get("controls")).length > 0 && (
        <ul>{renderControlsTypeGroup(group.get("controls"))}</ul>
      )}
    </li>
  )
}

MenuGroup.propTypes = {
  group: PropTypes.instanceOf(Map).isRequired,
  selected: PropTypes.string,
  behaviorSelected: PropTypes.bool,
  onSelect: PropTypes.func.isRequired
}

MenuGroup.defaultProps = {
  selected: null,
  behaviorSelected: false
}

export default MenuGroup
