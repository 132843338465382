/* eslint-disable import/prefer-default-export */
import styled from "styled-components"
import { theme, Typography } from "@elevate_security/elevate-component-library"

const { Text } = Typography

export const SendEmailWrapper = styled.div`
  display: flex;
  align-items: center;
  > span {
    margin-left: 8px;
    margin-top: 20px;
    > svg {
      path {
        fill: ${theme.icons.color.light};
        stroke: ${theme.icons.color.light};
      }
      &:hover {
        path {
          fill: ${theme.icons.color.linkHover};
          stroke: ${theme.icons.color.linkHover};
        }
      }
    }
  }
`

export const SendEmailButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: #0c7d84;
  padding: 22px 0 0 20px;
  :hover {
    text-decoration: underline;
    color: #0c7d84;
  }
`

export const StateSelectorContainer = styled.div`
  display: flex;
  padding: 16px 0;
  border-bottom: 1px solid ${theme.colors.border.light};
  flex-direction: row;
  justify-content: flex-start;

  & > div {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    & > div {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }

    & > div:not(:first-child) {
      margin-left: 20px;
    }

    & > div:last-child {
      flex: 1;
    }
  }
`

export const Label = styled(Text)`
  display: block;
  margin-bottom: 10px;
  color: #34383c;
  font-weight: bold;
`
