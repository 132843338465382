import { delay, put, select, takeEvery } from "redux-saga/effects"

import * as actions from "./actions"

export function* showAlert(action) {
  const state = yield select()
  const notificationId = state.alerts.nextId - 1
  yield delay(action.isError ? 10000 : 5000)
  yield put({
    type: actions.HIDE_ALERT,
    id: notificationId
  })
}

export default function* alertSaga() {
  yield takeEvery(actions.SHOW_ALERT, showAlert)
}
