import { call, put, takeLatest } from "redux-saga/effects"

import * as actions from "./actions"
import * as api from "./api"
import { showAlert } from "../alertsSaga/reducer"
import { getErrorMessage } from "../../utils/utils"

export function* fetchGroupsList(action) {
  try {
    const response = yield call(api.fetchGroupsList, action.searchValue)
    yield put({ type: actions.GET_GROUPS_LIST_SUCCESS, data: response.data })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_GROUPS_LIST_FAILED, error: e })
  }
}

export function* fetchGroupEmployees(action) {
  try {
    const response = yield call(api.fetchGroupEmployees, action.id, action.campaignType)
    yield put({
      type: actions.GET_GROUP_EMPLOYEES_SUCCESS,
      group_id: action.id,
      data: response.data
    })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_GROUP_EMPLOYEES_FAILED, error: e })
  }
}

export function* fetchSenderEmailsList() {
  try {
    const response = yield call(api.fetchSenderEmailsList)
    yield put({
      type: actions.GET_SENDER_EMAILS_LIST_SUCCESS,
      data: response.data
    })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_GROUPS_LIST_FAILED, error: e })
  }
}

export function* fetchRecipientsListById(action) {
  try {
    const response = yield call(api.fetchRecipientsListById, action.id, action.orgId)
    yield put({
      type: actions.GET_RECIPIENTS_LIST_SUCCESS,
      data: response.data
    })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_RECIPIENTS_LIST_FAILED, error: e })
  }
}

export default function* organizationsSaga() {
  yield takeLatest(actions.GET_GROUPS_LIST, fetchGroupsList)
  yield takeLatest(actions.GET_GROUP_EMPLOYEES, fetchGroupEmployees)
  yield takeLatest(actions.GET_SENDER_EMAILS_LIST, fetchSenderEmailsList)
  yield takeLatest(actions.GET_RECIPIENTS_LIST, fetchRecipientsListById)
}
