/* eslint-disable import/prefer-default-export */

const select = (state) => state.configurator

export const getTemplatesData = (store) => store.templates.templates
export const getConfigurations = (store) => select(store).configurations
export const isConfigurationLoading = (store) => select(store).isConfigurationLoading
export const getGroups = (store) => select(store).groups
export const getLanguages = (store) => select(store).languages
export const getState = (store) => select(store).state
export const getLanguage = (store) => select(store).language
export const getConfigurationId = (store) => select(store).configurationId
export const getTemplateId = (store) => select(store).templateId
export const getPreview = (store) => select(store).preview
export const isLoading = (store) => select(store).loading
export const isCreating = (store) => select(store).isCreating
export const getTestEmailSendState = (store) => select(store).testEmailState
