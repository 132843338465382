import styled from "styled-components"

export const ModalOverLayer = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1000001;
  font-size: 50px;
  text-align: center;
  line-height: 100px;
  background-color: rgba(42, 47, 53, 0.5);
  &::before {
    position: relative;
    display: block;
    content: "";
    background: transparent;
    backdrop-filter: blur(2px);
    backface-visibility: hidden;
    top: 0;
    z-index: 1;
    width: 100vw;
    height: 100vh;
  }
`
export const ModalContent = styled.div`
  text-align: left;
  position: fixed;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 684px;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0 4px 32px 0 rgba(42, 47, 53, 0.2);
  background-color: white;
  padding: ${({ padding }) => padding};
  left: 50%;
  top: 80px;
  transform: translateX(-50%);
  z-index: 1000002;
  line-height: 24px;
`
export const WelcomeModalImage = styled.img`
  display: block;
  width: 100%;
  height: auto;
  border-radius: 8px 8px 0 0;
`
export const WelcomeModalBodyContent = styled.div`
  display: flex;
  padding: 24px 40px;
  flex-direction: column;
  h1 {
    font-size: 24px;
    font-weight: 700;
  }
  p {
    font-size: 16px;
    line-height: 24px;
  }
  button {
    align-self: flex-end;
  }
`
export const CloseButton = styled.div`
  cursor: pointer;
  position: absolute;
  align-self: flex-end;
  background: rgba(42, 47, 53, 0.5);
  border-radius: 4px;
  padding: 0 10px;
  margin: 10px;
  width: 42px;
  > svg {
    height: 42px;
    stroke: white;
  }
`
