/* eslint-disable import/prefer-default-export */
import { Badge } from "@elevate_security/elevate-component-library"
import React from "react"
import mappingsEmployee from "./fieldGroups/employee/mappings.json"

import survey from "./fieldGroups/survey.json"
import footer from "./fieldGroups/footer.json"

import settings from "./fieldGroups/employee/settings.json"
import regional from "./fieldGroups/regional.json"
import header from "./fieldGroups/employee/header.json"
import subject from "./fieldGroups/employee/subject.json"
import postHeader from "./fieldGroups/employee/post-header.json"
import securityScore from "./fieldGroups/employee/security-score.json"
import pre_footer from "./fieldGroups/employee/pre-footer.json"

import cleanDesk from "./fieldGroups/employee/behaviors/clean-desk.json"
import deviceSecurity from "./fieldGroups/employee/behaviors/device-security.json"
import passwordManager from "./fieldGroups/employee/behaviors/password-manager.json"
import passwordStrength from "./fieldGroups/employee/behaviors/password_strength.json"
import malware from "./fieldGroups/employee/behaviors/malware.json"
import training from "./fieldGroups/employee/behaviors/training.json"
import sensitive_data_handling from "./fieldGroups/employee/behaviors/sensitive-data-handling.json"
import passwordSecurity from "./fieldGroups/employee/behaviors/password-security.json"
import physicalSecurity from "./fieldGroups/employee/behaviors/physical-security.json"
import upcoming_optional_activities from "./fieldGroups/employee/behaviors/upcoming-optional-activities.json"
import recap_optional_activities from "./fieldGroups/employee/behaviors/recap-optional-activities.json"
import secure_browsing from "./fieldGroups/employee/behaviors/secure-browsing.json"
import real_world_phishing from "./fieldGroups/employee/behaviors/real-world-phishing.json"
import real_world_phishing_no_data from "./fieldGroups/employee/behaviors/real-world-phishing-no-data.json"
import phishing_and_reporting from "./fieldGroups/employee/behaviors/phishing-and-reporting.json"

// Manager Template json-files
import mappingsManager from "./fieldGroups/manager/mappings.json"
import subject_manager from "./fieldGroups/manager/subject.json"
import header_manager from "./fieldGroups/manager/header.json"
import how_your_team_can_improve from "./fieldGroups/manager/how-your-team-can-improve.json"
import settings_manager from "./fieldGroups/manager/settings.json"
import securityScoreManager from "./fieldGroups/manager/security-score-manager.json"
// Manager Template V2 json-files
import subject_manager_v2 from "./fieldGroups/manager_v2/subject.json"
import header_manager_v2 from "./fieldGroups/manager_v2/header.json"
import msg_to_managers_v2 from "./fieldGroups/manager_v2/msg-to-managers.json"
import settings_manager_v2 from "./fieldGroups/manager_v2/settings.json"
import securityScoreManagerV2 from "./fieldGroups/manager_v2/security-score-manager.json"
import mappingsManagerV2 from "./fieldGroups/manager_v2/mappings.json"

export const LANGUAGES = ["en_US", "es_ES", "fr_FR", "de_DE", "pt_BR", "it_IT", "zh_CN", "ja_JP", "zh_Hant"]

export const EMAIL_SUBJECT_DEFAULTS = {
  en_US:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}Your {{organization_name|safe}} {SECURITY_SNAPSHOT}",
  es_ES:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}Tu {{organization_name|safe }} {SECURITY_SNAPSHOT}",
  fr_FR:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}Votre {SECURITY_SNAPSHOT} chez {{organization_name|safe}}",
  de_DE:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}Ihr {{organization_name|safe}} {SECURITY_SNAPSHOT}",
  pt_BR:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}{SECURITY_SNAPSHOT} da {{organization_name|safe}}",
  it_IT:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}{SECURITY_SNAPSHOT} di {{organization_name|safe}}",
  zh_CN:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}你的 {{organization_name|safe}} {SECURITY_SNAPSHOT}",
  ja_JP:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}あなたの {{organization_name|safe}} {SECURITY_SNAPSHOT}",
  zh_Hant:
    "{% if (employee_name) %}{{ employee_name|safe }}: {% endif %}你的 {{organization_name|safe}} {SECURITY_SNAPSHOT}"
}

export const SECURITY_SNAPSHOT_MATCHER = "{SECURITY_SNAPSHOT}"

export const DEFAULT_TEMPLATE = {
  EMPLOYEES: "employees/default",
  MANAGERS: "managers/default",
  MANAGERSV2: "managersV2/default"
}

export const BADGE = "badge"
export const MAPPING = "mapping"
export const CONTROLS_FILE_NAME = "controlsFile"
export const CONTROLS = "controls"
export const HEADER_GROUPS = "header_groups"
export const FOOTER_GROUPS = "footer_groups"
export const HIDDEN_GROUPS = "hidden_groups"
export const SCORE_GRAPHIC_FILE = "score_graphic_file"

export const DEFAULT_TEMPLATE_CONFIG = {
  [DEFAULT_TEMPLATE.EMPLOYEES]: {
    [BADGE]: <Badge text="Individuals" theme="white" />,
    [MAPPING]: mappingsEmployee,
    [HEADER_GROUPS]: {
      settings,
      regional,
      subject,
      header,
      post_header: postHeader,
      security_score: securityScore
    },
    [CONTROLS]: {
      phishing_and_reporting,
      real_world_phishing,
      real_world_phishing_no_data,
      secure_browsing,
      device_security: deviceSecurity,
      password_strength: passwordStrength,
      training,
      malware,
      clean_desk: cleanDesk,
      sensitive_data_handling,
      password_security: passwordSecurity,
      physical_security: physicalSecurity,
      upcoming_optional_activities,
      recap_optional_activities
    },
    [FOOTER_GROUPS]: {
      how_your_team_can_improve,
      pre_footer,
      survey,
      footer
    },
    [CONTROLS_FILE_NAME]: "settings", // file-name should be added in groups keys,
    [SCORE_GRAPHIC_FILE]: "security_score", // file-name should be added in groups keys
    [HIDDEN_GROUPS]: [
      // hide other templates groups for current type
      "subject_manager",
      "header_manager",
      "security_score_manager",
      "settings_manager",
      "how_your_team_can_improve",
      "settings_manager_v2",
      "subject_manager_v2",
      "header_manager_v2",
      "msg_to_managers_v2",
      "security_score_manager_v2"
    ]
  },
  [DEFAULT_TEMPLATE.MANAGERS]: {
    [BADGE]: <Badge text="Managers" theme="white" />,
    [MAPPING]: mappingsManager,
    [HEADER_GROUPS]: {
      settings_manager,
      subject_manager,
      header_manager,
      security_score_manager: securityScoreManager
    },
    [CONTROLS]: {
      password_manager: passwordManager
    },
    [CONTROLS_FILE_NAME]: "settings_manager", // file-name should be added in groups keys
    [SCORE_GRAPHIC_FILE]: "security_score_manager", // file-name should be added in groups keys
    [HIDDEN_GROUPS]: [
      // hide other template groups for current type
      "settings",
      "subject",
      "header",
      "security_score",
      "post_header",
      "behaviors",
      "pre_footer",
      "how_your_team_can_improve",
      "settings_manager_v2",
      "subject_manager_v2",
      "header_manager_v2",
      "msg_to_managers_v2",
      "security_score_manager_v2"
    ]
  },
  [DEFAULT_TEMPLATE.MANAGERSV2]: {
    [BADGE]: <Badge text="Managers V2" theme="white" />,
    [MAPPING]: mappingsManagerV2,
    [HEADER_GROUPS]: {
      settings_manager_v2,
      msg_to_managers_v2,
      subject_manager_v2,
      header_manager_v2,
      security_score_manager_v2: securityScoreManagerV2
    },
    [CONTROLS]: {
      password_manager: passwordManager // using save file for Employee, Manger and Manger V2
    },
    [CONTROLS_FILE_NAME]: "settings_manager_v2", // file-name should be added in groups keys
    [SCORE_GRAPHIC_FILE]: "security_score_manager_v2", // file-name should be added in groups keys
    [HIDDEN_GROUPS]: [
      // hide other template groups for current type
      "settings",
      "subject",
      "header",
      "security_score",
      "post_header",
      "behaviors",
      "pre_footer",
      "subject_manager",
      "header_manager",
      "security_score_manager",
      "settings_manager",
      "how_your_team_can_improve"
    ]
  }
}

export const CAMPAIGN_TYPES_MAPPING = {
  MANAGERSV2: "MANAGERS"
}

export const ALLOWED_EXTENSIONS_IMAGES_UPLOAD = /\.(jpg|jpeg|png|gif)$/i // Add the allowed extensions here
export const FILE_SIZE_VALIDATION_TEXT = "File exceeds maximum file size of 2MB."

export const DIAL_IMAGE_LINK = {
  // Managers and MangersV2 Templates Score Graphic Examples
  dragon_level_1_header: "dial_level_1_header",
  dragon_level_2_header: "dial_level_2_header",
  dragon_level_3_header: "dial_level_3_header",
  dragon_level_4_header: "dial_level_4_header",
  dragon_level_5_header: "dial_level_5_header",
  // Individual, Mangers and MangersV2 Templates Score Graphic Type
  score_graphics_type: "dial_graphic_type",
  // Individual Template Score Graphic Examples
  dragon_level_1_text: "dial_level_1_text",
  dragon_level_2_text: "dial_level_2_text",
  dragon_level_3_text: "dial_level_3_text",
  dragon_level_4_text: "dial_level_4_text",
  dragon_level_5_text: "dial_level_5_text"
}
