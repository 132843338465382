import React, { useState } from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  CustomSelect,
  Icon,
  Tooltip,
  RadioButton
} from "@elevate_security/elevate-component-library"
import TestEmailModal from "./components/TestEmailModal"
import UserTemplateSelector from "./components/UserTemplateSelector"
import { toMap } from "../../sagas/configuratorSaga/helpers/utils"
import { Label, StateSelectorContainer, SendEmailButton, SendEmailWrapper } from "./style"

const ConfiguratorStateSelector = ({
  templateId,
  configurationId,
  onStateChange,
  onLanguageChange,
  languages,
  state,
  language,
  currentConfigs,
  allTemplates
}) => {
  const currentTemplateConfigs =
    currentConfigs || allTemplates.find((template) => configurationId === template.id)

  const previewOptions = [
    { value: "by-score", label: "Behavior Score" },
    { value: "by-user", label: "User Email" }
  ]
  const states = [
    { value: "no-data", label: "No Data" },
    { value: "flimsy", label: currentTemplateConfigs.dragon_level_1_label_en_US || "Flimsy" },
    { value: "tenuous", label: currentTemplateConfigs.dragon_level_2_label_en_US || "Tenuous" },
    { value: "sturdy", label: currentTemplateConfigs.dragon_level_3_label_en_US || "Sturdy" },
    { value: "fortified", label: currentTemplateConfigs.dragon_level_4_label_en_US || "Fortified" },
    {
      value: "indestructible",
      label: currentTemplateConfigs.dragon_level_5_label_en_US || "Indestructible"
    }
  ]
  const [testEmailFormOpen, setTestEmailFormOpen] = useState(false)
  const [previewSelectedOption, setPreviewSelectedOption] = useState(previewOptions[0].value)
  return (
    <>
      <StateSelectorContainer>
        <div>
          <div>
            <Label>Preview by</Label>
            <RadioButton
              options={previewOptions}
              name="defaultRadio"
              radioSelected={previewSelectedOption}
              onChange={(e) => setPreviewSelectedOption(e)}
              controlled={false}
            />
          </div>
        </div>
      </StateSelectorContainer>
      {previewSelectedOption === previewOptions[0].value ? (
        <StateSelectorContainer>
          <div>
            <div>
              <Label>Preview by behavior score</Label>
              <CustomSelect
                placeholder="Select a state"
                options={states}
                defaultValue={state}
                onChange={(e) => onStateChange(states.find((st) => st.value === e.value))}
              />
            </div>
            <div>
              <Label>Preview by language</Label>
              <CustomSelect
                placeholder="Select a language"
                options={languages || []}
                defaultValue={language}
                onChange={(e) => onLanguageChange(languages.find((lang) => lang.value === e.value))}
              />
            </div>
            <div>
              {!testEmailFormOpen && (
                <SendEmailWrapper>
                  <SendEmailButton onClick={() => setTestEmailFormOpen(true)}>
                    Send a sample email template{" "}
                  </SendEmailButton>
                  <Tooltip
                    body="Test emails will come from yourfriends@elevatesecurity.com. Be sure to check your spam folder."
                    placement="right"
                    theme="light-border"
                    size="xsm"
                  >
                    <Icon name="CircleExclamation" size="xsm" />
                  </Tooltip>
                </SendEmailWrapper>
              )}
              {testEmailFormOpen && (
                <TestEmailModal
                  templateId={templateId}
                  configurationId={configurationId}
                  group={toMap({
                    controls: {
                      email: {
                        type: "text"
                      }
                    }
                  })}
                  onSent={() => setTestEmailFormOpen(false)}
                />
              )}
            </div>
          </div>
        </StateSelectorContainer>
      ) : (
        <StateSelectorContainer>
          <div>
            <div>
              <UserTemplateSelector templateId={templateId} configurationId={configurationId} />
            </div>
          </div>
        </StateSelectorContainer>
      )}
    </>
  )
}

const mapStateToProps = (state) => ({
  currentConfigs: state.configurator.current,
  allTemplates: state.configurator.configurations
})

ConfiguratorStateSelector.propTypes = {
  configurationId: PropTypes.string.isRequired,
  templateId: PropTypes.string.isRequired,
  onStateChange: PropTypes.func.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  state: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  language: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  }),
  languages: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string
    })
  ),
  currentConfigs: PropTypes.objectOf(PropTypes.object),
  allTemplates: PropTypes.arrayOf(PropTypes.object)
}

ConfiguratorStateSelector.defaultProps = {
  state: "flimsy",
  language: "en_US",
  languages: [],
  currentConfigs: {},
  allTemplates: {}
}

export default connect(mapStateToProps)(ConfiguratorStateSelector)
