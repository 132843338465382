import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"
import FilenameSize from "../FilenameSize"

const ReadOnlyContainer = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid ${theme.colors.border.light};
  border-radius: 4px;
  padding: 16px;

  & > div {
    &:first-child {
      flex: 0 0 auto;
      width: auto;
      height: 34px;
      padding-right: 16px;

      & > img {
        width: auto;
        height: 34px;
        height: 100%;
      }
    }
    &:last-child {
      flex: 1;
      font-size: 13px;
      line-height: 16px;
      p {
        margin-bottom: 0;
      }
    }
  }
`

const DropzoneReadOnly = ({ file }) => (
  <ReadOnlyContainer>
    <div>
      <img src={file} alt="Placeholder" />
    </div>
    <div>
      <FilenameSize file={file} breakLine />
    </div>
  </ReadOnlyContainer>
)

DropzoneReadOnly.propTypes = {
  // eslint-disable-next-line no-undef
  file: PropTypes.string.isRequired
}

export default DropzoneReadOnly
