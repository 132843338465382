export const ACTION_TYPE = {
  EDIT: "Edit",
  COPY: "Copy",
  DELETE: "Delete",
  PREVIEW: "Preview"
}

export const ACTION_PROPS = {
  [ACTION_TYPE.EDIT]: {
    icon: "Pencil",
    tooltip: "Edit entry"
  },
  [ACTION_TYPE.COPY]: {
    icon: "Copy",
    tooltip: "Copy entry"
  },
  [ACTION_TYPE.DELETE]: {
    icon: "Trash",
    tooltip: "Remove entry"
  },
  [ACTION_TYPE.PREVIEW]: {
    icon: "Eye",
    tooltip: "Preview entry"
  }
}

export const ACTION_ALLOWED = [
  ACTION_TYPE.PREVIEW,
  ACTION_TYPE.EDIT,
  ACTION_TYPE.COPY,
  ACTION_TYPE.DELETE
]
