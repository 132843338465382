import React from "react"
import PropTypes from "prop-types"
import ConfigurationMenu from "../Menu"
import { ConfiguratorSidebar } from "./styles"
import { ConfiguratorSidebarSkeletonLoader } from "./ConfiguratorSidebarSkeletonLoader"

const ConfigurationSidebar = ({ formRef, form, previewRef, loading }) => {
  if (loading) {
    return <ConfiguratorSidebarSkeletonLoader />
  }

  return (
    <ConfiguratorSidebar>
      <ConfigurationMenu formRef={formRef} form={form} previewRef={previewRef} />
    </ConfiguratorSidebar>
  )
}

ConfigurationSidebar.propTypes = {
  formRef: PropTypes.shape({ current: PropTypes.element }).isRequired,
  form: PropTypes.instanceOf(Map).isRequired,
  previewRef: PropTypes.element,
  loading: PropTypes.bool
}

ConfigurationSidebar.defaultProps = {
  previewRef: null
}

export default ConfigurationSidebar
