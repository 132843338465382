import cloneDeep from "lodash/cloneDeep"
import config from "./config"
import { findByMappingKey, getTemplateKeyData } from "./helpers/utils"
import runHydrators from "./hydrators"
import transform from "./transformers/transform"

import { CONTROLS, CONTROLS_FILE_NAME, DEFAULT_TEMPLATE_CONFIG, MAPPING } from "./constants"

/**
 * Form Manager
 *
 * This class creates a Map version of the a form
 * group Json structure.
 *
 * It also provides helper functions to access
 * this fields when needed.
 *
 * This class is used to generate the form structured and
 * keep track of the current actual values of the form, done
 * through the hydration functions when data is loaded.
 *
 * This class is meant to be simple, it should only be
 * responsible for finding groups and controls within the
 * form so users can interact with it, all other logic is
 * broken down into the following:
 *
 * # Hydration
 * This form uses a series of hydrators that take care of
 * properly assigning values to specific groups or even
 * specific controls.
 *
 * # Transformation
 * Things like adding fields or removing fields from the
 * form, changing properties, anything that changes the
 * structure of the form or any group or field in it should
 * be done via transformer functions.
 *
 * # Normalization
 * Normalization must occur right before the form data is
 * sent to the server in order to make sure the payload has
 * the right format. This gives flexibility when creating
 * complex custom fields since you can create them in any way
 * you want and normalize it's value in this layer.
 *
 * So before adding any logic to forms make sure you take this
 * into account.
 *
 * Modify this with caution as many places depend
 * on this class.
 */
export default class FormManager {
  controls

  /**
   * @param {Object} values The flat configuration object
   */
  constructor(values) {
    const controls = config()

    if (values) {
      const tempMapping = getTemplateKeyData(MAPPING)

      Object.keys({ ...tempMapping }).forEach((mappingKey) => {
        const group = findByMappingKey(mappingKey, controls)

        if (group) {
          runHydrators(mappingKey, group, values)
        }
      })
    }

    const allControls = this.getTemplateControls(controls)

    this.controls = transform(controls, new Map([...allControls]))
  }

  /**
   * Returns a clone of the controls configuration.
   *
   * @returns {Map<string, any>}
   */
  groups() {
    return new Map(cloneDeep(this.controls).entries())
  }

  getTemplateControls(controls) {
    return Object.keys(DEFAULT_TEMPLATE_CONFIG).reduce((previousValue, temp_id) => {
      const controlsFileName = DEFAULT_TEMPLATE_CONFIG[temp_id][CONTROLS_FILE_NAME]
      const filterControls = controls?.get(controlsFileName)?.get(CONTROLS) || []
      return [...previousValue, ...filterControls]
    }, [])
  }

  /**
   * Returns a array representation of the registered
   * form groups.
   *
   * @returns {Map<string, any>}
   */
  groupsArray(type = null) {
    return Array.from(this.groups().values()).filter((g) => (type ? g.get("type") === type : true))
  }

  /**
   * Find a form group recursively.
   *
   * @param groupName
   * @returns {Map}
   */
  get(groupName) {
    return findByMappingKey(groupName, this.controls)
  }
}
