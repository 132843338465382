import { useSelector, useDispatch } from "react-redux"
import { useEffect, useState } from "react"
import { DragosaurWithUmbrella } from "@elevate_security/elevate-component-library"
import Paginator from "../../../components/Paginator"
import { SpaceBetween } from "../../../components/SpaceBetween"
import {
  selectIsCampaignsLoading,
  selectCampaignsPage,
  selectSearchCampaign
} from "../../../sagas/recurringCampaignSaga/selectors"
import { getCampaigns } from "../../../sagas/recurringCampaignSaga/reducer"
import { RecurringCampaignRow } from "./RecurringCampaignRow"
import { EmptyListMessage } from "../styles"
import { SkeletonLoader } from "../../../components/skeleton"

const PAGE_SIZE = 25
const FETCH_CAMPAIGNS_INTERVAL = 20000

export const CampaignList = ({ base_path }) => {
  const campaignsPage = useSelector(selectCampaignsPage)
  const search = useSelector(selectSearchCampaign)
  const [interval, setMyInterval] = useState(false)
  const campaigns = campaignsPage?.results
  const dispatch = useDispatch()
  const isLoading = useSelector(selectIsCampaignsLoading)

  const refreshCampaigns = () => {
    // We need to refresh campaigns to check if we have running campaigns and show the progress bar
    if (isLoading) {
      return
    }
    dispatch(getCampaigns(campaignsPage.page_number, PAGE_SIZE, search, false))
  }

  useEffect(() => {
    dispatch(getCampaigns(1, PAGE_SIZE, search))
    return () => clearInterval(interval)
  }, [search])

  useEffect(() => {
    clearInterval(interval)
    const intervalFunc = setInterval(refreshCampaigns, FETCH_CAMPAIGNS_INTERVAL)
    setMyInterval(intervalFunc)
    // Clearing the interval
    return () => clearInterval(intervalFunc)
  }, [campaignsPage])

  const handlePaginator = (page) => {
    dispatch(getCampaigns(page, PAGE_SIZE, search))
  }

  const renderCampaigns = (campaignsParam) =>
    campaignsParam.map((campaign) => (
      <RecurringCampaignRow key={campaign.id} base_path={base_path} campaign={campaign} />
    ))

  const renderData = () => (
    <div>
      <SpaceBetween size="xs">{renderCampaigns(campaigns)}</SpaceBetween>
      <Paginator
        currentPage={campaignsPage.page_number - 1}
        pages={campaignsPage.pages}
        onNavigate={(page) => handlePaginator(page + 1)}
      />
    </div>
  )

  const renderLoading = () => (
    <div>
      <SkeletonLoader height={20} marginTop={20} />
      <SkeletonLoader height={100} marginTop={10} />
    </div>
  )

  if (isLoading) {
    return renderLoading()
  }

  if (campaigns?.length > 0) {
    return renderData()
  }

  return (
    <EmptyListMessage>
      <DragosaurWithUmbrella message="No data available" showTooltip={false} />
    </EmptyListMessage>
  )
}
