import { call, put, takeLatest, select } from "redux-saga/effects"
import { getAvailableTemplateTypes, getErrorMessage } from "../../utils/utils"
import { showAlert } from "../alertsSaga/reducer"
import { getState, getLanguage, getConfigurationId, getTemplateId } from "./selectors"
import * as actions from "./actions"
import * as api from "./api"
import { toFormData } from "./helpers/utils"

export function* listConfigurations(action) {
  try {
    const response = yield call(api.listConfiguration, action.payload)

    // getting available template-type
    const template_type = getAvailableTemplateTypes()

    const configurationList =
      response?.data?.filter((item) => {
        const template_id = item?.template_id?.replace("/default", "") || ""
        return template_type.includes(template_id)
      }) || []

    yield put({
      type: actions.LIST_CONFIGURATIONS_SUCCESS,
      data: configurationList
    })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.LIST_CONFIGURATIONS_FAILED, error: e })
  }
}

export function* fetchGroups() {
  try {
    const response = yield call(api.fetchGroups)
    yield put({
      type: actions.GET_GROUPS_SUCCESS,
      data: response.data ? response.data.results : []
    })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_GROUPS_FAILED, error: e })
  }
}

export function* getConfiguration(action) {
  try {
    const response = yield call(api.getConfiguration, action.payload)
    yield put({ type: actions.GET_CONFIGURATION_SUCCESS, data: response.data })

    if (typeof action.callback === "function") {
      action.callback(response.data)
    }
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_CONFIGURATION_FAILED, error: e })

    if (typeof action.callback === "function") {
      action.callback(null)
    }
  }
}

export function* getScoreConfiguration(action) {
  try {
    const response = yield call(api.getScoreConfiguration, action.payload)
    yield put({
      type: actions.GET_SCORE_CONFIGURATION_SUCCESS,
      data: response.data["include_behaviors"]
    })
  } catch (e) {
    if (e.response.status === 404) {
      yield put({
        type: actions.GET_SCORE_CONFIGURATION_SUCCESS,
        data: []
      })
    } else {
      yield put(showAlert(getErrorMessage(e), true))
      yield put({ type: actions.GET_SCORE_CONFIGURATION_FAILED, error: e })
    }
  }
}

export function* createConfiguration(action) {
  try {
    const response = yield call(api.createConfiguration, toFormData(action.payload))
    yield put({
      type: actions.CREATE_CONFIGURATION_SUCCESS,
      data: response.data
    })
    yield put(showAlert("<b>Success</b>. New template created!"))
    yield put({ type: actions.LIST_CONFIGURATIONS })

    if (typeof action.callback === "function") {
      action.callback(response.data)
    }
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.CREATE_CONFIGURATION_FAILED, error: e })
  }
}

export function* copyConfiguration(action) {
  try {
    const response = yield call(api.createConfiguration, toFormData(action.payload))
    yield put({ type: actions.COPY_CONFIGURATION_SUCCESS, data: response.data })
    yield put({ type: actions.LIST_CONFIGURATIONS })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.COPY_CONFIGURATION_FAILED, error: e })
  }
}

export function* patchConfiguration(action) {
  try {
    const response = yield call(
      api.patchConfiguration,
      action.payload.id,
      toFormData(action.payload.data)
    )
    yield put({
      type: actions.PATCH_CONFIGURATION_SUCCESS,
      name: action.payload.name,
      data: response.data
    })
    yield put({ type: actions.LIST_CONFIGURATIONS })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.PATCH_CONFIGURATION_FAILED, error: e })
  }
}

export function* deleteConfiguration(action) {
  try {
    yield call(api.deleteConfiguration, action.payload)
    yield put({ type: actions.DELETE_CONFIGURATION_SUCCESS })
    yield put(showAlert("<b>Success</b>. Template deleted!"))
    yield put({ type: actions.LIST_CONFIGURATIONS })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.DELETE_CONFIGURATION_FAILED, error: e })
  }
}

export function* getTemplatePreview(action) {
  try {
    const { type, variation, state, configurationId, language, recipientEmail } = action.payload
    const response = yield call(
      api.getTemplatePreview,
      type,
      variation,
      state,
      configurationId,
      language,
      recipientEmail
    )
    yield put({
      type: actions.GET_TEMPLATE_PREVIEW_SUCCESS,
      data: response.data
    })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.GET_TEMPLATE_PREVIEW_FAILED, error: e })
  }
}

export function* reloadTemplate() {
  const state = yield select(getState)
  const language = yield select(getLanguage)
  const configurationId = yield select(getConfigurationId)
  const templateId = yield select(getTemplateId)

  const [type, variation] = (templateId || "employees/default").split("/")

  yield put({
    type: actions.GET_TEMPLATE_PREVIEW,
    payload: {
      type,
      variation,
      state: state.value,
      language: language.value,
      configurationId
    }
  })
}

export function* sendTestEmail(action) {
  try {
    yield call(api.sendTestEmail, action.payload)
    yield put({ type: actions.SEND_EMAIL_SUCCESS })
  } catch (e) {
    yield put(showAlert(getErrorMessage(e), true))
    yield put({ type: actions.SEND_EMAIL_FAILED, error: e })
  }
}

export default function* configuratorSaga() {
  yield takeLatest(actions.LIST_CONFIGURATIONS, listConfigurations)
  yield takeLatest(actions.GET_GROUPS, fetchGroups)
  yield takeLatest(actions.GET_CONFIGURATION, getConfiguration)
  yield takeLatest(actions.GET_SCORE_CONFIGURATION, getScoreConfiguration)
  yield takeLatest(actions.CREATE_CONFIGURATION, createConfiguration)
  yield takeLatest(actions.COPY_CONFIGURATION, copyConfiguration)
  yield takeLatest(actions.PATCH_CONFIGURATION, patchConfiguration)
  yield takeLatest(actions.DELETE_CONFIGURATION, deleteConfiguration)
  yield takeLatest(actions.GET_TEMPLATE_PREVIEW, getTemplatePreview)
  yield takeLatest(actions.SET_PREVIEW_STATE, reloadTemplate)
  yield takeLatest(actions.SET_PREVIEW_LANGUAGE, reloadTemplate)
  yield takeLatest(actions.SEND_EMAIL, sendTestEmail)
}
