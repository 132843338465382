import { put, takeLatest } from "redux-saga/effects"

import * as actions from "./actions"

const defaultState = { message: "has not run" }

function* init() {
  yield put({
    type: actions.SET_STATE,
    payload: {
      ...defaultState,
      message: "has been run"
    }
  })
}

export default function* saga() {
  yield takeLatest(actions.INIT, init)
}
