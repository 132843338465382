import React, { useRef } from "react"
import PropTypes from "prop-types"
import { AsyncTypeahead } from "react-bootstrap-typeahead"
import { TypeAheadContainer } from "./styles"

export const StyledAsyncTypeAhead = ({
  options,
  selected,
  placeholder,
  maxResults,
  onSearch,
  onChange,
  labelKey,
  multiple,
  disabled,
  ItemComponent,
  labels,
  inputProps,
  delay,
  props
}) => {
  const input = useRef()

  return (
    <TypeAheadContainer>
      <AsyncTypeahead
        inputProps={inputProps}
        delay={delay}
        ref={input}
        multiple={multiple}
        selectHintOnEnter
        placeholder={placeholder}
        labelKey={labelKey}
        defaultSelected={selected}
        options={options}
        maxResults={maxResults}
        onSearch={onSearch}
        onChange={onChange}
        disabled={disabled}
        {...props}
        renderMenu={(results, menuProps) => (
          <ItemComponent results={results} menuProps={menuProps} labels={labels} />
        )}
        filterBy={() => true}
      />
    </TypeAheadContainer>
  )
}

StyledAsyncTypeAhead.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({})),
  selected: PropTypes.arrayOf(PropTypes.shape({})),
  onSearch: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  maxResults: PropTypes.number,
  placeholder: PropTypes.string,
  labelKey: PropTypes.string.isRequired,
  ItemComponent: PropTypes.element.isRequired,
  labels: PropTypes.arrayOf(PropTypes.string).isRequired,
  inputProps: PropTypes.object,
  delay: PropTypes.number,
  props: PropTypes.object
}

StyledAsyncTypeAhead.defaultProps = {
  multiple: false,
  disabled: false,
  options: [],
  selected: [],
  maxResults: 5,
  placeholder: "Search employees",
  inputProps: {},
  delay: 500,
  props: {}
}
