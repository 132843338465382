/* eslint-disable radix */
import moment from "moment-timezone"
import React from "react"
import styled from "styled-components"

import {
  convertTo12Hours,
  ordinal_suffix_of,
  OCCURRENCE,
  humanizeCadenceMap,
  isEmpty,
  formatTimezone
} from "./utils"

const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #34383c;
  padding-top: 16px;
  margin-bottom: 4px !important;
`

export const BoldText = styled.span`
  font-size: 16px;
  line-height: 24px;
  color: #34383c;
  font-weight: 700;
`

export const BigText = styled.span`
  color: #1d1e1f;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
`

export const ScheduleRender = ({ schedule, mode }) => {
  const {
    cadence,
    day_of_week,
    month,
    day,
    hour,
    minute,
    start_date,
    end_date,
    interval
    // timezone
  } = schedule

  const render_mode = mode || "normal"
  const convertedTime = convertTo12Hours(parseInt(schedule?.hour))
  let daysStr
  let minuteStr = `${minute}`

  const dateFormat = "MMMM Do YYYY"
  // Timezone can be shown if and when required
  // const formattedTimezone = formatTimezone(moment.tz(timezone).format("z"), timezone)
  const formatted_start_date = `${moment(start_date).format(dateFormat)}`
  const formatted_end_date = `${moment(end_date).format(dateFormat)}`

  if (day) {
    const sortedDays = day
      ?.split(",")
      ?.map((dayStr) => parseInt(dayStr))
      .sort((a, b) => a - b)
      ?.map((item) => ordinal_suffix_of(item))
    daysStr = sortedDays.join(", ")
  }

  if (minute < 10 && minute > 0) {
    minuteStr = `0${minute}`
  } else if (minute <= 0) {
    minuteStr = "00"
  }

  const generateCustomIntervalsOutput = () => {
    const hoursList = hour?.split(",")
    const times = hoursList.map((hourParam, index) => {
      const cvtTime = convertTo12Hours(parseInt(hourParam))
      let separator = ", "
      if (index === 0) {
        separator = ""
      } else if (index + 1 === hoursList.length) {
        separator = " and "
      }
      return `${separator}${cvtTime.hour}:${minuteStr} ${cvtTime?.timePeriod}`
    })
    const timesStr = times.join("")
    return `${timesStr} ${formatTimezone(
      moment.tz(schedule.timezone).format("z"),
      schedule.timezone
    )} `
  }

  const renderNormal = () => (
    <div className="schedule-render-container">
      <div>
        {cadence !== OCCURRENCE.INTERVAL && (
          <span className="schedule-cadence">{humanizeCadenceMap[cadence]} </span>
        )}
        {isEmpty(interval) && cadence === OCCURRENCE.INTERVAL && (
          <span className="schedule-interval-custom">
            {generateCustomIntervalsOutput()}
            daily
          </span>
        )}
        {interval && <span className="schedule-interval-hours">Every {interval} hours daily</span>}
        {month && <span className="schedule-month">in {month.replaceAll(",", ", ")}</span>}
        {day_of_week && (
          <span className="schedule-day-week">on {day_of_week.replaceAll(",", ", ")}</span>
        )}
        {daysStr && <span className="schedule-day"> on the {daysStr}</span>}
        {cadence !== OCCURRENCE.ASAP &&
          cadence !== OCCURRENCE.INTERVAL &&
          !isEmpty(convertedTime.hour) &&
          !isEmpty(minuteStr) && (
            <span className="schedule-interval-custom"> at {generateCustomIntervalsOutput()}</span>
          )}
      </div>

      <ul className="schedule-range-container">
        {start_date && <li className="schedule-start-date">Start date: {formatted_start_date}</li>}
        {end_date && <li className="schedule-end-date">End date: {formatted_end_date}</li>}
      </ul>
    </div>
  )

  const renderBigMode = () => (
    <div>
      <div>
        {cadence !== OCCURRENCE.INTERVAL && <BigText>{humanizeCadenceMap[cadence]} </BigText>}
        {isEmpty(interval) && cadence === OCCURRENCE.INTERVAL && (
          <Text>
            <BoldText>{generateCustomIntervalsOutput()}</BoldText>
            daily
          </Text>
        )}
        {interval && (
          <Text>
            <BoldText>Every {interval} hours </BoldText>daily
          </Text>
        )}
        {month && (
          <Text>
            in <BoldText>{month.replaceAll(",", ", ")} </BoldText>
          </Text>
        )}
        {day_of_week && (
          <Text>
            on <BoldText>{day_of_week.replaceAll(",", ", ")} </BoldText>
          </Text>
        )}
        {daysStr && (
          <Text>
            on the <BoldText>{daysStr} </BoldText>
          </Text>
        )}
        {cadence !== OCCURRENCE.ASAP &&
          cadence !== OCCURRENCE.INTERVAL &&
          !isEmpty(convertedTime.hour) &&
          !isEmpty(minuteStr) && (
            <Text>
              at <BoldText>{generateCustomIntervalsOutput()}</BoldText>
            </Text>
          )}
      </div>

      {start_date && (
        <p className="schedule-start-date">
          <BigText>Start date: </BigText>
          {formatted_start_date}
        </p>
      )}
      {end_date && (
        <p className="schedule-end-date">
          <BigText>End date: </BigText>
          {formatted_end_date}
        </p>
      )}
    </div>
  )

  if (render_mode === "big") {
    return renderBigMode()
  }

  return renderNormal()
}
