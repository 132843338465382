import styled from "styled-components"

export const ScoreTooltipWrapper = styled.div`
  display: block;
  width: 220px;
  height: 80px;
  padding: 24px 10px;
`
export const Row = styled.div`
  display: flex;
  align-items: center;
`
export const Column = styled.div`
  flex-direction: column;
  display: flex;
  padding: ${({ padding }) => (!padding ? "0" : padding)};
  margin: ${({ margin }) => (!margin ? "0" : margin)};
  width: ${({ width }) => (!width ? "initial" : width)};
  align-items: ${({ alignItems }) => (!alignItems ? "initial" : alignItems)};
  border: "1px solid #DDE1E7";
`
export const ScoreNumber = styled.strong`
  font-weight: 800;
  font-size: 32px;
  line-height: 1;
  color: #34383c;
`
export const ScoreLabel = styled.span`
  font-weight: bold;
  font-size: 13px;
  line-height: 16px;
  color: #34383c;
  text-transform: capitalize;
`
export const ScoreMonth = styled.span`
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  color: #34383c;
`
