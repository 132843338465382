import React, { Component } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import moment from "moment-timezone"
import { SchedulePicker } from "@elevate_security/elevate-component-library"
import { campaignType } from "../../../types"
import theme from "../../../css/theme"
import { getDefaultTimezone } from "../../../sagas/companySaga/selectors"
import Form from "../../../components/Form"

const AlertInfo = styled.div.attrs({
  className: "alert"
})`
  && {
    background-color: ${theme.colors.tag.secondary};
    border-radius: 4px;
    font-size: 14px;
    text-align: center;
    z-index: 0;
  }
`

class LaunchDate extends Component {
  constructor(props) {
    super(props)
    const { campaign } = props
    const default_timezone = getDefaultTimezone()
    const initialSchedule = {
      cadence: "run_now",
      timezone: default_timezone
    }
    const schedule = campaign && campaign.schedule ? campaign.schedule : initialSchedule
    this.state = {
      schedule
    }
  }

  handleSubmit = (e) => {
    e.preventDefault()

    const { schedule, isValid } = this.state
    const { handleSubmit } = this.props

    if (!isValid) {
      return
    }

    handleSubmit({
      schedule
    })
  }

  handleOnChange = (schedule) => {
    this.setState({ schedule })
  }

  handleIsValid = (isValidSchedule) => {
    const { schedule } = this.state

    if (!isValidSchedule) {
      this.setState({ validationMessage: "Fill with a valid schedule.", isValid: false })
      return
    }

    if (schedule?.single_run) {
      const dateTimeData = {
        year: schedule.year,
        month: schedule.month,
        date: schedule.date,
        hour: schedule.hour,
        minutes: schedule.minute
      }
      const momentDate = moment.tz(dateTimeData, dateTimeData.timezone)
      if (momentDate.isSameOrAfter(moment())) {
        this.setState({
          validationMessage: "Execution time should be in the future.",
          isValid: false
        })
        return
      }
    }

    this.setState({ validationMessage: "", isValid: true })
  }

  render() {
    const { validationMessage, schedule } = this.state
    return (
      <div>
        <Form handleSubmit={this.handleSubmit} />
        <SchedulePicker
          options={["run_now", "day", "week", "month", "year"]}
          scheduler={schedule}
          onChange={this.handleOnChange}
          isValid={this.handleIsValid}
        />
        {validationMessage && <AlertInfo>{validationMessage}</AlertInfo>}
      </div>
    )
  }
}

LaunchDate.propTypes = {
  campaign: campaignType,
  handleSubmit: PropTypes.func
}

LaunchDate.defaultProps = {
  campaign: null,
  handleSubmit: null
}

export default LaunchDate
