import React from "react"
import PropTypes from "prop-types"
import Icon from "@elevate_security/elevate-component-library/dist/Icon"
import Tooltip from "@elevate_security/elevate-component-library/dist/Tooltip"
import { Image, Legend, InfoIcon, LegendWrapper, Container } from "./styles"

const DistributionCurve = ({ distributionCurve }) => {
  if (!distributionCurve) return null

  const { average, imageUrl, legend, tooltipBody } = distributionCurve

  if (!average && average !== 0) return null

  return (
    <Container>
      <Image src={imageUrl} alt={average} />
      <LegendWrapper>
        <Legend>{legend}</Legend>
        <InfoIcon>
          <Tooltip body={tooltipBody} placement="top" readOnly size="xsm">
            <Icon name="CircleExclamation" fill="#959DA8" />
          </Tooltip>
        </InfoIcon>
      </LegendWrapper>
    </Container>
  )
}

DistributionCurve.propTypes = {
  distributionCurve: PropTypes.shape({
    average: PropTypes.number,
    imageUrl: PropTypes.string,
    legend: PropTypes.string,
    tooltipBody: PropTypes.element
  })
}

DistributionCurve.defaultProps = {
  distributionCurve: null
}

export default DistributionCurve
