/* eslint-disable import/prefer-default-export */
import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const Row = styled.div`
  display: flex;
  width: 100%;
`
export const Column = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  width: ${({ width }) => (!width ? "auto" : width)};
`

export const AxisLabel = styled.strong`
  display: block;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  white-space: nowrap;
  margin: ${({ margin }) => (!margin ? "initial" : margin)};
  padding: ${({ padding }) => (!padding ? "initial" : padding)};
  transform: ${({ transform }) => (!transform ? "initial" : transform)};
  writing-mode: ${({ writingMode }) => (!writingMode ? "initial" : writingMode)};
  text-orientation: ${({ textOrientation }) => (!textOrientation ? "initial" : textOrientation)};
`

export const NoDataMessage = styled.div`
  min-height: 280px;
  background-color: ${theme.colors.gray.opaque["100"]};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`
