/* eslint-disable import/prefer-default-export */
import styled from "styled-components"

export const HeaderRow = styled.div`
  display: flex;
  margin-bottom: 40px;
  align-items: center;
`

export const CampaignTitle = styled.h1`
  font-weight: 800;
  font-size: 32px;
  line-height: 40px;
  color: #2a2f35;
  margin-bottom: 0;
  margin-top: 16px;
`

export const StatusBadge = styled.span`
  background: #e3f6f2;
  border-radius: 100px;
  padding: 4px 12px;
  font-size: 13px;
  line-height: 16px;
  color: #565d66;
  text-transform: capitalize;
  margin: 19px 0px 0px 16px;
`

export const SectionTitle = styled.h2`
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #34383c;
  margin-bottom: 24px;
`

export const VerticalCardsContainer = styled.div`
  display: flex;
`

export const VerticalCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: #fdfbf8;
  border-radius: 4px;
  padding: 24px;
  height: 100%;
  width: 25%;
  min-height: 308px;
  min-width: 256px;
  margin-left: 12px;
  margin-right: 12px;
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
`

export const Title = styled.div`
  font-weight: bold;
  font-size: 44px;
  line-height: 56px;
  color: #34383c;
`

export const SubTitle = styled.div`
  font-weight: bold;
  font-size: 12px;
  line-height: 20px;
  color: #7b828a;
  text-transform: uppercase;
  margin-top: 8px;
  span > span {
    margin-left: 4px;
  }
`

export const Description = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #565d66;
  margin-top: 24px;
`

export const DetailsCard = styled.div`
  background: #fdfbf8;
  border-radius: 4px;
  padding: 40px 24px;
  min-height: 208px;
  margin-top: 24px;
  display: flex;
  position: relative;
`

export const DetailsCardTitle = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: #34383C;
  width: 208px;
  min-width: 208px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 72px;
  margin-top: 52px;
}
`

export const FieldsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  :last-child {
    margin-top: 24px;
  }
`

export const Field = styled.div`
  margin-right: 24px;
  margin-left: 24px;
  margin-bottom: 24px;
  flex-grow: 1;
  width: 25%;

  .field-label {
    font-size: 14px;
    line-height: 24px;
    color: #565d66;
    width: 208px;
  }
  .field-value {
    font-size: 14px;
    line-height: 24px;
    color: #565d66;
    font-weight: bold;
    &.field-link {
      color: #0c7d84;
      cursor: pointer;
    }
  }
`

export const TooltipContent = styled.div`
  font-size: 13px;
  span {
    font-weight: bold;
    margin: 0 3px;
  }
`
export const IconWrapper = styled.div`
  position: absolute;
  left: 66px;
  margin-top: 28px;

  svg {
    width: 32px;
    height: 40px;
  }
`
export const GraphContainer = styled.div`
  margin-top: 60px;
`
export const Row = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
  [class^="CustomSelectstyles__CustomSelectContainer"] {
    margin: 0 0 0 24px;
    [class^="inputstyles__StyledInput"] {
      border: 1px solid #C9CFD6;
      color: #565D66;
    } 
`
export const LegendLabel = styled.label`
  display: flex;
  white-space: nowrap;
  font-size: 14px;
  line-height: 15px;
  color: #565d66;
  align-content: center;
  cursor: pointer;
  > span {
    display: flex;
  }

  &:hover {
    svg g path {
      fill: #039ba1;
    }
  }

  :first-child {
    margin-right: 24px;
  }
`
export const LegendIcon = styled.div`
  display: flex;
  width: 20px;
  height: 15px;
  min-width: 20px;
  align-items: center;
`
export const LegendBullet = styled.span`
  display: block;
  width: 8px;
  height: 8px;
  min-width: 8px;
  min-height: 8px;
  margin: auto 8px;
  border-radius: 50%;
  background-color: ${({ color }) => (!color ? "#0C7D84" : color)};
`
export const LegendWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-content: center;
  height: 45px;
  margin: 0 0 0 auto;
`
