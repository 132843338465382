import styled from "styled-components"
import { theme } from "@elevate_security/elevate-component-library"

export const ConfiguratorSidebar = styled.div`
  height: 100%;
  max-height: 100%;
  position: relative;
  width: 264px;
  min-width: 264px;
  background: #fff;
  z-index: 10;
  padding-top: 28px;
`

export const MobileCloseMenuButton = styled.a`
  position: absolute;
  top: 0;
  right: -48px;
  left: auto;
  bottom: auto;
  width: 50px;
  height: 50px;
  background: #fff;
  border-right: 1px solid ${theme.colors.border.light};
  border-bottom: 1px solid ${theme.colors.border.light};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const MobileOpenMenuButton = styled.a`
  position: absolute;
  top: 20px;
  right: auto;
  left: 20px;
  bottom: auto;
  width: 50px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &.menu-enter,
  &.menu-enter-done {
    display: flex;
  }
`
