/* eslint-disable import/prefer-default-export */

export const isRequired =
  (message = "Required") =>
  (value, allValues, meta) =>
    !value ? message : undefined

const validationsMapping = {
  required: isRequired
}

export const composeValidators =
  (validators) =>
  (...args) =>
    validators.reduce((error, validator) => error || validator(...args), undefined)

export const resolveValidations = (config) =>
  composeValidators(
    (config || []).reduce((validations, validation) => {
      if (typeof validation === "string") {
        // eslint-disable-next-line no-param-reassign
        validation = {
          type: validation
        }
      }

      if (validation.type in validationsMapping) {
        validations.push(validationsMapping[validation.type](validation.message))
      }

      return validations
    }, [])
  )
