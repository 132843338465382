const theme = {}

const colors = {
  surfie_green: "#0C7D84",
  barley_white: "#FFF3CA",
  athens_gray: "#EEF0F3",
  iceberg: "#E3F6F2",
  manatee: "#959DA8",
  rolling_stone: "#7B828A",
  persian_green: "#039BA1",
  fountain_blue: "#60C5B7",
  outer_space: "#34383C",
  red: "#f8d7da",
  dark_red: "#f5c6cb",
  yellow: "#ecf568"
}

theme.colors = colors

theme.colors.border = {
  default: colors.athens_gray,
  success: colors.fountain_blue,
  error: colors.dark_red
}

theme.colors.text = {
  default: colors.outer_space,
  light: colors.rolling_stone,
  link: colors.surfie_green,
  linkHover: colors.persian_green
}

theme.colors.icon = {
  default: colors.manatee,
  hover: colors.persian_green,
  primary: colors.fountain_blue
}

theme.colors.tag = {
  default: colors.athens_gray,
  primary: colors.iceberg,
  secondary: colors.barley_white,
  test: colors.yellow
}

theme.colors.bg = {
  success: colors.iceberg,
  error: colors.red
}

export default theme
