/* eslint-disable import/prefer-default-export */
import styled from "styled-components"
import { Typography, theme } from "@elevate_security/elevate-component-library"

const { Small, Text } = Typography

export const FieldContainer = styled.div`
  display: ${(props) => (props.hidden ? "none" : "flex")};
  flex-direction: ${(props) => props.direction || "column"};
  padding: 14px 0;
  width: 100%;

  &:not(:last-child) {
    ${({ hasExampleLink }) => hasExampleLink && { paddingBottom: 0 }}
  }

  ${(props) => {
    if (props.separator) {
      return `border-bottom: 1px solid ${theme.colors.border.default};`
    }
    return ""
  }}

  position: relative;

  ${(props) =>
    props.direction === "row" &&
    `
    & > span {
      flex: 1;
    }
  `}

  ${(props) =>
    props.direction === "row-reverse" &&
    `
    & > span {
      flex: 1;
    }
  `}

  ${(props) => props.container_styles}
`

export const InputContainer = styled.div`
  ${(props) =>
    props.direction === "row-reverse" &&
    `
    flex: 0;
  `}
  & > * {
    width: 100%;

    input {
      width: 100%;
    }
  }
  .react-datepicker-popper {
    z-index: 5;
  }
`

export const Label = styled(Text)`
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 24px;
  color: #34383c;
  font-weight: bold;
  margin-right: 2px;
  [class^="FieldHelpToolTip__IconContainer"] {
    margin: 0 0 4px 6px;
  }
`

export const InputText = styled(Text)`
  font-weight: 300;
`

export const HeadingText = styled.h4`
  font-size: 18px;
  line-height: 24px;
  color: #34383c;
  font-weight: bold;
  margin-bottom: 0;
`

export const HelpText = styled(Small)`
  margin: 0 0 0 1rem;
`
