import styled from "styled-components"

export const ProgressBar = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: #eef0f3;
  border-radius: 2px;
  overflow: hidden;
  &:before {
    content: "";
    display: block;
    width: ${({ value = 0 }) => `${value}%`};
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-color: #0c7d84;
  }
`
export const LabelBar = styled.span`
  display: flex;
  justify-content: flex-start;
  padding-right: 16px;
  text-align: right;
  min-width: 142px;
  font-size: 14px;
  color: #565d66;
  padding-bottom: 4px;
`
export const ScoreValue = styled.strong`
  font-size: 14px;
  color: #34383c;
  margin-left: auto;
`
export const Row = styled.div`
  display: flex;
  width: 100%;
  padding-top: 16px;
`
export const TrackingPlatformWrapper = styled.div`
  width: 100%;
  display: flex;
`
export const LeftColumn = styled.div`
  width: 50%;
  margin-right: 40px;
`
export const RightColumn = styled.div`
  width: 50%;
`
export const Title = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #34383c;
  margin: 64px 0 24px 0;
`
