import { connect } from "react-redux"
import PropTypes from "prop-types"
import React from "react"
import styled from "styled-components"

import { Typography } from "@elevate_security/elevate-component-library"

import { getEmailSubject } from "../../../sagas/recurringCampaignSaga/reducer"
import { getGroupEmployees } from "../../../sagas/organizationsSaga/reducer"
import { campaignType } from "../../../types"
import { CAMPAIGN_STATUS, WIZARD_STEPS } from "../../../constants"
import stamp from "./stamp-envelope.svg"
import { getLanguagesOptions } from "../../../utils/utils"

import { ScheduleRender } from "../ScheduleRender"

const MainContainer = styled.div`
  text-align: center;
`
const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #34383c;
  padding-top: 16px;
  margin-bottom: 4px !important;
`

const CheckboxSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 16px;
`
export const Email = styled.b`
  border-bottom: 1px dashed #565d66;
  cursor: pointer;
`
const BigText = styled.span`
  color: #1d1e1f;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
`

const { H3, H5 } = Typography

const HelpingText = styled(H5).attrs({
  fontWeight: "normal",
  color: "700"
})`
  margin-bottom: 10px;
  margin-top: 24px;
`
const CheckboxText = styled(H5).attrs({
  fontWeight: "light",
  color: "500",
  padding: "0 0 0 8px"
})`
  margin-bottom: 0 !important;
`

export const Heading = styled(H3).attrs({
  color: "900"
})`
  border-bottom: 1px dashed #565d66;
  display: inline;
  font-weight: bold;
  cursor: pointer;
`

export class Rollout extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      checkAgreement: false
    }
  }

  componentDidMount() {
    const { campaign, getEmailSubjectFunc, getGroupEmployeesFunc } = this.props
    getGroupEmployeesFunc(campaign.group_id, campaign.campaign_type)
    getEmailSubjectFunc(campaign.id)
  }

  handleCheckAgreement = (event) => {
    const { handleCheck } = this.props
    handleCheck(event.currentTarget.checked)
    this.setState({
      checkAgreement: event.currentTarget.checked
    })
  }

  submit = (e) => {
    e.preventDefault()
    const { handleSubmit } = this.props
    handleSubmit({
      status: CAMPAIGN_STATUS.SCHEDULED
    })
  }

  getLanguage = () => {
    const { campaign, languageOption } = this.props
    return Object.keys(languageOption).find((key) => languageOption[key] === campaign.language)
  }

  render() {
    const { campaign, emailSubject, employees, handleEdit } = this.props
    const { checkAgreement } = this.state
    const employeeCount = employees ? employees.count : 0
    const employeesType =
      campaign && campaign.campaign_type ? campaign.campaign_type.toLowerCase() : null
    const language = this.getLanguage()

    return (
      <form onSubmit={this.submit} id="submit-form">
        <MainContainer className="main-container">
          <img src={stamp} alt="Stamp" />
          <Heading onClick={() => handleEdit(WIZARD_STEPS.third)}>
            <ScheduleRender mode="big" schedule={campaign.schedule} />
          </Heading>
          <Text>we will send out</Text>
          <Heading onClick={() => handleEdit(WIZARD_STEPS.first)}>
            {campaign.name}
            {"  "}
          </Heading>
          <BigText>in</BigText>
          <Heading onClick={() => handleEdit(WIZARD_STEPS.first)}>
            {"  "}
            {language}
            {"  "}
          </Heading>
          <BigText>to</BigText>
          <Heading onClick={() => handleEdit(WIZARD_STEPS.first)}>
            {"  "}
            {employeesType}
          </Heading>
          <Text>who are in</Text>
          <Heading onClick={() => handleEdit(WIZARD_STEPS.second)}>{campaign.group_name} </Heading>
          <BigText>{`(${employeeCount} recipient${employeeCount > 1 ? "s" : ""})`}</BigText>
          <HelpingText>
            Subject line will be&nbsp;
            <b>{emailSubject}</b>
          </HelpingText>
          <H5 fontWeight="normal" color="700">
            We’ll send as{" "}
            <Email
              onClick={() => handleEdit(WIZARD_STEPS.second)}
            >{`${campaign.sender_name} <${campaign.sender_email}>`}</Email>
          </H5>
          <CheckboxSection>
            <input
              type="checkbox"
              className="regular-checkbox"
              checked={checkAgreement}
              onChange={this.handleCheckAgreement}
              required
            />
            <CheckboxText>Yep, everything looks correct!</CheckboxText>
          </CheckboxSection>
        </MainContainer>
      </form>
    )
  }
}

const mapStateToProps = (state) => ({
  emailSubject: state.recurring_campaigns.emailSubject,
  employees: state.organization.employees,
  languageOption: getLanguagesOptions()
})

const mapDispatchToProps = {
  getEmailSubjectFunc: getEmailSubject,
  getGroupEmployeesFunc: getGroupEmployees
}

Rollout.propTypes = {
  campaign: campaignType,
  emailSubject: PropTypes.string,
  employees: PropTypes.shape({
    count: PropTypes.number
  }),
  getEmailSubjectFunc: PropTypes.func,
  getGroupEmployeesFunc: PropTypes.func,
  handleCheck: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleEdit: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  languageOption: PropTypes.object
}

Rollout.defaultProps = {
  campaign: null,
  emailSubject: null,
  employees: null,
  getEmailSubjectFunc: null,
  getGroupEmployeesFunc: null,
  handleCheck: null,
  handleSubmit: null,
  handleEdit: null,
  languageOption: {}
}

export default connect(mapStateToProps, mapDispatchToProps)(Rollout)
