/* eslint-disable no-shadow */
import * as campaignActions from "./actions"
import { CAMPAIGN_STATUS } from "../../constants"

const defaultState = {
  data: [],
  metadataTask: {},
  campaignInfo: null,
  campaignInfoLoading: false,
  currentStep: null,
  isCampaignsLoading: false,
  emailSubject: null,
  loading: false,
  error: null,
  success: null,
  historicalStats: null,
  newHiresRatio: null,
  recipients: null,
  breadcrumbs: ["Pulse", "Campaigns"]
}

export const getCampaigns = () => ({
  type: campaignActions.GET_CAMPAIGNS,
  payload: {}
})

export const getCampaignById = (id, cb) => ({
  type: campaignActions.GET_CAMPAIGN_BY_ID,
  id,
  cb
})

export const getMetadataTaskStatus = () => ({
  type: campaignActions.GET_METADATA_TASK_STATUS,
  payload: {}
})

export const createCampaign = (payload) => ({
  type: campaignActions.CREATE_CAMPAIGN,
  payload
})

export const deleteCampaign = (id) => ({
  type: campaignActions.DELETE_CAMPAIGN,
  id
})

export const getEmailSubject = (id) => ({
  type: campaignActions.GET_EMAIL_SUBJECT,
  id
})

export const sendTestEmail = (payload) => ({
  type: campaignActions.SEND_TEST_EMAIL,
  payload
})

export const updateCampaign = (id, payload, currentStep, noStep, toggleModal) => ({
  type: campaignActions.UPDATE_CAMPAIGN,
  id,
  payload,
  currentStep,
  noStep,
  toggleModal
})

export const setCampaign = (payload) => ({
  type: campaignActions.SET_CAMPAIGN,
  payload
})

export const moveCampaignWizardToStep = (step) => ({
  type: campaignActions.MOVE_CAMPAIGN_WIZARD_TO_STEP,
  step
})

export const resetCampaign = () => ({
  type: campaignActions.RESET_CAMPAIGN
})

export const setBreadcrumbs = (breadcrumbs) => ({
  type: campaignActions.SET_BREADCRUMBS,
  breadcrumbs: [...defaultState.breadcrumbs, ...breadcrumbs]
})

function getInitialStep(campaign) {
  if (campaign == null) {
    return 0
  }
  return campaign.status === CAMPAIGN_STATUS.SCHEDULED ? 4 : campaign.wizard_step
}

function getNextStep(currentStep, noStep) {
  if (noStep) {
    return currentStep
  }
  return currentStep >= 4 ? currentStep : currentStep + 1
}

export const getHistoricalStatsById = (id) => ({
  type: campaignActions.GET_HISTORICAL_STATS_BY_ID,
  id
})

export const getNewHiresRatioById = (id) => ({
  type: campaignActions.GET_NEW_HIRES_RATIO_BY_ID,
  id
})

export const getRecipientsCountById = (id) => ({
  type: campaignActions.GET_RECIPIENTS_COUNT_BY_ID,
  id
})

const ACTION_HANDLERS = {
  [campaignActions.GET_CAMPAIGNS]: (state) => ({ ...state, isCampaignsLoading: true }),
  [campaignActions.GET_CAMPAIGNS_SUCCESS]: (state, action) => ({
    ...state,
    data: action.data,
    isCampaignsLoading: false,
    currentStep: 1
  }),
  [campaignActions.GET_CAMPAIGNS_FAILED]: (state, { error }) => ({
    ...state,
    error,
    isCampaignsLoading: false
  }),

  [campaignActions.GET_METADATA_TASK_STATUS]: (state) => ({ ...state, loading: true }),
  [campaignActions.GET_METADATA_TASK_STATUS_SUCCESS]: (state, action) => ({
    ...state,
    metadataTask: action.data,
    loading: false
  }),
  [campaignActions.GET_METADATA_TASK_STATUS_FAILED]: (state, { error }) => ({
    ...state,
    error,
    loading: false
  }),

  [campaignActions.GET_CAMPAIGN_BY_ID]: (state) => ({
    ...state,
    loading: true,
    campaignInfoLoading: true
  }),
  [campaignActions.GET_CAMPAIGN_BY_ID_SUCCESS]: (state, action) => ({
    ...state,
    campaignInfo: action.data,
    loading: false,
    success: true,
    campaignInfoLoading: false
  }),
  [campaignActions.GET_CAMPAIGN_BY_ID_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error,
    campaignInfoLoading: false
  }),

  [campaignActions.GET_HISTORICAL_STATS_BY_ID]: (state) => ({ ...state, loading: true }),
  [campaignActions.GET_HISTORICAL_STATS_BY_ID_SUCCESS]: (state, action) => ({
    ...state,
    historicalStats: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_HISTORICAL_STATS_BY_ID_FAILED]: (state, action) => ({
    ...state,
    historicalStats: {},
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.GET_NEW_HIRES_RATIO_BY_ID]: (state) => ({ ...state, loading: true }),
  [campaignActions.GET_NEW_HIRES_RATIO_BY_ID_SUCCESS]: (state, action) => ({
    ...state,
    newHiresRatio: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_NEW_HIRES_RATIO_BY_ID_FAILED]: (state, action) => ({
    ...state,
    newHiresRatio: {},
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.GET_RECIPIENTS_COUNT_BY_ID]: (state) => ({ ...state, loading: true }),
  [campaignActions.GET_RECIPIENTS_COUNT_BY_ID_SUCCESS]: (state, action) => ({
    ...state,
    recipients: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_RECIPIENTS_COUNT_BY_ID_FAILED]: (state, action) => ({
    ...state,
    recipients: {},
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.CREATE_CAMPAIGN]: (state) => ({ ...state, loading: true }),
  [campaignActions.CREATE_CAMPAIGN_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    success: true,
    campaignInfo: action.data,
    currentStep: action.data.wizard_step,
    data: [action.data, ...state.data]
  }),
  [campaignActions.CREATE_CAMPAIGN_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.DELETE_CAMPAIGN]: (state) => ({ ...state, loading: true }),
  [campaignActions.DELETE_CAMPAIGN_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    success: true,
    data: state.data.filter((campaign) => campaign.id !== action.id)
  }),
  [campaignActions.DELETE_CAMPAIGN_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.SEND_TEST_EMAIL]: (state) => ({ ...state, loading: true }),
  [campaignActions.SEND_TEST_EMAIL_SUCCESS]: (state) => ({
    ...state,
    loading: false,
    success: true
  }),
  [campaignActions.SEND_TEST_EMAIL_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),

  [campaignActions.GET_EMAIL_SUBJECT]: (state) => ({ ...state, loading: true }),
  [campaignActions.GET_EMAIL_SUBJECT_SUCCESS]: (state, action) => ({
    ...state,
    emailSubject: action.data,
    loading: false,
    success: true
  }),
  [campaignActions.GET_EMAIL_SUBJECT_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),
  [campaignActions.UPDATE_CAMPAIGN]: (state) => ({ ...state, loading: true }),
  [campaignActions.UPDATE_CAMPAIGN_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    success: true,
    campaignInfo: action.data,
    currentStep: getNextStep(state.currentStep, action.noStep),
    data: state.data.map((campaign) => {
      if (campaign.id === action.data.id) {
        return {
          ...campaign,
          ...action.data
        }
      }
      return campaign
    })
  }),
  [campaignActions.UPDATE_CAMPAIGN_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  }),
  [campaignActions.RESET_CAMPAIGN]: (state) => ({
    ...state,
    campaignInfo: null,
    currentStep: 1
  }),
  [campaignActions.SET_CAMPAIGN]: (state, action) => ({
    ...state,
    campaignInfo: action.payload,
    currentStep: getInitialStep(action.payload)
  }),
  [campaignActions.MOVE_CAMPAIGN_WIZARD_TO_STEP]: (state, action) => ({
    ...state,
    currentStep: action.step
  }),
  [campaignActions.SET_BREADCRUMBS]: (state, action) => ({
    ...state,
    breadcrumbs: action.breadcrumbs
  })
}

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
