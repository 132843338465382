import React, { useMemo, useState } from "react"
import { Link } from "@elevate_security/elevate-component-library"
import styled from "styled-components"
import FieldExampleModal from "../FieldExampleModal"
import { ControlProps } from "../Form/types"
import { DIAL_IMAGE_LINK } from "../../../../../sagas/configuratorSaga/constants"
import { ASSETS_BASE_URL } from "../../../../../constants"

const LinkContainer = styled.div`
  text-align: right;
  margin-top: 12px;
`

const FieldExampleLink = (props) => {
  const {
    control: { example, label, name, parent, value }
  } = props
  const { description, title } = example || { description: null }
  const [open, setOpen] = useState(false)
  const titleString = `See ${title || label} example`

  const toDash = (text) => text?.replace(/_/g, "-") || ""

  function getImageLink(value, name, parent) {
    const hasDial = value === "DIAL" && Object.keys(DIAL_IMAGE_LINK).includes(name) // If score-graphic-type is DIAL and text field has example links
    const fieldName = hasDial ? DIAL_IMAGE_LINK[name] : name
    return `${ASSETS_BASE_URL}${toDash(parent)}/${toDash(fieldName)}-example@2x.png`
  }

  // examples images link
  const imageLink = useMemo(() => getImageLink(value, name, parent), [value, name, parent])

  if (!description) return null

  return (
    <>
      <LinkContainer>
        <Link
          to="/#"
          onClick={(e) => {
            e.preventDefault()
            setOpen(!open)
          }}
        >
          {titleString}
        </Link>
      </LinkContainer>
      {open && (
        <FieldExampleModal
          title={title || label}
          description={description}
          url={imageLink}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  )
}

FieldExampleLink.propTypes = {
  control: ControlProps.isRequired
}

export default FieldExampleLink
