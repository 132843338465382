import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Button, Typography } from "@elevate_security/elevate-component-library"
import { FormApi } from "final-form"
import { useDispatch, useSelector } from "react-redux"
import { Text as TextControl } from "../../../../routes/configurator/Template/components/Form/fields"
import widthForm from "../../../../routes/configurator/Template/components/Form/withForm"
import { FieldContainer } from "../../../../routes/configurator/Template/components/Form/fields/styles"
import { getLanguage, getTestEmailSendState } from "../../../../sagas/configuratorSaga/selectors"
import ActivityIndicator from "../../../ActivityIndicator"
import { sendTestEmail, resetTestEmailState } from "../../../../sagas/configuratorSaga/reducer"

const { Body } = Typography

const FormContainer = styled.form`
  width: 100%;
  position: relative;
  display: flex;
  flex: direction: row;
  justify-content: space-between;
  
  & > div:first-child {
    flex: 1;
    margin-right: 16px;
  }
  
  & > div:last-child {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
  }
  
  [class^=FieldContainer] {
    padding: 0 0;
  }
`

const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin-left: 20px;
`

const TestEmailModal = ({ formProps, templateId, configurationId, onSent }) => {
  const { form } = formProps
  const dispatch = useDispatch()
  const language = useSelector(getLanguage)
  const state = useSelector(getTestEmailSendState)
  const [timer, setTimer] = useState(-1)
  const [displaySuccessMessage, setDisplaySuccessMessage] = useState(false)
  const { success, sending, error } = state

  useEffect(() => {
    if (success && !sending && !displaySuccessMessage) {
      setDisplaySuccessMessage(true)

      if (timer !== -1) clearTimeout(timer)
      setTimer(
        setTimeout(() => {
          setDisplaySuccessMessage(false)
          dispatch(resetTestEmailState())
          onSent()
        }, 3000)
      )
    }

    return () => timer !== -1 && clearTimeout(timer)
  }, [state])

  useEffect(() => {
    if (error) {
      setTimer(
        setTimeout(() => {
          dispatch(resetTestEmailState())
        }, 6000)
      )
    }
  }, [error])

  const submit = () => {
    const email = form.getFieldState("email")

    dispatch(
      sendTestEmail({
        emails: email.value,
        language: language.name,
        template: templateId.concat(".jinja"),
        type: templateId.split("/")[0].toUpperCase(),
        template_data_id: configurationId
      })
    )
  }

  if (displaySuccessMessage) {
    return (
      <MessageContainer>
        <Body color="link">Sample email successfully sent!</Body>
      </MessageContainer>
    )
  }

  if (error) {
    return (
      <MessageContainer>
        <Body color="error">
          Sorry, there was an error while sending your email.{" "}
          <small style={{ display: "block" }}>please refresh the page and try again.</small>
        </Body>
      </MessageContainer>
    )
  }

  return (
    <FormContainer onSubmit={formProps.handleSubmit}>
      <TextControl
        form={formProps.form}
        control={{
          name: "email",
          type: "text",
          label: "Send a sample email to:",
          placeholder: "If sending to multiple emails, separate by comma",
          helpText:
            "Test emails will come from yourfriends@elevatesecurity.com. Be sure to check your spam folder."
        }}
      />
      <FieldContainer container_styles={{ width: "auto" }}>
        <Button disabled={sending} onClick={() => submit()}>
          Send sample email
        </Button>
      </FieldContainer>
      <ActivityIndicator active={sending} size={20} border={4} />
    </FormContainer>
  )
}

TestEmailModal.propTypes = {
  templateId: PropTypes.string.isRequired,
  configurationId: PropTypes.string.isRequired,
  formProps: PropTypes.shape({
    form: PropTypes.instanceOf(FormApi).isRequired,
    handleSubmit: PropTypes.func.isRequired
  }).isRequired,
  onSent: PropTypes.func
}

TestEmailModal.defaultProps = {
  onSent: () => null
}

export default widthForm(TestEmailModal)
