import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Typography } from "@elevate_security/elevate-component-library"
import { Spinner, SpinnerBackdrop } from "./style"

const { Text } = Typography

const ActivityIndicator = (props) => {
  const { name, message, active, opacity, size, border, margin, position, delay } = props
  const [stringSize] = useState(`${size}px`)
  const [stringBorderSize] = useState(`${border}px`)
  const [current, setCurrent] = useState(false)
  const [unloading, setUnloading] = useState(false)
  const [unloaded, setUnloaded] = useState(true)

  let timer

  useEffect(() => {
    if (current !== active && active === false && !unloading) {
      setUnloading(true)
      setCurrent(active)
      clearTimeout(timer)
      timer = setTimeout(() => setUnloaded(true), 300 + delay)
    }

    if (current !== active && active === true) {
      setUnloading(false)
      setUnloaded(false)
      setCurrent(active)
    }
  }, [active])

  if (unloaded) {
    return null
  }

  return (
    <SpinnerBackdrop
      opacity={opacity}
      className={`${unloading ? "unloading" : ""} ${name}-activity spinner-${position}`}
      data-id="hm-activity-indicator"
    >
      <Spinner
        size={stringSize}
        position={position}
        border={stringBorderSize}
        margin={margin}
        hasMessage={!!message}
      >
        <div>
          <div />
          <div />
          <div />
          <div />
        </div>
        {message && <Text className="activity-message">{message}</Text>}
      </Spinner>
    </SpinnerBackdrop>
  )
}

ActivityIndicator.propTypes = {
  name: PropTypes.string,
  message: PropTypes.string,
  active: PropTypes.bool,
  opacity: PropTypes.string,
  margin: PropTypes.string,
  size: PropTypes.number,
  border: PropTypes.number,
  delay: PropTypes.number,
  position: PropTypes.oneOf(["top-left", "top-right"])
}

ActivityIndicator.defaultProps = {
  name: "default",
  message: null,
  active: false,
  opacity: "50%",
  margin: "auto",
  size: 64,
  border: 8,
  delay: 0,
  position: "center"
}

export default ActivityIndicator
