/* eslint-disable jsx-a11y/click-events-have-key-events,max-len */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/forbid-prop-types */
import React from "react"
import { connect } from "react-redux"
import PropTypes from "prop-types"
import {
  Typography,
  Modal,
  Button,
  Header,
  Tooltip,
  Icon
} from "@elevate_security/elevate-component-library"
import moment from "moment-timezone"

import { deleteCampaign } from "../../../../sagas/campaignSaga/reducer"
import { showAlert } from "../../../../sagas/alertsSaga/reducer"
import { CAMPAIGN_STATUS } from "../../../../constants"
import { sanitizeContent } from "../../../../utils/utils"

import {
  Heading,
  ItemContainer,
  Status,
  TestCampaign,
  ContentRight,
  ActionIcon,
  RecipientsContainer,
  RecipientCount,
  RecipientLegend,
  FlexSpacer,
  RecipientProgressBar
} from "./styles"
import { getDefaultTimezone } from "../../../../sagas/companySaga/selectors"

const { Text, H2 } = Typography

const DELAYED_TITLE =
  "We’re currently refreshing Pulse data across your org. This process usually takes a few minutes but may take up to an hour. Scheduled campaigns cannot be sent during this time but will resume sending once the refresh is done!"

const CampaignStatus = ({ campaign, status, title, delayed }) => {
  switch (campaign.status) {
    case CAMPAIGN_STATUS.DRAFT:
      return <Status status={campaign.status}>{status}</Status>
    case CAMPAIGN_STATUS.SCHEDULED: {
      if (delayed) {
        return (
          <Tooltip body={DELAYED_TITLE} placement="top" readOnly size="sm">
            <Status status={campaign.status}>
              {status} - Delayed&nbsp;
              <Icon name="CircleExclamation" size="xsm" fill="#CB7D20" />
            </Status>
          </Tooltip>
        )
      }
      return <Status status={campaign.status}>{status}</Status>
    }
    default:
      return (
        <Tooltip body={title} placement="right" readOnly size="sm">
          <Status status={campaign.status}>{status}</Status>
        </Tooltip>
      )
  }
}

export class CampaignRow extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showConfirmationDialog: false
    }
  }

  editCampaign = () => {
    const { onOpenCampaign } = this.props
    onOpenCampaign()
  }

  toggleConfirmDialog = () => {
    const { showConfirmationDialog } = this.state
    this.setState({ showConfirmationDialog: !showConfirmationDialog })
  }

  onDeleteCampaign = () => {
    const { campaign, deleteCampaignFunc, showAlertFunc, reloadCampaigns } = this.props
    deleteCampaignFunc(campaign.id)
    this.setState({ showConfirmationDialog: false })
    showAlertFunc(`${sanitizeContent(campaign.name)} deleted.`)
    reloadCampaigns()
  }

  getTooltipText = () => {
    const { campaign } = this.props
    let title = ""
    if (campaign.status === CAMPAIGN_STATUS.ERROR) {
      title = campaign.error_message
    } else if (campaign.status === CAMPAIGN_STATUS.COMPLETED) {
      title = this.getCampaignInfo()
    }
    return title
  }

  getCampaignInfo = () => {
    const { campaign } = this.props
    return `
      ${campaign.completed_date ? `Completed: ${campaign.completed_date} ` : ""}
      Delivery: ${campaign.delivery_completed ? "Done" : "Pending"}
      ${
        campaign.delivery_status && campaign.delivery_status.recipient_count
          ? `Recipients: ${campaign.delivery_status.recipient_count} `
          : ""
      }
      ${
        campaign.delivery_status && campaign.delivery_status.sent_count
          ? `Sent: ${campaign.delivery_status.sent_count} `
          : ""
      }
      ${
        campaign.delivery_status && campaign.delivery_status.recipient_no_person_nid_count
          ? `No NID: ${campaign.delivery_status.recipient_no_person_nid_count} `
          : ""
      }
      ${
        campaign.delivery_status && campaign.delivery_status.recipient_on_leave_count
          ? `On Leave: ${campaign.delivery_status.recipient_on_leave_count} `
          : ""
      }
      `
  }

  render() {
    const { campaign, metadataTaskStatus } = this.props
    if (!campaign) return null
    const title = this.getTooltipText()
    const { showConfirmationDialog } = this.state
    const status = campaign.status.toLowerCase().replace(/^\w/, (c) => c.toUpperCase())
    const default_timezone = getDefaultTimezone()
    const utcLaunchDateMoment = moment.utc(campaign.launch_date)
    const utcLaunchDate = utcLaunchDateMoment.format("MMMM DD, YYYY hh:mm A [UTC]")
    const launchDateMoment = moment.utc(campaign.launch_date).tz(default_timezone)
    const launchDate = launchDateMoment.format("MMMM DD, YYYY")
    const launchTime = launchDateMoment.format("hh:mm A")
    const delayed =
      metadataTaskStatus && metadataTaskStatus.task_exists && launchDateMoment.isBefore()

    return (
      <>
        <ItemContainer>
          <FlexSpacer>
            <div>
              <div className="d-flex align-items-baseline">
                <Heading onClick={() => this.editCampaign()}>{campaign.name}</Heading>
                <CampaignStatus
                  campaign={campaign}
                  status={status}
                  title={title}
                  delayed={delayed}
                />
                {campaign.is_external_test && <TestCampaign>Test Campaign</TestCampaign>}
              </div>
              <Text color="300" fontWeight="normal">
                {campaign.status === CAMPAIGN_STATUS.DRAFT ? (
                  <>
                    {`Completed ${campaign.wizard_step - 1} of 4 steps. `}
                    <u className="pointer" onClick={() => this.editCampaign()}>
                      Continue where you left off
                    </u>
                  </>
                ) : (
                  <Tooltip body={utcLaunchDate} placement="top" readOnly size="sm">
                    Launching on {launchDate} at {launchTime} tz: {default_timezone}
                  </Tooltip>
                )}
              </Text>
            </div>
            {(campaign.status === CAMPAIGN_STATUS.RUNNING ||
              campaign.status === CAMPAIGN_STATUS.COMPLETED) && (
              <RecipientsContainer>
                <RecipientCount>
                  {campaign.status === CAMPAIGN_STATUS.RUNNING &&
                    `${campaign?.realtime_stats || 0} of `}
                  {campaign.status === CAMPAIGN_STATUS.RUNNING
                    ? campaign?.realtime_stats_total
                    : campaign?.delivery_status?.recipient_count}
                </RecipientCount>
                {campaign.status === CAMPAIGN_STATUS.RUNNING && (
                  <RecipientProgressBar
                    progress={
                      ((campaign?.realtime_stats || 0) / (campaign?.realtime_stats_total || 1)) *
                      100
                    }
                  />
                )}
                <RecipientLegend>
                  Recipients
                  {campaign.status === CAMPAIGN_STATUS.RUNNING && " processed"}
                </RecipientLegend>
              </RecipientsContainer>
            )}
          </FlexSpacer>
          <ContentRight>
            {([
              CAMPAIGN_STATUS.DRAFT,
              CAMPAIGN_STATUS.SCHEDULED,
              CAMPAIGN_STATUS.ERROR,
              CAMPAIGN_STATUS.COMPLETED
            ].indexOf(campaign.status) > -1 ||
              campaign.is_test) && (
              <div>
                {campaign.status !== CAMPAIGN_STATUS.ERROR && (
                  <span
                    onClick={() => this.editCampaign()}
                    className="pointer mr-4 btn-edit"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="Edit Campaign"
                  >
                    <ActionIcon name="Pencil" />
                  </span>
                )}

                <span
                  onClick={() => this.toggleConfirmDialog()}
                  className="pointer btn-delete"
                  data-toggle="tooltip"
                  data-placement="left"
                  title="Remove Campaign"
                >
                  <ActionIcon name="Trash" />
                </span>
              </div>
            )}
          </ContentRight>
        </ItemContainer>
        <Modal
          key="confirmationModal"
          style={{ maxWidth: "700px", maxHeight: "350px" }}
          onClose={this.toggleConfirmDialog}
          header={
            <Header>
              <H2 color="900">Delete Campaign</H2>
            </Header>
          }
          leftButtons={[
            <Button key="no" theme="ghost" onClick={this.toggleConfirmDialog}>
              No, Don&#39;t Delete
            </Button>,
            <Button key="yes" onClick={this.onDeleteCampaign}>
              Yes, Delete Campaign
            </Button>
          ]}
          isOpen={showConfirmationDialog}
        >
          <div>
            <Text fontWeight="normal">
              You&#39;re about to remove <b>{campaign.name}</b> from your Campaigns list.
            </Text>
            <br />
            <br />
            <Text fontWeight="normal">Are you sure you want to perform this action?</Text>
          </div>
        </Modal>
      </>
    )
  }
}

const mapDispatchToProps = {
  deleteCampaignFunc: deleteCampaign,
  showAlertFunc: showAlert
}

CampaignRow.propTypes = {
  campaign: PropTypes.object,
  onOpenCampaign: PropTypes.func.isRequired,
  showAlertFunc: PropTypes.func,
  deleteCampaignFunc: PropTypes.func.isRequired,
  reloadCampaigns: PropTypes.func,
  metadataTaskStatus: PropTypes.object
}

CampaignRow.defaultProps = {
  campaign: null,
  showAlertFunc: null,
  reloadCampaigns: null,
  metadataTaskStatus: null
}

export default connect(null, mapDispatchToProps)(CampaignRow)
