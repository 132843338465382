import PropTypes from "prop-types"
import React from "react"
import { Field } from "react-final-form"

import { Input } from "@elevate_security/elevate-component-library"

class SelectWithTextInput extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loaded: false,
      values: {
        ...props.value,
        new: ""
      }
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.value && !prevState.loaded) {
      return {
        loaded: true,
        values: {
          ...nextProps.value,
          new: ""
        }
      }
    }

    return null
  }

  onChangeGroup = (e, name, index) => {
    const groupId = e.target.value

    const updatedValues = { ...this.state.values }
    updatedValues[groupId] = updatedValues[name]
    delete updatedValues[name]

    if (name.indexOf("new") > -1) {
      updatedValues[`new${index}`] = ""
    }

    this.setState(
      {
        values: updatedValues
      },
      this.handleChangeClean
    )
  }

  onChangeURL = (e, name, index) => {
    const updatedValues = { ...this.state.values }
    updatedValues[name] = e

    if (name.indexOf("new") > -1) {
      updatedValues[`new${index}`] = ""
    }

    this.setState({
      values: updatedValues
    })
  }

  onHandleChange = () => {
    this.handleChangeClean()
  }

  handleChangeClean = () => {
    const cleanValues = Object.keys(this.state.values)
      .filter((key) => key.indexOf("new") === -1)
      .reduce((obj, key) => {
        obj[key] = this.state.values[key]
        return obj
      }, {})

    this.props.handleChange(cleanValues, this.props.fieldName, this.props.fieldType)
  }

  render() {
    const { customGroups, defaultGroups, fieldName } = this.props

    const { values } = this.state

    return (
      <div>
        {Object.keys(values).map((k, i) => (
          <div key={`${k}_${i}`} className={`form-check space-between padding-0`}>
            <Field key={`${fieldName}_${k}_group`} name={`${fieldName}_${k}_group`}>
              {() => (
                <select
                  className="form-control"
                  disabled={!defaultGroups && !customGroups}
                  key={`${fieldName}_${k}_group_select`}
                  onChange={(e) => this.onChangeGroup(e, k, i)}
                >
                  <option value="" selected={k.indexOf("new") > -1} disabled>
                    Choose group...
                  </option>
                  {customGroups ? (
                    <optgroup label="CUSTOM GROUPS">
                      {customGroups.map((group) => (
                        <option key={group.id} value={group.id} selected={group.id === k}>
                          {group.name}
                        </option>
                      ))}
                    </optgroup>
                  ) : null}
                  {defaultGroups ? (
                    <optgroup label="DEFAULT GROUPS">
                      {defaultGroups.map((group) => (
                        <option key={group.id} value={group.id} selected={group.id === k}>
                          {group.name}
                        </option>
                      ))}
                    </optgroup>
                  ) : null}
                </select>
              )}
            </Field>
            <Field key={`${fieldName}_${k}_url`} name={`${fieldName}_${k}_url`} value={values[k]}>
              {() => (
                <Input
                  className="config-container"
                  key={`${fieldName}_${k}_url_input`}
                  onChange={(e) => this.onChangeURL(e, k, i)}
                  onBlur={(e) => this.onHandleChange(e, k, i)}
                  value={values[k]}
                />
              )}
            </Field>
          </div>
        ))}
      </div>
    )
  }
}

SelectWithTextInput.propTypes = {
  id: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      helpText: PropTypes.string
    })
  ),
  disabled: PropTypes.bool,
  handleChange: PropTypes.func,
  placeholderGroup: PropTypes.string,
  placeholderUrl: PropTypes.string
}

SelectWithTextInput.defaultProps = {
  options: [],
  handleChange: null
}

export default SelectWithTextInput
