import { DEFAULT_TEMPLATE_CONFIG, SCORE_GRAPHIC_FILE } from "../constants"

/**
 * Enables and disables the score graphics upload fields based on the
 * current value of the score graphic type field.
 *
 * @param {Map} group The group to enable or disable
 */

const getScoreGaphicFiles = () => {
  try {
    return Object.keys(DEFAULT_TEMPLATE_CONFIG)
      .map((templateId) => DEFAULT_TEMPLATE_CONFIG[templateId]?.[SCORE_GRAPHIC_FILE] || "")
      ?.filter((item) => item)
  } catch {
    return []
  }
}
const updateScoreGraphics = (group) => {
  // getting all scores-graphic file
  const scoreGraphicFiles = getScoreGaphicFiles()
  if (group && scoreGraphicFiles.includes(group.get("name"))) {
    const controls = group.get("controls")
    const selected = controls.get("score_graphics_type").get("value")

    if (!selected || ["dragosaurs", "dial"].indexOf(selected.toLowerCase()) > -1) {
      Array.from(controls.keys()).forEach((controlName) => {
        if (controlName.startsWith("image_level_")) {
          controls.delete(controlName)
        }
      })
    }
  }
}

export default updateScoreGraphics
