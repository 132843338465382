import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { Typography } from "@elevate_security/elevate-component-library"
import { FieldContainer, Label } from "../styles"

const { Body } = Typography

const Description = styled(Body)`
  font-weight: 400;
`

const Informational = ({ control: { label, description, hidden } }) => (
  <FieldContainer hidden={hidden}>
    {label && <Label>{label}</Label>}
    {description && <Description fontWeight="400">{description}</Description>}
  </FieldContainer>
)

Informational.propTypes = {
  control: PropTypes.shape({
    label: PropTypes.string,
    description: PropTypes.string,
    hidden: PropTypes.bool
  }).isRequired
}

export default Informational
