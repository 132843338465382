/* eslint-disable react/forbid-prop-types */
import React, { useState } from "react"
import PropTypes from "prop-types"

import { IconAction } from "@elevate_security/elevate-component-library"

import ConfirmationModal from "../ConfirmationModal"

import { ActionContainer, IconWrapper } from "./styles"
import { ACTION_TYPE, ACTION_ALLOWED, ACTION_PROPS } from "./utils"

const TemplateActions = ({ template, onEdit, onCopy, onDelete, onPreview, options }) => {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [actionOnTemplate, setActionOnTemplate] = useState(null)

  const onToggleConfirmationModal = () => {
    setShowConfirmationModal(!showConfirmationModal)
  }

  const onActionClick = (action) => {
    if (action === ACTION_TYPE.EDIT) onEdit(template)
    else if (action === ACTION_TYPE.PREVIEW) onPreview(template)
    else {
      setActionOnTemplate(action)
      onToggleConfirmationModal()
    }
  }

  const handleEditTemplate = async () => {
    if (onEdit) onEdit(template)
  }

  const handleDeleteTemplate = async () => {
    if (onDelete) onDelete(template)
  }

  const handleCopyTemplate = async () => {
    if (onCopy) onCopy(template)
  }

  const handlePreviewTemplate = async () => {
    if (onPreview) onPreview(template)
  }

  const executeActionOnTemplate = () => {
    switch (actionOnTemplate) {
      case "Edit":
        handleEditTemplate()
        break
      case "Delete":
        handleDeleteTemplate()
        break
      case "Copy":
        handleCopyTemplate()
        break
      case "Preview":
        handlePreviewTemplate()
        break
      default:
    }
  }

  const handleConfirmUpdateTemplate = () => {
    executeActionOnTemplate()
    onToggleConfirmationModal()
  }

  const renderConfirmationText = () => (
    <div>
      {`You're about to ${actionOnTemplate.toLowerCase()} `}
      <b>{template.name}</b>
      {options.ConfirmationText ? options.ConfirmationText : " from your Templates list."}
    </div>
  )

  const renderConfirmationModal = () => {
    if (!showConfirmationModal) return null

    return (
      <ConfirmationModal
        isOpen={showConfirmationModal}
        headerText={`${actionOnTemplate} Template`}
        bodyText={renderConfirmationText()}
        helpText="Are you sure you want to perform this action?"
        confirmText={`Yes, ${actionOnTemplate} Template`}
        dismissText={`No, Don't ${actionOnTemplate}`}
        onClose={onToggleConfirmationModal}
        onConfirm={handleConfirmUpdateTemplate}
      />
    )
  }

  const removeHiddenActions = (actionType) => !options.hideActions.includes(actionType)

  const renderActionButtons = () => (
    <ActionContainer>
      {ACTION_ALLOWED.filter(removeHiddenActions).map((action) => (
        <IconWrapper>
          <IconAction
            name={ACTION_PROPS[action].icon}
            onClick={() => onActionClick(action)}
            tooltipText={ACTION_PROPS[action].tooltip}
            renderTooltipOnHover
          />
        </IconWrapper>
      ))}
    </ActionContainer>
  )

  if (!template) return null

  return (
    <>
      {renderActionButtons()}
      {renderConfirmationModal()}
    </>
  )
}

TemplateActions.propTypes = {
  template: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string
  }),
  onEdit: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onPreview: PropTypes.func,
  options: PropTypes.shape({
    ConfirmationText: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    hideActions: PropTypes.arrayOf(PropTypes.string)
  })
}

TemplateActions.defaultProps = {
  template: null,
  onEdit: () => null,
  onCopy: () => null,
  onDelete: () => null,
  onPreview: () => null,
  options: null
}

export default TemplateActions
