import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import {
  Typography,
  ButtonIcon,
  Badge,
  theme,
  SkeletonLoader
} from "@elevate_security/elevate-component-library"

import { renderTemplateType } from "../../../../../utils/utils"

const Container = styled.div`
  position: relative;

  h1 {
    margin-top: 12px;
    font-weight: bolder;
  }
`

const { H1 } = Typography

const Heading = styled(H1)`
  button {
    svg > g > g > path {
      fill: ${theme.icons.color.light} !important;
    }
    &:hover {
      svg > g > g > path {
        fill: ${theme.icons.color.linkHover} !important;
      }
    }
  }
`

const TitleForm = ({ title, isDefaultTemplate, templateId, disabled, onEdit, loading }) => {
  if (loading) {
    return <SkeletonLoader width={258} height={40} marginBottom={12} marginTop={12} />
  }

  return (
    <Container>
      <Heading color="700" fontWeight="bolder">
        <span>{title}</span>
        {renderTemplateType(templateId)}
        {isDefaultTemplate && <Badge text="Default" theme="gray" />}
        {!isDefaultTemplate && (
          <ButtonIcon
            theme="ghost"
            disabled={disabled}
            size="sm"
            icon="Pencil"
            onClick={() => onEdit(true)}
          />
        )}
      </Heading>
    </Container>
  )
}

TitleForm.propTypes = {
  title: PropTypes.string.isRequired,
  isDefaultTemplate: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  onEdit: PropTypes.func,
  templateId: PropTypes.string.isRequired
}

TitleForm.defaultProps = {
  disabled: false,
  onEdit: () => null
}

export default TitleForm
