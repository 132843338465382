/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import regeneratorRuntime from "regenerator-runtime"

import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import singleSpaReact from "single-spa-react"

import Pulse from "./App"
import store from "./store"

function domElementGetter() {
  const ID = "pulse"
  let el = document.getElementById(ID)
  if (!el) {
    el = document.createElement("div")
    el.id = ID
    document.body.appendChild(el)
  }

  return el
}

const reactLifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: () => (
    <Suspense fallback="loading">
      <Provider store={store}>
        <Pulse />
      </Provider>
    </Suspense>
  ),
  domElementGetter
})

export const bootstrap = [reactLifecycles.bootstrap]

export const mount = [reactLifecycles.mount, () => Promise.resolve(console.log("Mounting pulse"))]

export const unmount = [
  reactLifecycles.unmount,
  () => Promise.resolve(console.log("Unmounting pulse"))
]

export const unload = [reactLifecycles.unload]
