/* eslint-disable import/prefer-default-export */
import styled from "styled-components"
import { Typography, theme } from "@elevate_security/elevate-component-library"

const { H1 } = Typography

export const ConfigurationsTitle = styled(H1)`
  margin-top: 16px;
  font-weight: 800;
  color: ${theme.text.color["700"]};
`

export const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border: 1px solid rgb(221, 225, 231);
  border-radius: 5px;
  padding: 25px;
`
