export const GET_CAMPAIGNS = "GET_CAMPAIGNS"
export const GET_CAMPAIGNS_SUCCESS = "GET_CAMPAIGNS_SUCCESS"
export const GET_CAMPAIGNS_FAILED = "GET_CAMPAIGNS_FAILED"

export const GET_CAMPAIGN_BY_ID = "GET_CAMPAIGN_BY_ID"
export const GET_CAMPAIGN_BY_ID_SUCCESS = "GET_CAMPAIGN_BY_ID_SUCCESS"
export const GET_CAMPAIGN_BY_ID_FAILED = "GET_CAMPAIGN_BY_ID_FAILED"

export const GET_HISTORICAL_STATS_BY_ID = "GET_HISTORICAL_STATS_BY_ID"
export const GET_HISTORICAL_STATS_BY_ID_SUCCESS = "GET_HISTORICAL_STATS_BY_ID_SUCCESS"
export const GET_HISTORICAL_STATS_BY_ID_FAILED = "GET_HISTORICAL_STATS_BY_ID_FAILED"

export const GET_NEW_HIRES_RATIO_BY_ID = "GET_NEW_HIRES_RATIO_BY_ID"
export const GET_NEW_HIRES_RATIO_BY_ID_SUCCESS = "GET_NEW_HIRES_RATIO_BY_ID_SUCCESS"
export const GET_NEW_HIRES_RATIO_BY_ID_FAILED = "GET_NEW_HIRES_RATIO_BY_ID_FAILED"

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGNS"
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS"
export const CREATE_CAMPAIGN_FAILED = "CREATE_CAMPAIGN_FAILED"

export const GET_EMAIL_SUBJECT = "GET_EMAIL_SUBJECT"
export const GET_EMAIL_SUBJECT_SUCCESS = "GET_EMAIL_SUBJECT_SUCCESS"
export const GET_EMAIL_SUBJECT_FAILED = "GET_EMAIL_SUBJECT_FAILED"

export const SEND_TEST_EMAIL = "SEND_TEST_EMAIL"
export const SEND_TEST_EMAIL_SUCCESS = "SEND_TEST_EMAIL_SUCCESS"
export const SEND_TEST_EMAIL_FAILED = "SEND_TEST_EMAIL_FAILED"

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN"
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS"
export const UPDATE_CAMPAIGN_FAILED = "UPDATE_CAMPAIGN_FAILED"

export const DELETE_CAMPAIGN = "DELETE_CAMPAIGN"
export const DELETE_CAMPAIGN_SUCCESS = "DELETE_CAMPAIGN_SUCCESS"
export const DELETE_CAMPAIGN_FAILED = "DELETE_CAMPAIGN_FAILED"

export const SET_CAMPAIGN = "SET_CAMPAIGN"
export const MOVE_CAMPAIGN_WIZARD_TO_STEP = "MOVE_CAMPAIGN_WIZARD_TO_STEP"
export const RESET_CAMPAIGN = "RESET_CAMPAIGN"

export const GET_RECIPIENTS_COUNT_BY_ID = "GET_RECIPIENTS_COUNT_BY_ID"
export const GET_RECIPIENTS_COUNT_BY_ID_SUCCESS = "GET_RECIPIENTS_COUNT_BY_ID_SUCCESS"
export const GET_RECIPIENTS_COUNT_BY_ID_FAILED = "GET_RECIPIENTS_COUNT_BY_ID_FAILED"

export const SET_BREADCRUMBS = "SET_BREADCRUMBS"

export const GET_METADATA_TASK_STATUS = "GET_METADATA_TASK_STATUS"
export const GET_METADATA_TASK_STATUS_SUCCESS = "GET_METADATA_TASK_STATUS_SUCCESS"
export const GET_METADATA_TASK_STATUS_FAILED = "GET_METADATA_TASK_STATUS_FAILED"
