/* eslint-disable prefer-destructuring */
/* eslint-disable no-undef */
import axios from "axios"

function getSubdomain() {
  const { host } = window.location
  return host.split(".")[0].split("-pr")[0]
}

const axiosOpts = {
  headers: { "X-Tenant": getSubdomain() },
  withCredentials: true
}

const UNAUTHORIZED = "global:unauthorized"

// Create the preconfigured axios instance, with the base URL of our API
const api = axios.create(axiosOpts)

// Add the Token to requests made with Axios
api.interceptors.request.use(
  (config) => {
    // Make sure the request is sent to our API to avoid leaking the token
    if (!config.headers.Authorization) {
      // eslint-disable-next-line no-undef
      const token = localStorage.getItem("JWT")
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.Authorization = token
      }
    }
    return config
  },
  (error) => Promise.reject(error)
)

// Register a handler for 401 responses from axios
api.interceptors.response.use(
  (response) => response,
  (error) => {
    const { response } = error
    // if 401 is returned, dispatch event to initiate authorization flow
    if (response && response.status === 401) {
      window.dispatchEvent(
        // eslint-disable-next-line no-undef
        new Event(UNAUTHORIZED, { bubbles: true, cancelable: false })
      )
    }
    return Promise.reject(error)
  }
)

export default api
