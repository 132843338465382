import * as actions from "./actions"

export const getEmailTemplates = () => ({
  type: actions.GET_TEMPLATES
})

const ACTION_HANDLERS = {
  [actions.GET_TEMPLATES]: (state) => ({ ...state, loading: true }),
  [actions.GET_TEMPLATES_SUCCESS]: (state, action) => ({
    ...state,
    loading: false,
    success: true,
    templates: action.data
  }),
  [actions.GET_TEMPLATES_FAILED]: (state, action) => ({
    ...state,
    loading: false,
    success: false,
    error: action.error
  })
}

const defaultState = {
  data: {
    emailTemplate: ">>>> select a template <<<<",
    generatedEmails: null,
    selectedTemplate: null,
    templateList: []
  }
}

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[action.type]
  return handler ? handler(state, action) : state
}
