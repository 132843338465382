import dompurify from "dompurify"
import {
  BADGE,
  DEFAULT_TEMPLATE,
  DEFAULT_TEMPLATE_CONFIG
} from "../sagas/configuratorSaga/constants"

export const renderTemplateType = (template_id) => {
  try {
    if (template_id.includes("managers")) {
      return getMangersBadge(template_id)
    }
    return DEFAULT_TEMPLATE_CONFIG[template_id][BADGE]
  } catch {
    return null
  }
}

export const parseTitle = (title, isDefault) => {
  if (!title) return ""
  if (title.includes("Employees") && isDefault) {
    return title.replace("Employees", "Individuals")
  }
  return title
}

/* eslint-disable no-undef */
export const copyToClipboard = (text, cb) => {
  if (!navigator.clipboard) {
    const textArea = document.createElement("textarea")
    textArea.value = text
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      const successful = document.execCommand("copy")
      if (cb) cb(successful)
    } catch (err) {
      if (cb) cb(false)
    }

    document.body.removeChild(textArea)
    return
  }

  navigator.clipboard.writeText(text).then(cb)
}

export const getErrorMessage = (error) => {
  const { response } = error
  if (response && response.data && response.data.detail) {
    return response.data.detail
  }
  let { message } = error

  if (response?.status === 409) {
    message = response.data.message
  } else if (error?.config && error?.config?.url && error?.config?.method) {
    message = `${message} while sending ${error.config.method.toUpperCase()} request to ${
      error.config.url
    }`
  }
  return message
}

export const darkly = () => window && window.ldClient

export const darklyGetFlag = (flag) => {
  const ld = darkly()

  if (!ld) {
    return false
  }

  const allFlags = ld.allFlags()

  return allFlags && allFlags[flag]
}

export const darklyGetVariation = (flag) => {
  const ld = darkly()

  if (!ld) {
    return false
  }

  return ld.variation(flag)
}

export const getLanguagesOptions = () => {
  const languages = darklyGetVariation("pulse-languages") || []
  const result = {}

  languages.forEach((language) => {
    result[language] = language.toUpperCase()
  })

  return result
}

export const getAvailableTemplateTypes = () => {
  try {
    return darklyGetFlag("pulse-available-template-type")
  } catch {
    return ["static", "employees", "managers"]
  }
}

export const getLanguagesOptionsReverted = (languageOption = {}) => {
  const result = {}
  Object.keys(languageOption).forEach((language) => {
    result[language.toUpperCase()] = language
  })
  return result
}

export const changeManagerV2text = () => {
  // getting template-types
  const template_types = getAvailableTemplateTypes()
  const managerType = DEFAULT_TEMPLATE.MANAGERS.split("/")[0]
  const managerV2Type = DEFAULT_TEMPLATE.MANAGERSV2.split("/")[0]

  return !template_types.includes(managerType) && template_types.includes(managerV2Type)
}

/**
 * It will return managers-badge.
 * If mangers type not exist and mangersV2 type exist
 * than it will return managers badge instance of managersV2 badge
 */
export const getMangersBadge = (template_id) => {
  if (changeManagerV2text()) {
    return DEFAULT_TEMPLATE_CONFIG[DEFAULT_TEMPLATE.MANAGERS][BADGE]
  }
  return DEFAULT_TEMPLATE_CONFIG[template_id][BADGE]
}

export const getTemplateId = (tempId = "") => {
  let newTempId = tempId
  if (tempId.includes("managers")) {
    // if manger type not exist and mangerV2 type exist, it will return managers text instance of managersV2 text
    newTempId = changeManagerV2text() ? DEFAULT_TEMPLATE.MANAGERS : tempId
  }
  return newTempId?.split("/")[0]
}

export const setManagerLabel = (templateTypes = []) => {
  const managerV2Type = DEFAULT_TEMPLATE.MANAGERSV2.split("/")[0]
  const managerType = DEFAULT_TEMPLATE.MANAGERS.split("/")[0]

  if (!changeManagerV2text()) return templateTypes

  return templateTypes.map((template) => {
    if (template.template_type === managerV2Type) {
      // replacing mangersV2 label with manager label
      const managerLabel =
        templateTypes.filter((item) => item.template_type === managerType)?.[0]?.label || ""
      return { ...template, label: managerLabel }
    }
    return { ...template }
  })
}
/**
 * It takes a dirty HTML as input and return a clean string
 * free of any svg, svg filters, mathML and HTML
 *
 * @param {string} dirty
 */
export const sanitizeContent = (dirty) =>
  dompurify.sanitize(dirty, {
    USE_PROFILES: {
      svg: false,
      svgFilters: false,
      mathMl: false,
      html: false
    }
  })

export const LANGUAGES_WITH_VALUES = [
  { value: "en_US", label: "English", name: "ENGLISH" },
  { value: "es_ES", label: "Spanish", name: "SPANISH" },
  { value: "fr_FR", label: "French", name: "FRENCH" },
  { value: "de_DE", label: "German", name: "GERMAN" },
  { value: "pt_BR", label: "Portuguese", name: "PORTUGUESE" },
  { value: "it_IT", label: "Italian", name: "ITALIAN" },
  { value: "zh_CN", label: "Chinese", name: "CHINESE" },
  { value: "ja_JP", label: "Japanese", name: "JAPANESE" },
  { value: "zh_Hant", label: "Traditional Chinese", name: "TRADITIONAL_CHINESE"}
]
