import React from "react"
import { SkeletonLoader } from "@elevate_security/elevate-component-library"
import { LoaderWrapper } from "./styles"

function ConfiguratorPageSkeletonLoader() {
  return (
    <LoaderWrapper>
      {Array(15)
        .fill(null)
        .map(() => (
          // eslint-disable-next-line react/jsx-key
          <div>
            <SkeletonLoader height={20} />
            <hr />
          </div>
        ))}
    </LoaderWrapper>
  )
}

export default ConfiguratorPageSkeletonLoader
