/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { FormApi } from "final-form"
import { toObject } from "../../../../../sagas/configuratorSaga/helpers/utils"
import {
  Checkbox,
  CheckboxGroup,
  ImageUpload,
  TextArea,
  Toggle,
  Text as TextControl,
  Dropdown,
  RadioInput,
  Language,
  ToggleGroup,
  TextListener,
  CustomDateSelect,
  UrlGroupModule,
  TrainingUrl,
  Heading
} from "./fields"
import widthForm from "./withForm"
import Informational from "./fields/Informational"

const HiddenInput = styled.input`
  visibility: hidden;
  position: absolute;
`

const HtmlForm = styled.form`
  display: flex;
  flex-wrap: wrap;
`

const Form = ({ formProps, onLoad, group, locked, showErrors }) => {
  const formFields = Array.from(group.get("controls").values()).map(toObject)
  const { form, handleSubmit } = formProps

  useEffect(() => {
    if (form) {
      onLoad(form)
    }
  }, [form])

  return (
    <HtmlForm onSubmit={handleSubmit} id="submit-form">
      <HiddenInput type="submit" id="submit-form" />
      {formFields.map((f) => {
        const field = { ...f, props: { ...f.props, disabled: locked } }
        const {
          type,
          visibility_source: visibilitySource,
          visibility_source_value: visibilitySourceValue
        } = field

        /**
         * visibility_source is used to conditionally render the field in the
         * form.
         * It is used to show/hide certain fields depending upon the value of
         * visibility_source.
         *
         * Field is visible based on the value of visibility_source provided
         * in visibility_source_value
         */
        if (visibilitySource && visibilitySourceValue) {
          const sourceFieldState = form.getFieldState(visibilitySource)
          if (sourceFieldState && sourceFieldState.value !== visibilitySourceValue) {
            field.hidden = true
          }
        }

        switch (type) {
          case "language":
            return <Language key={field.name} form={form} control={field} />
          case "toggle":
            return <Toggle form={form} key={field.name} control={field} />

          case "toggle-group":
            return <ToggleGroup form={form} key={field.name} control={field} />

          case "text":
            return (
              <TextControl key={field.name} form={form} control={field} showErrors={showErrors} />
            )

          case "textarea":
            return <TextArea key={field.name} form={form} control={field} showErrors={showErrors} />
          case "checkbox":
            return <Checkbox key={field.name} form={form} control={field} />

          case "checkbox-group":
            return <CheckboxGroup key={field.name} form={form} control={field} />

          case "radio":
            return <RadioInput key={field.name} form={form} control={field} orientation="column" />

          case "upload-img":
            return <ImageUpload key={field.name} form={form} control={field} />

          case "dropdown":
            return <Dropdown key={field.name} form={form} control={field} />

          case "date":
            return <CustomDateSelect key={field.name} form={form} control={field} />

          case "text-listener":
            return <TextListener key={field.name} form={form} control={field} />

          case "informational":
            return <Informational key={field.name} form={form} control={field} />
          case "url-group-module":
            return <UrlGroupModule key={field.name} form={form} control={field} />
          case "training-url":
            return <TrainingUrl key={field.name} form={form} control={field} />
          case "heading":
            return <Heading key={field.name} form={form} control={field} />

          default:
            return null
        }
      })}
    </HtmlForm>
  )
}

Form.propTypes = {
  group: PropTypes.instanceOf(Map).isRequired,
  formProps: PropTypes.shape({
    form: PropTypes.instanceOf(FormApi).isRequired,
    handleSubmit: PropTypes.func.isRequired
  }).isRequired,
  locked: PropTypes.bool,
  onLoad: PropTypes.func,
  showErrors: PropTypes.bool
}

Form.defaultProps = {
  locked: false,
  onLoad: () => null,
  showErrors: false
}

export default widthForm(Form)
