import * as actions from "./actions"

export const init = () => ({
  type: actions.INIT,
  payload: {}
})

const ACTION_HANDLERS = {
  [actions.SET_STATE]: (state, action) => ({ ...state, ...action.payload }),
  [actions.INIT]: (state, action) => ({ ...state, ...action.payload })
}

const defaultState = { loading: true }

export const reducer = (state = defaultState, action) => {
  const handler = ACTION_HANDLERS[actions.type]
  return handler ? handler(state, action) : state
}
