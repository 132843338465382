/* eslint-disable import/prefer-default-export */
import PropTypes from "prop-types"

export const ControlPropsDefinition = {
  index: PropTypes.number,
  name: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  separator: PropTypes.bool,
  language_source: PropTypes.string,
  constrains: PropTypes.arrayOf(PropTypes.string),
  suppressFeedback: PropTypes.bool,
  helpText: PropTypes.string,
  defaultValue: PropTypes.instanceOf(Object),
  example: PropTypes.shape({
    title: PropTypes.string,
    url: PropTypes.string,
    description: PropTypes.string
  }),
  props: PropTypes.shape({
    required: PropTypes.bool
  })
}

export const ControlProps = PropTypes.shape(ControlPropsDefinition)

export const ControlBoolProps = PropTypes.shape({
  ...ControlPropsDefinition,
  value: PropTypes.bool
})
