/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import * as d3 from "d3"
import moment from "moment"
/* eslint-disable max-len */

/** *
 * Define the area for the graph based on the parent block, if the dimensions of the parent changes,
 * The graph area should change as well, keeping the responsiveness
 ** */

export function createGraphArea({ graphRef }) {
  const element = graphRef.current // getting the current svg `<svg ref={graphRef} />`

  const parentNode = element.parentElement // getting the parent which will define size

  // getting the dimensions with padding
  // eslint-disable-next-line no-undef
  const styles = window.getComputedStyle(parentNode)
  const padding = parseFloat(styles.paddingLeft) + parseFloat(styles.paddingRight)

  const parentWidth = parentNode.clientWidth - padding || 500 // getting the  minimal of width for the graph
  const parentHeight = 310 // define the minimal height

  // offsets for the graph area
  const offsetLeft = 40
  const offsetTop = 20

  const svgWidth = parentWidth - offsetLeft
  const svgHeight = parentHeight - offsetTop

  // create container
  const svgContainer = d3.select(element).attr("width", "100%").attr("height", parentHeight)

  // create the graph area
  const graphArea = svgContainer.append("g").attr("width", "100%").attr("height", "100%")
  return {
    graphArea,
    svgContainer,
    svgWidth,
    svgHeight,
    offsetLeft,
    offsetTop
  }
}

/** **
 * With the container and area defined,we can create the XAxis and YAxis
 */
export function createGraphAxis({
  dataXAxis,
  graphArea,
  svgWidth,
  svgHeight,
  offsetTop,
  offsetLeft,
  maxYAxis,
  minYAxis
}) {
  // First, define the scales

  const xScale = d3.scaleBand().domain(dataXAxis).rangeRound([0, svgWidth])

  const yScale = d3
    .scaleLinear()
    .domain([minYAxis, maxYAxis])
    .rangeRound([svgHeight - offsetTop, 0])

  // Second, define the AXIS
  const xAxis = d3.axisBottom(xScale).tickSize(0).tickPadding(15).tickSizeOuter(0).scale(xScale)

  const yAxis = d3
    .axisLeft(yScale)
    .tickSize(0)
    .tickSizeOuter(0)
    .tickPadding(15)
    .ticks(6)
    .scale(yScale)

  // Third, render the axis
  const xAxisRender = graphArea
    .append("g")
    .style("font-size", "14px")
    .style("line-height", "24px")
    .style("cursor", "pointer")
    .attr("transform", `translate(${offsetLeft},${svgHeight - offsetTop / 2})`)
  xAxisRender.call(xAxis).selectAll("path").attr("fill", "none").attr("stroke", "none")

  const yAxisRender = graphArea
    .append("g")
    .style("font-size", "14px")
    .style("line-height", "24px")
    .attr("transform", `translate(${offsetLeft},${offsetTop * 0.5})`)
    .style("cursor", "pointer")
    .call(yAxis)
    .selectAll("path")
    .attr("fill", "none")
    .attr("stroke", "none")

  return {
    yAxis,
    xAxis,
    xScale,
    yScale,
    xAxisRender,
    yAxisRender
  }
}

/** **
 * For this graph, theres only grids in the horizontal
 */
export function createGraphHorizontalGrids({ graphArea, svgWidth, yScale, offsetLeft, offsetTop }) {
  const grid = graphArea
    .append("g")
    .attr("class", "grid")
    .attr("transform", `translate(${offsetLeft},${offsetTop / 2})`)
    .call(
      d3
        .axisLeft(yScale)
        .tickSize(0)
        .tickSizeOuter(0)
        .tickSizeInner(-svgWidth)
        .ticks(4)
        .tickFormat("")
    )
  grid.selectAll("path").attr("fill", "none").attr("stroke", "none")
  grid.selectAll("line").attr("stroke-width", "1").attr("stroke", "#C9CFD6").attr("fill", "#C9CFD6")
  return { grid }
}

export function createLine({
  dataLine,
  graphArea,
  xAxisRender,
  xScale,
  onMouseEnter,
  yScale,
  color = "green",
  opacity = 0.7,
  offsetTop,
  hasDot = false,
  hideDotes,
  barType
}) {
  const valueLine = d3
    .line()
    .x(({ xValue }) => xScale(xValue))
    .y(({ yValue }) => yScale(yValue))
  const line = graphArea.append("path")
  const translateX =
    parseFloat(
      xAxisRender
        .selectAll(".tick")
        .nodes()[0]
        .getAttribute("transform")
        .match(/translate\(([0-9.]*)/)[1]
    ) + 35

  line
    .data([dataLine])
    .attr("class", "line")
    .style("cursor", "pointer")
    .attr("fill", "none")
    .attr("stroke", color)
    .on("mouseenter", function () {
      d3.select(this).style("opacity", 1)
    })
    .on("mouseleave", function () {
      d3.select(this).style("opacity", opacity)
    })
    .attr("stroke-width", "2")
    .attr("transform", `translate(${translateX},${offsetTop / 2 - 1} )`)
    .attr("d", valueLine)
    .on("click", () => {
      hideDotes()
    })

  if (hasDot) {
    graphArea
      .selectAll("dot")
      .data(dataLine)
      .enter()
      .append("circle")
      .attr("fill", "white")
      .attr("stroke", color)
      .attr("stroke-width", "2")
      .style("cursor", "pointer")
      .attr("transform", `translate(${translateX},${offsetTop / 2 - 1})`)
      .attr("r", 6)
      .attr("cx", ({ xValue }) => xScale(xValue))
      .attr("cy", ({ yValue }) => yScale(yValue))
      .attr("id", ({ date }) => `b${date}`)
      .on("mouseenter", (obj) => {
        onMouseEnter({
          score: obj.target.__data__.yValue,
          date: obj.target.__data__.date,
          elementId: `b${obj.target.__data__.date}`,
          barType
        })
      })
  }
}
function formatDate(date, format = "M/D") {
  return moment(date).format(format)
}
function getLatertStat(stats, pulseDays) {
  const laterClickedStats = {}
  const laterOpenedStats = {}
  const statsData = { opened: [], clicked: [] }
  if (!stats || !Object.keys(stats).length) return []

  Object.getOwnPropertyNames(stats)
    .reverse()
    .forEach((key, index) => {
      if (pulseDays === 1 ? index < 14 : index < 7) {
        laterOpenedStats[key] = stats[key].Opened
        laterClickedStats[key] = stats[key].Clicked
      }
    })

  Object.entries(laterOpenedStats).forEach(([key, value], index) => {
    statsData.opened[index] = {
      xValue: formatDate(key),
      yValue: value,
      date: key
    }
  })

  Object.entries(laterClickedStats).forEach(([key, value], index) => {
    statsData.clicked[index] = {
      xValue: formatDate(key),
      yValue: value,
      date: key
    }
  })
  return statsData
}

function getLaterStatsMaxScore(stats, pulseDays) {
  const maxClickScore = {}
  const maxOpenScore = {}

  Object.getOwnPropertyNames(stats)
    .reverse()
    .forEach((key, index) => {
      if (pulseDays === 1 ? index < 14 : index < 7) {
        maxClickScore[key] = stats[key].Opened
        maxOpenScore[key] = stats[key].Clicked
      }
    })
  const totalScore = Object.values(maxClickScore).concat(Object.values(maxOpenScore))
  const maxScore = Math.max(...totalScore)
  return Math.ceil((maxScore + 1) / 10) * 10
}

function getLaterStatsDates(stats, pulseDays) {
  const recentDates = {}
  if (!stats || !Object.keys(stats).length) return []

  Object.getOwnPropertyNames(stats)
    .reverse()
    .forEach((key, index) => {
      if (pulseDays === 1 ? index < 14 : index < 7) {
        recentDates[formatDate(key)] = stats[key]
      }
    })
  const dates = Object.keys(recentDates)
  return dates
}

export function buildGraph({
  graphRef,
  historicalStats,
  pulseDays,
  dataLines = getLatertStat(historicalStats, pulseDays),
  dataXAxis = getLaterStatsDates(historicalStats, pulseDays),
  maxYAxis = getLaterStatsMaxScore(historicalStats, pulseDays),
  minYAxis = 0,
  onMouseEnter,
  barType,
  setBarType,
  hideOpened,
  hideClicked
}) {
  if (!graphRef || !graphRef.current) return
  graphRef.current.innerHTML = ""

  const { graphArea, svgWidth, svgHeight, offsetTop, offsetLeft } = createGraphArea({ graphRef })
  const { yScale, xScale, xAxisRender } = createGraphAxis({
    dataXAxis,
    graphArea,
    svgWidth,
    svgHeight,
    offsetTop,
    offsetLeft,
    maxYAxis,
    minYAxis
  })

  createGraphHorizontalGrids({
    graphArea,
    svgWidth,
    yScale,
    offsetLeft,
    offsetTop
  })

  createLine({
    dataLine: hideOpened ? [] : dataLines.opened,
    graphArea,
    xAxisRender,
    xScale,
    yScale,
    offsetTop,
    onMouseEnter,
    hasDot: barType === "opened",
    hideDotes: () => {
      setBarType("opened")
    },
    barType
  })

  createLine({
    dataLine: hideClicked ? [] : dataLines.clicked,
    graphArea,
    xAxisRender,
    xScale,
    yScale,
    offsetTop,
    hasDot: barType === "clicked",
    color: "#668CE5",
    opacify: 0.3,
    onMouseEnter,
    hideDotes: () => {
      setBarType("clicked")
    },
    barType
  })
}
